import * as t from 'io-ts'
import { TransactionType } from '../TransactionType'
import { Maybe } from '@mxt/zio/codec'
import { SourceType } from '../SourceType'

const Base = <C extends t.Mixed>(codec: C) =>
  t.intersection([
    t.type({
      transactionType: t.literal(TransactionType.VOICE_RECORDING)
    }),
    codec
  ])

const payloadPulse4Ops = t.type({
  body: t.type({
    proposalNumber: Maybe(t.string),
    poType: Maybe(t.string),
    poPhoneNumber: Maybe(t.string),
    poFullName: Maybe(t.string),
    productCode: Maybe(t.string),
    productName: Maybe(t.string),
    agentCode: Maybe(t.string),
    channel: Maybe(t.string),
    bankCode: Maybe(t.string),
    bankName: Maybe(t.string),
    idNumber: Maybe(t.string),
    sumAssured: Maybe(t.string),
    premiumAmount: Maybe(t.string),
    premiumFrequency: Maybe(t.string),
    planedPremiumTerm: Maybe(t.string),
    clientId: Maybe(t.string),
    dob: Maybe(t.string),
    name: Maybe(t.string),
    nationality: Maybe(t.string),
    sex: Maybe(t.string),
    attributes: Maybe(
      t.type({
        LOCATION_CODE: Maybe(t.string),
        LOCATION_DESC: Maybe(t.string),
        NATIONAL_ID_ISSUE_DATE: Maybe(t.string)
      })
    ),
    externalIds: Maybe(
      t.type({
        NATIONAL_ID: Maybe(t.string)
      })
    ),
    attributesExt: Maybe(
      t.type({
        issuedDate: Maybe(t.string),
        issuedBy: Maybe(t.string),
        idType: Maybe(t.string),
        idNumber: Maybe(t.string),
        MOBILE_CODE: Maybe(t.string),
        COMPANY_CODE: Maybe(t.string),
        HOME_PHONE_NUMBER: Maybe(t.string),
        HOME_CODE: Maybe(t.string),
        BIRTH_PLACE: Maybe(t.string),
        BIRTH_COUNTRY: Maybe(t.string),
        CLIENT_TYPE: Maybe(t.string),
        ID_DATE: Maybe(t.string),
        CATEGORY: Maybe(t.string),
        NATIONALITY_2: Maybe(t.string),
        FORMAT_NAME: Maybe(t.string),
        EMPLOYEE_ID: Maybe(t.string),
        FATCA_TAX_NUMBER: Maybe(t.string),
        FATCA_TAX_DECLARE: Maybe(t.string),
        FATCA_SIGNED_DATE: Maybe(t.string),
        FATCA_EXPIRY_DATE: Maybe(t.string),
        FOREIGN_COUNTRY: Maybe(t.string),
        FOREIGN_STREET: Maybe(t.string),
        FOREIGN_WARD: Maybe(t.string),
        FOREIGN_DISTRICT: Maybe(t.string),
        FOREIGN_CITY: Maybe(t.string),
        COMPANY_NAME: Maybe(t.string),
        COMPANY_PHONE_NUMBER: Maybe(t.string),
        COMPANY_STREET: Maybe(t.string),
        COMPANY_WARD: Maybe(t.string),
        COMPANY_DISTRICT: Maybe(t.string),
        COMPANY_CITY: Maybe(t.string),
        OCCUPATION_GROUP: Maybe(t.string),
        OCCUPATION_DESC: Maybe(t.string),
        FAX: Maybe(t.string)
      })
    ),
    createdDate: Maybe(t.string),
    majorType: Maybe(t.string),
    optionalRequest: Maybe(t.string),
    policyNumber: Maybe(t.string),
    policyStatus: Maybe(t.string),
    officeCode: Maybe(t.string),
    recordDate: Maybe(t.string),
    recordDuration: Maybe(t.string),
    voiceCheckRuleAIResult: Maybe(t.string),
    autoVerification: Maybe(t.string),
    scriptType: Maybe(t.string),
    supplementInfoRequestDate: Maybe(t.string),
    caseSubmittedDate: Maybe(t.string),
    verificationDecision: Maybe(t.string),
    poRole: Maybe(t.string),
    agentName: Maybe(t.string),
    laType: Maybe(t.string),
    blackListAgent: Maybe(t.boolean),
    recordDocument: Maybe(
      t.type({
        azureUrl: Maybe(t.string),
        container:Maybe(t.string),
        fileSize: Maybe(t.string),
        totalRecordTime: Maybe(t.string),
        recordStartDate: Maybe(t.string),
        metadata: Maybe(
          t.array(
            t.type({
              scriptId: Maybe(t.string),
              timeStart: Maybe(t.string),
              timeEnd: Maybe(t.string),
              azureUrl: Maybe(t.string),
              container: Maybe(t.string),
              fileSize: Maybe(t.string),
              totalRecordTime: Maybe(t.string)
            })
          )
        )
      })
    )
  })
})

const PRUDAILY = Base(
  t.type({
    source: t.literal(SourceType.PRUDAILY),
    payload: payloadPulse4Ops,
    policyStatus: Maybe(t.string),
    saleChannel: Maybe(t.string),
    officeCode: Maybe(t.string),
    recordDate: Maybe(t.string),
    recordDuration: Maybe(t.string),
    voiceCheckRuleAIResult: Maybe(t.string),
    autoVerification: Maybe(t.string),
    scriptType: Maybe(t.string),
    supplementInfoRequestDate: Maybe(t.string),
    caseSubmittedDate: Maybe(t.string),
    verificationDecision: Maybe(t.string),
    voiceRecordingType: Maybe(t.string),
    firstSuspendDate: Maybe(t.string),
    blackListAgent: Maybe(t.boolean),
  })
)

export const VoiceRecordingModel = PRUDAILY
export type VoiceRecordingModel = t.TypeOf<typeof VoiceRecordingModel>
