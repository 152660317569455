import { refCache, RefSessionStorage } from '@pulseops/common'
import * as t from 'io-ts'
import * as O from 'fp-ts/Option'
import { date as dateCodec, option } from 'io-ts-types'
import { Lens } from 'monocle-ts'
import { flow, pipe } from 'fp-ts/lib/function'

export namespace InquiryComplaintState {
  const Canbe = <C extends t.Mixed>(codec: C) => t.union([codec, t.undefined])

  export const COMPLAINTMISMONEYDETAILS = t.type({
    hasReciept: t.string,
    recieptType: t.string,
    recieptNum: t.string,
    complaintAmount: t.number,
    misPremiumDate: t.string,
    evidenceStatus: Canbe(t.string), // update payload fields
    verifiedAmount: Canbe(t.number) // update payload fields
  })

  export const COMPLAINTSALEDETAILS = t.type({
    agentCode: t.string,
    agentName: t.string,
    financeNeedCode: t.string,
    isPoSignedInProposal: t.boolean,
    isCustomerReceiveKit: t.boolean,
    customerReceiveDate: t.string,
    evidence: Canbe(t.boolean) // update payload fields
  })

  export const COMPLAINTGADETAILS = t.type({
    generalAgency: t.string,
    personComplaint: t.string,
    role: Canbe(t.string), // update payload fields
    verifiedAmount: Canbe(t.number), // update payload fields
    evidenceStatus: Canbe(t.string) // update payload fields
  })

  export const CONSERVATIONINFO = t.type({
    product: t.string,
    servicingAgentCode: t.string,
    servicingAgentName: t.string,
    conservationGAStaff: t.string,
    conservationAgentCode: t.string,
    conservationAgentName: t.string,
    reasonFullSurrender: t.string,
    conservationResult: t.string,
    conservationSolution: t.string,
    conservationBonus: t.string,
    conservationNote: t.string
  })

  export const CloudData = t.type({
    tag: t.literal('Cloud'),
    fileName: t.string,
    fileExtension: t.string,
    size: t.number,
    base64: Canbe(t.string),
    uploadedDate: dateCodec,
    url: t.string,
    blobInfo: t.string,
    storageConfig: t.type({
      sas: t.string
    })
  })

  const VeriAdditionalDocuments = t.type({
    transactionType: t.string,
    attachmentList: t.array(CloudData)
  })

  export const SubmitData = t.type({
    CATEGORY_CODE: t.string,
    GROUP_CODE: t.string,
    SUB_SVC_TYPE_CODE: t.string,
    SUB_TRANS_TYPE_CODE: t.string,
    REQUEST_WITH_ROLE: t.string,
    REQUESTER_FULL_NAME: t.string,
    REQUESTER_NATIONAL_ID: t.string,
    TOUCH_POINT: t.string,
    RLA_WITH_PO: t.string,
    METHOD: t.string,
    SOURCE: t.string,
    HOT: t.boolean,
    FCR: t.boolean,
    CUSTOMER_CMT: t.string,
    RES_TO_CUSTOMER: t.string,
    TOTAL_MIS_MONEY_AMT: t.number,
    TOTAL_VERIFIED_AMOUNT: Canbe(t.number),
    COMPLAINT_MIS_MONEY_DETAILS: t.array(COMPLAINTMISMONEYDETAILS),
    COMPLAINT_SALE_DETAILS: t.array(COMPLAINTSALEDETAILS),
    COMPLAINT_GA_DETAILS: t.array(COMPLAINTGADETAILS),
    CONSERVATION_INFO: Canbe(t.union([CONSERVATIONINFO, t.type({})])),
    CLOSED_INFO: Canbe(t.string)
  })

  const VeriPayloadData = t.type({
    source: t.string,
    createdBy: t.string,
    documents: Canbe(
      t.array(
        t.type({
          name: t.string,
          url: t.string
        })
      )
    ),
    primaryPolicyNo: t.string,
    clientId: t.string,
    officeCode: t.string,
    createdDate: t.string,
    body: t.type({
      attributesExt: SubmitData,
      auditDetail: t.type({
        createTime: t.string
      })
    }),
    additionalDocuments: Canbe(t.array(VeriAdditionalDocuments))
  })

  export const CodeC = t.type(
    {
      savedPayoutData: option(VeriPayloadData)
    },
    'InquiryComplaintState'
  )

  export type InquiryComplaintStateType = t.TypeOf<typeof CodeC>

  const defaultValue: InquiryComplaintStateType = {
    savedPayoutData: O.none
  }

  const ref = RefSessionStorage.make(CodeC, defaultValue)

  export const cache = refCache(ref)

  export const lens = {
    savedPayoutData: Lens.fromProp<InquiryComplaintStateType>()('savedPayoutData')
  }

  export const savedPayoutData = {
    getOption: pipe(flow(lens.savedPayoutData.get, O.toNullable), ref.select),
    set: (value: VeriPayloadData) => pipe(O.some(value), lens.savedPayoutData.set, ref.update),
    watch: pipe(flow(lens.savedPayoutData.get, O.toNullable), ref.watch),
    reset: () => ref.set(defaultValue)
  }

  export type VeriPayloadData = t.TypeOf<typeof VeriPayloadData>
}
