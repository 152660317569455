import React from 'react'
import { ScrollView, StyleSheet, Text, TouchableOpacity, View } from 'react-native'
import { OBButton, OBFormat, OBSectionCol, OBSectionRow, OBSharedStyles, OBTitle } from '../../ob-common'
import { useTranslation } from 'react-i18next'
import { OBCallOutPopupConst } from './OBCallOutPopupConst'
import {
  AppContext,
  AuthService,
  CustomedDateTimePicker,
  DatePicker,
  ErrorHandling,
  Input,
  Permission,
  PulseOpsFormat,
  SelectSearch,
  assets
} from '@pulseops/common'
import { pipe } from 'fp-ts/lib/function'
import { OBCallOutPopupService, OBCallProgramTypeCode, OBGeneralService, OBPolicyData } from '../../ob-service'
import { ZIO } from '@mxt/zio'
import { Controller, useWatch } from 'react-hook-form'
import { OBCallingRecordForm } from './OBCallingRecordForm'
import { CallOutDetailContext } from '../ob-common'
import { useLoading } from '@mxt/zio-react'
import * as O from 'fp-ts/lib/Option'
import moment from 'moment'
import _, { isNil } from 'lodash'
import { OBCallIndicatorPopup } from './OBCallIndicatorPopup'
import { useIsFocused } from '@react-navigation/native'
import { calloutPopupContext } from './OBCallOutPopup'

enum GuideLineDocumentZNS {
  REMINDER = 'REMINDER',
  LAPSE = 'LAPSE'
}

enum TypePromotion {
  PRE_REMINDER = 'PRE-REMINDER',
  LAPSE_REIN = 'LAPSE-REIN'
}

export const OBCallingRecord = () => {
  const { t, i18n } = useTranslation()
  const [isLoading, bindLoader] = useLoading(false)
  const { showToast, showGlobalLoading } = React.useContext(AppContext.AppContextInstance)
  const {
    caseId,
    policyNumber,
    processInstanceId,
    callProgram,
    assignee,
    teamLeader,
    channel,
    isSavedCalloutPopupData,
    setIsSavedCalloutPopupData,
    callingRecordForm,
    setDisabledSubmit,
    isDisableSubmit,
    aging,
    permissions,
    isSendComunication,
    setIsSendComunication,
    isOpenedAvayaCallBlock,
    avayaCallID,
    avayaCallingPhoneNumber,
    avayaCallDate,
    setAvayaCallID,
    setAvayaCallDate,
    setIsSavedAfterCallingAvaya,
    transactionType,
    phoneType,
    setPhoneType,
    executionTime,
    clientNumber,
    isUpdatedChangeContactInfo,
    setIsUpdatedChangeContactInfo,
    setIsDisableSuspend,
    isDisableSuspend,
    promotionFlag
  } = React.useContext(CallOutDetailContext)
  const [isShowCallbackTime, setShowCallbackTime] = React.useState(false)
  const isDisabledEdit = !permissions.includes(Permission['EditCallOutOBTaskDetail'])
  const intialValueForm = callingRecordForm?.control._defaultValues
  const [callingResultArr, setCallingResultArr] = React.useState<
    Array<{ nameVi: string; nameEn: string; code: string }>
  >([])
  const [A7s3ConfigInfo, setA7s3ConfigInfo] = React.useState<OBCallOutPopupService.A7s3Config>()

  const isFocused = useIsFocused()
  const [isOpenedCallIndicatorPopup, setIsOpenedCallIndicatorPopup] = React.useState<boolean>(false)
  // const {isSavedChangeContactInfoPopup, setIsSavedChangeContactInfoPopup} = React.useContext(calloutPopupContext)
  const checkDisableSubmitButton = (agingActionDate: number) => {
    if (aging !== undefined && agingActionDate <= aging && isDisableSubmit) setDisabledSubmit(false)
  }

  const userInfo = pipe(
    AuthService.userInfo,
    ZIO.map((userData) => userData),
    ErrorHandling.runDidMount()
  )

  const policyInfo: OBPolicyData | null = pipe(
    OBGeneralService.getPolicyInfo(policyNumber, caseId),
    ZIO.map((data) => data),
    ErrorHandling.runDidMount()
  )
  // const callReceiverList = pipe(OBCallOutPopupService.getCallReceiverList(), ErrorHandling.runDidMount()) || []

  const { callScriptList, guidelineDocumentArr, doNotCallList, callingMethodArr, guidelineZNSArr } = pipe(
    ZIO.zipPar(
      OBCallOutPopupService.getCallScriptList(),
      OBCallOutPopupService.getGuidelineDocumentList(transactionType || ''),
      OBCallOutPopupService.getDoNotCallList(),
      OBCallOutPopupService.getCallingMethodList(),
      OBCallOutPopupService.getGuidelineDocumentList('COMMON_OB_ZNS' || '')
    ),
    ZIO.map(([callArr, guidelineDocumentArr, doNotCallList, callingMethodArr, guidelineZNSArr]) => {
      const customCallScripts = callArr.filter((x) => x.transactionTypeWF === transactionType)
      return {
        callScriptList: customCallScripts,
        guidelineDocumentArr: guidelineDocumentArr,
        doNotCallList,
        callingMethodArr,
        guidelineZNSArr
      }
    }),
    bindLoader,
    ErrorHandling.runDidMount()
  ) || {
    callScriptList: [],
    guidelineDocumentArr: [],
    doNotCallList: [],
    callingMethodArr: [],
    guidelineZNSArr: []
  }

  const { orginCallingResultList, callReceiverList } = pipe(
    ZIO.zipPar(
      OBCallOutPopupService.getCallingResultList1(),
      OBCallOutPopupService.getA7s7ConfigList(transactionType || ''),
      OBCallOutPopupService.getA7s3ConfigList(''),
      OBCallOutPopupService.getCallReceiverList()
    ),
    ZIO.map(([callingData, A7s7ConfigList, A7s3ConfigList, callReceiverArr]) => {
      const A7S3 = pipe(
        A7s3ConfigList.find((x) => x.functionName.includes('Call Back')),
        O.fromNullable,
        O.map((item) => {
          return item
        }),
        O.getOrElse(() => ({
          functionCode: '',
          functionName: '',
          functionDescription: ''
        }))
      )
      const callReceiverList = callReceiverArr.filter((x) => A7s7ConfigList.some((p) => p.callReceiver === x.code))
      setA7s3ConfigInfo(A7S3)
      // const callingList = callingData.filter((x) => A7s7ConfigList.some((p) => p.callOutCode === x.code))
      // const callingList = callingData
      return {
        orginCallingResultList: callingData,
        callReceiverList: callReceiverList
      }
    }),
    ErrorHandling.runDidMount()
  ) || {
    orginCallingResultList: [],
    callReceiverList: []
  }
  const checkChangeForm = useWatch({ control: callingRecordForm?.control })

  React.useEffect(() => {
    showGlobalLoading(isLoading)
  }, [isLoading])

  const getNewCallingMethodByCode = (code: string) => {
    return pipe(
      OBCallOutPopupConst.CallingMethodConst.find((x) => x.code === code),
      O.fromNullable,
      O.map((item) => ({ label: i18n.language === 'en' ? item.nameEn : item.nameVi, value: item.code })),
      O.getOrElse(() => ({ label: 'None', value: 'CM01' }))
    )
  }

  React.useEffect(() => {
    if (!_.isEqual(checkChangeForm, intialValueForm) && isSavedCalloutPopupData) {
      setIsSavedCalloutPopupData && setIsSavedCalloutPopupData(false)
    }
  }, [checkChangeForm])

  React.useEffect(() => {
    setDefaultValueForCallScript()
  }, [channel, callScriptList])

  const setDefaultValueForCallScript = () => {
    if (callScriptList && callScriptList.length > 0) {
      switch (channel) {
        case 'AGENCY': {
          const callScriptItem = pipe(
            callScriptList.find(
              (x) => (x.saleChannelCode === 'AGENCY' || x.saleChannelCode === 'ALL') && x.isDefaultOfCallScript
            ),
            O.fromNullable,
            O.map((item) => ({ label: item.description, value: item.code })),
            O.getOrElse(() => ({ label: '', value: '' }))
          )
          callingRecordForm && callingRecordForm.setValue('callScript', callScriptItem)
          if (intialValueForm) intialValueForm.callScript = callScriptItem
          break
        }
        case 'BANCA': {
          const callScriptItem = pipe(
            callScriptList.find(
              (x) => (x.saleChannelCode === 'BANCA' || x.saleChannelCode === 'ALL') && x.isDefaultOfCallScript
            ),
            O.fromNullable,
            O.map((item) => ({ label: item.description, value: item.code })),
            O.getOrElse(() => ({ label: '', value: '' }))
          )
          callingRecordForm && callingRecordForm.setValue('callScript', callScriptItem)
          if (intialValueForm) intialValueForm.callScript = callScriptItem
          break
        }
      }
    }
  }

  React.useEffect(() => {
    if (!isDisabledEdit) {
      switch (phoneType) {
        case 'M': {
          const newData = getNewCallingMethodByCode('CM02')
          callingRecordForm && callingRecordForm.setValue('callingMethod', newData)
          break
        }
        case 'H': {
          const newData = getNewCallingMethodByCode('CM03')
          callingRecordForm && callingRecordForm.setValue('callingMethod', newData)
          break
        }
        case 'C': {
          const newData = getNewCallingMethodByCode('CM04')
          callingRecordForm && callingRecordForm.setValue('callingMethod', newData)
          break
        }
        case 'O': {
          const newData = getNewCallingMethodByCode('CM05')
          callingRecordForm && callingRecordForm.setValue('callingMethod', newData)
          break
        }
        default: {
          callingRecordForm && callingRecordForm.setValue('callingMethod', { label: 'None', value: 'CM01' })
        }
      }
    }
  }, [phoneType])

  React.useEffect(() => {
    return () => {
      setIsOpenedCallIndicatorPopup(false)
    }
  }, [isFocused])

  // React.useEffect(() => {
  //   callingRecordForm && callingRecordForm.setValue('callingResult', undefined)
  //   checkHasCallBackTimeToRequire('')
  // }, [callingRecordForm?.watch('callReceiver')])

  const resetForm = () => {
    callingRecordForm && callingRecordForm.reset(OBCallingRecordForm.defaultFormValues)
    setPhoneType('')
    setDefaultValueForCallScript()
    setAvayaCallID && setAvayaCallID('')
    setAvayaCallDate && setAvayaCallDate(undefined)
  }

  const getOptions = (dataList: any[]) => {
    return dataList.map((item) => ({ label: i18n.language === 'en' ? item.nameEn : item.nameVi, value: item.code }))
  }

  // const getCallResultOption = () => {
  //   const receiverValue = callingRecordForm && callingRecordForm.watch('callReceiver')?.value
  //   if (receiverValue) {
  //     switch (receiverValue) {
  //       case 'PO':
  //         const listPO = callingResultList.filter((item) => item.isPOCallReceiverAccepted)
  //         return getOptions(listPO)
  //       case 'NON_PO':
  //         const listNONPO = callingResultList.filter((item) => item.isNonPOCallReceiverAccepted)
  //         return getOptions(listNONPO)
  //       case 'RELATIVES':
  //         const listRELATIVES = callingResultList.filter((item) => item.isRelativeCallReceiverAccepted)
  //         return getOptions(listRELATIVES)
  //       case 'NA':
  //         const listNA = callingResultList.filter((item) => item.isNACallReceiverAccepted)
  //         return getOptions(listNA)
  //       default:
  //         return []
  //     }
  //   }
  //   return []
  // }

  const onChangeCallReceiverEvent = (callReceiverCode: string) => {
    callingRecordForm && callingRecordForm.setValue('callingResult', undefined)
    checkHasCallBackTimeToRequire('')
    pipe(
      OBCallOutPopupService.getA7s8ConfigList(callReceiverCode),
      ZIO.map((responseData) => {
        let customedCallReceiverList: Array<{
          nameVi: string
          nameEn: string
          code: string
        }> = []
        switch (transactionType) {
          case OBCallProgramTypeCode.IDC:
            customedCallReceiverList = orginCallingResultList
              .filter((x) =>
                responseData.some(
                  (p) =>
                    p.callOutCode === x.code &&
                    p.transactionTypeWF === transactionType &&
                    p.callReceiver === callReceiverCode
                )
              )
              .filter((p) => Number(executionTime) < 2 || (Number(executionTime) >= 2 && p.code !== 'A14'))
            break
          default:
            customedCallReceiverList = orginCallingResultList.filter((x) =>
              responseData.some(
                (p) =>
                  p.callOutCode === x.code &&
                  p.transactionTypeWF === transactionType &&
                  p.callReceiver === callReceiverCode
              )
            )
            break
        }
        setCallingResultArr(customedCallReceiverList)
        return responseData
      }),
      bindLoader,
      ZIO.unsafeRun({})
    )
  }

  const getCallScriptOptions = (dataList: OBCallOutPopupService.CallScriptInfo[]) => {
    const dataLoad = dataList.filter((item) => item.saleChannelCode === channel || item.saleChannelCode === 'ALL')
    return dataLoad.map((item) => ({
      label: i18n.language === 'en' ? item.description : item.description,
      value: item.code
    }))
  }

  const getGuideDocumentOptions = (dataList: OBCallOutPopupService.GuideDocumentInfo[]) => {
    return dataList.map((item) => ({
      label: i18n.language === 'en' ? item.pushCodeName : item.pushCodeName,
      value: item.pushCode
    }))
  }

  const getGuideDocumentZNSOptions = (dataList: OBCallOutPopupService.GuideDocumentInfo[]) => {
    const filterData = () => {
      const hasPromotion = promotionFlag?.hasPromotion
      const typePromotion = promotionFlag?.typePromotion
      const premiumRein = parseInt(promotionFlag?.premiumRein || '0')
      const filterDataByPromotion = dataList.filter((x) =>
        hasPromotion ? x.pushCode.includes('PROMOTION') : !x.pushCode.includes('PROMOTION')
      )

      if (!hasPromotion) {
        switch (policyInfo?.statusCode) {
          case 'IF':
            return filterDataByPromotion.filter((x) => x.pushCode.includes(GuideLineDocumentZNS.REMINDER))
          case 'LA':
            return filterDataByPromotion.filter((x) => x.pushCode.includes(GuideLineDocumentZNS.LAPSE))
          default:
            return []
        }
      } else if (typePromotion === TypePromotion.PRE_REMINDER) {
        return filterDataByPromotion.filter((x) => x.pushCode.includes(GuideLineDocumentZNS.REMINDER))
      } else if (typePromotion === TypePromotion.LAPSE_REIN) {
        return filterDataByPromotion.filter((x) => x.pushCode.includes(GuideLineDocumentZNS.LAPSE))
      } else {
        return []
      }
    }

    return filterData().map((item) => ({
      label: i18n.language === 'en' ? item.pushCodeName : item.pushCodeName,
      value: item.pushCode
    }))
  }

  const getDoNotCallOption = (dataList: OBCallOutPopupService.DoNotCallInfo[]) => {
    let dataItems = dataList.map((item) => ({
      label: i18n.language === 'vi' ? item.nameVi : item.nameEn,
      value: item.code
    }))
    // dataItems = [
    //   { label: 'ALL', value: 'ALL' },
    //   ...dataItems
    // ]
    return dataItems
  }

  const isShowTimeError = (value?: Date) => {
    if (!value || !moment(value).isValid()) {
      return true
    } else if (moment(value).valueOf() > moment().valueOf()) {
      return true
    } else {
      return false
    }
  }

  const isShowCallbackTimeError = (val: Date) => {
    if (!val || !moment(val).isValid()) {
      return true
    } else if (moment(val).valueOf() <= moment().valueOf()) {
      return true
    } else {
      return false
    }
  }

  const checkHasCallBackTimeToRequire = (callResultCode: string) => {
    // const checkRequire = callingResultList && callingResultList.find((item) => value === item.code)?.hasCallBackTime
    // const checkRequire = true
    // if (!checkRequire) {
    //   callingRecordForm && callingRecordForm.resetField('callbackTime')
    // }
    // setShowCallbackTime(checkRequire ?? false)
    pipe(
      !!callResultCode ? OBCallOutPopupService.getA7s9ConfigList(callResultCode) : ZIO.succeed([]),
      ZIO.map((responseData) => {
        const isShowCallbackBlock =
          responseData && responseData.length
            ? responseData.some(
                (x) => x.functionCode === A7s3ConfigInfo?.functionCode && x.callOutCode === callResultCode
              )
            : false
        if (!isShowCallbackBlock) {
          callingRecordForm && callingRecordForm.resetField('callbackTime')
        }
        setShowCallbackTime(isShowCallbackBlock)
      }),
      bindLoader,
      ZIO.unsafeRun({})
    )
  }

  const onViewFile = () => {
    const callScriptVal = callingRecordForm && callingRecordForm.watch('callScript.value')
    if (!!callScriptVal) {
      const docID = pipe(
        callScriptList.find((x) => x.code === callScriptVal),
        O.fromNullable,
        O.map((dataItem) => dataItem.docId),
        O.getOrElse(() => '')
      )
      pipe(
        OBCallOutPopupService.getDocumentByDocID(policyNumber, docID),
        ZIO.flatMap((responseData) => {
          const url = responseData && responseData.length > 0 ? responseData[0].url : ''
          if (!!url) {
            return ZIO.fromPromise(() => getFileByUrl(url))
          } else {
            showToast(t('message:OB0004'), 'error')
            return ZIO.unit
          }
        }),
        bindLoader,
        ZIO.unsafeRun({})
      )
    }
  }

  const getFileByUrl = (url: string) => {
    return pipe(
      ZIO.zipPar(AuthService.token, AuthService.getLoginType),
      ZIO.flatMap(([token, loginType]) => {
        return ZIO.fromPromise(() =>
          fetch(url, {
            method: 'GET',
            headers: {
              Authorization: `Bearer ${token}`,
              'X-Authen-Vendor': loginType
            }
          })
        )
      }),
      ZIO.flatMap((res) =>
        ZIO.zipPar(
          ZIO.succeed(res),
          ZIO.fromPromise(() => res.blob())
        )
      ),
      ZIO.map(([res, blob]) => {
        const fileType = res.headers.get('content-type') || ''
        const blobUrl = window.URL.createObjectURL(blob)
        if (fileType === 'image/tiff') {
          OBFormat.viewTiffFileByNewTab(blobUrl)
        } else {
          window.open(blobUrl, '_blank')
        }
      }),
      ErrorHandling.run()
    )
  }

  const isInValidSending = () => {
    return (
      !callingRecordForm ||
      !callingRecordForm.watch('sendingType') ||
      !callingRecordForm.watch('sendingType').value ||
      !callingRecordForm.watch('guidelineDocument') ||
      !callingRecordForm.watch('guidelineDocument').value
    )
  }

  const validateDoNotContactValue = () => {
    if (
      callingRecordForm &&
      !!callingRecordForm?.watch('doNotContact') &&
      !!callingRecordForm?.watch('doNotContact').value
    ) {
      setIsOpenedCallIndicatorPopup(true)
      return false
    } else {
      setIsOpenedCallIndicatorPopup(false)
      return true
    }
  }

  const onSaveEvent = async (isNotCheckingDoNotContact?: boolean) => {
    const isFormValid = await callingRecordForm?.trigger()

    if (isFormValid && (isNotCheckingDoNotContact || validateDoNotContactValue())) {
      const formData = callingRecordForm?.getValues()
      const callbackTime = formData?.callbackTime ? formData.callbackTime.toISOString() : ''
      const callReceiver = formData?.callReceiver ? formData.callReceiver.value : ''
      const callScript = formData?.callScript ? formData.callScript.value || '' : ''
      const doNotContact = formData?.doNotContact ? formData.doNotContact.value : ''
      const sendingType = !!formData?.sendingType && !!formData.sendingType.value ? formData.sendingType.value : 'NONE'
      const guidelineDocument = formData?.guidelineDocument ? formData.guidelineDocument.value : ''
      const callingMethod = formData?.callingMethod ? formData.callingMethod.value : ''
      const currentDate = new Date().toISOString()
      const communicationHistory = isSendComunication ? 'yes' : 'no'
      const hint = formData?.hint && formData.hint.value ? formData.hint.value : ''
      // const time = formData?.time ? formData.time.toISOString() : ''
      const time = !!avayaCallDate ? avayaCallDate.toISOString() : ''
      const callingResult =
        !!formData?.callingResult && !!formData?.callingResult.value ? formData?.callingResult.value : ''
      // console.log('avayaCallID:' + avayaCallID)
      const isChangeContactInfo = pipe(
        O.fromNullable(isUpdatedChangeContactInfo),
        O.map((isChanged) => {
          return isChanged
        }),
        O.getOrElse(() => false)
      )
      const data: OBCallOutPopupService.CallOutDetailInput = {
        businessKey: caseId,
        processInstanceId: processInstanceId,
        policyNumber: policyNumber || '',
        assignee: assignee,
        teamLeader: teamLeader,
        transactionType: transactionType || '',
        calledDate: time,
        savedDate: currentDate,
        createdBy: userInfo?.email ?? '',
        createdDate: currentDate,
        updatedBy: userInfo?.email ?? '',
        updatedDate: currentDate,
        clientNumber: clientNumber,
        isChangeContactInfo: isChangeContactInfo,
        callingInfoDTO: {
          callReceiver: callReceiver,
          callingMethod: callingMethod,
          callbackTime: callbackTime,
          callIndicator: doNotContact,
          callingResult: callingResult,
          quickNote: formData?.quickNote || '',
          callScript: callScript,
          hint: hint,
          phoneNumber: avayaCallingPhoneNumber || '',
          callingDuration: formData?.length || '',
          connectionStatus: '',
          callId: avayaCallID || ''
        },
        sendingFileInfoDTO: {
          sendingType: sendingType,
          guidelineDocument: guidelineDocument,
          communicationHistory: communicationHistory
        }
      }
      pipe(
        OBCallOutPopupService.saveCallOutPopupDetail(data),
        ZIO.tap((responseData) => {
          if (responseData === 'SUCCESS' || responseData === 'Success') {
            const agingAction = data.savedDate
              ? PulseOpsFormat.getDifferenceInDays(new Date(), new Date(data.savedDate))
              : 0
            checkDisableSubmitButton(agingAction)
            setIsSavedCalloutPopupData && setIsSavedCalloutPopupData(true)
            setIsSavedAfterCallingAvaya && setIsSavedAfterCallingAvaya(true)
            if (isDisableSuspend && setIsDisableSuspend) setIsDisableSuspend(false)
            showToast(t('message:OB0040'), 'success')
            setIsOpenedCallIndicatorPopup(false)
            resetForm()
            setIsUpdatedChangeContactInfo && setIsUpdatedChangeContactInfo(false)
          } else {
            showToast(t('message:OB0042'), 'error')
          }
          return ZIO.unit
        }),
        ZIO.tapError((error) => {
          showToast(t('message:OB0042'), 'error')
          setIsOpenedCallIndicatorPopup(false)
          return ZIO.unit
        }),
        bindLoader,
        ZIO.unsafeRun({})
      )
    }
  }

  // const checkEmail = () => {
  //   const selectEmail = callingRecordForm && callingRecordForm.watch('sendingType')?.value === 'EMAIL'
  //   return pipe(
  //     OBGeneralService.getPolicyInfo(policyNumber),
  //     ZIO.map((res) => {
  //       if (selectEmail && res && !res.consent.isEmail) {
  //         showToast(t('message:OB0048'), 'error')
  //         return false
  //       }
  //       return true
  //     }),
  //     ZIO.unsafeRun({})
  //   )
  // }

  const onSendEvent = async () => {
    // const validHasMail = await checkEmail()
    if (
      callingRecordForm &&
      callingRecordForm.watch('sendingType') &&
      callingRecordForm.watch('guidelineDocument') &&
      !!callingRecordForm.watch('guidelineDocument').value
      // validHasMail
    ) {
      const submitedData: OBCallOutPopupService.SendCommunicationInfo = {
        processInstanceId: processInstanceId,
        policyNumber: policyNumber,
        pushCode: callingRecordForm.watch('guidelineDocument').value,
        channel: callingRecordForm.watch('sendingType').value.toLowerCase(),
        requestTime: moment().toISOString(),
        callId: avayaCallID || '',
        feature: 'OB'
      }
      pipe(
        OBCallOutPopupService.sendCommunication(submitedData),
        ZIO.mapError((error) => {
          showToast(t('message:OB0042'), 'error')
          return error
        }),
        ZIO.map((sentResult) => {
          setIsSendComunication && setIsSendComunication(true)
          showToast(t('message:OB0043'), 'success')
          callingRecordForm.setValue('sendingType', { label: '', value: '' })
          callingRecordForm.setValue('guidelineDocument', { label: '', value: '' })
          return sentResult
        }),
        bindLoader,
        ZIO.unsafeRun({})
      )
    }
  }

  const onCloseCallIndicatorPopupEvent = () => {
    setIsOpenedCallIndicatorPopup(false)
  }

  const onYesCallIndicatorPopupEvent = () => {
    onSaveEvent(true)
  }
  const checkRuleZNS = () => {
    const hasPromotion = promotionFlag?.hasPromotion
    const typePromotion = promotionFlag?.typePromotion as TypePromotion
    const premiumRein = parseInt(promotionFlag?.premiumRein || '0')

    const setErrorGuidelineDocument = (message: string) => {
      return callingRecordForm?.setError('guidelineDocument', { message: t(message) })
    }

    if (promotionFlag && promotionFlag?.countPromotion >= 2) {
      setErrorGuidelineDocument('message:OB0104')
    } else if (!hasPromotion && !['IF', 'LA'].includes(policyInfo?.statusCode || '')) {
      setErrorGuidelineDocument('message:OB0105')
    } else if (hasPromotion && ![TypePromotion.LAPSE_REIN, TypePromotion.PRE_REMINDER].includes(typePromotion)) {
      setErrorGuidelineDocument('message:OB0106')
    } else {
      callingRecordForm?.clearErrors('guidelineDocument')
    }
  }

  return (
    <View style={callingStyles.container}>
      <View style={callingStyles.headerContent}>
        <OBTitle textStyle={callingStyles.titleText} text={t('Outbound:OBCallOutPopup:CALLINGRESULT')}></OBTitle>
      </View>
      <ScrollView style={callingStyles.bodyContent}>
        <View>
          <OBSectionRow>
            <OBSectionCol style={[OBSharedStyles.sectionCol10]}>
              <Controller
                control={callingRecordForm && callingRecordForm.control}
                name="callScript"
                render={({ field: { value, onChange, onBlur }, fieldState: { error } }) => {
                  return (
                    <SelectSearch
                      label={t('Outbound:OBCallOutPopup:CallScript')}
                      options={getCallScriptOptions(callScriptList)}
                      value={value}
                      disabled={isDisabledEdit}
                      onChange={onChange}
                      // errorMessage={''}
                      popupIcon={<assets.ArrowDownDropdownIcon />}
                      isShowFullText={true}
                      placeholder={t('common:Select')}
                    />
                  )
                }}
              />
            </OBSectionCol>
            <OBSectionCol style={[OBSharedStyles.sectionCol2, callingStyles.viewContent]}>
              <TouchableOpacity onPress={() => onViewFile()}>
                <View>
                  <assets.OBEye />
                  {/* <Text style={OBSharedStyles.actionContentText}>{t('common:View')}</Text> */}
                </View>
              </TouchableOpacity>
            </OBSectionCol>
          </OBSectionRow>
          <OBSectionRow style={callingStyles.secondLine}>
            <OBSectionCol style={OBSharedStyles.sectionCol12}>
              <Controller
                control={callingRecordForm && callingRecordForm.control}
                name="callingMethod"
                render={({ field: { value, onChange, onBlur }, fieldState: { error } }) => {
                  return (
                    <SelectSearch
                      label={t('Outbound:OBCallOutPopup:CallingMethod')}
                      options={getOptions(callingMethodArr)}
                      value={value}
                      onChange={onChange}
                      onBlur={onBlur}
                      disabled={true}
                      showPopupIcon={false}
                      disableUnderline={true}
                      isShowFullText={true}
                      placeholder={t('common:Select')}
                    />
                  )
                }}
              />
            </OBSectionCol>
          </OBSectionRow>
          <OBSectionRow style={callingStyles.secondLine}>
            <OBSectionCol style={OBSharedStyles.sectionCol12}>
              <Controller
                control={callingRecordForm && callingRecordForm.control}
                name="callReceiver"
                rules={{
                  required: {
                    value: true,
                    message: t('message:OB0006')
                  }
                }}
                render={({ field: { value, onChange, onBlur }, fieldState: { error } }) => {
                  // console.log('callReceiver : ' + JSON.stringify(callingRecordForm.watch('callReceiver')))
                  return (
                    <SelectSearch
                      label={t('Outbound:OBCallOutPopup:CallReceiver')}
                      options={getOptions(callReceiverList)}
                      value={value}
                      onChange={(val) => {
                        onChange(val)
                        onChangeCallReceiverEvent(val?.value || '')
                      }}
                      onBlur={onBlur}
                      disabled={isDisabledEdit}
                      errorMessage={
                        !!callingRecordForm &&
                        !!callingRecordForm.watch('callReceiver') &&
                        !!callingRecordForm.watch('callReceiver').value
                          ? ''
                          : error?.message
                      }
                      popupIcon={<assets.ArrowDownDropdownIcon />}
                      required={true}
                      placeholder={t('common:Select')}
                    />
                  )
                }}
              />
            </OBSectionCol>
          </OBSectionRow>
          <OBSectionRow style={callingStyles.secondLine}>
            <OBSectionCol style={OBSharedStyles.sectionCol12}>
              <Controller
                control={callingRecordForm && callingRecordForm.control}
                name="callingResult"
                rules={{
                  required: {
                    value: true,
                    message: t('message:OB0010')
                  }
                }}
                render={({ field: { value, onChange, onBlur }, fieldState: { error } }) => {
                  return (
                    <SelectSearch
                      label={t('Outbound:OBCallOutPopup:CallingResult')}
                      options={getOptions(callingResultArr)}
                      value={value}
                      onChange={(val) => {
                        onChange(val)
                        checkHasCallBackTimeToRequire(val?.value ?? '')
                      }}
                      disabled={isDisabledEdit}
                      errorMessage={!value || !value.value ? error?.message : ''}
                      popupIcon={<assets.ArrowDownDropdownIcon />}
                      required={true}
                      placeholder={t('common:Select')}
                    />
                  )
                }}
              />
            </OBSectionCol>
          </OBSectionRow>
          {isShowCallbackTime && (
            <OBSectionRow style={callingStyles.secondLine}>
              <OBSectionCol style={OBSharedStyles.sectionCol12}>
                <Controller
                  control={callingRecordForm && callingRecordForm.control}
                  name="callbackTime"
                  rules={{
                    validate: (val) => {
                      if (!val || !moment(val).isValid()) {
                        return `${t('message:MS020001', { field: t('Outbound:OBCallOutPopup:CallbackTime') })}`
                      } else if (moment(val).valueOf() <= moment().valueOf()) {
                        return `${t('message:OB0002')}`
                      } else {
                        return true
                      }
                    }
                  }}
                  render={({ field: { value, onChange, onBlur }, fieldState: { error } }) => {
                    return (
                      <CustomedDateTimePicker
                        label={t('Outbound:OBCallOutPopup:CallbackTime')}
                        onChange={(val) => {
                          onChange(val)
                        }}
                        onBlur={onBlur}
                        value={value}
                        required={true}
                        disabled={isDisabledEdit}
                        hideUnderline
                        minDate={new Date()}
                        errorMessage={
                          isShowCallbackTimeError(callingRecordForm?.watch('callbackTime') as Date)
                            ? error?.message
                            : ''
                        }
                      />
                    )
                  }}
                />
              </OBSectionCol>
            </OBSectionRow>
          )}
          <OBSectionRow style={callingStyles.secondLine}>
            <OBSectionCol style={OBSharedStyles.sectionCol12}>
              <Controller
                control={callingRecordForm && callingRecordForm.control}
                name="quickNote"
                rules={{
                  required: {
                    value: true,
                    message: t('message:OB0009')
                  }
                }}
                render={({ field: { value, onChange, onBlur }, fieldState: { error } }) => {
                  return (
                    <Input
                      title={t('Outbound:OBCallOutPopup:QuickNote')}
                      placeholder={t('Outbound:OBCallOutPopup:QuickNote')}
                      value={value}
                      onChange={onChange}
                      onBlur={onBlur}
                      disabled={isDisabledEdit}
                      required
                      maxLength={120}
                      errorMessage={!value ? error?.message : ''}
                    />
                  )
                }}
              />
            </OBSectionCol>
          </OBSectionRow>
          <OBSectionRow style={callingStyles.secondLine}>
            <OBSectionCol style={OBSharedStyles.sectionCol12}>
              <Controller
                control={callingRecordForm && callingRecordForm.control}
                name="doNotContact"
                render={({ field: { value, onChange, onBlur }, fieldState: { error } }) => {
                  return (
                    <SelectSearch
                      value={value}
                      onChange={onChange}
                      onBlur={onBlur}
                      disabled={isDisabledEdit}
                      popupIcon={<assets.ArrowDownDropdownIcon />}
                      label={t('Outbound:OBCallOutPopup:CallIndicator')}
                      options={getDoNotCallOption(doNotCallList)}
                      placeholder={t('common:Select')}
                    />
                  )
                }}
              />
            </OBSectionCol>
          </OBSectionRow>
          <OBSectionRow style={callingStyles.secondLine}>
            <OBSectionCol style={OBSharedStyles.sectionCol12}>
              <Controller
                control={callingRecordForm && callingRecordForm.control}
                name="hint"
                render={({ field: { value, onChange, onBlur }, fieldState: { error } }) => {
                  return (
                    <SelectSearch
                      label={t('Outbound:OBCallOutPopup:Hint')}
                      options={getOptions(OBCallOutPopupConst.HintList)}
                      value={value}
                      onChange={onChange}
                      disabled={isDisabledEdit}
                      onBlur={onBlur}
                      popupIcon={<assets.ArrowDownDropdownIcon />}
                      placeholder={t('common:Select')}
                    />
                  )
                }}
              />
            </OBSectionCol>
          </OBSectionRow>
          {/* <OBTitle
            style={callingStyles.secondLine}
            textStyle={callingStyles.titleText}
            text={t('Outbound:OBCallOutPopup:MANUALUPDATE')}
          ></OBTitle> */}
          {/* <OBSectionRow style={callingStyles.secondLine}>
            <OBSectionCol style={OBSharedStyles.sectionCol12}>
              <Controller
                control={callingRecordForm && callingRecordForm.control}
                name="phoneNumber"
                rules={{
                  required: {
                    value: true,
                    message: t('message:MS020001', { field: t('Outbound:OBCallOutPopup:PhoneNumber') })
                  }
                }}
                render={({ field: { value, onChange, onBlur }, fieldState: { error } }) => {
                  return (
                    <Input
                      title={t('Outbound:OBCallOutPopup:PhoneNumber')}
                      placeholder={t('Outbound:OBCallOutPopup:PhoneNumber')}
                      inputType="number"
                      value={value}
                      onChange={onChange}
                      disabled={isDisabledEdit}
                      onBlur={onBlur}
                      maxLength={12}
                      required
                      errorMessage={!value ? error?.message : ''}
                    />
                  )
                }}
              />
            </OBSectionCol>
          </OBSectionRow>
          <OBSectionRow style={callingStyles.secondLine}>
            <OBSectionCol style={OBSharedStyles.sectionCol12}>
              <Controller
                control={callingRecordForm && callingRecordForm.control}
                name="time"
                rules={{
                  validate: (val) => {
                    if (!val || !moment(val).isValid()) {
                      return `${t('message:MS020001', { field: t('Outbound:OBCallOutPopup:Time') })}`
                    } else if (moment(val).valueOf() > moment().valueOf()) {
                      return `${t('message:OB0036')}`
                    } else {
                      return true
                    }
                  }
                }}
                render={({ field: { value, onChange, onBlur }, fieldState: { error } }) => {
                  return (
                    <CustomedDateTimePicker
                      label={t('Outbound:OBCallOutPopup:Time')}
                      required={true}
                      value={value}
                      onChange={onChange}
                      onBlur={onBlur}
                      errorMessage={isShowTimeError(callingRecordForm?.watch('time') as Date) ? error?.message : ''}
                      // maxDate={new Date()}
                      hideUnderline
                      disabled={isDisabledEdit}
                      // invalidDateMessage={null}
                      // minDateMessage={null}
                      // maxDateMessage={null}
                    />
                  )
                }}
              />
            </OBSectionCol>
          </OBSectionRow>
          <OBSectionRow style={callingStyles.secondLine}>
            <OBSectionCol style={OBSharedStyles.sectionCol12}>
              <Controller
                control={callingRecordForm && callingRecordForm.control}
                name="length"
                rules={{
                  // required: {
                  //   value: true,
                  //   message: t('message:MS020001', { field: t('Length') })
                  // },
                  validate: (val) => {
                    const textFormat = /(^[0-9]{2}):(?:[012345]\d):(?:[012345]\d$)/
                    if (!val) {
                      return `${t('message:MS020001', { field: t('Outbound:OBCallOutPopup:Length') })}`
                    } else if (!!val && !textFormat.test(val)) {
                      return `${t('message:OBlength')}`
                    } else {
                      return true
                    }
                  }
                }}
                render={({ field: { value, onChange, onBlur }, fieldState: { error } }) => {
                  return (
                    <Input
                      title={t('Outbound:OBCallOutPopup:Length')}
                      placeholder={'hh:mm:ss'}
                      maxLength={15}
                      value={value}
                      required
                      disabled={isDisabledEdit}
                      onChange={(val) => {
                        // const textFormat = /(?:[01]\d|2[0123]):(?:[012345]\d):(?:[012345]\d)/
                        // if (textFormat.test(val)) {
                        //   onChange(val)
                        // }
                        onChange(val)
                      }}
                      onBlur={onBlur}
                      errorMessage={error?.message}
                    />
                  )
                }}
              />
            </OBSectionCol>
          </OBSectionRow> */}
          <OBTitle
            style={callingStyles.secondLine}
            textStyle={callingStyles.titleText}
            text={t('Outbound:OBCallOutPopup:SENDCOMMUNICATION')}
          ></OBTitle>
          <OBSectionRow style={callingStyles.secondLine}>
            <OBSectionCol style={OBSharedStyles.sectionCol12}>
              <Controller
                control={callingRecordForm && callingRecordForm.control}
                name="sendingType"
                render={({ field: { value, onChange, onBlur }, fieldState: { error } }) => {
                  return (
                    <SelectSearch
                      label={t('Outbound:OBCallOutPopup:SendingType')}
                      options={getOptions(OBCallOutPopupConst.SendingTypeList)}
                      value={value}
                      onChange={(value) => {
                        if (value && value?.value === 'ZNS') {
                          checkRuleZNS()
                        } else {
                          callingRecordForm?.clearErrors('guidelineDocument')
                        }
                        callingRecordForm?.setValue('guidelineDocument', { label: '', value: '' })
                        onChange(value)
                      }}
                      disabled={isDisabledEdit}
                      onBlur={onBlur}
                      popupIcon={<assets.ArrowDownDropdownIcon />}
                      placeholder={t('common:Select')}
                    />
                  )
                }}
              />
            </OBSectionCol>
          </OBSectionRow>
          <OBSectionRow style={callingStyles.secondLine}>
            <OBSectionCol style={OBSharedStyles.sectionCol12}>
              <Controller
                control={callingRecordForm && callingRecordForm.control}
                name="guidelineDocument"
                render={({ field: { value, onChange, onBlur }, fieldState: { error } }) => {
                  const sendingType = callingRecordForm?.watch().sendingType
                  const options =
                    sendingType && sendingType.value === 'ZNS'
                      ? promotionFlag && promotionFlag?.countPromotion < 2 && isNil(error?.message)
                        ? getGuideDocumentZNSOptions(guidelineZNSArr)
                        : []
                      : getGuideDocumentOptions(guidelineDocumentArr)

                  return (
                    <SelectSearch
                      label={t('Outbound:OBCallOutPopup:GuidelineDocument')}
                      options={options}
                      value={value}
                      onChange={onChange}
                      disabled={isDisabledEdit || _.isUndefined(sendingType)}
                      popupIcon={<assets.ArrowDownDropdownIcon />}
                      placeholder={t('common:Select')}
                      errorMessage={error?.message}
                    />
                  )
                }}
              />
            </OBSectionCol>
          </OBSectionRow>
          <View style={callingStyles.buttonContainer}>
            <OBButton
              text={t('Outbound:OBCallOutPopup:Send')}
              disabled={isInValidSending() || isDisabledEdit}
              onHandleClickEvent={() => onSendEvent()}
              buttonContainerStyle={callingStyles.cancelBtnContent}
            ></OBButton>
            <OBButton
              text={t('Outbound:OBCallOutPopup:Save')}
              onHandleClickEvent={() => onSaveEvent(false)}
              isHightLight={!isOpenedAvayaCallBlock}
              disabled={isOpenedAvayaCallBlock}
            ></OBButton>
          </View>
        </View>
      </ScrollView>
      <OBCallIndicatorPopup
        isVisible={isOpenedCallIndicatorPopup}
        onCloseEvent={onCloseCallIndicatorPopupEvent}
        onYesEvent={onYesCallIndicatorPopupEvent}
      ></OBCallIndicatorPopup>
    </View>
  )
}

const callingStyles = StyleSheet.create({
  container: {
    display: 'flex',
    width: '100%',
    height: '100%',
    padding: 16
  },
  headerContent: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: 16
  },
  bodyContent: {
    width: '100%',
    paddingRight: 10
  },
  secondLine: {
    marginTop: 16
  },
  viewContent: {
    marginTop: 16,
    alignItems: 'center',
    justifyContent: 'center',
    // paddingHorizontal: 0
    paddingLeft: 0
  },
  titleText: {
    color: '#27272A'
  },
  buttonContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    marginTop: 24
  },
  cancelBtnContent: {
    backgroundColor: '#fff',
    marginBottom: 16
  }
})
