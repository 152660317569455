/* eslint-disable @nrwl/nx/enforce-module-boundaries */
import * as t from 'io-ts'
import { form2 } from '@pulseops/common'

export namespace AgentEvaluationForm {
  export const codec = t.type({
    certificateCheck: t.boolean,
    qsCoreCheck: t.boolean,
    qualifiedCheck: t.boolean,
    candidateCheck: t.boolean,
    sumAge: t.number,
    Educations: form2.selectOption.optional,
    workExperiences: form2.string.optional,
    hasAListOfPotentialCustomers: form2.string.optional,
    total: t.number,
    nameSystem: form2.string.optional,
    nameApplication: form2.string.optional,
    DateOfbirthSys: form2.string.optional,
    DateOfbirthApp: form2.string.optional,
    MOFnumber: form2.string.optional,
    MOFdate: form2.string.optional,
    ExamCode: form2.string.optional,
    sumAgeCandidate: t.number,
    isSaveAgent: t.boolean
  })
  export type Raw = t.OutputOf<typeof codec>

  export type Validated = t.TypeOf<typeof codec>
}
