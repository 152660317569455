import * as t from 'io-ts'
import { SupplementaryInfo } from './model'
import { pipe } from 'fp-ts/lib/function'
import { ZIO } from '@mxt/zio'
import { POApi } from '../POApi'
import { AuthService } from '../auth'
import { StorageBlob } from '../storage-blob'
import { Maybe } from '@mxt/zio/codec'
export namespace SupplementaryInfoService {
  export const getAllPendingCode = (processInstanceId: string) =>
    POApi.get(`pulseops/api/v1/supplementary/get-suspend-group-list?processInstanceId=${processInstanceId}`)(
      SupplementaryInfo.Groups
    )

  export const getPendingCodeBySuspendCode = (processInstanceId: string, suspendGroupCode: string) =>
    POApi.get(
      `pulseops/api/v1/supplementary/get-suspend-type-list?processInstanceId=${processInstanceId}&suspendGroupCode=${suspendGroupCode}`
    )(SupplementaryInfo.Types)

  export const getHistoryList = (processInstanceId: string) =>
    POApi.get(`pulseops/api/v1/supplementary/get-history-list?processInstanceId=${processInstanceId}`)(
      SupplementaryInfo.HistoryList
    )

  export const checkExpireDate = (processInstanceId: string) =>
    POApi.get(`pulseops/api/v1/supplementary/get-expired-date?processInstanceId=${processInstanceId}`)(
      SupplementaryInfo.ExpireTime
    )

  export const getHistoryListAndUsers = (processInstanceId: string) =>
    pipe(
      ZIO.zipPar(getHistoryList(processInstanceId), AuthService.userInfo),
      ZIO.map(([historyList, userInfo]) => ({ currentUser: String(userInfo.email).toLowerCase(), historyList }))
    )

  export const updateList = (data: SupplementaryInfo.UpdateList, documents: StorageBlob.Documents) =>
    POApi.put(`pulseops/api/v1/supplementary/update-list`)(t.unknown)({
      ...data,
      documents
    })

  export const save = (
    data: SupplementaryInfo.Save,
    documents: StorageBlob.Documents,
    letterContentDocument?: SupplementaryInfo.DataPostPreview,
    pendingLetterContentDocument?: SupplementaryInfo.DataPostPreview,
    typeGenerateLetter?: string
  ) =>
    POApi.put(`pulseops/api/v1/supplementary/save`)(t.unknown)({
      ...data,
      documents,
      letterContentDocument,
      pendingLetterContentDocument,
      typeGenerateLetter
    })

  export const getNewURL = (policyNum: string, category: string) =>
    pipe(
      POApi.post(`pulseops/api/v1/azure/sas-token`)(
        t.type({
          account: t.string,
          containerName: t.string,
          sas: t.string,
          folderName: t.string
        })
      )({
        category: category,
        corelationId: policyNum
      }),
      ZIO.map((res) => res)
    )

  export const searchBySupplementaryId = (SupplementaryId: string, modifiedDate: string) =>
    pipe(
      POApi.post(`premium-collection-internal/premium/report-data/get-unsuspend-receipts`)(
        t.type({
          body: t.type({
            receiptHistory: Maybe(
              t.array(
                t.type({
                  receiptNo: Maybe(t.string),
                  createdDate: Maybe(t.string),
                  transactions: Maybe(t.array(t.type({ amount: t.number })))
                })
              )
            )
          })
        })
      )({
        supplementaryId: SupplementaryId,
        modifiedDate: modifiedDate
      }),
      ZIO.map((res) => res.body)
    )

  export const getListGeneralInfo = (caseId: string) =>
    pipe(
      POApi.get(`pulseops/api/v1/supplementary/get-letter-list?caseId=${caseId}`)(SupplementaryInfo.GeneralInfoList),
      ZIO.map((res) => res)
    )

  export const previewLetter = (data: SupplementaryInfo.DataPostPreview | undefined) =>
    pipe(
      ZIO.zipPar(
        AuthService.token,
        AuthService.getLoginType,
        POApi.buildUrl(`document-generation/letter-content/create-file-temp`)
      ),
      ZIO.flatMap(([token, loginType, url]) =>
        ZIO.fromPromise(() => {
          return fetch(url, {
            method: 'POST',
            headers: {
              'Cache-Control': 'no-cache',
              Pragma: 'no-cache',
              'Content-Type': 'application/json; charset=utf-8',
              Authorization: `Bearer ${token}`,
              'X-Authen-Vendor': loginType
            },
            body: JSON.stringify({ body: { ...data } })
          })
        })
      )
    )

  export const getDataPendingLetter = (caseId: string) =>
    pipe(
      POApi.get(`document-generation/letter-content/${caseId}`)(SupplementaryInfo.GeneralInfo),
      ZIO.map((res) => res)
    )
  export const previewLetterCL = (data: SupplementaryInfo.DataPostPreview | undefined) =>
    pipe(
      ZIO.zipPar(
        AuthService.token,
        AuthService.getLoginType,
        POApi.buildUrl(`document-generation/api/v1/generate-service/first-pending-letter-generate`)
      ),
      ZIO.flatMap(([token, loginType, url]) =>
        ZIO.fromPromise(() => {
          return fetch(url, {
            method: 'POST',
            headers: {
              'Cache-Control': 'no-cache',
              Pragma: 'no-cache',
              'Content-Type': 'application/json; charset=utf-8',
              Authorization: `Bearer ${token}`,
              'X-Authen-Vendor': loginType
            },
            body: JSON.stringify({ body: { ...data } })
          })
        })
      )
    )

  export const getListGeneralInfoCL = (caseId: string, status: string) =>
    pipe(
      POApi.get(
        `document-generation/api/v1/generate-service/get-pending-letter?processInstanceId=${caseId}&status=${status}`
      )(SupplementaryInfo.GeneralInfoList),
      ZIO.map((res) => res)
    )
}
