import * as api from '../task-detail-api'
// import { PaymentMethod } from '../PaymentMethod'
import { TransactionType } from '../TransactionType'
// import { Option } from 'fp-ts/Option'
// import { Client } from '../Client'
import { TaskDetailBase } from '../task-detail-api'
import { TopUp } from '../Topup'
import { InquiryComplaintData } from '../InquiryComplaint'
import { PulseOpsFormat } from '../../../Formatter'

export interface CancelRiderData {
  tag: TransactionType.CANCEL_RIDER
  clientName: string
  lifeAssureds: VeriLARiderInfo[]
  totalPremium: string
  riderNameList: InquiryComplaintData.IACSources[]
}

export interface VeriCanRiderClientInfo {
  clientNumberOfLA: string
  life: string
  clientName: string
}

export interface VeriLARiderInfo {
  clientName: string
  lifeAssured: string
  coreRiders: VeriCancelRiderInfo[]
  submissionRiders: VeriCancelRiderInfo[]
}

export interface VeriCancelRiderInfo {
  productCode: string
  riskCommDate: string
  riskCessDate: string
  sumAssured: string
  installmentPremium: string
  lifeNo: string
  coverageNo: string
  riderNo: string
  riderStatus: string
}

export const CancelRider = (
  detail: api.CancelRider & TaskDetailBase,
  riderClientInfo: VeriCanRiderClientInfo[],
  poName: string,
  totalPremium: number | null | undefined,
  extraPolicyInfo: TopUp.PolicyExtraInfo,
  riderNameList: InquiryComplaintData.IACSources[]
): CancelRiderData => {
  const payload = detail.payload.body
  const coreRiderList = extraPolicyInfo.policyExtraInfoDetail.filter(
    (x) => x.lifeNo !== '01' || x.coverageNo !== '01' || x.riderNo !== '00'
  )
  const laRiderList: VeriLARiderInfo[] =
    payload.lifeAssureds?.map((item) => {
      return {
        lifeAssured: item.lifeAssured ?? '',
        clientName: riderClientInfo.find((x) => x.clientNumberOfLA === item.lifeAssured)?.clientName || '',
        coreRiders: item.riders.map((x) => {
          const customedRiskCommDate = coreRiderList.find(
            (coreItem) => coreItem.lifeNo === x.lifeNo && coreItem.productCode === x.productCode
          )?.riskCommDate
          const customedriskCessDate = coreRiderList.find(
            (coreItem) => coreItem.lifeNo === x.lifeNo && coreItem.productCode === x.productCode
          )?.riskCessDate
          const sumAssured = coreRiderList.find(
            (coreItem) => coreItem.lifeNo === x.lifeNo && coreItem.productCode === x.productCode
          )?.sumAssured
          const installmentPremium = coreRiderList.find(
            (coreItem) => coreItem.lifeNo === x.lifeNo && coreItem.productCode === x.productCode
          )?.installmentPremium
          return {
            ...x,
            riskCommDate: PulseOpsFormat.dateStrWF(customedRiskCommDate, 'DD/MM/YYYY'),
            riskCessDate: PulseOpsFormat.dateStrWF(customedriskCessDate, 'DD/MM/YYYY'),
            sumAssured: PulseOpsFormat.thousandSepartor(sumAssured ?? 0),
            installmentPremium: PulseOpsFormat.thousandSepartor(installmentPremium ?? 0)
          }
        }) as VeriCancelRiderInfo[],
        submissionRiders: item.riders.map((x) => ({
          ...x,
          riskCommDate: PulseOpsFormat.dateStrWF(x.riskCommDate, 'DD/MM/YYYY'),
          riskCessDate: PulseOpsFormat.dateStrWF(x.riskCessDate, 'DD/MM/YYYY'),
          sumAssured: String(x.sumAssured),
          installmentPremium: String(x.installmentPremium)
        })) as VeriCancelRiderInfo[]
      }
    }) || []
  const sumRider = laRiderList.map((item) => {
    return item.submissionRiders.reduce((total, item) => total + Number(item.installmentPremium), 0)
  })
  const totalRider = sumRider.reduce((total, item) => total + item, 0)
  const totalPremiumCalc = Number(totalPremium ? totalPremium : 0) - totalRider
  return {
    tag: TransactionType.CANCEL_RIDER,
    clientName: poName,
    lifeAssureds: laRiderList,
    totalPremium: PulseOpsFormat.thousandSepartor(totalPremiumCalc),
    riderNameList: riderNameList
  }
}
