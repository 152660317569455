import { Task, ZIO } from '@mxt/zio'
import { ZStream } from '@mxt/zio/stream'
import * as A from 'fp-ts/Array'
import * as E from 'fp-ts/Either'
import { flow, pipe } from 'fp-ts/function'
import { Subject } from 'rxjs'
import { AuthService } from '../auth'
import { PageParameter, PageResult } from '../pagination'
import { BillingChangeService } from './BillingChangeService'
import { CancelFromInceptionService } from './CancelFromInceptionService'
import { CancelRiderService } from './CancelRiderService'
import { ChangeContactInfoService } from './ChangeContactInfoService'
import { CommonRequestService } from './CommonRequestService'
import { ExcellentStudentAwardService } from './ExcellentStudentAwardService'
import { ExcessPremiumService } from './ExcessPremiumService'
import { notEmpty } from './format'
import { GeneralInfoService } from './GeneralInfoService'
import { GeneralService } from './GeneralService'
import { InquiryComplaintService } from './InquiryComplaintService'
import { LoanService } from './LoanService'
import { MaturityAdvanceService } from './MaturityAdvanceService'
import { MaturityPaymentService } from './MaturityPaymentService'
import { ProductOptionSwitchingService } from './product-option-switching-service'
import {
  calculateAgingDay,
  ClientPolicy,
  Customer,
  SourceType,
  SubTasksResponse,
  SupendedTaskList,
  TaskComment,
  TaskDetail,
  TaskDetailApi,
  TaskOp,
  TaskType,
  TransactionStatus,
  TransactionType,
  TransactionTypeC,
  Vip
} from './model'
import { EformBillingChange, OpEformDetail } from './model/OpEformDetail'
import { PruCashPaymentService } from './PrucashPaymentService'
import { PruKid369Service } from './PruKid369Service'
import { PulseOpsApi } from './PulseOpsApi'
import { PulseOpsState } from './PulseOpsState'
import { ReinstatementService } from './ReinstatementService'
import { SubmissionService } from './SubmissionService'
import { TopUpService } from './TopUpService'
import { CashLessService } from './CashLessService'
import { SpecialBonusService } from './SpecialBonusService'
import { RiderAlterationService } from './RiderAlterationService'
import { PayoutService } from '@pulseops/common'
import { ClaimService } from './ClaimService'
import { BeneficiaryDesignationService } from './BeneficiaryDesignationService'

const getPolicyList = (policyNumList: string[]) =>
  pipe(
    policyNumList,
    A.map((policyNum) =>
      pipe(
        PulseOpsApi.sqlChangeContactInfoAddress(policyNum),
        ZIO.map((value) => ({
          policyNumber: policyNum,
          address: value.body?.address || '-'
        }))
      )
    ),
    ZIO.sequence
  )

const getPoNameData = (clientNum: string) =>
  pipe(
    PulseOpsApi.getCustomer(clientNum),
    ZIO.map((client) => {
      return {
        poName: client.body.name,
        otpConsent: client.body.otpConsentStatus,
        poDob: '',
        poExternalIds: ''
      }
    })
  )

const getPoNameDataByPolicy = (policyNum: string, clientId = '') =>
  pipe(
    PulseOpsApi.getPolicy(policyNum ? policyNum : 'null'),
    ZIO.flatMap((policy) => getPoNameData(policy.body.owners.id || clientId))
  )

const getPolicyDigital = (policyNum: string) =>
  pipe(
    SubmissionService.getPolicyInquiry(policyNum),
    ZIO.map((policy) => {
      const owner = policy.customerRoles?.find((x) => x.role === 'Owner')
      return {
        poName: owner?.customer?.firstName ?? '',
        poDob: owner?.customer?.dob ?? '',
        poExternalIds: owner?.customer?.externalIds?.NATIONAL_ID ?? '',
        otpConsent: ''
      }
    })
  )

const getPolicyDigitalPVA = (policyNum: string) =>
  pipe(
    SubmissionService.getPolicyDigitalPVA(policyNum),
    ZIO.map((policy) => {
      return (
        {
          id: policy.id ?? '',
          policyNo: policy.policyNo,
          effectiveDate: policy.inceptionDate,
          status: policy.status,
          premiumStatus: policy.premiumStatus ?? '',
          currency: policy?.paymentOption?.currency || '',
          productCode: policy?.product?.code || '',
          productOptions: policy.productOptions?.map((data) => ({
            idMain: data.id ?? '',
            totalPremium: data.totalPremium,
            riskCessDate: data.riskCessDate,
            productMainCode: data.product.code,
            productMainName: data.product.fullName,
            mainTotalSumAssured: data.totalSumAssured,
            productMainStatus: data.status,
            planName: data?.options.planName,
            productName: data?.product.fullName,
            productComponent: data.productComponentOptions?.map((sub) => ({
              idSub: sub.id ?? '',
              component: {
                id: sub.component.id ?? '',
                name: sub.component.name,
                code: sub.component.code
              },
              options: {
                totalSumAssured: sub.options.totalSumAssured,
                totalPremium: sub.options.totalPremium,
                claimTemplateType: sub.options.claimTemplateType,
                claimantRole: sub.options.claimantRole
              },
              riskCessDate: sub.riskCessDate,
              productSubCode: sub.component.code,
              subTotalSumAssured: sub.options.totalSumAssured,
              subStatus: sub.status,
              isICU: sub.component.name.includes('ICU')
            }))
          }))
        } || undefined
      )
    })
  )

export const getCustomerNames = (clientIds: Array<string>) =>
  pipe(
    SubmissionService.getClients(clientIds.map((id) => ({ clientId: id }))),
    ZIO.map((clients): Array<{ clientId: string; clientName: string }> => {
      return clients.body.map((client): { clientId: string; clientName: string } => ({
        clientId: client.clientId ?? '',
        clientName: client.name ?? ''
      }))
    })
  )

const getCustomerPolicies = (policies: Array<string>): Task<Array<ClientPolicy>> =>
  pipe(
    policies,
    A.map((policyNum) =>
      pipe(
        PulseOpsApi.getPolicy(policyNum),
        ZIO.flatMap((policy) =>
          pipe(
            PulseOpsApi.getCustomer(policy.body.owners.id),
            ZIO.map(
              (client): ClientPolicy => ({
                name: client.body.name,
                policy: policyNum
              })
            )
          )
        )
      )
    ),
    ZIO.sequence
  )

const isChangeContactTransaction = (t: string) => {
  switch (t) {
    case TransactionType.CHANGE_CONTACT_INFO_PHONE_AND_EMAIL:
    case TransactionType.CHANGE_CONTACT_INFO_PHONE:
    case TransactionType.CHANGE_CONTACT_INFO_EMAIL:
    case TransactionType.CHANGE_CONTACT_INFO_ADDRESS:
    case TransactionType.CHANGE_CLIENT_INFO: // new
      return true
    default:
      return false
  }
}

const getBenClientIds = (task: TaskDetailApi.Type): Array<string> => {
  if (task.transactionType === TransactionType.BENEFICIARY_DESIGNATION) {
    switch (task.source) {
      case SourceType.PULSE4OPS:
        return [...new Set(task.payload.body.map((e) => e.customer.clientId ?? '').filter((e) => notEmpty<string>(e)))]
      case SourceType.ZALO:
      case SourceType.EKIOSK:
        return [...new Set(task.payload.body.map((e) => e.customer.clientId ?? '').filter((e) => notEmpty<string>(e)))]
      case SourceType.PULSE:
        return [...new Set(task.payload.body.map((e) => e.customer.clientId ?? '').filter((e) => notEmpty<string>(e)))]
      case SourceType.BANCA:
      case SourceType.PRUGREAT:
      case SourceType.PRUONLINE:
        break
      default:
        break
    }
  }
  return []
}

export interface TaskQueryFilter {
  source?: SourceType[]
  vip?: Vip[]
  policyNum?: string
  caseId?: string
  transactionType?: TransactionType[]
  fromDate?: Date
  toDate?: Date
  assignee?: string
  client?: string
}

export interface TaskQueryOptions {
  isTeam: boolean
  pagination: PageParameter
  filter: TaskQueryFilter
  order?: 'desc' | 'asc'
  taskCategory?: SubTasksResponse
  category?: string
  active?: boolean
}

export interface InquiryQueryFilter {
  source?: SourceType[]
  // policyNum?: string
  // caseId?: string
  // transactionType?: TransactionType[]
  // fromDate?: Date
  // toDate?: Date
  // assignee?: string

  // source?: TaskSource[]
  policyNum?: string
  caseId?: string
  transactionType?: TransactionType[]
  fromDate?: Date
  toDate?: Date
  assignee?: string
}

export interface InquiryQueryOptions {
  pagination: PageParameter
  order?: 'desc' | 'asc'
  filter: InquiryQueryFilter
}

const isClientId = (task: TransactionType) => {
  return [
    TransactionType.CHANGE_CONTACT_INFO_PHONE,
    TransactionType.CHANGE_CONTACT_INFO_EMAIL,
    TransactionType.CHANGE_CONTACT_INFO_PHONE_AND_EMAIL,
    TransactionType.CHANGE_CLIENT_INFO,
    TransactionType.FATCA_DECLARATION
  ].includes(task)
}

export namespace PulseOpsService {
  const detailMapper = (
    task: TaskDetailApi.Type,
    taskDetailOTP: (otp: boolean) => (payload: TaskDetail.Payload) => TaskDetail.Type
  ): Task<TaskDetail.Type> =>
    pipe(
      task.transactionType === TransactionType.VOICE_RECORDING
        ? ZIO.succeed({ poName: '', poDob: '', poExternalIds: '', otpConsent: '' })
        : isClientId(task.transactionType)
          ? getPoNameData(task.clientNumber || '')
          : task.transactionType === TransactionType.NEW_CLAIM_REGISTER ||
            task.transactionType === TransactionType.CHANGE_CONTACT_INFO_ADDRESS_DIGITAL
            ? getPolicyDigital(task.policyNumber || (task.proposalNumber || ''))
            : getPoNameDataByPolicy(task.correlationId || '', task.clientNumber || ''),
      ZIO.flatMap(({ poName, poDob, poExternalIds, otpConsent }) => {
        const taskDetail = taskDetailOTP(otpConsent === '1')
        switch (task.transactionType) {
          case TransactionType.BENEFICIARY_DESIGNATION: {
            const clientIds = getBenClientIds(task)
            return pipe(
              ZIO.zipPar(
                PulseOpsApi.getPolicy(task.correlationId || ''),
                SubmissionService.getClients(clientIds.map((id) => ({ clientId: id }))),
                GeneralService.getCountries,
                GeneralService.getProvinces
              ),
              ZIO.flatMap(([policy, clients, countries, provinces]) =>
                pipe(
                  ZIO.zipPar(
                    PulseOpsApi.getCustomer(policy.body.owners.id ?? ''),
                    BeneficiaryDesignationService.getSeaBankMessgeList(
                      task.correlationId ?? '',
                      policy.body.owners.id ?? ''
                    )
                  ),
                  ZIO.map(([client, seaBankWarningMsg]) => {
                    return TaskDetail.ChangeBeneficialInfo(
                      task,
                      client,
                      poName,
                      clients.body,
                      countries,
                      provinces,
                      seaBankWarningMsg
                    )
                  }),
                  ZIO.map(taskDetail)
                )
              )
            )
          }
          case TransactionType.CHANGE_CONTACT_INFO_EMAIL: {
            let clientDuplicates: string[] = []
            if (
              (task.source === SourceType.PULSE4OPS || task.source === SourceType.PRUONLINE || task.source === SourceType.OUTBOUND || task.source === SourceType.INBOUND) &&
              task.payload.body.attributesExt
            ) {
              const mailDups =
                task.payload.body.attributesExt.EMAILS_DUP?.filter((e) => !!e.clientId).map((e) => e.clientId ?? '') ??
                []
              clientDuplicates = [...new Set(mailDups)]
            }

            return pipe(
              ZIO.zipPar(PulseOpsApi.getCustomer(task.correlationId || ''), getCustomerNames(clientDuplicates)),
              ZIO.map(([client, dupClients]) => TaskDetail.ChangeEmailInfo(task, client, dupClients)),
              ZIO.map(taskDetail)
            )
          }
          case TransactionType.CHANGE_CONTACT_INFO_PHONE: {
            let clientDuplicates: string[] = []
            if (
              (task.source === SourceType.PULSE4OPS || task.source === SourceType.PRUONLINE || task.source === SourceType.OUTBOUND || task.source === SourceType.INBOUND) &&
              task.payload.body.attributesExt
            ) {
              const phoneDups =
                task.payload.body.attributesExt.PHONES_DUP?.filter((e) => !!e.clientId).map((e) => e.clientId ?? '') ??
                []
              clientDuplicates = [...new Set(phoneDups)]
            }

            return pipe(
              ZIO.zipPar(PulseOpsApi.getCustomer(task.correlationId || ''), getCustomerNames(clientDuplicates)),
              ZIO.map(([client, dupClients]) => TaskDetail.ChangePhoneInfo(task, client, dupClients)),
              ZIO.map(taskDetail)
            )
          }

          case TransactionType.CHANGE_CONTACT_INFO_ADDRESS:
          case TransactionType.CHANGE_CONTACT_INFO_ADDRESS_DIGITAL: {
            return pipe(
              ZIO.zipPar(PulseOpsApi.getCustomer(task.correlationId || ''), GeneralService.getProvinces),
              ZIO.flatMap(([client, provinces]) => {
                const { city, district, ward } = getDefaultAddress(task, client)
                return pipe(
                  ZIO.zipPar(
                    GeneralService.getDistricts(city),
                    GeneralService.getWards({ provinceCode: city, districtCode: district })
                  ),
                  ZIO.map(([districts, wards]) =>
                    TaskDetail.ChangePrimaryAddressInfo(task, client, {
                      city:
                        provinces?.find((d) => d.code === city)?.name ??
                        client?.body?.addressDetails.PRIMARY?.line1 ??
                        '-',
                      district:
                        districts?.find((d) => d.code === district)?.name ??
                        client?.body.addressDetails.PRIMARY?.line3 ??
                        '-',
                      ward: wards.find((d) => d.code === ward)?.name ?? client.body.addressDetails.PRIMARY?.line2 ?? '-'
                    })
                  ),
                  ZIO.map(taskDetail)
                )
              })
            )
          }
          case TransactionType.CHANGE_CONTACT_INFO_PHONE_AND_EMAIL:
          case TransactionType.CHANGE_CONTACT_INFO: {
            const isPermissionSource = task.source === SourceType.PULSE4OPS || task.source === SourceType.PRUONLINE || task.source === SourceType.OUTBOUND || task.source === SourceType.INBOUND
            let listOfAppliedPolicy: string[] | null | undefined
            const policyId =
              isPermissionSource && task.payload.body && task.payload.body.policyNo
                ? task.payload.body.policyNo
                : task.correlationId

            if (
              task.source !== SourceType.PULSE4OPS &&
              task.source !== SourceType.EKIOSK &&
              task.source !== SourceType.PRUONLINE &&
              task.source !== SourceType.ZALO &&
              task.source !== SourceType.BANCA_SEA &&
              task.source !== SourceType.OUTBOUND &&
              task.source !== SourceType.INBOUND
              //task.source !== SourceType.ONLINE_PAYMENT
            ) {
              listOfAppliedPolicy = task.payload.body.listOfAppliedPolicy
            }
            let clientDuplicates: string[] = []
            if (isPermissionSource && task.payload.body.attributesExt) {
              const phoneDups =
                task.payload.body.attributesExt.PHONES_DUP?.filter((e) => !!e.clientId).map((e) => e.clientId ?? '') ??
                []
              const mailDups =
                task.payload.body.attributesExt.EMAILS_DUP?.filter((e) => !!e.clientId).map((e) => e.clientId ?? '') ??
                []
              clientDuplicates = [...new Set(phoneDups.concat(mailDups))]
            }

            return pipe(
              ZIO.zipPar(
                PulseOpsApi.getPolicy(policyId ?? ''),
                getPolicyList(listOfAppliedPolicy || []),
                getCustomerNames(clientDuplicates)
              ),
              ZIO.flatMap(([policy, policies, clients]) =>
                pipe(
                  PulseOpsApi.getCustomer(policy.body.owners.id),
                  ZIO.flatMap((client) => {
                    const { city, district, ward } = getDefaultAddress(task, client)
                    return pipe(
                      ZIO.zipPar(
                        GeneralService.getProvinces,
                        GeneralService.getDistricts(city),
                        GeneralService.getWards({ provinceCode: city, districtCode: district })
                      ),
                      ZIO.map(([provinces, districts, wards]) =>
                        TaskDetail.ChangeContactInfo(task, client, policies, clients, {
                          city:
                            provinces.find((d) => d.code === city)?.name ??
                            client.body.addressDetails.PRIMARY?.line1 ??
                            '-',
                          district:
                            districts.find((d) => d.code === district)?.name ??
                            client.body.addressDetails.PRIMARY?.line3 ??
                            '-',
                          ward:
                            wards.find((d) => d.code === ward)?.name ?? client.body.addressDetails.PRIMARY?.line2 ?? '-'
                        })
                      ),
                      ZIO.map(taskDetail),
                      ZIO.map((taskDetail) => ({
                        ...taskDetail,
                        policyNumber: policyId || ''
                      }))
                    )
                  })
                )
              )
            )
          }
          case TransactionType.LOAN: {
            const policyList =
              task.payload?.body?.cashOutOptions &&
                task.payload?.body?.cashOutOptions.length > 0 &&
                task.payload?.body?.cashOutOptions[0].policy
                ? task.payload?.body?.cashOutOptions
                  .map((item) => {
                    return !!item.policy && !!item.policy?.policyNo ? String(item.policy.policyNo) : ''
                  })
                  .filter((e) => !!e)
                : []
            const branchCode: string =
              task.payload.body?.cashOutOptions
                ?.filter((e) => e.type === 'BANKTRANSFER' || e.type === 'PAID_AT_BANK')
                .map(
                  (e) => ((e.type === 'BANKTRANSFER' || e.type === 'PAID_AT_BANK') && e.bankAccount?.branchCode) || ''
                )[0] || '-'
            return pipe(
              ZIO.zipPar(
                PulseOpsApi.getCustomer(task.correlationId || ''),
                LoanService.getDetail(task.correlationId || ''),
                getCustomerPolicies(policyList),
                PayoutService.bankBranchByCode(branchCode)
              ),
              ZIO.map(([client, loanDetail, clients, branchs]) =>
                TaskDetail.CashOutLoan(
                  task,
                  client,
                  poName,
                  loanDetail,
                  clients,
                  branchs.length > 0 ? branchs[0].name : ''
                )
              ),
              ZIO.map(taskDetail)
            )
          }
          case TransactionType.LOAN_STATEMENTS: {
            return pipe(
              pipe(task, ZIO.succeed),
              ZIO.map((taskInfo) => TaskDetail.LoanStatements(taskInfo, poName)),
              ZIO.map(taskDetail)
            )
          }
          case TransactionType.PARTIAL_SURRENDER: {
            const cashoutPolicies = [
              ...new Set(
                task.payload.body?.cashOutOptions
                  ?.filter((e) => notEmpty(e.policy?.policyNo))
                  .map((e) => e.policy?.policyNo ?? '')
              )
            ]
            const branchCode: string =
              task.payload.body?.cashOutOptions
                ?.filter((e) => e.type === 'BANKTRANSFER' || e.type === 'PAID_AT_BANK')
                .map(
                  (e) => ((e.type === 'BANKTRANSFER' || e.type === 'PAID_AT_BANK') && e.bankAccount?.branchCode) || ''
                )[0] || '-'
            return pipe(
              ZIO.zipPar(
                ZIO.succeed(task),
                getCustomerPolicies(cashoutPolicies),
                // PayoutService.bankBranch(branchCode)
                PayoutService.bankBranchByCode(branchCode)
              ),
              ZIO.map(([taskInfo, clients, branchs]) =>
                TaskDetail.PartialSurrender(poName, taskInfo, clients, branchs.length > 0 ? branchs[0].name : undefined)
              ),
              ZIO.map(taskDetail)
            )
          }
          case TransactionType.CHANGE_SUM_ASSURED: {
            return pipe(ZIO.succeed(TaskDetail.ChangeSumAssured(task)), ZIO.map(taskDetail))
          }
          case TransactionType.CHANGE_PREMIUM: {
            return pipe(ZIO.succeed(TaskDetail.ChangePremium(task)), ZIO.map(taskDetail))
          }
          case TransactionType.PRUCASH_PAYMENT: {
            const cashoutPolicies = [
              ...new Set(
                task.payload.body?.cashOutOptions
                  ?.filter((e) => notEmpty(e.policy?.policyNo))
                  .map((e) => e.policy?.policyNo ?? '')
              )
            ]
            const branchCode: string =
              task.payload.body?.cashOutOptions
                ?.filter((e) => e.type === 'BANKTRANSFER' || e.type === 'PAID_AT_BANK')
                .map(
                  (e) => ((e.type === 'BANKTRANSFER' || e.type === 'PAID_AT_BANK') && e.bankAccount?.branchCode) || ''
                )[0] || '-'
            return pipe(
              ZIO.zipPar(
                PulseOpsApi.getCustomer(task.correlationId || ''),
                PruCashPaymentService.getDetail(task.correlationId || ''),
                getCustomerPolicies(cashoutPolicies),
                // PayoutService.bankBranch(branchCode)
                PayoutService.bankBranchByCode(branchCode)
              ),
              ZIO.map(([client, prucashData, clients, branchs]) =>
                TaskDetail.CashOutPrucash(
                  task,
                  client,
                  poName,
                  prucashData,
                  clients,
                  branchs.length > 0 ? branchs[0].name : undefined
                )
              ),
              ZIO.map(taskDetail)
            )
          }
          case TransactionType.MATURITY: {
            const cashoutPolicies = [
              ...new Set(
                task.payload.body?.cashOutOptions
                  ?.filter((e) => notEmpty(e.policy?.policyNo))
                  .map((e) => e.policy?.policyNo ?? '')
              )
            ]
            const branchCode: string =
              task.payload.body?.cashOutOptions
                ?.filter((e) => e.type === 'BANKTRANSFER' || e.type === 'PAID_AT_BANK')
                .map(
                  (e) => ((e.type === 'BANKTRANSFER' || e.type === 'PAID_AT_BANK') && e.bankAccount?.branchCode) || ''
                )[0] || '-'

            return pipe(
              ZIO.zipPar(
                PulseOpsApi.getPolicy(task.correlationId || ''),
                MaturityPaymentService.getDetail(task.correlationId || ''),
                getCustomerPolicies(cashoutPolicies),
                // PayoutService.bankBranch(branchCode)
                PayoutService.bankBranchByCode(branchCode)
              ),
              ZIO.flatMap(([policy, maturityData, clients, branchs]) =>
                pipe(
                  PulseOpsApi.getCustomer(policy.body.owners.id),
                  ZIO.map((client) =>
                    TaskDetail.CashOutMaturity(
                      task,
                      client,
                      poName,
                      maturityData,
                      clients,
                      branchs.length > 0 ? branchs[0].name : ''
                    )
                  ),
                  ZIO.map(taskDetail)
                )
              )
            )
          }
          case TransactionType.BILLING_CHANGE:
          case TransactionType.BILLING_CHANGE_CORPORATE: {
            return pipe(
              ZIO.zipPar(
                PulseOpsApi.getPolicy(task.correlationId || ''),
                PulseOpsApi.getBillingChangeData(task.correlationId || ''),
                PulseOpsApi.sqlBillingChangeReversalDate(task.correlationId || ''),
                PulseOpsApi.getAccountBalance({
                  policyNum: task.correlationId || '',
                  function: 'TOTAL',
                  accountGroups: [
                    {
                      sacsCode: 'LP', //suspend
                      sacsType: 'S'
                    }
                  ]
                }),
                PulseOpsApi.getAccountBalance({
                  policyNum: task.correlationId || '',
                  function: 'TOTAL',
                  accountGroups: [
                    {
                      sacsCode: 'LN',
                      sacsType: 'VP'
                    }
                  ]
                }),
                BillingChangeService.getDetail(task.correlationId || '')
              ),
              ZIO.map(([policy, billingData, date, accountBalanceLP, accountBalanceVP, billingChangeData]) => {
                return TaskDetail.BillingChange(
                  task,
                  policy,
                  billingData,
                  poName,
                  accountBalanceLP.body.accountGroups,
                  accountBalanceVP.body.accountGroups,
                  billingChangeData
                )
              }),
              ZIO.map(taskDetail)
            )
          }
          case TransactionType.REINSTATEMENT: {
            return pipe(
              ZIO.zipPar(
                GeneralInfoService.getClientsInfoData(task.correlationId || ''),
                ReinstatementService.getReinData(task.correlationId || '')
              ),
              ZIO.map(([sqlFunds, reinData]) => TaskDetail.Reinstatement(task, sqlFunds.laClient, reinData, poName)),
              ZIO.map(taskDetail)
            )
          }
          case TransactionType.BILLING_FREQUENCY_REVERSAL: {
            return pipe(
              ZIO.zipPar(
                SubmissionService.getPolicy(task.correlationId || ''),
                SubmissionService.getPolicyHeader(task.correlationId || ''),
                SubmissionService.getPolicies([
                  {
                    policyNo: task.correlationId || '',
                    function: 'BILLREVERT'
                  }
                ])
              ),
              ZIO.map(([info, clientName, policyInfo]) => {
                console.log(policyInfo)
                return TaskDetail.BillingChangeReversal(task, clientName.ownerName, info.body, policyInfo[0])
              }),
              ZIO.map(taskDetail)
            )
          }

          // case TransactionType.CHANGE_NATIONAL_ID_CARD:
          case TransactionType.CHANGE_CLIENT_INFO: {
            const occupation = GeneralService.getOccupations
            const countries = GeneralService.getCountries
            const provinces = GeneralService.getProvinces
            const getDefaultData = (clientId: string) =>
              ZIO.zipPar(PulseOpsApi.getCustomer(clientId), countries, provinces)

            return task.source === SourceType.PRUONLINE
              ? pipe(
                PulseOpsApi.getPolicy(task.policyNumber || ''),
                ZIO.flatMap((policy) =>
                  pipe(
                    getDefaultData(policy.body.owners.id),
                    // PulseOpsApi.getCustomer(policy.body.owners.id),
                    ZIO.map(([customer, countries, provinces]) => {
                      task.correlationId = policy.body.owners.id
                      return TaskDetail.ChangeClientInfo(task, customer, countries, provinces)
                    }),
                    ZIO.map(taskDetail)
                  )
                )
              )
              : task.source === SourceType.PULSE4OPS
                ? pipe(
                  ZIO.zipPar(
                    // PulseOpsApi.getCustomer(task.correlationId),
                    getDefaultData(task.payload.body.attributesExt?.clientNum || ''),
                    PulseOpsApi.getCustomer(task.payload.body.attributesExt?.clientNum || ''),
                    occupation
                  ),
                  ZIO.map(([[customer, countries, provinces], target, occ]) =>
                    TaskDetail.ChangeClientInfo(task, customer, countries, provinces, occ, target)
                  ),
                  ZIO.map(taskDetail)
                )
                : pipe(
                  getDefaultData(task.correlationId || ''),
                  ZIO.map(([customer, countries, provinces]) =>
                    TaskDetail.ChangeClientInfo(task, customer, countries, provinces)
                  ),
                  ZIO.map(taskDetail)
                )
          }
          case TransactionType.TOP_UP: {
            return pipe(
              ZIO.zipPar(
                TopUpService.getAccountBalance(task.correlationId || ''),
                SubmissionService.getPolicy(task.correlationId || '')
              ),
              ZIO.map(([balance, policyInfo]) =>
                TaskDetail.Topup(task, poName, balance, String(policyInfo.body?.basicCode))
              ),
              ZIO.map(taskDetail)
            )
          }
          case TransactionType.CONFIRMATION_OF_PREMIUM_RECEIPT: {
            return pipe(
              SubmissionService.getOwnerInfo(task.correlationId || ''),
              ZIO.map((customer) => TaskDetail.ConfirmationPremiumReceipt(task, customer)),
              ZIO.map(taskDetail)
            )
          }
          case TransactionType.CANCEL_FROM_INCEPTION: {
            const cashoutPolicies = [
              ...new Set(
                task.payload.body &&
                  task.payload.body?.policiesReceived &&
                  task.payload.body?.policiesReceived.length > 0
                  ? task.payload.body.policiesReceived
                    .filter((e) => notEmpty(e.policyReceivedNum))
                    .map((e) => e.policyReceivedNum ?? '')
                  : task.payload.body?.cashOutOptions
                    ?.filter((e) => notEmpty(e.policy?.policyNo))
                    .map((e) => e.policy?.policyNo ?? '')
              )
            ]
            const branchCode: string =
              task.payload.body && task.payload.body?.policiesReceived && task.payload.body?.policiesReceived.length > 0
                ? task.payload.body?.policiesReceived
                  ?.filter((e) => e.payoutOptionType === 'BANKTRANSFER' || e.payoutOptionType === 'PAID_AT_BANK')
                  .map(
                    (e) =>
                      ((e.payoutOptionType === 'BANKTRANSFER' || e.payoutOptionType === 'PAID_AT_BANK') &&
                        e.bankKey) ||
                      ''
                  )[0] ?? '-'
                : task.payload.body?.cashOutOptions
                  ?.filter((e) => e.type === 'BANKTRANSFER' || e.type === 'PAID_AT_BANK')
                  .map(
                    (e) =>
                      ((e.type === 'BANKTRANSFER' || e.type === 'PAID_AT_BANK') && e.bankAccount?.branchCode) || ''
                  )[0] ?? '-'
            return pipe(
              ZIO.zipPar(
                SubmissionService.getOwnerInfo(task.correlationId || ''),
                PulseOpsApi.sqlCFI(task.correlationId || ''),
                getCustomerPolicies(cashoutPolicies),
                CancelFromInceptionService.getDetail(task.correlationId || ''),
                // PayoutService.bankBranch(branchCode)
                PayoutService.bankBranchByCode(branchCode)
              ),
              ZIO.map(([client, cfi, clientInfo, cfiDetail, branchs]) => {
                return TaskDetail.CancelFromInception(
                  task,
                  `${client.body.surName} ${client.body.firstName}`,
                  cfi,
                  clientInfo,
                  cfiDetail,
                  branchs.length > 0 ? branchs[0].name : ''
                )
              }),
              ZIO.map(taskDetail)
            )
          }
          case TransactionType.CANCEL_RIDER: {
            return pipe(
              ZIO.zipPar(
                SubmissionService.getPolicy(task.payload.body.policyNo || task.correlationId || ''),
                CancelRiderService.getClientRiderInfo(task.payload.body.policyNo || task.correlationId || ''),
                TopUpService.GetPolicyExtraInfo([task.payload.body.policyNo || task.correlationId || ''], ''),
                CancelRiderService.getRiderInfoList()
              ),
              ZIO.map(([policyInfo, ClientRiderInfo, extraPolicyInfo, riderNameList]) =>
                TaskDetail.CancelRider(
                  task,
                  ClientRiderInfo,
                  poName,
                  policyInfo.body?.totalPremium,
                  extraPolicyInfo,
                  riderNameList
                )
              ),
              ZIO.map(taskDetail)
            )
          }
          case TransactionType.EXCESS_PREMIUM: {
            const cashoutPolicies = [SourceType.PULSE4OPS, SourceType.PRUONLINE].includes(task.source)
              ? [
                ...new Set(
                  task.payload.body.cashOutOptions
                    ?.filter((e) => notEmpty(e.policy?.policyNo))
                    .map((e) => e.policy?.policyNo ?? '')
                )
              ]
              : []
            const branchCode: string =
              ([SourceType.PULSE4OPS, SourceType.PRUONLINE].includes(task.source) &&
                task.payload.body?.cashOutOptions
                  ?.filter((e) => e.type === 'BANKTRANSFER' || e.type === 'PAID_AT_BANK')
                  .map(
                    (e) => ((e.type === 'BANKTRANSFER' || e.type === 'PAID_AT_BANK') && e.bankAccount?.branchCode) || ''
                  )[0]) ||
              '-'

            return pipe(
              ZIO.zipPar(
                ExcessPremiumService.getDetail(task.correlationId || ''),
                getCustomerPolicies(cashoutPolicies),
                // PayoutService.bankBranch(branchCode)
                PayoutService.bankBranchByCode(branchCode)
              ),
              ZIO.map(([excess, client, branchList]) =>
                TaskDetail.ExcessPremium(
                  task,
                  client,
                  poName,
                  excess,
                  branchList && branchList.length > 0 ? branchList[0].name : ''
                )
              ),
              ZIO.map(taskDetail)
            )
          }

          case TransactionType.AUTO_DEBIT_TOPUP_ACCOUNT_REQUEST:
          case TransactionType.AUTO_DEBIT_TOPUP_ACCOUNT_CANCEL: {
            return pipe(ZIO.succeed(TaskDetail.PartialWithdrawalAutoDebit(task)), ZIO.map(taskDetail))
          }
          case TransactionType.PARTIAL_WITHDRAWAL: {
            // return pipe(
            //   ZIO.zipPar(
            //     PulseOpsApi.getCustomer(task.payload.body.poClientNum || task.correlationId),
            //     PulseOpsApi.sqlPwFunds(task.correlationId)
            //   ),
            //   ZIO.map(([client, sqlFunds]) => TaskDetail.PartialWithdrawal(task, client, sqlFunds)),
            //   ZIO.map(taskDetail)
            // )
            const cashoutPolicies = [
              ...new Set(
                task.payload.body?.cashOutOptions
                  ?.filter((e) => notEmpty(e.policy?.policyNo))
                  .map((e) => e.policy?.policyNo ?? '')
              )
            ]
            const branchCode: string =
              task.payload.body?.cashOutOptions
                ?.filter((e) => e.type === 'BANKTRANSFER' || e.type === 'PAID_AT_BANK')
                .map(
                  (e) => ((e.type === 'BANKTRANSFER' || e.type === 'PAID_AT_BANK') && e.bankAccount?.branchCode) || ''
                )[0] || '-'
            return pipe(
              ZIO.zipPar(
                getCustomerPolicies(cashoutPolicies),
                // PayoutService.bankBranch(branchCode)
                PayoutService.bankBranchByCode(branchCode),
                BeneficiaryDesignationService.getSeaBankMessgeList(task.correlationId ?? '', task.clientNumber ?? '')
              ),
              ZIO.map(([clients, branchs, seaBankWarningMsg]) =>
                TaskDetail.PartialWithdrawal(
                  task,
                  poName,
                  clients,
                  branchs.length > 0 ? branchs[0].name : '',
                  seaBankWarningMsg
                )
              ),
              ZIO.map(taskDetail)
            )
          }
          case TransactionType.MATURITY_ADVANCE: {
            const cashoutPolicies = [
              ...new Set(
                task.payload.body?.cashOutOptions
                  ?.filter((e) => notEmpty(e.policy?.policyNo))
                  .map((e) => e.policy?.policyNo ?? '')
              )
            ]
            return pipe(
              ZIO.zipPar(MaturityAdvanceService.getDetail(task.correlationId || ''), getCustomerPolicies(cashoutPolicies)),
              ZIO.map(([maturityAdvData, clientInfo]) =>
                TaskDetail.MaturityAdvance(task, poName, maturityAdvData, clientInfo)
              ),
              ZIO.map(taskDetail)
            )
          }
          case TransactionType.GO_ABROAD: {
            return pipe(ZIO.succeed(TaskDetail.GoAbroad(task, poName)), ZIO.map(taskDetail))
          }
          case TransactionType.POLICY_CERTIFICATE: {
            return pipe(
              pipe(task, ZIO.succeed),
              ZIO.map((taskInfo) => TaskDetail.PolicyCertificate(taskInfo, poName)),
              ZIO.map(taskDetail)
            )
          }

          case TransactionType.CONFIRMATION_LETTER_OF_POLICY_ACCOUNT_AND_SURRENDER_VALUE: {
            return pipe(
              pipe(task, ZIO.succeed),
              ZIO.map((taskInfo) => TaskDetail.PolicyCertificate(taskInfo, poName)),
              ZIO.map(taskDetail)
            )
          }

          //COMPLAINT,INQUIRY category
          case TransactionType.COMPLAINT_AND_INQUIRY:
          case TransactionType.INQUIRY:
          case TransactionType.COMPLAINT: {
            const category = task.payload.body.attributesExt.CATEGORY_CODE === 'COMPLAINT' ? 'CH' : 'CS'
            const officeCode = task.payload.officeCode ?? ''
            return pipe(
              ZIO.zipPar(
                InquiryComplaintService.getDetail(task.correlationId || ''),
                InquiryComplaintService.getTypes(undefined),
                InquiryComplaintService.getSubType(undefined),
                InquiryComplaintService.getComplaintSubtypes(task.payload.body.attributesExt.SUB_TRANS_TYPE_CODE ?? ''),
                InquiryComplaintService.getDocTypeListIncludeMetaData([category], officeCode),
                ZIO.zipPar(
                  category === 'CH' ? InquiryComplaintService.getT22Data('evidence-status') : ZIO.succeed([]),
                  category === 'CH' ? InquiryComplaintService.getT22Data('fna') : ZIO.succeed([]),
                  InquiryComplaintService.getDiaryTypeList(),
                  category === 'CH' ? InquiryComplaintService.getT22Data('role') : ZIO.succeed([]),
                  category === 'CH' ? InquiryComplaintService.getGeneralAgency() : ZIO.succeed([]),
                  category === 'CH' ? InquiryComplaintService.getT22Data('receipt-type') : ZIO.succeed([])
                ),
                ZIO.zipPar(
                  category === 'CH' ? InquiryComplaintService.getComplaintAgainst() : ZIO.succeed([]),
                  category === 'CH' ? InquiryComplaintService.getResolution() : ZIO.succeed([]),
                  category === 'CH' ? InquiryComplaintService.getResponseMethod() : ZIO.succeed([]),
                  category === 'CH' ? InquiryComplaintService.getReasonForDealing() : ZIO.succeed([]),
                  category === 'CH' ? InquiryComplaintService.getCorrectiveSettlement() : ZIO.succeed([]),
                  category === 'CH' ? InquiryComplaintService.getReason() : ZIO.succeed([]),
                  category === 'CH' ? InquiryComplaintService.getReasonForExceedingTAT() : ZIO.succeed([])
                )
              ),
              ZIO.map(
                ([
                  coreDataInfo,
                  serviceTypeList,
                  transactionSubTypeList,
                  slaStandard,
                  documentTypeArr,
                  [
                    evidenceStatusArr,
                    financialNeedAnalysisList,
                    diaryTypeList,
                    roleList,
                    generalAgencyList,
                    receiptTypeList
                  ],
                  [
                    complaintAgainstList,
                    resolutionList,
                    responseMethodList,
                    reasonForDealingList,
                    correctiveSettlementList,
                    reasonList,
                    reasonForExceedingTAT
                  ]
                ]) =>
                  TaskDetail.InquiryComplaint(
                    task,
                    poName,
                    coreDataInfo,
                    serviceTypeList,
                    transactionSubTypeList,
                    {
                      roleList,
                      generalAgencyList,
                      evidenceStatusArr,
                      financialNeedAnalysisList,
                      receiptTypeList
                    },
                    {
                      complaintAgainstList,
                      resolutionList,
                      responseMethodList,
                      reasonForDealingList,
                      correctiveSettlementList,
                      reasonList,
                      reasonForExceedingTAT,
                      diaryTypeList
                    },
                    slaStandard,
                    documentTypeArr
                  )
              ),
              ZIO.map(taskDetail)
            )
          }

          case TransactionType.PREMIUM_REDIRECTION: {
            return pipe(ZIO.succeed(TaskDetail.PremiumRedirection(task, poName)), ZIO.map(taskDetail))
          }

          case TransactionType.FULL_SURRENDER_REVERSAL: {
            const clientIDList = task.payload.body.lifeAssureds.map((lifeItem) => {
              return {
                clientId: lifeItem.attributesExt.CLIENT_NO
              }
            })
            return pipe(
              ZIO.zipPar(GeneralService.getOccupations, SubmissionService.getClients(clientIDList)),
              ZIO.map(([occupationList, clientList]) =>
                TaskDetail.FullSurrenderReversal(task, occupationList, clientList.body, poName)
              ),
              ZIO.map(taskDetail)
            )
          }

          case TransactionType.ZALO_REQUEST_PROCESS: {
            return pipe(
              ZIO.zipPar(PulseOpsApi.getCustomer(task.correlationId || ''), PulseOpsApi.sqlPoName(task.correlationId || '')),
              ZIO.map(([client, sql]) => TaskDetail.ZaloRequestProcess(task, client, sql)),
              ZIO.map(taskDetail)
            )
          }
          case TransactionType.ZALO_RECOMMENDATION: {
            return pipe(
              ZIO.zipPar(PulseOpsApi.getCustomer(task.correlationId || ''), PulseOpsApi.sqlPoName(task.correlationId || '')),
              ZIO.map(([client, sql]) => TaskDetail.ZaloRecommendation(task, client, sql)),
              ZIO.map(taskDetail)
            )
          }
          case TransactionType.ZALO_PREMIUM_COLLECTION: {
            return pipe(
              ZIO.zipPar(PulseOpsApi.getCustomer(task.correlationId || ''), PulseOpsApi.sqlPoName(task.correlationId || '')),
              ZIO.map(([client, sql]) => TaskDetail.ZaloPremiumCollection(task, client, sql)),
              ZIO.map(taskDetail)
            )
          }
          case TransactionType.ZALO_CLAIM_DECISION: {
            return pipe(
              ZIO.zipPar(PulseOpsApi.getCustomer(task.correlationId || ''), PulseOpsApi.sqlPoName(task.correlationId || '')),
              ZIO.map(([client, sql]) => TaskDetail.ZaloClaimDecision(task, client, sql)),
              ZIO.map(taskDetail)
            )
          }
          case TransactionType.ZALO_OTHERS: {
            return pipe(
              ZIO.zipPar(PulseOpsApi.getCustomer(task.correlationId || ''), PulseOpsApi.sqlPoName(task.correlationId || '')),
              ZIO.map(([client, sql]) => TaskDetail.ZaloOther(task, client, sql)),
              ZIO.map(taskDetail)
            )
          }
          case TransactionType.E_SERVICE_REGISTRATION: {
            return pipe(ZIO.succeed(TaskDetail.Eservice(task, poName)), ZIO.map(taskDetail))
          }
          case TransactionType.SPECIAL_BONUS:
          case TransactionType.SPECIAL_BONUS_CUSTOMER: {
            const cashoutPolicies = [
              ...new Set(
                task.payload.body?.cashOutOptions
                  ?.filter((e) => notEmpty(e.policy?.policyNo))
                  .map((e) => e.policy?.policyNo ?? '')
              )
            ]
            // const getAmount = () => pipe(
            //   ZIO.zipPar(SpecialBonusService.getAccountBalance(task?.policyNumber || '', 'B811'), SpecialBonusService.getAccountBalance(task?.policyNumber || '', 'T209')),
            //   ZIO.map(([amount1, amount2]) => amount2 > 0 ? amount1 - amount2 : amount1)
            // )
            const branchCode: string =
              task.payload.body?.cashOutOptions
                ?.filter((e) => e.type === 'BANKTRANSFER' || e.type === 'PAID_AT_BANK')
                .map(
                  (e) => ((e.type === 'BANKTRANSFER' || e.type === 'PAID_AT_BANK') && e.bankAccount?.branchCode) || ''
                )[0] || '-'
            return pipe(
              ZIO.zipPar(
                getCustomerPolicies(cashoutPolicies),
                ClaimService.getBanks(),
                SpecialBonusService.getAccountBalance(task?.policyNumber || '', 'SPECIALBONUS'),
                // PayoutService.bankBranch(branchCode)
                PayoutService.bankBranchByCode(branchCode)
              ),
              ZIO.map(([clients, banks, amount, branchs]) =>
                TaskDetail.CashOutSpecialBonus(
                  poName,
                  task,
                  clients,
                  banks,
                  amount,
                  branchs.length > 0 ? branchs[0].name : undefined
                )
              ),
              ZIO.map(taskDetail)
            )
          }

          // case TransactionType.SPECIAL_BONUS_CUSTOMER: {
          //   const cashoutPolicies = [
          //     ...new Set(
          //       task.payload.body?.cashOutOptions
          //         ?.filter((e) => notEmpty(e.policy?.policyNo))
          //         .map((e) => e.policy?.policyNo ?? '')
          //     )
          //   ]

          //   const getAmount = () => pipe(
          //     ZIO.zipPar(SpecialBonusService.getAccountBalance(task?.policyNumber || '', 'B811'), SpecialBonusService.getAccountBalance(task?.policyNumber || '', 'T209')),
          //     ZIO.map(([amount1, amount2]) => amount2 > 0 ? amount1 - amount2 : amount1)
          //   )

          //   return pipe(
          //     // getCustomerPolicies(cashoutPolicies),
          //     // ZIO.map((clients) => TaskDetail.CashOutSpecialBonus(poName, task, clients, [])),
          //     ZIO.zipPar(getCustomerPolicies(cashoutPolicies), ClaimService.getBanks()),
          //     ZIO.map(([clients, banks]) => TaskDetail.CashOutSpecialBonus(poName, task, clients, banks, amount)),
          //     ZIO.map(taskDetail)
          //   )
          // }
          case TransactionType.EXCELLENT_STUDENT_AWARD: {
            const cashoutPolicies = [
              ...new Set(
                task.payload.body?.cashOutOptions
                  ?.filter((e) => notEmpty(e.policy?.policyNo))
                  .map((e) => e.policy?.policyNo ?? '')
              )
            ]
            const branchCode: string =
              task.payload.body?.cashOutOptions
                ?.filter((e) => e.type === 'BANKTRANSFER' || e.type === 'PAID_AT_BANK')
                .map(
                  (e) => ((e.type === 'BANKTRANSFER' || e.type === 'PAID_AT_BANK') && e.bankAccount?.branchCode) || ''
                )[0] || '-'
            return pipe(
              ZIO.zipPar(
                ExcellentStudentAwardService.getDetail(task.correlationId || ''),
                getCustomerPolicies(cashoutPolicies),
                // PayoutService.bankBranch(branchCode)
                PayoutService.bankBranchByCode(branchCode)
              ),
              ZIO.map(([detail, clients, branchs]) =>
                TaskDetail.ExcellentStudentAward(
                  task,
                  detail,
                  poName,
                  clients,
                  branchs.length > 0 ? branchs[0].name : undefined
                )
              ),
              ZIO.map(taskDetail)
            )
          }
          case TransactionType.FULL_SURRENDER: {
            const cashoutPolicies = [
              ...new Set(
                task.payload.body?.cashOutOptions
                  ?.filter((e) => notEmpty(e.policy?.policyNo))
                  .map((e) => e.policy?.policyNo ?? '')
              )
            ]
            const branchCode: string =
              task.payload.body?.cashOutOptions
                ?.filter((e) => e.type === 'BANKTRANSFER' || e.type === 'PAID_AT_BANK')
                .map(
                  (e) => ((e.type === 'BANKTRANSFER' || e.type === 'PAID_AT_BANK') && e.bankAccount?.branchCode) || ''
                )[0] || '-'
            return pipe(
              ZIO.zipPar(
                ZIO.succeed(task),
                getCustomerPolicies(cashoutPolicies),
                // PayoutService.bankBranch(branchCode)
                PayoutService.bankBranchByCode(branchCode),
                BeneficiaryDesignationService.getSeaBankMessgeList(task.correlationId ?? '', task.clientNumber ?? '')
              ),
              ZIO.map(([taskInfo, clients, branchs, seaBankWarningMsg]) =>
                TaskDetail.FullSurrender(
                  poName,
                  taskInfo,
                  clients,
                  branchs.length > 0 ? branchs[0].name : undefined,
                  seaBankWarningMsg
                )
              ),
              ZIO.map(taskDetail)
            )
          }

          case TransactionType.PRUKID369: {
            const cashoutPolicies = [
              ...new Set(
                task.payload.body?.cashOutOptions
                  ?.filter((e) => notEmpty(e.policy?.policyNo))
                  .map((e) => e.policy?.policyNo ?? '')
              )
            ]
            const branchCode: string =
              task.payload.body?.cashOutOptions
                ?.filter((e) => e.type === 'BANKTRANSFER' || e.type === 'PAID_AT_BANK')
                .map(
                  (e) => ((e.type === 'BANKTRANSFER' || e.type === 'PAID_AT_BANK') && e.bankAccount?.branchCode) || ''
                )[0] || '-'
            return pipe(
              ZIO.zipPar(
                PruKid369Service.getDetailVer(task.correlationId || ''),
                getCustomerPolicies(cashoutPolicies),
                // PayoutService.bankBranch(branchCode)
                PayoutService.bankBranchByCode(branchCode)
              ),
              ZIO.map(([detail, clients, branchs]) =>
                TaskDetail.Prukid369(task, detail, poName, clients, branchs.length > 0 ? branchs[0].name : undefined)
              ),
              ZIO.map(taskDetail)
            )
          }
          case TransactionType.BONUS_SURRENDER: {
            const cashoutPolicies = [
              ...new Set(
                task.payload.body?.cashOutOptions
                  ?.filter((e) => notEmpty(e.policy?.policyNo))
                  .map((e) => e.policy?.policyNo ?? '')
              )
            ]
            const branchCode: string =
              task.payload.body?.cashOutOptions
                ?.filter((e) => e.type === 'BANKTRANSFER' || e.type === 'PAID_AT_BANK')
                .map(
                  (e) => ((e.type === 'BANKTRANSFER' || e.type === 'PAID_AT_BANK') && e.bankAccount?.branchCode) || ''
                )[0] || '-'
            return pipe(
              ZIO.zipPar(
                getCustomerPolicies(cashoutPolicies),
                // PayoutService.bankBranch(branchCode)
                PayoutService.bankBranchByCode(branchCode)
              ),
              ZIO.map(([clients, branchs]) =>
                TaskDetail.BonusSurrender(task, poName, clients, branchs.length > 0 ? branchs[0].name : '')
              ),
              ZIO.map(taskDetail)
            )
          }
          case TransactionType.FUND_SWITCHING:
            return pipe(ZIO.succeed(TaskDetail.FundSwitching(task, poName)), ZIO.map(taskDetail))
          case TransactionType.FATCA_DECLARATION: {
            return pipe(
              PulseOpsApi.getCustomer(task.correlationId || ''),
              ZIO.map((customer) => TaskDetail.FatcaDeclaration(task, customer.body.name, customer.body.clientId)),
              ZIO.map(taskDetail)
            )
          }
          case TransactionType.HEALTH_DECLARATION: {
            return pipe(
              PulseOpsApi.getCustomer(task.correlationId || ''),
              ZIO.map((customer) => TaskDetail.HealthRedeclaration(task, poName, customer.body.clientId)),
              ZIO.map(taskDetail)
            )
          }
          case TransactionType.SIGNATURE_REGISTRATION:
            return pipe(
              SubmissionService.getOwnerInfoExt(task.correlationId || ''),
              ZIO.map((data) => TaskDetail.SignatureRegistration(task, poName, data.body.clientId)),
              ZIO.map(taskDetail)
            )
          case TransactionType.STOP_APL:
            return pipe(
              SubmissionService.getPolicy(task.correlationId || ''),
              ZIO.map((data) => TaskDetail.StopApl(task, poName)),
              ZIO.map(taskDetail)
            )
          case TransactionType.ASSIGNMENT:
            return pipe(ZIO.succeed(TaskDetail.Assignment(task, poName)), ZIO.map(taskDetail))
          case TransactionType.PAID_UP:
            return pipe(ZIO.succeed(TaskDetail.PaidUp(task, poName)), ZIO.map(taskDetail))
          case TransactionType.INHERITANCE: {
            let clientDuplicates: string[] = []
            if (task.source === SourceType.PULSE4OPS && task.payload.body.attributesExt) {
              const phoneDups =
                task.payload.body.attributesExt.PHONES_DUP?.filter((e) => !!e.clientId).map((e) => e.clientId ?? '') ??
                []
              const mailDups =
                task.payload.body.attributesExt.EMAILS_DUP?.filter((e) => !!e.clientId).map((e) => e.clientId ?? '') ??
                []
              clientDuplicates = [...new Set(phoneDups.concat(mailDups))]
            }

            return pipe(
              ZIO.zipPar(
                getCustomerNames(clientDuplicates),
                ChangeContactInfoService.getPolicyList(
                  task.payload.body.policyNo ?? '',
                  task.payload.body.clientId ?? ''
                )
              ),
              ZIO.flatMap(([clients, policyList]) =>
                !task.payload.body.clientNo
                  ? ZIO.succeed(TaskDetail.Inheritance(task, poName, 'Other', clients, policyList))
                  : pipe(
                    SubmissionService.getCustomer(task.payload.body.clientNo),
                    ZIO.flatMap((c) =>
                      ZIO.succeed(
                        TaskDetail.Inheritance(
                          task,
                          poName,
                          `${c.body.surName} ${c.body.firstName}`,
                          clients,
                          policyList
                        )
                      )
                    )
                  )
              ),
              ZIO.map(taskDetail)
            )
          }
          case TransactionType.SCHEDULED_PAYMENT_STATEMENT:
            return pipe(ZIO.succeed(TaskDetail.ScheduledPaymentStatement(task, poName)), ZIO.map(taskDetail))
          case TransactionType.PAYOUT:
            return pipe(ZIO.succeed(TaskDetail.VerificationClaimPayout(task, poName)), ZIO.map(taskDetail))
          case TransactionType.NEW_CLAIM_REGISTER:
            return pipe(
              ZIO.zipPar(GeneralService.getSubClaimTypeBenefit(), getPolicyDigitalPVA(task.policyNumber || '')),
              ZIO.flatMap(([subClaimData, pva]) => {
                return ZIO.succeed(TaskDetail.VerificationClaim(task, poName, subClaimData, pva, poDob, poExternalIds))
              }),
              ZIO.map(taskDetail)
            )

          case TransactionType.OTHER_MAJOR_ALTERATION:
          case TransactionType.OTHER_MINOR_ALTERATION:
          case TransactionType.OTHER_PAYOUT:
          case TransactionType.MAJOR_CHANGE_IN_FREE_LOOK:
          case TransactionType.MAJOR_CHANGE_AFTER_FREE_LOOK:
          case TransactionType.COMMON_REQUEST_FORM:
            return pipe(
              CommonRequestService.getCommonRequestList(),
              ZIO.flatMap((requestList) => ZIO.succeed(TaskDetail.CommonRequest(task, poName, requestList))),
              ZIO.map(taskDetail)
            )

          case TransactionType.PRODUCT_OPTION_SWITCHING:
            return pipe(
              ProductOptionSwitchingService.getDetail(task.policyNumber || ''),
              ZIO.map((detail) => ({
                converageCode: detail.coverageCode,
                benefitOption: detail.benefitOption,
                currentSumAssured: detail.currentSumAssured,
                curRenewalPremium: detail.curRenewalPremium
              })),
              ZIO.map((val) => TaskDetail.ProductOptionSwitching(task, val)),
              ZIO.map(taskDetail)
            )
          case TransactionType.REDATING:
            return pipe(
              ZIO.zipPar(GeneralService.getOccupations, ReinstatementService.getReinData(task.correlationId || '')),
              ZIO.map(([occupationList, reinData]) => TaskDetail.Redating(task, poName, occupationList, reinData)),
              ZIO.map(taskDetail)
            )
          case TransactionType.RIDER_REINSTATEMENT:
            return pipe(
              ZIO.zipPar(GeneralService.getOccupations, ReinstatementService.getReinData(task.correlationId || '')),
              ZIO.map(([occupationList, reinData]) =>
                TaskDetail.RiderReinstatement(task, poName, occupationList, reinData)
              ),
              ZIO.map(taskDetail)
            )
          case TransactionType.RIDER_ALTERATION:
            const commonRequest = task.payload.body.request
            return !!commonRequest
              ? pipe(
                CommonRequestService.getCommonRequestList(),
                ZIO.flatMap((requestList) => ZIO.succeed(TaskDetail.CommonRequest(task, poName, requestList))),
                ZIO.map(taskDetail)
              )
              : pipe(
                ZIO.zipPar(
                  CancelRiderService.getClientRiderInfo(task.policyNumber || task.correlationId || ''),
                  TopUpService.GetPolicyExtraInfo([task.policyNumber || task.correlationId || ''], ''),
                  CancelRiderService.getRiderInfoList(),
                  RiderAlterationService.getWaiverProducts('TR517', '')
                ),
                ZIO.map(([ClientRiderInfo, extraPolicyInfo, riderNameList, waiverList]) =>
                  TaskDetail.RiderAlteration(
                    task,
                    ClientRiderInfo,
                    poName,
                    extraPolicyInfo,
                    riderNameList,
                    waiverList
                  )
                ),
                ZIO.map(taskDetail)
              )
          case TransactionType.MAJOR_COMBINED_CHANGE:
            return pipe(
              ZIO.zipPar(
                GeneralService.getOccupations,
                ReinstatementService.getReinData(task.correlationId || ''),
                ProductOptionSwitchingService.getDetail(task.policyNumber || ''),
                BillingChangeService.getDetail(task.correlationId || ''),
                CancelRiderService.getClientRiderInfo(task.policyNumber || task.correlationId || ''),
                SubmissionService.getPolicy(task.policyNumber || task.correlationId || ''),
                ZIO.zipPar(RiderAlterationService.getWaiverProducts('TR517', ''), CancelRiderService.getRiderInfoList())
              ),
              ZIO.map(
                ([
                  occupationList,
                  reinData,
                  valueProductOption,
                  valueBillingChange,
                  ClientRiderInfo,
                  policyInfo,
                  [waiverList, riderNameList]
                ]) => {
                  const dataProductOption = {
                    coverageCode: valueProductOption.coverageCode,
                    benefitOption: valueProductOption.benefitOption,
                    currentSumAssured: valueProductOption.currentSumAssured,
                    curRenewalPremium: valueProductOption.curRenewalPremium
                  }
                  return TaskDetail.MajorCombinedChange(
                    task,
                    poName,
                    occupationList,
                    reinData,
                    dataProductOption,
                    valueBillingChange,
                    ClientRiderInfo,
                    policyInfo.body?.totalPremium,
                    waiverList,
                    riderNameList
                  )
                }
              ),
              ZIO.map(taskDetail)
            )

          case TransactionType.CASHLESS_O1:
          case TransactionType.CASHLESS_O3:
          case TransactionType.CASHLESS_P2:
          case TransactionType.CASHLESS_M3:
          case TransactionType.CASHLESS_V4:
          case TransactionType.CASHLESS_A0:
          case TransactionType.CASHLESS_DK:
          case TransactionType.CASHLESS_C9:
          case TransactionType.CASHLESS_V2:
          case TransactionType.CASHLESS_M2:
          case TransactionType.CASHLESS_A9:
          case TransactionType.CASHLESS_S6:
          case TransactionType.CASHLESS_L3:
          case TransactionType.CASHLESS_D7:
          case TransactionType.CASHLESS_I4:
          case TransactionType.CASHLESS_P9:
          case TransactionType.CASHLESS_I2:
          case TransactionType.CASHLESS_O2:
          case TransactionType.CASHLESS_A7:
          case TransactionType.CASHLESS_S1:
          case TransactionType.CASHLESS_P3:
          case TransactionType.CASHLESS_S2:
          case TransactionType.CASHLESS_B3:
          case TransactionType.CASHLESS_S3:
          case TransactionType.CASHLESS_C3:
          case TransactionType.CASHLESS_U3:
          case TransactionType.CASHLESS_V3:
          case TransactionType.CASHLESS_I3:
          case TransactionType.CASHLESS_C7:
          case TransactionType.CASHLESS_X1:
          case TransactionType.CASHLESS_T3:
          case TransactionType.CASHLESS_M9:
          case TransactionType.CASHLESS_D6:
          case TransactionType.CASHLESS_D8:
          case TransactionType.CASHLESS_D9:
          case TransactionType.CASHLESS_V5:
            return pipe(
              CashLessService.getDetailData(
                task.payload.body.policyNumber ?? '',
                task.payload.body.proposalNumber ?? '',
                task.businessKey ?? ''
              ),
              ZIO.map((cashlessDetail) => {
                return TaskDetail.CashLess(task, cashlessDetail)
              }),
              ZIO.map(taskDetail)
            )

          case TransactionType.AGENT_ONBOARD_REINS:
          case TransactionType.AGENT_ONBOARD_NEW: {
            return pipe(ZIO.succeed(TaskDetail.AgentOnboardNew(task)), ZIO.map(taskDetail))
          }
          case TransactionType.AGENT_TRANSFER_POLICY:
          case TransactionType.AGENT_OFFICE_MOVING:
          case TransactionType.AGENT_UNIT_TRANSFER:
          case TransactionType.AGENT_TERMINATION_AG: {
            return pipe(ZIO.succeed(TaskDetail.AgentVerificationNew(task)), ZIO.map(taskDetail))
          }
          case TransactionType.JOURNAL_PAYOUT:
          case TransactionType.JOURNAL_ALTERATION: {
            return pipe(ZIO.succeed(TaskDetail.TransferJournal(task, poName)), ZIO.map(taskDetail))
          }

          case TransactionType.UPLOAD_DOCUMENT:
            return pipe(ZIO.succeed(TaskDetail.UploadDocument(task, poName)), ZIO.map(taskDetail))
          case TransactionType.CHANGE_NATIONAL_ID_CARD: {
            const countries = GeneralService.getCountries
            const provinces = GeneralService.getProvinces
            const getDefaultData = (clientId: string) =>
              ZIO.zipPar(PulseOpsApi.getCustomer(clientId), countries, provinces)

            return task.source === SourceType.LANDING_PAGE
              ? pipe(
                getDefaultData(task.correlationId || ''),
                ZIO.map(([customer, countries, provinces]) =>
                  TaskDetail.ChangeClientInfo(task, customer, countries, provinces)
                ),
                ZIO.map(taskDetail)
              )
              : pipe(
                PulseOpsApi.getCustomer(task.payload.body?.attributesExt?.clientNum ?? ''),
                ZIO.map((customer) => {
                  return TaskDetail.ChangeNationalIDCard({ detail: task as any, customer })
                }),
                ZIO.map(taskDetail)
              )
          }
          case TransactionType.CHANGE_OCCUPATION_PERSONAL: {
            return pipe(
              ZIO.zipPar(
                PulseOpsApi.getCustomer(task.payload.body.attributesExt?.clientNum ?? ''),
                GeneralService.getOccupationDetailByCode(task.payload.body.occupation ?? '')
              ),
              ZIO.map(([customer, occupation]) => {
                return TaskDetail.ChangeOccupationPersonal(task, customer, occupation)
              }),
              ZIO.map(taskDetail)
            )
          }
          case TransactionType.CHANGE_DOB_GENDER: {
            return pipe(
              PulseOpsApi.getCustomer(task.payload.body.attributesExt?.clientNum ?? ''),
              ZIO.map((customer) => {
                return TaskDetail.ChangeDOBGender(task, customer)
              }),
              ZIO.map(taskDetail)
            )
          }
          case TransactionType.CHANGE_OTHER_INFORMATION_PERSONAL: {
            return pipe(
              ZIO.zipPar(
                PulseOpsApi.getCustomer(task.payload.body.attributesExt?.clientNum ?? ''),
                GeneralService.getCountries,
                GeneralService.getProvinces
              ),
              ZIO.map(([customer, countries, provinces]) => {
                return TaskDetail.ChangeOtherInfomationPersonal({
                  detail: task,
                  customer,
                  countries,
                  provinces
                })
              }),
              ZIO.map(taskDetail)
            )
          }
          case TransactionType.CHANGE_NATIONAL_ID_CARD: {
            const countries = GeneralService.getCountries
            const provinces = GeneralService.getProvinces
            const getDefaultData = (clientId: string) =>
              ZIO.zipPar(PulseOpsApi.getCustomer(clientId), countries, provinces)

            return task.source === SourceType.LANDING_PAGE
              ? pipe(
                getDefaultData(task.correlationId || ''),
                ZIO.map(([customer, countries, provinces]) =>
                  TaskDetail.ChangeClientInfo(task, customer, countries, provinces)
                ),
                ZIO.map(taskDetail)
              )
              : pipe(
                PulseOpsApi.getCustomer(task.payload.body?.attributesExt?.clientNum ?? ''),
                ZIO.map((customer) => {
                  return TaskDetail.ChangeNationalIDCard({ detail: task as any, customer })
                }),
                ZIO.map(taskDetail)
              )
          }
          case TransactionType.INDEPENDENT_CHECK: {
            return pipe(ZIO.succeed(TaskDetail.IndependenceCheck(task)), ZIO.map(taskDetail))
          }
          case TransactionType.VOICE_RECORDING: {
            // console.log(TransactionType.VOICE_RECORDING, '==========TransactionType.VOICE_RECORDING')
            // console.log(task, '========task=========')
            return pipe(ZIO.succeed(TaskDetail.VoiceRecording(task)), ZIO.map(taskDetail))
          }
        }
      })
    )

  export const getTaskDetail = (taskId: string, category?: string | null): Task<TaskDetail.Type> => {
    return pipe(
      // category && category === SubTasksResponse.PS_SUSPENDED
      category && SupendedTaskList.includes(category as SubTasksResponse)
        ? PulseOpsApi.getTaskSuspendById(taskId)
        : PulseOpsApi.getTaskById(taskId),
      ZIO.flatMap((task) => {
        //console.log('suspend task', task)
        const taskDetail =
          // category && category === SubTasksResponse.PS_SUSPENDED
          category && SupendedTaskList.includes(category as SubTasksResponse)
            ? TaskDetail.init({ ...task, processInstanceId: task.id }, task.payload?.authenOption || 'OTP')
            : TaskDetail.init(task, task.payload?.authenOption || 'OTP')
        return detailMapper(task, taskDetail)
      })
    )
  }

  export const getHistoryDetail = (taskId: string): Task<TaskDetail.Type> =>
    pipe(
      PulseOpsApi.getHistoricDetail(taskId),
      ZIO.flatMap((task) => {
        const taskDetail = TaskDetail.init(task, task.payload?.authenOption || 'OTP')
        return detailMapper(task, taskDetail)
      })
    )

  export const getHistoricTasks = ({ pagination, order, filter }: InquiryQueryOptions): Task<PageResult<TaskOp>> =>
    pipe(
      PulseOpsApi.getHistoricTasks({
        start: pagination.pageIndex * pagination.pageSize,
        size: pagination.pageSize,
        order,
        sourceIn: filter.source,
        transactionTypeIn: filter.transactionType,
        correlationId: filter.policyNum,
        businessKey: filter.caseId,
        startedAfter: filter.fromDate,
        startedBefore: filter.toDate,
        involvedUser: filter.assignee
      }),
      ZIO.map((result) =>
        result.data
          ? PageResult({
            items: pipe(
              result.data,
              A.map(
                (v): TaskOp => ({
                  id: v.id,
                  category: v.category ? v.category : '-',
                  caseId: v.businessKey ? v.businessKey : '',
                  policyNumber:
                    v.correlationId && v.transactionType && !isChangeContactTransaction(v.transactionType)
                      ? v.correlationId
                      : '',
                  clientNumber:
                    v.correlationId && v.transactionType && isChangeContactTransaction(v.transactionType)
                      ? v.correlationId
                      : '',
                  // StartDate: v.startTime,
                  startDate: v.createTime,
                  lastUpdated: v.endTime ?? null,
                  source: v.source ? v.source : '-',
                  transactionType: pipe(
                    TransactionTypeC.decode(v.transactionType),
                    E.mapLeft(() => v.transactionType || '-'),
                    E.fold(
                      (_) => TransactionType.BENEFICIARY_DESIGNATION,
                      (e) => e
                    )
                  ),
                  amlResult: null,
                  assignee: '-',
                  assigneeName: '-',
                  aging: calculateAgingDay(TransactionStatus.COMPLETED, v.createTime, v.endTime).toString(),
                  agingTotal: '-',
                  status: TransactionStatus.COMPLETED,
                  // Status: v.suspended
                  //     ? TransactionStatus.PENDING
                  //     : v.ended
                  //       ? TransactionStatus.COMPLETED
                  //       : TransactionStatus.IN_PROGRESS
                  // ,
                  activity: '-',
                  processInstanceId: v.processInstanceId ? v.processInstanceId : '-',
                  updateToCore: v.updateToCore,
                  createdBy: '-',
                  createdDate: null,
                  lastUpdateByAdj: '-',
                  lastUpdateByVer: '-',
                  lastUpdateDateAdj: null,
                  lastUpdateDateVer: null,
                  subServiceType: '-',
                  subTransactionType: '-',
                  poClientNumber: '-',
                  poName: '-',
                  poPhoneNumber: '-',
                  claimType: '-',
                  lifeAssuredName: '-',
                  claimNumber: '-',
                  redFlagResult: null,
                  request: '-',
                  proposalNumber: null,
                  eref: '-',
                  endTime: null,
                  agingDate: '',
                  lastUpdateDate: null,
                  paymentMethod: '',
                  printCount: 0,
                  officeCode: '',
                  agentCode: '',
                  suspendReason: '',
                  unsuspendReason: '',
                  amount: ''
                })
              )
            ),
            pageIndex: pagination.pageSize,
            pageSize: pagination.pageSize,
            total: result.total
          })
          : <PageResult<TaskOp>>{
            items: [],
            pageIndex: 0,
            pageSize: 0,
            total: 0
          }
      ),
      ZIO.tap((result) => PulseOpsState.updateTasks(result.items))
    )

  export const getDefaultAddress = (
    task: TaskDetailApi.Type,
    client: Customer
  ): { city: string; district: string; ward: string } => {
    let city = ''
    let district = ''
    let ward = ''
    if (
      (task.transactionType === TransactionType.CHANGE_CONTACT_INFO ||
        task.transactionType === TransactionType.CHANGE_CONTACT_INFO_ADDRESS ||
        task.transactionType === TransactionType.CHANGE_CONTACT_INFO_PHONE_AND_EMAIL) &&
      task.source === SourceType.PULSE4OPS
    ) {
      const p4ops = task.payload.body
      city = p4ops.dispatchAddress && p4ops.dispatchAddress.city ? p4ops.dispatchAddress.city : city
      district = p4ops.dispatchAddress && p4ops.dispatchAddress.district ? p4ops.dispatchAddress.district : district
      ward = p4ops.dispatchAddress && p4ops.dispatchAddress.subDistrict ? p4ops.dispatchAddress.subDistrict : ward
    }
    return {
      city,
      district,
      ward
    }
  }

  export const getCommentList = (processId: string): Task<TaskComment[]> =>
    pipe(
      PulseOpsApi.getCommentList(processId),
      ZIO.map((res): TaskComment[] => {
        return res.map(
          (v): TaskComment => ({
            username: v.author,
            time: v.time,
            message: v.message
          })
        )
      }),
      ZIO.tap(flow(PulseOpsState.lens.comments(processId).set, PulseOpsState.ref.update))
    )

  export const addComment = (
    taskId: string,
    processId: string,
    data: { author: string; message: string }
  ): Task<TaskComment> =>
    pipe(
      ZIO.zipPar(
        pipe(
          PulseOpsApi.addComment(taskId, data),
          ZIO.tapError((_) =>
            pipe(
              PulseOpsState.lens.comments(processId).modify(([_, ...tail]) => tail),
              PulseOpsState.ref.update
            )
          )
        ),
        pipe(
          PulseOpsState.lens.comments(processId).modify((comments) => [
            TaskComment({
              username: data.author,
              message: data.message,
              time: new Date()
            }),
            ...comments
          ]),
          PulseOpsState.ref.update
        )
      ),
      ZIO.map((res) => ({
        username: data.author,
        message: data.message,
        time: new Date()
      }))
    )

  export const getBanks = pipe(
    PulseOpsApi.getBanks,
    ZIO.map((results): Map<string, string> => {
      const myMap = new Map()
      results.map((v) => myMap.set(v.code, v.name))
      return myMap
    })
  )

  export const completeTask = (request: {
    taskId: string
    data: { author: string; message: string }
    updateToCore: boolean
    totalFaceAmount?: number
    claimType?: string
    subClaimType?: string
    category?: string
    isUWTransaction?: boolean
    UWDecision?: string
    nextStep?: string
    isUWCase?: boolean
    isUWQC?: boolean
    isCancelPayment?: boolean
    uwResult?: string
    newPaymentRef?: string
    pvType?: string
  }) => {
    const {
      taskId,
      data,
      updateToCore,
      totalFaceAmount,
      claimType,
      subClaimType,
      category,
      isUWTransaction,
      UWDecision,
      isUWCase,
      nextStep,
      isUWQC,
      uwResult,
      newPaymentRef,
      pvType
    } = request
    let variables: Array<{ name: string; type: string; value: any }> = [
      { name: 'is_skip_update_core_system', type: 'boolean', value: !updateToCore }
    ]
    category && variables.push({ name: 'stpType', type: 'string', value: '0' })
    totalFaceAmount && variables.push({ name: 'totalFaceAmount', type: 'string', value: totalFaceAmount.toString() })
    claimType && variables.push({ name: 'claimType', type: 'string', value: claimType })
    subClaimType && variables.push({ name: 'subClaimType', type: 'string', value: subClaimType })

    //setting values for Underwritting cases
    if (isUWQC) {
      variables.push({ name: 'form_check_uw_quality_outcome', type: 'string', value: 'complete' })
    }

    if (isUWQC && uwResult === 'JET') {
      variables.push({ name: 'is_skip_uw_check', type: 'boolean', value: true })
      variables.push({ name: 'is_pass_verification', type: 'boolean', value: false })
    }
    // case is transaction to has UW
    if (isUWTransaction === true && !isUWQC) {
      if (isUWCase === true) {
        if (nextStep === 'CONTINUE') {
          variables.push({ name: 'is_skip_uw_check', type: 'boolean', value: true })
          variables.push({ name: 'is_pass_verification', type: 'boolean', value: true })
          variables.push({ name: 'is_skip_authority', type: 'boolean', value: true })
          variables.push({ name: 'uwDecision', type: 'string', value: UWDecision?.toUpperCase() })
          variables.push({ name: 'autoVerification', type: 'string', value: 'PASS' })
        } else if (nextStep === 'ASSIGNMENT') {
          variables.push({ name: 'is_skip_uw_check', type: 'boolean', value: true })
          variables.push({ name: 'is_pass_verification', type: 'boolean', value: false })
          variables.push({ name: 'uwDecision', type: 'string', value: UWDecision?.toUpperCase() })
        }
      } else {
        if (UWDecision === 'ACCEPT') {
          //UW decision is not null
          variables.push({ name: 'uwDecision', type: 'string', value: 'ACCEPT' })
          variables.push({ name: 'is_skip_uw_check', type: 'boolean', value: true })
          variables.push({ name: 'is_pass_verification', type: 'boolean', value: true })
          variables.push({ name: 'is_skip_authority', type: 'boolean', value: true })
          variables.push({ name: 'autoVerification', type: 'string', value: 'PASS' })
        } else if (UWDecision === 'DC-TRANSFER') {
          variables.push({ name: 'uwDecision', type: 'string', value: UWDecision.toUpperCase() })
          variables.push({ name: 'is_skip_uw_check', type: 'boolean', value: false })
          variables.push({ name: 'is_pass_verification', type: 'boolean', value: true })
          variables.push({ name: 'autoVerification', type: 'string', value: 'PASS' })
        } else {
          //UW decision is not null
          variables.push({ name: 'uwDecision', type: 'string', value: 'ACCEPT' })
          variables.push({ name: 'is_skip_uw_check', type: 'boolean', value: uwResult === 'JET' ? true : false })
          variables.push({ name: 'is_pass_verification', type: 'boolean', value: true })
          variables.push({ name: 'autoVerification', type: 'string', value: 'PASS' })
        }
      }
    } else if (![TaskType.Claim, TaskType.PremiumCollection].includes(category as TaskType)) {
      // case is transaction to has no UW
      variables.push({ name: 'is_pass_verification', type: 'boolean', value: true })
      variables.push({ name: 'is_skip_uw_check', type: 'boolean', value: true })
    }
    newPaymentRef && variables.push({ name: 'paymentRef', type: 'string', value: newPaymentRef })
    pvType && variables.push({ name: 'pVType', type: 'string', value: pvType })
    if (TaskType.IDC === category) {
      variables = []
    }
    return pipe(
      PulseOpsApi.taskAction({
        taskId,
        action: 'complete', //complete
        outcome: 'complete', // suspend
        author: data.author,
        comment: data.message,
        variables: variables,
        isCancelPayment: request.isCancelPayment,
        category: category
      }),
      ZIO.tap((_) => refresh)
    )
  }
  export const suspendTask = (
    taskId: string,
    data: { author: string; message: string; expiryDate: string; communication: boolean },
    updateToCore: boolean,
    category?: string,
    isCancelPayment?: boolean
  ) => {
    let variableArr: Array<{ name: string; type?: string; value: any }> = [
      { name: 'is_skip_update_core_system', type: 'boolean', value: !updateToCore },
      { name: 'expiryDate', value: data.expiryDate === '-' ? '' : data.expiryDate },
      { name: 'is_send_communication', value: data.communication, type: 'boolean' },
      { name: 'is_pending_letter', value: data.communication, type: 'boolean' }
    ]
    if (![TaskType.Claim, TaskType.PremiumCollection, TaskType.Outbound, TaskType.IDC].includes(category as TaskType))
      variableArr.push({ name: 'is_pass_verification', type: 'boolean', value: true })
    if (![TaskType.Claim, TaskType.PremiumCollection, TaskType.Outbound, TaskType.IDC].includes(category as TaskType))
      variableArr.push({ name: 'is_skip_uw_check', type: 'boolean', value: true })
    if (TaskType.IDC === category) {
      variableArr = variableArr.filter((p) => p.name === "expiryDate")
    }
    return pipe(
      PulseOpsApi.taskAction({
        taskId,
        action: 'complete',
        outcome: 'suspend',
        author: data.author,
        comment: data.message,
        variables: variableArr,
        isCancelPayment: isCancelPayment,
        category: [TaskType.Outbound, TaskType.IDC].includes(category as TaskType) ? category : undefined
      }),
      ZIO.tap((_) => refresh)
    )
  }

  export const unSuspendTask = (
    processInstanceIds: string[],
    assignee: string,
    data: {
      message: string
      author: string
    },
    updateToCore: boolean,
    basket: string,
    userGroupCode: string,
    category?: string
  ) =>
    pipe(
      PulseOpsApi.unSuspendAction({
        processInstanceIds,
        assignee,
        comment: data.message,
        author: data.author,
        variables: TaskType.IDC === category ? [] : [{ name: 'is_skip_update_core_system', type: 'boolean', value: !updateToCore }],
        basket: basket,
        userGroupCode: userGroupCode,
        category: category
      }),
      ZIO.tap((_) => refresh)
    )

  // add comment
  export const transferTask = (
    taskId: string,
    userEmail: string,
    assigneeGroup: string,
    data: { author: string; message: string },
    updateToCore: boolean,
    category?: string
  ) =>
    pipe(
      PulseOpsApi.transferAction({
        taskId,
        assignee: userEmail,
        author: data.author,
        comment: data.message,
        assigneeGroup,
        category,
        variables: TaskType.IDC === category ? [] : [{ name: 'is_skip_update_core_system', type: 'boolean', value: !updateToCore }]
      }),
      ZIO.tap((_) => refresh)
    )

  export const transferToMe = (taskId: string[]) =>
    pipe(
      PulseOpsApi.transferToMe({ taskId }),
      ZIO.tap((_) => refresh)
    )

  export const reCheckTask = (
    taskId: string,
    isUWQC?: boolean,
    uwResult?: string,
    // userEmail: string,
    // group: string,
    outcome?: string,
    category?: string
  ) =>
    pipe(
      PulseOpsApi.reCheckAction({
        taskId,
        variables: isUWQC
          ? [
            { name: 'form_check_uw_quality_outcome', type: 'string', value: 'transfer' },
            { name: 'is_skip_uw_check', type: 'boolean', value: uwResult === 'JET' ? true : false },
            { name: 'is_pass_verification', type: 'boolean', value: uwResult === 'JET' ? false : true }
          ]
          : [],
        outcome,
        category: category
      }),
      ZIO.tap((_) => refresh)
    )

  export const endTask = (
    taskId: string,
    data: { author: string; message: string; reason: string },
    updateToCore: boolean,
    category?: string
  ) => {
    let variableArr: Array<{ name: string; type: string; value: any }> = [
      { name: 'is_skip_update_core_system', type: 'boolean', value: !updateToCore },
      {
        name: 'reject_reason_code',
        type: 'string',
        value: data.reason
      }
    ]
    ![TaskType.Claim, TaskType.PremiumCollection].includes(category as TaskType) &&
      variableArr.push({ name: 'is_pass_verification', type: 'boolean', value: true })
    ![TaskType.Claim, TaskType.PremiumCollection].includes(category as TaskType) &&
      variableArr.push({ name: 'is_skip_uw_check', type: 'boolean', value: true })
    return pipe(
      PulseOpsApi.taskAction({
        taskId,
        action: 'complete',
        outcome: 'end',
        author: data.author,
        comment: data.message,
        variables: variableArr,
        reason: data.reason,
        category: category
      }),
      ZIO.tap(() => refresh)
    )
  }

  export const rejectTask = (
    taskId: string,
    data: { author: string; message: string; reason: string },
    updateToCore: boolean,
    category?: string
  ) => {
    let variableArr: Array<{ name: string; type: string; value: any }> = [
      {
        name: 'is_skip_update_core_system',
        type: 'boolean',
        value: !updateToCore
      },
      {
        name: 'reject_reason_code',
        type: 'string',
        value: data.reason
      }
    ]
    ![TaskType.Claim, TaskType.PremiumCollection].includes(category as TaskType) &&
      variableArr.push({ name: 'is_pass_verification', type: 'boolean', value: true })
    ![TaskType.Claim, TaskType.PremiumCollection].includes(category as TaskType) &&
      variableArr.push({ name: 'is_skip_uw_check', type: 'boolean', value: true })
    if (TaskType.IDC === category) {
      variableArr = [
        {
          name: 'rejectReasonCode',
          type: 'string',
          value: data.reason
        }
      ]
    }
    return pipe(
      AuthService.userInfo,
      ZIO.flatMap((x) =>
        PulseOpsApi.taskAction({
          taskId,
          action: 'complete',
          outcome: 'reject',
          author: x.email,
          comment: data.message,
          variables: variableArr,
          reason: data.reason,
          category: category
        })
      ),
      ZIO.tap(() => refresh)
    )
  }

  const refreshSubject = new Subject()

  export const refreshStream = ZStream.fromObservable(refreshSubject)

  export const refresh = ZIO.effect(() => {
    refreshSubject.next(null)
  })

  //-----Submission----
  export const searchPolicy = (policyNum: string): Task<TaskDetail.PolicySearchRes | null> =>
    pipe(
      PulseOpsApi.getPolicy(policyNum),
      ZIO.flatMap((policy) => {
        return policy && policy.body.owners.id
          ? pipe(
            PulseOpsApi.getCustomer(policy.body.owners.id),
            ZIO.map(
              (customer): TaskDetail.PolicySearchRes => ({
                policyNum: policy.body.policyNo,
                clientNum: policy.body.owners.id,
                nationalID: customer.body.externalIds.SOE_VALUE || '-',
                clientName: customer.body.name,
                lifeAssure: customer.body.name
              })
            )
          )
          : ZIO.succeed(null)
      })
    )

  export const getEformDetail = (policyNum: string, transactionType: TransactionType): Task<OpEformDetail> => {
    switch (transactionType) {
      case TransactionType.BILLING_CHANGE:
      case TransactionType.BILLING_CHANGE_CORPORATE:
        return pipe(
          ZIO.zipPar(PulseOpsApi.getBillingChangeData(policyNum), PulseOpsApi.getPolicy(policyNum)),
          ZIO.map(
            ([detail, policy]): OpEformDetail => ({
              policyNum: policyNum,
              clientName: '',
              officeAddress: 'VP A5',
              payload: EformBillingChange(detail, policy)
            })
          )
        )
      case TransactionType.BENEFICIARY_DESIGNATION:
        return pipe(
          ZIO.zipPar(PulseOpsApi.getBillingChangeData(policyNum), PulseOpsApi.getPolicy(policyNum)),
          ZIO.map(
            ([detail, policy]): OpEformDetail => ({
              policyNum: policyNum,
              clientName: '',
              officeAddress: 'VP A5',
              payload: EformBillingChange(detail, policy)
            })
          )
        )
      default:
        return pipe(
          ZIO.zipPar(PulseOpsApi.getBillingChangeData(policyNum), PulseOpsApi.getPolicy(policyNum)),
          ZIO.map(
            ([detail, policy]): OpEformDetail => ({
              policyNum: policyNum,
              clientName: '',
              officeAddress: 'VP A5',
              payload: EformBillingChange(detail, policy)
            })
          )
        )
    }
  }
}
