import * as t from 'io-ts'
import { TransactionType } from '../TransactionType'
import { SourceType } from '../SourceType'
import { enumC, Maybe } from '@mxt/zio/codec'
import { PoliciesReceived } from '../PoliciesReceived'
import { PaymentTypeC } from '../PaymentType'
import { LasStatus } from '../LasStatus'
import { PaymentData } from './PaymentData'
const Pulse4OpsPayoutC = t.type({
  amount: Maybe(t.string),
  payoutOptionType: Maybe(t.string),
  bankAccountName: Maybe(t.string),
  bankAccountNumber: Maybe(t.string),
  bankName: Maybe(t.string),
  bankBranch: Maybe(t.string),
  nationalId: Maybe(t.string),
  policyReceivedNum: Maybe(t.string),
  status: Maybe(enumC(LasStatus))
})

export type Pulse4OpsPayout = t.TypeOf<typeof Pulse4OpsPayoutC>

const Base = <C extends t.Mixed>(codec: C) =>
  t.intersection([
    t.type({
      transactionType: t.literal(TransactionType.EXCESS_PREMIUM)
    }),
    t.type({
      payload: t.type({
        body: t.type({
          payouts: Maybe(t.array(Pulse4OpsPayoutC))
        }),
        lasStatus: t.union([t.literal(LasStatus.SUCCESS), t.literal(LasStatus.FAILED), t.undefined])
      })
    }),
    codec
  ])

// const PruOnline = Base(
//   t.type({
//     source: t.literal(SourceType.PRUONLINE),
//     payload: t.type({
//       body: t.type({
//         excessPremiumAmount: Maybe(t.union([t.string, t.number])),
//         totalPayoutAmount: Maybe(t.union([t.string, t.number])),
//         policiesReceived: Maybe(t.array(PoliciesReceived)),
//         poClientNum: Maybe(t.string),
//         payoutOptionType: Maybe(PaymentTypeC)
//       }),
//       lasStatus: t.union([t.literal(LasStatus.SUCCESS), t.literal(LasStatus.FAILED)])
//     })
//   })
// )

const ZaloEkioskCodec = t.type({
  payload: t.type({
    body: t.type({
      excessPremiumAmount: Maybe(t.union([t.string, t.number])),
      totalPayoutAmount: Maybe(t.union([t.string, t.number])),
      policiesReceived: Maybe(t.array(PoliciesReceived)),
      poClientNum: Maybe(t.string),
      payoutOptionType: Maybe(PaymentTypeC)
    }),
    lasStatus: t.union([t.literal(LasStatus.SUCCESS), t.literal(LasStatus.FAILED)])
  })
})

const Zalo = Base(t.intersection([t.type({ source: t.literal(SourceType.ZALO) }), ZaloEkioskCodec]))

const Ekiosk = Base(t.intersection([t.type({ source: t.literal(SourceType.EKIOSK) }), ZaloEkioskCodec]))

const Pulse = Base(
  t.type({
    source: t.literal(SourceType.PULSE),
    payload: t.type({
      body: t.type({
        excessPremiumAmount: Maybe(t.union([t.string, t.number])),
        totalPayoutAmount: Maybe(t.union([t.string, t.number])),
        policiesReceived: Maybe(t.array(PoliciesReceived)),
        poClientNum: Maybe(t.string),
        payoutOptionType: Maybe(PaymentTypeC)
      }),
      lasStatus: t.union([t.literal(LasStatus.SUCCESS), t.literal(LasStatus.FAILED)])
    })
  })
)

const PruGreat = Base(
  t.type({
    source: t.literal(SourceType.PRUGREAT),
    payload: t.type({
      body: t.type({
        excessPremiumAmount: Maybe(t.union([t.string, t.number])),
        totalPayoutAmount: Maybe(t.union([t.string, t.number])),
        policiesReceived: Maybe(t.array(PoliciesReceived)),
        poClientNum: Maybe(t.string),
        payoutOptionType: Maybe(PaymentTypeC)
      }),
      lasStatus: t.union([t.literal(LasStatus.SUCCESS), t.literal(LasStatus.FAILED)])
    })
  })
)

const Banca = Base(
  t.type({
    source: t.literal(SourceType.BANCA),
    payload: t.type({
      body: t.type({
        excessPremiumAmount: Maybe(t.union([t.string, t.number])),
        totalPayoutAmount: Maybe(t.union([t.string, t.number])),
        policiesReceived: Maybe(t.array(PoliciesReceived)),
        poClientNum: Maybe(t.string),
        payoutOptionType: Maybe(PaymentTypeC)
      }),
      lasStatus: t.union([t.literal(LasStatus.SUCCESS), t.literal(LasStatus.FAILED)])
    })
  })
)

const Pulse4Ops = t.type({
  source: t.union([t.literal(SourceType.PULSE4OPS), t.literal(SourceType.PRUONLINE)]),
  transactionType: t.literal(TransactionType.EXCESS_PREMIUM),
  payload: t.type({
    body: t.type({
      // excessPremiumAmount: Maybe(t.string),
      // status: t.union([t.literal(LasStatus.SUCCESS), t.literal(LasStatus.FAILED)]),
      // totalPayoutAmount: Maybe(t.string),
      // poClientNum: Maybe(t.string),
      cashOutOptions: Maybe(
        t.array(
          t.union([
            PaymentData.BankTransfer,
            PaymentData.EWalletMomo,
            PaymentData.PayPremium,
            PaymentData.Premium,
            PaymentData.PayLoan,
            PaymentData.TopUp,
            PaymentData.Reinstatement,
            PaymentData.NoType,
            PaymentData.RepayOpl,
            PaymentData.RepayApl,
            PaymentData.Other,
            PaymentData.Reins,
            PaymentData.CashAtCounter,
            PaymentData.PaidAtBank
          ])
        )
      ),
      policy: t.type({
        attributesExt: t.type({
          EXCESS_PREMIUM_AMOUNT: t.string,
          TOTAL_PAYOUT_AMOUNT: t.string
        }),
        lasStatus: t.union([t.literal(LasStatus.SUCCESS), t.literal(LasStatus.FAILED), t.undefined])
      })
    })
  })
})

const PruOnline = Pulse4Ops

export const ExcessPremium = t.union([PruOnline, Pulse, Zalo, Banca, Ekiosk, PruGreat, Pulse4Ops])
export type ExcessPremium = t.TypeOf<typeof ExcessPremium>
