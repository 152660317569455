import {
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow
} from '@material-ui/core'
import { ZIO } from '@mxt/zio'
import { useLoading } from '@mxt/zio-react'
import {
  assets,
  AuthService,
  mapTransactionType,
  SourceType,
  SubTaskCodeFromString,
  SubTaskCount,
  SubTaskType,
  TaskCount,
  TaskOp,
  TaskService,
  TaskSource,
  TaskType,
  TransactionType,
  getAMLResultLabel,
  CommonRequestService,
  ErrorHandling,
  TransactionStatus,
  AppContext,
  TaskComment,
  PulseOpsService,
  ModalComponent,
  Panel,
  PulseOpsFormat,
  useMobile,
  QueryTask,
  Permission,
  SubTasksResponse,
  ErrorActivity,
  totalAgingDays,
  TableHeaderSort,
  InquiryComplaintService,
  SelectOption,
  SUCommonRequestData
} from '@pulseops/common'
import { UserDrawerParamList } from '@pulseops/main'
import { CanView } from '@pulseops/task/common'
import { useNavigation } from '@react-navigation/core'
import { DrawerNavigationProp } from '@react-navigation/drawer'
import { format } from 'date-fns'
import { pipe } from 'fp-ts/function'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { ActivityIndicator, Pressable, Text, useWindowDimensions, View, ViewStyle } from 'react-native'
import { ScrollView, TouchableOpacity } from 'react-native-gesture-handler'
import { from, Subscription } from 'rxjs'
import { IconSwitch } from '../IconSwitch'
import { TaskCardType } from '../TaskCard'
import { TaskStatus } from '../TaskStatus'
import { TaskFilterForm } from './TaskFilterForm'
import { getSourceLabel, QueryTaskOptions } from './TaskFilterFormTypes'
import { formatPeriodTime, parseAging } from './TaskFilterHelper'
import { PanelComment } from './PanelComment'
import { TaskCommentFormModal } from './TaskCommentFormModal'
import { TaskAddCommentModal } from './TaskAddCommentModal'
import { ErrorHandlerTable } from './ErrorHandlerTable'
import * as O from 'fp-ts/lib/Option'
import { Duration } from '@mxt/zio/date'

const MAX_PAGE_ITEMS = 10
const UNASSIGNED_TASKS_FILTER = {
  USER_CHARACTER: '" "',
  VALUE: 'NULL_FILTER_ASSIGNEE'
}
const SUB_TASK_TYPE_SHOW_LAST_ACTIVITY = [
  SubTasksResponse.PS_VER,
  SubTasksResponse.PS_QC,
  SubTasksResponse.PS_SUSPENDED,
  SubTasksResponse.CH_VER,
  SubTasksResponse.CH_QC,
  SubTasksResponse.CH_SUSPENDED,
  SubTasksResponse.CS_VER,
  SubTasksResponse.CS_QC,
  SubTasksResponse.CS_SUSPENDED,
  SubTasksResponse.CL_VER,
  SubTasksResponse.CL_SUSPEND,
  SubTasksResponse.CL_QC,
  SubTasksResponse.CL_ADJ
]

const getTaskList = ({
  subTask,
  category,
  pageIndex,
  pageSize = MAX_PAGE_ITEMS,
  searchPolicyNum = '',
  filter,
  isTeam = false
}: QueryTaskOptions) => {
  const isErrorHandling = subTask?.code.subTask === SubTaskType.ErrorHandling
  const isSuspended = [SubTaskType.Suspend, SubTaskType.WriteOff].includes(subTask?.code.subTask as SubTaskType)
  const getEref = category === 'DS' ? searchPolicyNum.trim() || (filter?.eref || '').trim() : undefined
  const getCorrelationId = ['DS', 'IDC'].includes(category as string) ? undefined : searchPolicyNum.trim() || (filter?.client || '').trim()
  // const transactionType = isSuspended && category === TaskType.IDC ? [TransactionType.VOICE_RECORDING] : filter?.transactionType.map((item) => item.value) as TransactionType[]
  const transactionType = filter?.transactionType.map((item) => item.value) as TransactionType[]
  const policyNum = searchPolicyNum && category === TaskType.IDC ? searchPolicyNum.trim() : undefined

  if (isErrorHandling) {
    return pipe(
      TaskService.getErrorActivity({
        filter: {
          caseId: (filter?.caseId || '').trim(),
          client: (filter?.client || '').trim(),
          agentCode: (filter?.agentCode || '').trim(),
          eref: getEref,
          policyNum: searchPolicyNum ? searchPolicyNum.trim() : undefined,
          source: filter?.source.map((item) => item.value) as TaskSource[],
          paymentMethod: filter?.paymentMethod ? filter?.paymentMethod.value : '',
          transactionType: filter?.transactionType.map((item) => item.value) as TransactionType[],
          ...(filter?.agingFrom && filter.agingTo && parseAging(Number(filter.agingFrom), Number(filter.agingTo))),
          ...(filter?.createdFromDate &&
            filter?.createdToDate &&
            formatPeriodTime(filter.createdFromDate, filter.createdToDate))
        },
        // taskCategory: subTask?.code,
        category: subTask?.code.task as string,
        pagination: { pageIndex, pageSize }
      })
    )
  }

  return pipe(
    AuthService.userInfo,
    ZIO.flatMap(({ email }) =>
      TaskService.queryTasks({
        suspended: isSuspended,
        isTeam: isTeam,
        isFilterTeam: !!filter && !!filter.assignee,
        order: 'desc',
        active: true,
        filter: {
          assignee: category === TaskType.IDC && !filter?.assignee && isTeam ? '' :
            (filter?.assignee === UNASSIGNED_TASKS_FILTER.USER_CHARACTER
              ? UNASSIGNED_TASKS_FILTER.VALUE
              : (filter?.assignee || email).trim()),
          caseId: (filter?.caseId || '').trim(),
          client: (filter?.client || '').trim(),
          agentCode: (filter?.agentCode || '').trim(),
          claimNumber: filter?.claimNumber || '',
          eref: getEref,
          correlationId: getCorrelationId,
          source: filter?.source.map((item) => item.value) as TaskSource[],
          paymentMethod: filter?.paymentMethod ? filter?.paymentMethod.value ?? '' : '',
          transactionType: transactionType,
          lastActivity: filter?.lastActivity ? filter?.lastActivity.value ?? '' : '',
          createdBy: filter?.submittedBy ?? '',
          amlResult: filter?.AMLScreening ? filter?.AMLScreening.value : undefined,
          policyNum: policyNum,
          redFlagResult:
            filter?.AMLRedflag && filter?.AMLRedflag.value
              ? filter?.AMLRedflag.value === 'Y'
                ? true
                : false
              : undefined,
          ...(filter?.agingFrom && filter.agingTo && parseAging(Number(filter.agingFrom), Number(filter.agingTo))),
          ...(filter?.createdFromDate &&
            filter?.createdToDate &&
            formatPeriodTime(filter.createdFromDate, filter.createdToDate))
        },
        taskCategory: subTask?.code,
        category: subTask?.code.task as string,
        pagination: { pageIndex, pageSize }
      })
    ),
    ZIO.map((res) => {
      if (filter?.assignee && isSuspended) {
        res.items = []
        res.pageIndex = 0
        res.pageSize = 0
        res.total = 0
      }
     
      return res
    })
  )
}

const validateEmail = (mail: string) => {
  if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(mail)) {
    return true
  }
  return false
}

export const GroupType = ['PS', 'CH', 'CS']
export const GroupTypeLastActivity = ['PS', 'CH', 'CS', 'CL']

interface Props {
  searchPolicyNum?: string
  filter?: TaskFilterForm.Validated
  cardType?: TaskCardType
  permissions: string[]
  basketSelected: SubTaskCount | undefined
  taskSelected: Map<string, TaskOp>
  containerStyle?: ViewStyle
  taskGroupSelected: TaskCount | undefined
  onTaskSelected: (basket: SubTaskCount, task: TaskOp) => void
  onTaskUnSelected: (task: TaskOp) => void
  tabIndex: number
  onRefreshed?: () => void
  shouldRefresh?: boolean | undefined
  subTask: SubTaskCount[]
  subServiceTypeList: SelectOption[]
  subTransactionTypeList: (SelectOption & { slaStandard: string })[]
  commonRequests: SUCommonRequestData.CommonRequest[]
}

interface commentProps {
  caseId: string
  taskId: string
  processId: string
}

export const TaskTable = ({
  cardType = 'MyTask',
  taskSelected,
  searchPolicyNum = '',
  filter,
  permissions,
  basketSelected,
  onTaskSelected,
  onTaskUnSelected,
  taskGroupSelected,
  tabIndex,
  onRefreshed,
  shouldRefresh,
  subTask,
  subServiceTypeList,
  subTransactionTypeList,
  commonRequests
}: Props) => {
  const { t } = useTranslation(['TaskManagement', 'DistributionService'])
  const { height } = useWindowDimensions()
  const { showGlobalLoading } = React.useContext(AppContext.AppContextInstance)

  const useStyles = makeStyles({
    container: {
      border: `1px solid #D3DCE6`,
      borderWidth: 1,
      borderRadius: 8,
      maxHeight: height - 340
    },
    table: {},
    tableHeader: {
      backgroundColor: '#E7E7E7'
    },
    tableBody: {
      backgroundColor: '#FFFFFF'
    },
    tableCellHeader: {
      backgroundColor: '#F4F4F4',
      whiteSpace: 'nowrap',
      fontWeight: 'bold',
      color: '#70777E'
    },
    tableCell: {
      whiteSpace: 'nowrap'
    },
    commentCell: {
      position: 'sticky',
      right: -20,
      backgroundColor: '#fff',
      maxWidth: 120,
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-around',
      width: 94,
      height: 64,
      boxShadow: '-1px 0px 4px 0px #e3dbdb'
    }
  })

  const classes = useStyles()

  const { navigate } = useNavigation<DrawerNavigationProp<UserDrawerParamList>>()

  const [page, setPage] = React.useState<number>(0)
  const [pageSize, setPageSize] = React.useState<number>(10)
  const [tasks, setTasks] = React.useState<TaskOp[] | ErrorActivity.TaskOp[]>([])
  const [totalTask, setTotalTask] = React.useState<number>(0)
  const [isLoading, bindLoading] = useLoading(false)
  const [isFirstTime, setIsFirstTime] = React.useState<boolean>(true)
  const [isPSComment, setIsPSComment] = React.useState<boolean>(false)
  const [PSCommentData, setPSCommentData] = React.useState<TaskComment[]>([])
  const [isAddComment, setIsAddComment] = React.useState<boolean>(false)
  const [itemComment, setItemComment] = React.useState<TaskOp>()
  const [softItem, setSoftItem] = React.useState<{ field: string; order: 'asc' | 'desc' | undefined }>({
    field: '',
    order: 'asc'
  })
  // const [subServiceTypeList, setSubServiceTypeList] = React.useState<SelectOption[]>([])
  // const [subTransactionTypeList, setSubTransactionTypeList] = React.useState<(SelectOption & { slaStandard: string })[]>([])
  const { i18n } = useTranslation()
  const { isWide } = useMobile()
  const userInfo = pipe(
    AuthService.userInfo,
    ZIO.map((data) => data),
    ErrorHandling.runDidMount()
  )
  const getTasks = (pageNum: number, pageSi: number) => {
    let subscribe: Subscription | undefined = undefined
    if (taskGroupSelected) {
      const category = taskGroupSelected?.type as string
      const promise = pipe(
        getTaskList({
          subTask: subTask[tabIndex],
          category: category,
          pageIndex: pageNum,
          pageSize: pageSi,
          isTeam: cardType === 'TeamTask',
          searchPolicyNum,
          filter
        }),
        ZIO.tap((data) => {
          console.log(data)
          return ZIO.unit
        }),
        bindLoading,
        ZIO.unsafeRun({})
      )

      subscribe = from(promise).subscribe({
        next: (response) => {
          const { items, total } = response
          setTasks(
            items.map((item) => ({
              ...item,
              transactionTypeName: item.transactionType ? t(mapTransactionType.get(item.transactionType) ?? '-') : '-',
              policyNumberName: !!item.policyNumber ? item.policyNumber : item.proposalNumber ?? '-',
              amlResultName: item.amlResult ? getAMLResultLabel(item.amlResult) : '-',
              sourceName: item.source ? getSourceLabel(item.source) : '-'
            }))
          )
          setTotalTask(total)
          subscribe?.remove(subscribe)
        },
        error: (error) => {
          console.log('errorLog', error)
        },
        complete: () => {
          shouldRefresh && onRefreshed && onRefreshed()
        }
      })
    } else {
      shouldRefresh && onRefreshed && onRefreshed()
    }

    return () => {
      subscribe && subscribe.unsubscribe()
    }
  }

  // React.useEffect(() => {
  //   setTasks([])
  //   return getTasks()
  // }, [page, pageSize, tabIndex])

  // React.useEffect(() => {
  //   if (page !== 0) {
  //     setPage(0)
  //     return
  //   }

  //   return getTasks()
  // }, [taskGroupSelected, tabIndex])

  React.useEffect(() => {
    showGlobalLoading(isLoading)
  }, [isLoading])

  React.useEffect(() => {
    setTasks([])
    if (taskGroupSelected) {
      setPage(0)
      setPageSize(10)
      getTasks(0, 10)
    }
  }, [taskGroupSelected, tabIndex, searchPolicyNum, filter, i18n.language])

  // React.useEffect(() => {
  //   if (isFirstTime) {
  //     setIsFirstTime(false)
  //     setTasks([])
  //   }
  //   if (page !== 0) {
  //     setPage(0)
  //     return
  //   }

  //   return getTasks()
  // }, [searchPolicyNum, filter, i18n.language])

  React.useEffect(() => {
    console.log('shouldRefresh out', shouldRefresh)
    if (shouldRefresh) {
      console.log('shouldRefresh in', shouldRefresh)
      if (page !== 0) {
        setPage(0)
        return () => { }
      }
      return getTasks(page, pageSize)
    }
    return () => { }
  }, [shouldRefresh])

  const { PSSLAStandardArr, CSSLAStandardArr } = pipe(
    ZIO.zipPar(
      // CommonRequestService.getCommonRequestList(),
      TaskService.getPSSLAStandardList(),
      TaskService.getCustomerServiceStandardSLAList()
    ),
    ZIO.map(([PSSLAStandardArr, CSSLAStandardArr]) => {
      return {
        // commonRequests: requests,
        PSSLAStandardArr: PSSLAStandardArr,
        CSSLAStandardArr
      }
    }),
    ErrorHandling.runDidMount()
  ) || {
    // commonRequests: [],
    PSSLAStandardArr: [],
    CSSLAStandardArr: []
  }

  // const { subServiceTypeList, subTransactionTypeArr } = pipe(
  //   ZIO.zipPar(InquiryComplaintService.getTypesFromCachedApi(), InquiryComplaintService.getSubTypeFromCachedApi()),
  //   ZIO.map(([typeList, subTypeArr]) => {
  //     const subServiceTypeList = typeList.slice().map((x) => ({
  //       label: i18n.language === 'en' ? x.name : x.nameVi,
  //       value: x.code
  //     }))
  //     const subTransactionTypeArr = subTypeArr.slice().map((x) => ({
  //       label: i18n.language === 'en' ? x.name : x.nameVi,
  //       value: x.code,
  //       slaStandard: x.slaStandard ?? ''
  //     }))
  //     return { subServiceTypeList, subTransactionTypeArr }
  //   }),
  //   ErrorHandling.runDidUpdate([i18n.language])
  // ) || {
  //   subServiceTypeList: [],
  //   subTransactionTypeArr: []
  // }

  const isEnableNavigateToDetail = (item: TaskOp) => {
    // tạm thời cho view detail khi task source không phải là PULSE4OPS
    if (
      item.source === SourceType.PULSE4OPS ||
      (item.source === SourceType.PULSE &&
        ((item.policyNumber.length === 9 && item.transactionType === TransactionType.NEW_CLAIM_REGISTER) ||
          item.transactionType !==
          (TransactionType.CHANGE_CONTACT_INFO_ADDRESS && TransactionType.NEW_CLAIM_REGISTER) ||
          (taskGroupSelected?.type as string) === TaskType.Claim))
    ) {
      return true
    }

    if (item.source === SourceType.BANCA_SEA && !!item.policyNumber && item.policyNumber !== 'null') {
      return true
    }

    // tạm thời cho view detail task có source từ LANDING_PAGE
    if (item.source === SourceType.LANDING_PAGE && !!item.policyNumber && item.policyNumber !== 'null') {
      return true
    }

    if (item.source === SourceType.ZALO && (taskGroupSelected?.type as string) === TaskType.CustomerService) {
      return true
    }

    if (
      !!item.policyNumber &&
      item.policyNumber !== 'null' &&
      [TaskType.Claim].includes(taskGroupSelected?.type as TaskType) &&
      [SourceType.PRUONLINE].includes(item?.source as SourceType)
    ) {
      return true
    }

    // This condition for LEGACY of ZALO
    if (
      !!item.policyNumber &&
      item.policyNumber !== 'null' &&
      [TaskType.PolicyService].includes(taskGroupSelected?.type as TaskType) &&
      [SourceType.ZALO].includes(item?.source as SourceType) &&
      [
        TransactionType.REINSTATEMENT,
        TransactionType.BILLING_CHANGE,
        TransactionType.CONFIRMATION_OF_PREMIUM_RECEIPT,
        TransactionType.CHANGE_CONTACT_INFO,
        TransactionType.TOP_UP,
        TransactionType.BENEFICIARY_DESIGNATION,
        TransactionType.BILLING_FREQUENCY_REVERSAL,
        TransactionType.PRUCASH_PAYMENT,
        TransactionType.LOAN,
        TransactionType.MATURITY,
        TransactionType.CANCEL_RIDER,

        TransactionType.CHANGE_NATIONAL_ID_CARD,
        TransactionType.CHANGE_OCCUPATION_PERSONAL,
        TransactionType.CHANGE_DOB_GENDER,
        TransactionType.CHANGE_OTHER_INFORMATION_PERSONAL
      ].includes(item.transactionType as TransactionType)
    ) {
      return true
    }

    // This condition for LEGACY of PRUONLINE
    if (
      !!item.policyNumber &&
      item.policyNumber !== 'null' &&
      [TaskType.PolicyService].includes(taskGroupSelected?.type as TaskType) &&
      [SourceType.PRUONLINE].includes(item?.source as SourceType) &&
      [
        TransactionType.POLICY_CERTIFICATE,
        TransactionType.CONFIRMATION_OF_PREMIUM_RECEIPT,
        TransactionType.BILLING_CHANGE,
        TransactionType.GO_ABROAD,
        TransactionType.REINSTATEMENT,
        TransactionType.TOP_UP,
        TransactionType.BILLING_FREQUENCY_REVERSAL,
        TransactionType.FUND_SWITCHING,
        TransactionType.PREMIUM_REDIRECTION,
        TransactionType.CANCEL_RIDER,
        TransactionType.CHANGE_CONTACT_INFO,
        TransactionType.LOAN,
        TransactionType.MATURITY,

        TransactionType.CHANGE_NATIONAL_ID_CARD,
        TransactionType.CHANGE_OCCUPATION_PERSONAL,
        TransactionType.CHANGE_DOB_GENDER,
        TransactionType.CHANGE_OTHER_INFORMATION_PERSONAL,

        TransactionType.EXCESS_PREMIUM,
        TransactionType.CANCEL_FROM_INCEPTION,
        TransactionType.PARTIAL_WITHDRAWAL,
        TransactionType.PRUCASH_PAYMENT,
        TransactionType.CHANGE_CONTACT_INFO_PHONE_AND_EMAIL,
        TransactionType.CHANGE_CONTACT_INFO_PHONE,
        TransactionType.CHANGE_CONTACT_INFO_EMAIL,
        TransactionType.CHANGE_CONTACT_INFO_ADDRESS
      ].includes(item.transactionType as TransactionType)
    ) {
      return true
    }

    // tạm thời cho view detail cho task rổ Complain có source từ ZALO và EKIOSK
    if (
      (taskGroupSelected?.type as string) === TaskType.Complaint &&
      ([SourceType.ZALO, SourceType.EKIOSK] as string[]).includes(item.source)
    ) {
      return true
    }

    //tạm thời cho view detail cho task rổ Complain có source từ PCweb
    if (
      [
        SourceType.PRUONLINE,
        SourceType.ONLINE_PAYMENT,
        SourceType.ShopeePay,
        SourceType.Ebanking_seabank,
        SourceType.MSB_BANK,
        SourceType.MOMO,
        SourceType.MOMO_FOR_AGENT,
        SourceType.MY_VIB,
        SourceType.Shopee,
        SourceType.PAYOO,
        SourceType.POS_SeABank,
        SourceType.VIB_AUTODEBIT,
        SourceType.Agribank_VBA,
        SourceType.Viettel,
        SourceType.VNPOST,
        SourceType.VIETTEL_TELCO,
        SourceType.MPOS_EXIMBANK,
        SourceType.Offline,
        SourceType.SACOMBANK,
        SourceType.CREDITLIFEPORTAL
      ].includes(item.source as SourceType) &&
      item.transactionType?.startsWith('CASHLESS') &&
      // item.policyNumber &&
      // item.policyNumber !== 'null'
      (item.policyNumber || item.proposalNumber) &&
      (item.policyNumber !== 'null' || item.proposalNumber) //
    ) {
      return true
    }

    // condition can view detail source OB/IB for change contact info trans
    if (
      !!item.policyNumber &&
      item.policyNumber !== 'null' &&
      (item.source === SourceType.OUTBOUND || item.source === SourceType.INBOUND) &&
      [
        TransactionType.CHANGE_CONTACT_INFO,
        TransactionType.CHANGE_CONTACT_INFO_PHONE_AND_EMAIL,
        TransactionType.CHANGE_CONTACT_INFO_PHONE,
        TransactionType.CHANGE_CONTACT_INFO_EMAIL,
        TransactionType.CHANGE_CONTACT_INFO_ADDRESS
      ].includes(item.transactionType as TransactionType)
    ) {
      return true
    }

    if (
      !!item.policyNumber &&
      item.policyNumber !== 'null' &&
      item.source === SourceType.INBOUND &&
      [
        TransactionType.INQUIRY,
        TransactionType.COMPLAINT,
        TransactionType.COMPLAINT_AND_INQUIRY
      ].includes(item.transactionType as TransactionType)
    ) {
      return true
    }

    if (item.source === SourceType.PRUDAILY && item.proposalNumber) {
      return true
    }

    return false
  }

  const formatAssigneeName = (name: string, email: string, defaultValue = '') => {
    if (name && email && name !== '-' && email !== '-') {
      return (
        <View>
          <Text numberOfLines={1}>{name}</Text>
          <Text numberOfLines={1}>({email.split('@')[0]})</Text>
        </View>
      )
    }
    return defaultValue
  }

  const onSelectedTask = (active: boolean, subTask: SubTaskCount, task: TaskOp) => {
    if (active) {
      onTaskSelected(subTask, task)
    } else {
      onTaskUnSelected(task)
    }
  }

  const onChangePageSize = (size: number) => {
    // setPageSize(Number(size))
    // setPage(0)
    setPageSize(size)
    if (totalTask <= (page * size)) {
      setPage(0)
      getTasks(0, size)
    } else {
      getTasks(page, size)
    }
  }

  const isSuspended = taskGroupSelected && tasks.some((_) => _.status === TransactionStatus.PENDING)

  const mapRequestCode = (requestCode: string) => {
    const requestObj = commonRequests?.find((c) => c.requestCode === requestCode)
    return requestObj ? (i18n.language === 'vi' ? requestObj.requestNameVn : requestObj.requestNameEn) : '-'
  }

  const getCommentById = async (item: TaskOp | undefined) => {
    const processInstanceId = item?.processInstanceId !== '-' ? item?.processInstanceId : item.id
    if (!item) {
      return
    }
    setItemComment(item)
    showGlobalLoading(true)
    await pipe(
      PulseOpsService.getCommentList(processInstanceId ?? '-'),
      ZIO.tap((comments) => {
        showGlobalLoading(false), setPSCommentData(comments ?? [])
        return ZIO.unit
      }),
      ZIO.unsafeRun({})
    )
    await setIsPSComment(true)
  }

  const showModalAddComment = async () => {
    await setIsPSComment(false)
    await setIsAddComment(true)
  }

  if (subTask[tabIndex].code.subTask === SubTaskType.ErrorHandling) {
    return (
      <View>
        <ErrorHandlerTable dataSource={tasks as ErrorActivity.TaskOp[]} isLoading={false} />
        <TablePagination
          page={page}
          rowsPerPage={pageSize}
          count={totalTask}
          onPageChange={(e, page) => {
            // setPage(page)
            setPage(page)
            getTasks(page, pageSize)
          }}
          onRowsPerPageChange={(e) => {
            onChangePageSize(Number(e.target.value))
          }}
          labelRowsPerPage={t('common:PaginationSize')}
          labelDisplayedRows={({ from, to, count }) => t('common:Pagination', { from, to, count })}
          component={View}
        />
      </View>
    )
  }
  const isStandardSLAExceed = (taskItem: TaskOp, transactionType: string, category: string) => {
    switch (category) {
      case TaskType.PolicyService:
        return pipe(
          O.fromNullable(PSSLAStandardArr.find((x) => x.transactionTypeWf === transactionType)),
          O.fold(
            () => false,
            (standardItem) => {
              return taskItem.createdDate && totalAgingDays(taskItem.createdDate) >= Number(standardItem.slaStandard)
            }
          )
        )
      case TaskType.CustomerService:
        return pipe(
          O.fromNullable(CSSLAStandardArr.find((x) => x.subTransactionCode === taskItem.subTransactionCode)),
          O.fold(
            () => false,
            (standardItem) => {
              return taskItem.createdDate && totalAgingDays(taskItem.createdDate) >= Number(standardItem.slaDays)
            }
          )
        )
      case TaskType.Complaint:
        return pipe(
          O.fromNullable(subTransactionTypeList.find((x) => x.value === taskItem.subTransactionCode)),
          O.fold(
            () => false,
            (standardItem) =>
              taskItem.createdDate && totalAgingDays(taskItem.createdDate) >= Number(standardItem.slaStandard)
          )
        )
      default:
        return false
    }
  }

  const getStandardSLAExceed = (taskItem: TaskOp, transactionType: string, category: string) => {
    switch (category) {
      case TaskType.PolicyService:
        return pipe(
          O.fromNullable(PSSLAStandardArr.find((x) => x.transactionTypeWf === transactionType)),
          O.fold(
            () => '',
            (standardItem) => {
              return standardItem.slaStandard
            }
          )
        )
      case TaskType.CustomerService:
        return pipe(
          O.fromNullable(CSSLAStandardArr.find((x) => x.subTransactionCode === taskItem.subTransactionCode)),
          O.fold(
            () => '',
            (standardItem) => {
              return standardItem.slaDays
            }
          )
        )
      case TaskType.Complaint:
        return pipe(
          O.fromNullable(subTransactionTypeList.find((x) => x.value === taskItem.subTransactionCode)),
          O.fold(
            () => '',
            (standardItem) => standardItem.slaStandard
          )
        )

      default:
        return ''
    }
  }

  const checkSuspendWriteOffPC = (item: TaskOp) => {
    return (
      isSuspended &&
      subTask[tabIndex].code.subTask === SubTaskType.Suspend &&
      item.category === TaskType.PremiumCollection &&
      item.writeOffAction !== 'ENABLE_WRITE_OFF'
    )
  }
  const checkUnsuspendWriteOffPC = (item: TaskOp) => {
    return (
      isSuspended &&
      subTask[tabIndex].code.subTask === SubTaskType.WriteOff &&
      item.category === TaskType.PremiumCollection &&
      item.writeOffAction !== 'ENABLE_REVERT_WRITE_OFF'
    )
  }

  const getPendingReasonPC = (suspendReason: { code: string; typeEn: string; typeVn: string }[] | null) => {
    const pendingReason =
      suspendReason?.reduce(
        (prevVal, curVal) => (prevVal += i18n.language === 'vi' ? curVal.typeVn + '; ' : curVal.typeEn + '; '),
        ''
      ) || ''

    // if (pendingReason?.length > 64) {
    //   return pendingReason?.slice(0, 63) + '...'
    // }

    return pendingReason ? pendingReason?.substring(0, pendingReason.length - 2) : '-'
  }
  const showLabelType = (type: string) => {
    return subServiceTypeList.find((item) => item.value === type)?.label ?? ''
  }

  const showLabelSubType = (type: string) => {
    return subTransactionTypeList.find((item) => item.value === type)?.label ?? ''
  }

  return (
    <View>
      {taskGroupSelected?.type == 'DS' ? (
        <TableContainer component={Paper} className={classes.container} elevation={1}>
          <Table className={classes.table} stickyHeader>
            <TableHead className={classes.tableHeader}>
              <TableRow>
                <TableCell className={classes.tableCellHeader} />
                <TableCell className={classes.tableCellHeader}>{t('DistributionService:Status')}</TableCell>
                <TableCell className={classes.tableCellHeader}>{t('DistributionService:TransactionID')}</TableCell>
                <TableCell className={classes.tableCellHeader}>{t('DistributionService:eREF')}</TableCell>
                <TableCell className={classes.tableCellHeader}>{t('DistributionService:IDNumber')}</TableCell>
                <TableCell className={classes.tableCellHeader}>{t('DistributionService:AMLScreening')}</TableCell>
                {/* <TableCell className={classes.tableCellHeader}>{t('DistributionService:AvicadStatus')}</TableCell> */}
                <TableCell className={classes.tableCellHeader}>{t('DistributionService:Assignee')}</TableCell>
                <TableCell className={classes.tableCellHeader}>{t('DistributionService:Agingdays')}</TableCell>
                <TableCell className={classes.tableCellHeader}>{t('DistributionService:Totalagingdays')}</TableCell>
                <TableCell className={classes.tableCellHeader}>{t('DistributionService:TransactionType')}</TableCell>
                <TableCell className={classes.tableCellHeader}>{t('DistributionService:CaseID')}</TableCell>
                <TableCell className={classes.tableCellHeader}>{t('DistributionService:SubmissionDate')}</TableCell>
                <TableCell className={classes.tableCellHeader}>{t('DistributionService:SourceSubmission')}</TableCell>
                {/* <TableCell className={classes.tableCellHeader}>{t('DistributionService:SaleType')}</TableCell> */}
              </TableRow>
            </TableHead>
            <TableBody className={classes.tableBody}>
              {/* {isLoading ? (
                <TableRow>
                  <TableCell style={{ textAlign: 'center' }} colSpan={12}>
                    <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
                      <ActivityIndicator size="large" color="red" />
                    </View>
                  </TableCell>
                </TableRow>
              ) : (
                <> */}
              {tasks.length > 0 ? (
                <>
                  {tasks.map((itemTask: TaskOp | ErrorActivity.TaskOp) => {
                    const item = itemTask as TaskOp
                    const subTaskObject = taskGroupSelected?.subTasks.find(
                      (subTask) =>
                        subTask.code.task === item.category &&
                        subTask.code.subTask === (!isSuspended ? item.activity : 'SUSPENDED')
                    )

                    const PS_DIGITAL = item?.transactionType ? item?.transactionType.includes('DIGITAL') : false
                    const agentNumber =
                      item.transactionType === TransactionType.AGENT_OFFICE_MOVING ||
                      item.transactionType === TransactionType.AGENT_TERMINATION_AG ||
                      item.transactionType === TransactionType.AGENT_UNIT_TRANSFER
                    const policyNumber = item.transactionType === TransactionType.AGENT_TRANSFER_POLICY
                    const eRefIdnumber =
                      item.transactionType === TransactionType.AGENT_ONBOARD_NEW ||
                      item.transactionType === TransactionType.AGENT_ONBOARD_REINS
                    const checkAvicadStatus = () => {
                      if (
                        (item.transactionType === TransactionType.AGENT_ONBOARD_NEW &&
                          item.suspendReason === 'AVICAD_SUSPEND' &&
                          item.unsuspendReason !== 'AVICAD_SUSPEND') ||
                        (item.transactionType === TransactionType.AGENT_ONBOARD_REINS &&
                          item.suspendReason === 'AVICAD_SUSPEND' &&
                          item.unsuspendReason !== 'AVICAD_SUSPEND')
                      ) {
                        return 'Not Pass'
                      } else if (agentNumber || policyNumber) {
                        return '-'
                      } else {
                        return 'Pass'
                      }
                    }
                    const checkAgentKey = () => {
                      if (policyNumber) {
                        return item?.policyNumber ?? '-'
                      } else if (agentNumber) {
                        return item.agentCode ?? '-'
                      } else {
                        return item.clientNumber === 'null' ? '-' : item?.eref + '-' + item?.clientNumber || '-'
                      }
                    }
                    return (
                      <TableRow
                        key={item.id}
                        style={{ backgroundColor: taskSelected?.has(item.id) ? '#FAFAFA' : '#FFFFFF' }}
                      >
                        <TableCell className={classes.tableCell} style={{ fontWeight: 'bold' }}>
                          <IconSwitch
                            size={24}
                            disable={
                              basketSelected &&
                              (!isSuspended ? item.activity : 'SUSPENDED') !== basketSelected.code.subTask
                            }
                            active={taskSelected?.has(item.id)}
                            ActiveIcon={<assets.TaskIcon_Checked />}
                            InactiveIcon={
                              basketSelected &&
                                (!isSuspended ? item.activity : 'SUSPENDED') !== basketSelected.code.subTask ? (
                                <assets.TaskIcon_UnChecked_Disable />
                              ) : (
                                <assets.TaskIcon_UnChecked />
                              )
                            }
                            onChanged={(event) => subTaskObject && onSelectedTask(event, subTaskObject, item)}
                          />
                        </TableCell>
                        <TableCell className={classes.tableCell}>
                          <CanView condition={!!item.status}>
                            <TaskStatus type={item.status as any} containerStyle={{ marginTop: 6 }} />
                          </CanView>
                        </TableCell>
                        <TableCell className={classes.tableCell}>
                          <Pressable
                            // tạm ko cho navigate qua trang detail khi source ko phải là PULSE4OPS
                            // disabled={!eRefIdnumber}
                            onPress={() =>
                              taskGroupSelected &&
                              navigate('TaskDetailStack', {
                                screen: 'TaskDetailScreen',
                                params: {
                                  id:
                                    userInfo?.email !== item.assignee && !isSuspended && item.processInstanceId
                                      ? item.processInstanceId
                                      : item.id,
                                  caterogy: taskGroupSelected?.type,
                                  basket: item.activity ? (item.activity as SubTaskType) : SubTaskType.Suspend,
                                  basketName: SubTaskCodeFromString.encode(subTask[tabIndex]?.code),
                                  isInquiry: userInfo?.email !== item.assignee && !isSuspended ? '1' : '',
                                  isHistory: 'false',
                                  isPSDigital: PS_DIGITAL.toString(),
                                  isBreadcrumb: '0'
                                }
                              })
                            }
                          >
                            <Text
                              numberOfLines={1}
                              style={{
                                fontWeight: '600',
                                fontSize: 15,
                                color: eRefIdnumber || policyNumber || agentNumber ? '#1ea5fc' : '#333333',
                                textDecorationLine:
                                  eRefIdnumber || policyNumber || agentNumber ? 'underline' : 'none'
                              }}
                            >
                              {checkAgentKey()}
                            </Text>
                          </Pressable>
                        </TableCell>
                        <TableCell className={classes.tableCell}>{item.eref || '-'}</TableCell>
                        <TableCell className={classes.tableCell}>
                          {' '}
                          {item.clientNumber === 'null' ? '-' : item?.clientNumber ?? '-'}
                        </TableCell>
                        <TableCell className={classes.tableCell}>
                          {item.amlResult ? getAMLResultLabel(item.amlResult) : '-'}
                        </TableCell>
                        {/* <TableCell className={classes.tableCell}>{checkAvicadStatus()}</TableCell> */}
                        <TableCell className={classes.tableCell}>
                          {formatAssigneeName(item.assigneeName, item.assignee, '-')}
                        </TableCell>
                        <TableCell>{item.aging}</TableCell>
                        <TableCell>{item.agingTotal}</TableCell>
                        <TableCell className={classes.tableCell}>
                          {item.transactionType ? t(mapTransactionType.get(item.transactionType) ?? '-') : '-'}
                        </TableCell>
                        <TableCell className={classes.tableCell}>{item.caseId || '-'}</TableCell>

                        <TableCell>
                          {item.createdDate ? format(item.createdDate, 'dd/MM/yyyy - HH:mm:ss') : '-'}
                        </TableCell>
                        <TableCell className={classes.tableCell}>{item.source}</TableCell>
                        {/* <TableCell className={classes.tableCell}>{'-'}</TableCell> */}
                      </TableRow>
                    )
                  })}
                </>
              ) : (
                <TableRow>
                  <TableCell style={{ textAlign: 'center' }} colSpan={12}>
                    {t('common:Nodata')}
                  </TableCell>
                </TableRow>
              )}
              {/* </>
              )} */}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <TableContainer component={Paper} className={classes.container} elevation={1}>
          <Table className={classes.table} stickyHeader>
            <TableHead className={classes.tableHeader}>
              <TableHeaderSort
                data={tasks}
                setData={setTasks}
                setSortItem={setSoftItem}
                sortItem={softItem}
                column={[
                  {
                    label: '',
                    disabled: true
                  },
                  {
                    label: t('TaskManagement:Status'),
                    disabled: true
                  },
                  {
                    label: t('TaskManagement:policy'),
                    field: 'policyNumberName'
                  },
                  {
                    label: t('TaskManagement:AMLScreening'),
                    field: 'amlResultName',
                    visible: ![TaskType.Complaint, TaskType.IDC].includes(taskGroupSelected?.type as TaskType)
                  },
                  {
                    label: t('TaskManagement:AMLRedflag'),
                    field: 'redFlagResult',
                    visible: ![TaskType.Complaint, TaskType.IDC].includes(taskGroupSelected?.type as TaskType)
                  },
                  {
                    label: t('TaskManagement:PoName'),
                    field: 'poName',
                    visible: taskGroupSelected?.type === TaskType.Complaint
                  },
                  {
                    label: t('TaskManagement:Type'),
                    field: 'type',
                    visible: taskGroupSelected?.type === TaskType.Complaint
                  },
                  {
                    label: t('TaskManagement:SubType'),
                    field: 'subType',
                    visible: taskGroupSelected?.type === TaskType.Complaint
                  },
                  {
                    label: t('common:ClientNumber'),
                    field: 'clientNumber'
                  },
                  {
                    label: t('TaskManagement:transactiontype'),
                    field: 'transactionTypeName'
                  },
                  {
                    label: t('TaskManagement:Request'),
                    field: 'request',
                    visible: taskGroupSelected?.type !== TaskType.IDC
                  },
                  {
                    label: t('TaskManagement:assignee'),
                    field: 'assigneeName'
                  },
                  {
                    label: t('TaskManagement:aging'),
                    field: 'aging'
                  },
                  {
                    label: t('TaskManagement:totalAging'),
                    field: 'agingTotal'
                    // visible: SubTaskCodeFromString.encode(subTask[tabIndex]?.code).includes('CL')
                  },
                  {
                    label: t('TaskManagement:createddate'),
                    field: 'startDate'
                  },
                  {
                    label: t('TaskManagement:StandardSLA'),
                    visible: GroupType.includes(taskGroupSelected?.type ?? ''),
                    disabled: true
                  },
                  {
                    label: t('TaskManagement:SubmittedBy'),
                    field: 'createdBy',
                    visible: taskGroupSelected?.type !== TaskType.IDC
                  },
                  {
                    label: t('TaskManagement:source'),
                    field: 'source'
                  },
                  {
                    label: t('TaskManagement:claimNumber'),
                    field: 'claimNumber',
                    visible: SubTaskCodeFromString.encode(subTask[tabIndex]?.code).includes('CL')
                  },
                  {
                    label: t('TaskManagement:CaseID'),
                    disabled: true
                  },
                  {
                    label: t('common:OfficeCode'),
                    disabled: true,
                    visible: GroupType.includes(taskGroupSelected?.type ?? '')
                  },
                  {
                    label: t('TaskManagement:LastActivity'),
                    disabled: true,
                    visible: GroupTypeLastActivity.includes(taskGroupSelected?.type ?? '')
                  },
                  {
                    label: t('TaskManagement:ReasonPending'),
                    disabled: true,
                    visible: [TaskType.Claim, TaskType.PremiumCollection].includes(taskGroupSelected?.type as TaskType)
                  },
                  {
                    label: t('ServiceInquiry:table:Comment'),
                    disabled: true,
                    visible:
                      GroupType.includes(taskGroupSelected?.type ?? '') &&
                      !userInfo?.isGaLogin &&
                      ((cardType === 'MyTask' && permissions.includes(Permission.ViewCommentMyTask)) ||
                        (cardType === 'TeamTask' && permissions.includes(Permission.ViewCommentTeamTask)))
                  }
                ]}
              />
              {/* <TableRow>
                <TableCell className={classes.tableCellHeader} />

                <TableCell className={classes.tableCellHeader}>{t('TaskManagement:Status')}</TableCell>
                <TableCell className={classes.tableCellHeader}>{t('TaskManagement:policy')}</TableCell>
                {taskGroupSelected?.type !== TaskType.Complaint && (
                  <TableCell className={classes.tableCellHeader}>{t('TaskManagement:AMLScreening')}</TableCell>
                )}
                {taskGroupSelected?.type !== TaskType.Complaint && (
                  <TableCell className={classes.tableCellHeader}>{t('TaskManagement:AMLRedflag')}</TableCell>
                )}
                {taskGroupSelected?.type === TaskType.Complaint && (
                  <TableCell className={classes.tableCellHeader}>{t('TaskManagement:PoName')}</TableCell>
                )}
                {taskGroupSelected?.type === TaskType.Complaint && (
                  <TableCell className={classes.tableCellHeader}>{t('TaskManagement:Type')}</TableCell>
                )}
                {taskGroupSelected?.type === TaskType.Complaint && (
                  <TableCell className={classes.tableCellHeader}>{t('TaskManagement:SubType')}</TableCell>
                )}
                <TableCell className={classes.tableCellHeader}>{t('common:ClientNumber')}</TableCell>
                <TableCell className={classes.tableCellHeader}>{t('TaskManagement:transactiontype')}</TableCell>
                <TableCell className={classes.tableCellHeader}>{t('TaskManagement:Request')}</TableCell>
                <TableCell className={classes.tableCellHeader}>{t('TaskManagement:assignee')}</TableCell>
                <TableCell className={classes.tableCellHeader}>{t('TaskManagement:aging')}</TableCell>
                {/* {SubTaskCodeFromString.encode(subTask[tabIndex]?.code).includes('CL') && (
                <TableCell className={classes.tableCellHeader}>{t('TaskManagement:totalAging')}</TableCell>
                {/* )}
                <TableCell className={classes.tableCellHeader}>{t('TaskManagement:createddate')}</TableCell>
                {GroupType.includes(taskGroupSelected?.type ?? '') && (
                  <TableCell className={classes.tableCellHeader}>{t('TaskManagement:StandardSLA')}</TableCell>
                )}
                <TableCell className={classes.tableCellHeader}>{t('TaskManagement:SubmittedBy')}</TableCell>
                <TableCell className={classes.tableCellHeader}>{t('TaskManagement:source')}</TableCell>
                {SubTaskCodeFromString.encode(subTask[tabIndex]?.code).includes('CL') && (
                  <TableCell className={classes.tableCellHeader}>{t('TaskManagement:claimNumber')}</TableCell>
                )}
                {SUB_TASK_TYPE_SHOW_LAST_ACTIVITY.includes(basketName as SubTasksResponse) && (
                  <TableCell className={classes.tableCellHeader}>{t('TaskManagement:LastActivity')}</TableCell>
                )}
                <TableCell className={classes.tableCellHeader}>{t('TaskManagement:CaseID')}</TableCell>
              </TableRow>
                {[TaskType.Claim, TaskType.PremiumCollection].includes(taskGroupSelected?.type as TaskType) && (
                  <TableCell className={classes.tableCellHeader}>{t('TaskManagement:ReasonPending')}</TableCell>
                )}
              </TableRow> */}
            </TableHead>
            <TableBody className={classes.tableBody}>
              {/* {isLoading ? (
                <TableRow>
                  <TableCell style={{ textAlign: 'center' }} colSpan={12}>
                    <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
                      <ActivityIndicator size="large" color="red" />
                    </View>
                  </TableCell>
                </TableRow>
              ) : (
                <> */}
              {tasks.length > 0 ? (
                <>
                  {tasks.map((itemTask: TaskOp | ErrorActivity.TaskOp) => {
                    const item = itemTask as TaskOp
                    const subTaskObject = taskGroupSelected?.subTasks.find(
                      (subTask) =>
                        subTask.code.task === item.category &&
                        subTask.code.subTask === (!isSuspended ? item.activity : 'SUSPENDED')
                    )

                    const PS_DIGITAL = item?.transactionType ? item?.transactionType.includes('DIGITAL') : false
                    const isHighlightAMLScreening = item.amlResult === 'HIT'
                    const isHighlightAMLScreeningPC = ['HIT', 'ERROR'].includes(item.amlResult as string)
                    const isHighlightAMLRedFlag = !!item.redFlagResult
                    const PC_WRITEOFF = subTask[tabIndex].code.subTask === SubTaskType.WriteOff ? 'true' : 'false'

                    const checkItemWriteOff =
                      item.writeOffAction === 'ENABLE_WRITE_OFF' &&
                      subTask[tabIndex].code.subTask === SubTaskType.Suspend
                    const PCRedFlagResult =
                      item.category === TaskType.PremiumCollection &&
                      !item.redFlagResult &&
                      item.stpFunction === 'MS990091'
                    const CL_PAYOUT = item?.transactionType === 'PAYOUT' ? 'CL-PV' : undefined
                    return (
                      <TableRow
                        key={item.id}
                        style={{
                          backgroundColor: taskSelected?.has(item.id)
                            ? checkItemWriteOff
                              ? '#ed1b2e26'
                              : '#FAFAFA'
                            : item.paymentMethod === 'CASH_AT_COUNTER' || checkItemWriteOff
                              ? '#ed1b2e26'
                              : '#FFFFFF'
                        }}
                      >
                        <TableCell className={classes.tableCell} style={{ fontWeight: 'bold' }}>
                          <IconSwitch
                            size={24}
                            disable={
                              (basketSelected &&
                                (!isSuspended ? item.activity : 'SUSPENDED') !== basketSelected.code.subTask) ||
                              checkSuspendWriteOffPC(item) ||
                              checkUnsuspendWriteOffPC(item)
                            }
                            active={taskSelected?.has(item.id)}
                            ActiveIcon={<assets.TaskIcon_Checked />}
                            InactiveIcon={
                              (basketSelected &&
                                (!isSuspended ? item.activity : 'SUSPENDED') !== basketSelected.code.subTask) ||
                                checkSuspendWriteOffPC(item) ||
                                checkUnsuspendWriteOffPC(item) ? (
                                <assets.TaskIcon_UnChecked_Disable />
                              ) : (
                                <assets.TaskIcon_UnChecked />
                              )
                            }
                            onChanged={(event) => subTaskObject && onSelectedTask(event, subTaskObject, item)}
                          />
                        </TableCell>
                        <TableCell className={classes.tableCell}>
                          <CanView condition={!!item.status}>
                            <TaskStatus type={item.status as any} containerStyle={{ marginTop: 6 }} />
                          </CanView>
                        </TableCell>

                        <TableCell className={classes.tableCell}>
                          <Pressable
                            // tạm ko cho navigate qua trang detail khi source ko phải là PULSE4OPS
                            disabled={!isEnableNavigateToDetail(item)}
                            onPress={() =>
                              taskGroupSelected &&
                              navigate('TaskDetailStack', {
                                screen: 'TaskDetailScreen',
                                params: {
                                  id:
                                    userInfo?.email !== item.assignee && !isSuspended && item.processInstanceId
                                      ? item.processInstanceId
                                      : item.id,
                                  caterogy: taskGroupSelected?.type,
                                  basket: item.activity ? (item.activity as SubTaskType) : SubTaskType.Suspend,
                                  basketName: SubTaskCodeFromString.encode(subTask[tabIndex]?.code),
                                  isInquiry: userInfo?.email !== item.assignee && !isSuspended ? '1' : '',
                                  isHistory: 'false',
                                  isPSDigital: PS_DIGITAL.toString(),
                                  isBreadcrumb: '0',
                                  isPCWriteOff: PC_WRITEOFF,
                                  isCLPayout: CL_PAYOUT
                                }
                              })
                            }
                          >
                            <Text
                              numberOfLines={1}
                              style={{
                                fontWeight: '600',
                                fontSize: 15,
                                color: !isEnableNavigateToDetail(item)
                                  ? '#333333'
                                  : item.isCancelPayment
                                    ? '#ff0000'
                                    : '#1ea5fc',
                                textDecorationLine: !isEnableNavigateToDetail(item) ? 'none' : 'underline'
                              }}
                            >
                              {!!item.policyNumber ? item.policyNumber : item.proposalNumber ?? '-'}
                            </Text>
                          </Pressable>
                        </TableCell>
                        {![TaskType.Complaint, TaskType.IDC].includes(taskGroupSelected?.type as TaskType) && (
                          <TableCell
                            className={classes.tableCell}
                            style={isHighlightAMLScreening ? { color: '#ed1b2e' } : {}}
                          >
                            {item.amlResult ? getAMLResultLabel(item.amlResult) : '-'}
                          </TableCell>
                        )}
                        {![TaskType.Complaint, TaskType.IDC].includes(taskGroupSelected?.type as TaskType) && (
                          <TableCell
                            className={classes.tableCell}
                            style={isHighlightAMLRedFlag ? { color: '#ed1b2e' } : {}}
                          >
                            {item.redFlagResult === null
                              ? '-'
                              : item.redFlagResult
                                ? 'Y'
                                : PCRedFlagResult
                                  ? 'ERROR'
                                  : 'N'}
                          </TableCell>
                        )}
                        {taskGroupSelected?.type === TaskType.Complaint && (
                          <TableCell className={classes.tableCell}>{item.poName ?? '-'}</TableCell>
                        )}
                        {taskGroupSelected?.type === TaskType.Complaint && (
                          <TableCell className={classes.tableCell}>
                            {item.subServiceTypeCode ? showLabelType(item.subServiceTypeCode) : '-'}
                          </TableCell>
                        )}
                        {taskGroupSelected?.type === TaskType.Complaint && (
                          <TableCell className={classes.tableCell}>
                            {item.subTransactionCode ? showLabelSubType(item.subTransactionCode) : '-'}
                          </TableCell>
                        )}
                        <TableCell className={classes.tableCell}>{item.clientNumber || '-'}</TableCell>
                        <TableCell className={classes.tableCell}>
                          {item.transactionType ? t(mapTransactionType.get(item.transactionType) ?? '-') : '-'}
                        </TableCell>
                        {taskGroupSelected?.type !== TaskType.IDC && (
                          <TableCell className={classes.tableCell}>
                            {item.transactionType === TransactionType.COMMON_REQUEST_FORM && item.request
                              ? mapRequestCode(item.request)
                              : '-'}
                          </TableCell>
                        )}
                        <TableCell className={classes.tableCell}>
                          {formatAssigneeName(item.assigneeName, item.assignee, '-')}
                        </TableCell>
                        <TableCell>{item.aging}</TableCell>
                        {GroupType.includes(taskGroupSelected?.type ?? '') ? (
                          <TableCell>{item.createdDate ? totalAgingDays(item.createdDate) : ''}</TableCell>
                        ) : (
                          <TableCell>{item.agingTotal}</TableCell>
                        )}

                        <TableCell className={classes.tableCell}>
                          {item.startDate ? format(item.startDate, 'dd/MM/yyyy - HH:mm:ss') : '-'}
                        </TableCell>
                        {GroupType.includes(taskGroupSelected?.type ?? '') && (
                          <TableCell
                            style={
                              isStandardSLAExceed(
                                item,
                                item.transactionType ?? '',
                                taskGroupSelected?.type as TaskType
                              )
                                ? { color: '#ed1b2e' }
                                : {}
                            }
                          >
                            {getStandardSLAExceed(
                              item,
                              item.transactionType ?? '',
                              taskGroupSelected?.type as TaskType
                            )}
                          </TableCell>
                        )}
                        {taskGroupSelected?.type !== TaskType.IDC && (
                          <TableCell className={classes.tableCell}>
                            {item.createdBy && validateEmail(item.createdBy)
                              ? item.createdBy.split('@')[0]
                              : item.createdBy}
                          </TableCell>
                        )}
                        <TableCell className={classes.tableCell}>
                          {item.source ? getSourceLabel(item.source) : '-'}
                        </TableCell>
                        {[TransactionType.NEW_CLAIM_REGISTER, TransactionType.PAYOUT].includes(
                          item.transactionType as TransactionType
                        ) && <TableCell className={classes.tableCell}>{item.claimNumber || '-'}</TableCell>}
                        <TableCell className={classes.tableCell}>{item.caseId || '-'}</TableCell>
                        {GroupType.includes(taskGroupSelected?.type ?? '') && (
                          <TableCell className={classes.tableCell}>{item.officeCode ?? '-'}</TableCell>
                        )}

                        {GroupTypeLastActivity.includes(taskGroupSelected?.type ?? '') && (
                          <TableCell className={classes.tableCell}>{item?.lastActivity ?? '-'}</TableCell>
                        )}
                        {[TransactionType.NEW_CLAIM_REGISTER, TransactionType.PAYOUT].includes(
                          item.transactionType as TransactionType
                        ) && <TableCell>{t(`TaskManagement:${item.suspendReason}`)}</TableCell>}
                        {item.category === TaskType.PremiumCollection && (
                          <TableCell className={classes.tableCell}>
                            {getPendingReasonPC(item.suspendTypesPC)}
                          </TableCell>
                        )}
                        {GroupType.includes(taskGroupSelected?.type ?? '') &&
                          !userInfo?.isGaLogin &&
                          ((cardType === 'MyTask' && permissions.includes(Permission.ViewCommentMyTask)) ||
                            (cardType === 'TeamTask' && permissions.includes(Permission.ViewCommentTeamTask))) && (
                            <TableCell className={classes.commentCell}>
                              <View
                                style={{
                                  display: 'flex',
                                  flexDirection: 'row',
                                  alignItems: 'center',
                                  justifyContent: 'space-around',
                                  width: isWide ? 120 : 80,
                                  height: 53.02,
                                  backgroundColor: '#fff',
                                  marginLeft: 'auto',
                                  marginRight: 0,
                                  paddingVertical: 10,
                                  paddingLeft: 0,
                                  paddingRight: 0
                                }}
                              >
                                <TouchableOpacity onPress={() => getCommentById(item)}>
                                  <assets.IconComment />
                                </TouchableOpacity>
                              </View>
                            </TableCell>
                          )}
                      </TableRow>
                    )
                  })}
                </>
              ) : (
                <TableRow>
                  <TableCell style={{ textAlign: 'center' }} colSpan={12}>
                    {t('common:Nodata')}
                  </TableCell>
                </TableRow>
              )}
              {/* </>
              )} */}
            </TableBody>
          </Table>
        </TableContainer>
      )}

      <TablePagination
        page={page}
        rowsPerPage={pageSize}
        count={totalTask}
        onPageChange={(e, page) => {
          setPage(page)
          getTasks(page, pageSize)
        }}
        onRowsPerPageChange={(e) => {
          onChangePageSize(Number(e.target.value))
        }}
        labelRowsPerPage={t('common:PaginationSize')}
        labelDisplayedRows={({ from, to, count }) => t('common:Pagination', { from, to, count })}
        component={View}
      />

      <TaskAddCommentModal
        visible={isAddComment}
        caseId={itemComment?.caseId}
        taskId={itemComment?.id}
        processId={itemComment?.processInstanceId ?? ''}
        policyNum={itemComment?.policyNumber ?? ''}
        onPressClose={() => {
          setIsAddComment(false)
          getCommentById(itemComment ? itemComment : undefined)
        }}
      />

      <ModalComponent
        title={t('ServiceInquiry:CommentDetail')}
        onClose={() => {
          setIsPSComment(false), setPSCommentData([])
        }}
        visible={isPSComment}
        actions={[
          {
            text: t('common:Cancel'),
            type: 'filled',
            disabled: false,
            loading: false,
            onlyWide: false,
            style: { height: 40 },
            action: () => {
              setIsPSComment(false), setPSCommentData([])
            }
          },
          {
            text: t('TaskManagement:AddComment'),
            type: 'filled',
            disabled: itemComment?.status === TransactionStatus.PENDING,
            loading: false,
            onlyWide: false,
            style: { height: 40, marginLeft: 10 },
            action: () => {
              showModalAddComment()
            },
            hideButton: itemComment?.status === TransactionStatus.PENDING
          }
        ]}
        modalWidth={864}
      >
        <ScrollView>
          {PSCommentData && PSCommentData.length > 0 && (
            <View
              style={{
                marginHorizontal: 20,
                marginTop: 20,
                // maxHeight: height - 200,
                borderWidth: 1,
                borderRadius: 8,
                borderColor: '#dcdcdc',
                paddingTop: 16,
                paddingHorizontal: 16
              }}
            >
              {PSCommentData.map((item, index) => (
                <PanelComment
                  key={index}
                  title={item.username}
                  comment={PulseOpsFormat.datetoFormat(item.time, 'DD/MM/YYYY HH:mm:ss')}
                  isExand={true}
                  isHaveComment
                  isHiddenTitle={false}
                >
                  <Text>{item?.message}</Text>
                </PanelComment>
              ))}
            </View>
          )}
          {PSCommentData.length === 0 && (
            <View
              style={{
                marginHorizontal: 20,
                marginTop: 20,
                maxHeight: height - 200,
                borderWidth: 1,
                borderRadius: 8,
                borderColor: '#dcdcdc',
                paddingHorizontal: 16,
                minHeight: 80
              }}
            >
              <Text style={{ marginVertical: 'auto' }}>{t('ServiceInquiry:CommentNotFound')}</Text>
            </View>
          )}
        </ScrollView>
      </ModalComponent>
    </View>
  )
}
