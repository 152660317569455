import { useLoading } from '@mxt/zio-react'
import {
  AppContext,
  assets,
  AuthService,
  CashOutService,
  Category,
  DatePicker,
  ErrorHandling,
  form2,
  GeneralService,
  HistoricTask,
  Input,
  InquiryComplaintService,
  mapTransactionType,
  ModalComponent,
  Permission,
  PulseOpsApi,
  PulseOpsFormat,
  RadioButton,
  RBAC,
  Select,
  SelectOption,
  SelectSearch,
  ServiceInquiryCategories,
  ServiceInquiryNote,
  TaskOp,
  TaskService,
  TaskSource,
  TransactionType
} from '@pulseops/common'
import React from 'react'
import { useTranslation } from 'react-i18next'
import {
  StyleSheet,
  useWindowDimensions,
  View,
  Text,
  TextStyle,
  TouchableOpacity,
  SafeAreaView,
  ScrollView,
  Pressable
} from 'react-native'
import { pipe } from 'fp-ts/function'
import { ZIO } from '@mxt/zio'
import {
  makeStyles,
  Paper,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableBody,
  LinearProgress,
  TablePagination,
  Icon
} from '@material-ui/core'
import { BasicSearchForm, TabSearch, UploadDocumentDetail } from '@pulseops/task'
import { Controller, useForm } from 'react-hook-form'
import _ from 'lodash'
import { useIsFocused } from '@react-navigation/native'
import { DrawerContentComponentProps, DrawerContentOptions } from '@react-navigation/drawer'
import moment from 'moment'
import { readMoney } from './read-money'
import { UploadStatus } from './UpdateStatus'
import { Duration } from '@mxt/zio/date'

const currentDate = new Date()
const useStyles = makeStyles({
  tableHeader: {
    backgroundColor: '#E5EAEF'
  },
  tableCell: {
    fontWeight: 'bold',
    whiteSpace: 'nowrap'
  },
  cellNoWrap: {
    whiteSpace: 'nowrap'
  }
})

type Option = {
  value: string
  label: string
}
export interface StatusChangeForm {
  status: Option
  notes: string
  createdBy: string
  updatedDate: string
}

interface CancelPaymentForm {
  processInstanceId: string
  paymentNumber: string
  policyNumber: string
  cancelBy: string
  cancelDate: string
  comment: string
}

export const Status = {
  VALID: 'VALID_DOC',
  INVALID: 'INVALID_DOC',
  INCOMPLETE: 'INCOMPLETE_DOC',
  NOTUPDATE: 'NOT_UPDATE_DOC'
}

export const cashOutTransDescription = [
  { value: 'BONUS_SURRENDER', description: 'Bảo tức tích lũy' },
  { value: 'PARTIAL_WITHDRAWAL', description: 'Rút một phần giá trị quỹ_giá trị tài khoản' },
  { value: 'EXCELLENT_STUDENT_AWARD', description: 'Quyền lợi đăng khoa' },
  { value: 'EXCESS_PREMIUM', description: 'Phí dư' },
  { value: 'FULL_SURRENDER', description: 'Hủy hợp đồng nhận giá trị hoàn lại/Rút toàn bộ giá trị đầu tư' },
  { value: 'MATURITY', description: 'Thanh toán quyền lợi đáo hạn' },
  { value: 'LOAN', description: 'Tạm ứng từ giá trị hoàn lại' },
  { value: 'CANCEL_FROM_INCEPTION', description: 'Hủy trong 21 ngày' },
  { value: 'PRUCASH_PAYMENT', description: 'Quyền lợi định kỳ' },
  { value: 'PRUKID369', description: '	Quyền lợi hoàn phí BH SP cho trẻ em' },
  { value: 'SPECIAL_BONUS', description: 'Bảo tức đặc biệt' }
]

export const CashOutHistoryList = (props: DrawerContentComponentProps<DrawerContentOptions>) => {
  const { height } = useWindowDimensions()
  const classes = useStyles()
  const [pageSizeCashout, setPageSizeCashout] = React.useState<number>(10)
  const [pageNumCashout, setPageNumCashout] = React.useState<number>(0)
  const [pageSizePrint, setPageSizePrint] = React.useState<number>(10)
  const [pageNumPrint, setPageNumPrint] = React.useState<number>(0)
  const [officeGA, setOfficeGA] = React.useState<SelectOption | null>(null)

  const [totalItemCashout, setTotalItemCashout] = React.useState<number>(0)
  const [totalItemPrint, setTotalItemPrint] = React.useState<number>(0)
  const [loading, bindLoading] = useLoading(false)
  const [loadingList, setLoadingList] = React.useState<boolean>(true)
  const [infoVisible, setInfoVisible] = React.useState<boolean>(false)
  const [gaCode, setGaCode] = React.useState<string>('')

  const { t, i18n } = useTranslation()

  const [rowsCashout, setRowsCashout] = React.useState<TaskOp[]>([])
  const [rowsPrint, setRowsPrint] = React.useState<TaskOp[]>([])
  const [messageResponse, setMessageResponse] = React.useState<string | null>(null)
  const [tab, setTab] = React.useState<TabSearch>(TabSearch.CashOutSearch)
  const { showGlobalLoading, showToast } = React.useContext(AppContext.AppContextInstance)

  const [officeCodes, setOfficeCodes] = React.useState<SelectOption[]>([])
  const [submittedData, setSubmittedData] = React.useState<HistoricTask.Filter>({})
  const [uploadDocVisible, setUploadDocVisible] = React.useState<boolean>(false)
  const [uploadDocumentTaskId, setUploadDocumentTaskId] = React.useState<string>('')
  const [updateNoteVisible, setUpdateNoteVisible] = React.useState<boolean>(false)
  const [uploadNoteTaskId, setUploadNoteTaskId] = React.useState<string>('')
  const [createdByName, setCreatedByName] = React.useState<string>('')
  const [cancelPaymentVisible, setCancelPaymentVisible] = React.useState<boolean>(false)

  const statusChangeForm = useForm<StatusChangeForm>()
  const cancelPaymentForm = useForm<CancelPaymentForm>()

  const TransactionLabel = (type: TransactionType | null): string => (type ? mapTransactionType.get(type) || '-' : '-')

  const transactions = (type: TransactionType) => ({
    label: TransactionLabel(type),
    value: type
  })

  const transactionTypeCashoutOption = [
    transactions(TransactionType.BONUS_SURRENDER),
    transactions(TransactionType.CANCEL_FROM_INCEPTION),
    transactions(TransactionType.EXCELLENT_STUDENT_AWARD),
    transactions(TransactionType.EXCESS_PREMIUM),
    transactions(TransactionType.FULL_SURRENDER),
    transactions(TransactionType.MATURITY),
    transactions(TransactionType.LOAN),
    transactions(TransactionType.PARTIAL_SURRENDER),
    transactions(TransactionType.PARTIAL_WITHDRAWAL),
    transactions(TransactionType.PRUCASH_PAYMENT),
    transactions(TransactionType.PRUKID369),
    transactions(TransactionType.SPECIAL_BONUS)
  ]

  const optionStatusNote = [
    {
      value: Status.INCOMPLETE,
      label: t('CashOut:NotCompleteDoc')
    },
    {
      value: Status.NOTUPDATE,
      label: t('CashOut:NotUpdateDoc')
    },
    {
      value: Status.VALID,
      label: t('CashOut:ValidDoc')
    },
    {
      value: Status.INVALID,
      label: t('CashOut:InvalidDoc')
    }
  ]

  const optionStatus = [
    {
      label: t('CashOut:All'),
      value: 'ALL'
    },
    {
      label: t('CashOut:NoPrint'),
      value: 'NO_PRINT'
    },
    {
      label: t('CashOut:Printed'),
      value: 'PRINT'
    }
  ]

  const [noteDetail, setNoteDetail] = React.useState<ServiceInquiryNote>({
    customerComment: '',
    closedInfo: '',
    responseToCustomer: ''
  })
  const [subTransactionTypeList, setSubTransactionTypeList] = React.useState<(SelectOption & { typeCode: string })[]>(
    []
  )
  const roles: string[] = pipe(
    RBAC.permissions,
    ZIO.map((permissions) => {
      return permissions
    }),
    ErrorHandling.runDidMount([])
  )

  const isFocused = useIsFocused()
  const { changeBreadcrumb } = React.useContext(AppContext.AppContextInstance)

  const defaultValues: BasicSearchForm.Raw = {
    policyNumber: '',
    policyNumberClaim: '',
    idNumber: '',
    clientNumber: '',
    phoneNumber: '',
    source: [],
    category: ServiceInquiryCategories[0],
    transactionType: [],
    fromDate: currentDate,
    toDate: currentDate,
    subServiceType: [],
    subTransactionType: [],
    offices: officeGA ? [officeGA] : [],
    claimNumber: '',
    status: {
      label: 'No print',
      value: 'NO_PRINT'
    },
    claimTypes: []
  }

  type InfoCashout = {
    payeeName: string
    payeeID: string
    paymentAmount: string
    officeCode: string
    paymentNumber: string
    paymentService: string
    businessKey: string
  }

  const { base, handleSubmit } = form2.useForm(BasicSearchForm.codec, {
    defaultValues: defaultValues
  })

  const firstSetTab = () => {
    if (roles.includes(Permission['CashoutListCashout'])) {
      BasicSearchForm.setTab(tab)
    } else if (roles.includes(Permission['PrintPVCashout'])) {
      BasicSearchForm.setTab(TabSearch.CashOutComplete)
      setTab(TabSearch.CashOutComplete)
    }
  }

  firstSetTab()

  React.useEffect(() => {
    showGlobalLoading(loading)
  }, [loading])

  React.useEffect(() => {
    if (isFocused) {
      changeBreadcrumb([
        {
          title: '',
          navigate: () => {
            props.navigation.navigate('HomeScreen')
          }
        },
        {
          title: t('menu:Cashout'),
          navigate: null
        }
      ])

      // load sub transaction type
      pipe(
        InquiryComplaintService.getSubType(),
        ZIO.map((typeList) => {
          if (typeList && typeList.length > 0) {
            setSubTransactionTypeList(
              typeList.slice().map((x) => ({
                label: i18n.language === 'en' ? x.name : x.nameVi,
                value: x.code,
                typeCode: x.typeCode
              }))
            )
          } else {
            setSubTransactionTypeList([])
          }
        }),
        ZIO.unsafeRun({})
      )
      // load getOfficeCodes
      pipe(
        GeneralService.getOfficeCashOut(),
        ZIO.zipWith(AuthService.userInfo),
        ZIO.flatMap(([codes, userInfo]) =>
          pipe(
            userInfo.officeCode ? GeneralService.getOfficeCode(userInfo.officeCode) : ZIO.succeed({ body: [] }),
            ZIO.map((officeGA) => ({ codes, userInfo, officeGA: officeGA.body }))
          )
        ),
        ZIO.map(({ codes, userInfo, officeGA }) => {
          setOfficeCodes(
            codes.map((code) => ({
              value: code.code,
              label: i18n.language === 'vi' ? code.nameVi : code.nameEn
            }))
          )
          if (userInfo.officeCode) {
            const office = {
              value: userInfo.officeCode,
              label: (i18n.language === 'en' ? officeGA[0]?.nameEn : officeGA[0]?.nameVi) || userInfo.officeCode
            }
            setOfficeGA(office)
            if (tab === TabSearch.CashOutSearch) {
              loadCashoutList(office)
            } else if (tab === TabSearch.CashOutComplete) {
              loadPrintList(office)
            }
            const selectOffices = [
              {
                value: 'ALL',
                label: 'ALL'
              }
            ]
            selectOffices.push(office)
            setOfficeCodes(selectOffices)
            base.setValue('offices', [office])
          } else {
            if (tab === TabSearch.CashOutSearch) {
              loadCashoutList()
            } else if (tab === TabSearch.CashOutComplete) {
              loadPrintList()
            }
          }
        }),
        ZIO.unsafeRun({})
      )

      pipe(
        AuthService.userInfo,
        ZIO.map((userInfo) => {
          userInfo.isGaLogin ? setCreatedByName(userInfo.name) : setCreatedByName(userInfo.email)
        }),
        ZIO.unsafeRun({})
      )
    }
  }, [isFocused])

  React.useEffect(() => {
    getGACode()
    if (roles.includes(Permission['CashoutListCashout'])) {
      setTab(TabSearch.CashOutSearch)
    } else if (roles.includes(Permission['PrintPVCashout'])) {
      setTab(TabSearch.CashOutComplete)
    }
    setInfoVisible(false)
    setMessageResponse(null)
    setTotalItemCashout(0)
    setTotalItemPrint(0)
  }, [])

  React.useEffect(() => {
    if (tab === TabSearch.CashOutSearch && officeGA)
      getCashoutPending({ offices: [officeGA.value] }, pageSizeCashout, pageNumCashout, false)
    else if (tab === TabSearch.CashOutComplete && officeGA)
      getCashoutCompleted({ offices: [officeGA.value] }, pageSizePrint, pageNumPrint, false)
  }, [officeGA])

  const loadCashoutList = (officeData?: SelectOption) => {
    const office = officeData ? officeData : officeGA
    getCashoutPending(office ? { offices: [office.value] } : {}, pageSizeCashout, pageNumCashout, false)
    setSubmittedData(office ? { offices: [office.value] } : {})
  }

  const loadPrintList = (officeData?: SelectOption) => {
    const office = officeData ? officeData : officeGA
    getCashoutCompleted(
      office
        ? {
            offices: [office.value],
            fromDate: PulseOpsFormat.startOfDate(currentDate),
            toDate: PulseOpsFormat.endOfDate(currentDate),
            caseStatus: 0
          }
        : {
            fromDate: PulseOpsFormat.startOfDate(currentDate),
            toDate: PulseOpsFormat.endOfDate(currentDate),
            caseStatus: 0
          },
      pageSizePrint,
      pageNumPrint,
      false
    )
    setSubmittedData(
      office
        ? { offices: [office.value], fromDate: currentDate, toDate: currentDate, caseStatus: 0 }
        : { fromDate: currentDate, toDate: currentDate, caseStatus: 0 }
    )
  }

  const changeTab = (tabChange: TabSearch) => {
    if (tabChange === TabSearch.CashOutSearch) {
      loadCashoutList()
    } else if (tabChange === TabSearch.CashOutComplete) {
      loadPrintList()
    }
    resetToDefault()
  }

  const resetToDefault = () => {
    setPageSizeCashout(10)
    setPageNumCashout(0)
    setPageSizePrint(10)
    setPageNumPrint(0)
    setInfoVisible(false)
    setMessageResponse(null)
    base.reset(defaultValues)
  }

  const getCashoutPending = (
    submitData: HistoricTask.Filter,
    pageSize: number,
    pageNum: number,
    loadFromSearch?: boolean
  ) => {
    setLoadingList(true)
    pipe(
      TaskService.getHistoricCashOut(
        {
          pagination: {
            pageIndex: pageNum,
            pageSize: pageSize
          },
          order: 'desc',
          filter: submitData
        },
        'SPS50'
      ),
      ZIO.mapError((e) => {
        // console.log(e)
        return ZIO.fail(e)
      }),
      ZIO.tap((res) => {
        setTotalItemCashout(res.total)
        setRowsCashout(res.items)
        setMessageResponse(res.items.length === 0 && loadFromSearch ? t('message:MS030029') : null)
        return ZIO.unit
      }),
      bindLoading,
      ZIO.unsafeRun({})
    )
  }

  const convertToAmount = (amountNumber: string): string => {
    const number = Math.round(Number(amountNumber)).toString()
    if (!isNaN(Number(amountNumber))) {
      const amount = (number + '.').replace(/\d(?=(\d{3})+\.)/g, '$&,')
      return amount.split('.')[0]
    }
    return ''
  }

  const getCashoutCompleted = (
    submitData: HistoricTask.Filter,
    pageSize: number,
    pageNum: number,
    loadFromSearch?: boolean
  ) => {
    setLoadingList(true)
    pipe(
      TaskService.getCashOutComplete({
        pagination: {
          pageIndex: pageNum,
          pageSize: pageSize
        },
        order: 'desc',
        filter: submitData
      }),
      ZIO.mapError((e) => {
        // console.log(e)
        return ZIO.fail(e)
      }),
      ZIO.tap((res) => {
        setMessageResponse(res.items.length === 0 && loadFromSearch ? t('message:MS030029') : null)
        setTotalItemPrint(res.total)
        setRowsPrint(res.items)
        return ZIO.unit
      }),
      bindLoading,
      ZIO.unsafeRun({})
    )
  }

  const getServiceInquiryNote = (taskId: string) => {
    pipe(
      TaskService.getServiceInquiryNote(taskId),
      ZIO.tap((note) => {
        setNoteDetail({
          customerComment: note.customerComment,
          closedInfo: note.closedInfo,
          responseToCustomer: note.responseToCustomer
        })
        setInfoVisible(true)
        return ZIO.unit
      }),
      bindLoading,
      ErrorHandling.run()
    )
  }

  const updateChangeStatusCashout = async () => {
    const validateForm = await statusChangeForm.trigger()
    if (validateForm) {
      const dataStatusChange: CashOutService.CashoutStatusInfo = {
        caseId: uploadNoteTaskId ?? '',
        notes: statusChangeForm.watch('status.value') !== Status.VALID ? statusChangeForm.watch('notes') : '',
        status: statusChangeForm.watch('status.value'),
        createdBy: statusChangeForm.watch('createdBy') ? statusChangeForm.watch('createdBy') : createdByName,
        updatedBy: createdByName
      }
      setUpdateNoteVisible(false)
      statusChangeForm.reset()
      pipe(
        CashOutService.updateCashoutStatus(dataStatusChange),
        ZIO.tap(() => {
          return ZIO.unit
        }),
        bindLoading,
        ErrorHandling.run()
      )
      loadPrintList()
      resetToDefault()
    }
  }

  const showUploadDocument = (taskId: string) => {
    setUploadDocVisible(true)
    setUploadDocumentTaskId(taskId)
  }

  const transTypeOptions = React.useMemo(
    () => transactionTypeCashoutOption.map((item) => ({ ...item, label: t(item.label) })),
    []
  )

  const getGACode = () => {
    pipe(
      AuthService.userInfo,
      ZIO.flatMap((userData) => {
        if (userData.officeCode !== null)
          pipe(
            GeneralService.getOfficeCode(userData.officeCode),
            ZIO.tap((y) => {
              const officeCode = y.body[0]
              setGaCode(officeCode.gaCode ?? '')
              return ZIO.unit
            }),
            bindLoading,
            ZIO.unsafeRun({})
          )
        return ZIO.unit
      }),
      bindLoading,
      ZIO.unsafeRun({})
    )
  }

  const showUploadNote = (taskId: string) => {
    setUploadNoteTaskId(taskId)
    setLoadingList(false)
    pipe(
      CashOutService.getCashoutStatus(taskId),
      ZIO.map((cashoutInfo) => {
        if (cashoutInfo) {
          // console.log(cashoutInfo)
          const valueStatus = optionStatusNote.find((item) => cashoutInfo.status === item.value)
          if (valueStatus) statusChangeForm.setValue('status', valueStatus)
          statusChangeForm.setValue('notes', cashoutInfo?.notes ?? '')
          statusChangeForm.setValue('createdBy', cashoutInfo?.createdBy ?? createdByName)
          statusChangeForm.setValue('updatedDate', cashoutInfo?.updatedDate ?? '')
        }
        setUpdateNoteVisible(true)
      }),
      bindLoading,
      ZIO.unsafeRun({})
    )
  }

  const search = handleSubmit((data) => {
    setPageNumPrint(0)
    setPageNumCashout(0)
    setMessageResponse(null)
    const getSubmitData = (): HistoricTask.Filter => {
      return {
        policyNum: data.policyNumber || undefined,
        idNumber: data.idNumber ? data.idNumber.trim() : undefined,
        clientNum: data.clientNumber || undefined,
        phoneNumber: data.phoneNumber || undefined,
        fromDate: PulseOpsFormat.startOfDate(data.fromDate, true),
        toDate: PulseOpsFormat.endOfDate(data.toDate, true),
        offices:
          data.offices.length > 0 && data.offices[0].value !== 'ALL' ? data.offices.map((x) => x.value) : undefined,
        source: data.source.length > 0 ? data.source.map((x) => x.value as TaskSource) : undefined,
        categories: data.category?.value
          ? [data.category.value]
          : [Category.COMPLAINT, Category.INQUIRY, Category.POLICY_SERVICE, Category.GENERAL],
        transactionType:
          data.transactionType.length > 0 ? data.transactionType.map((x) => x.value as TransactionType) : undefined,
        subServiceTypes: data.subServiceType.length > 0 ? data.subServiceType.map((x) => x.value) : undefined,
        subTransactionTypes:
          data.subTransactionType.length > 0 ? data.subTransactionType.map((x) => x.value) : undefined,
        caseStatus: data.status?.value === 'PRINT' ? 1 : data.status?.value === 'NO_PRINT' ? 0 : undefined
      }
    }

    const submitData: HistoricTask.Filter = getSubmitData()
    setSubmittedData(submitData)
    if (tab === TabSearch.CashOutSearch) getCashoutPending(submitData, pageSizeCashout, 0, true)
    else if (tab === TabSearch.CashOutComplete) getCashoutCompleted(submitData, pageSizePrint, 0, true)
  })

  const changeToAmount = (amount: string) => {
    if (!isNaN(Number(amount))) {
      const number = Math.round(Number(amount)).toString()
      return readMoney(number)
    }
    return ''
  }

  const printCashout = (item: TaskOp) => {
    setLoadingList(false)
    pipe(
      PulseOpsApi.getHistoricDetail(item?.id),
      ZIO.mapError((e) => {
        return ZIO.fail(e)
      }),
      ZIO.map((value) => {
        if (
          value?.transactionType === TransactionType.SPECIAL_BONUS ||
          value?.transactionType === TransactionType.MATURITY ||
          value?.transactionType === TransactionType.BONUS_SURRENDER ||
          value?.transactionType === TransactionType.EXCELLENT_STUDENT_AWARD ||
          value?.transactionType === TransactionType.FULL_SURRENDER ||
          value?.transactionType === TransactionType.LOAN ||
          value?.transactionType === TransactionType.PARTIAL_SURRENDER ||
          value?.transactionType === TransactionType.PARTIAL_WITHDRAWAL ||
          value?.transactionType === TransactionType.PRUCASH_PAYMENT ||
          value?.transactionType === TransactionType.PRUKID369 ||
          value?.transactionType === TransactionType.EXCESS_PREMIUM ||
          value?.transactionType === TransactionType.CANCEL_FROM_INCEPTION
        ) {
          const paymentServiceIndex = cashOutTransDescription.findIndex((i) => item.transactionType === i.value)
          const infoCashOut = {
            payeeName: '',
            payeeID: '',
            paymentAmount: item.amount ?? '',
            officeCode: '',
            paymentNumber: '',
            paymentService:
              item.transactionType && paymentServiceIndex !== -1
                ? cashOutTransDescription[paymentServiceIndex].description
                : '',
            businessKey: value.businessKey
          }
          let payeeNotPo = false
          if (value.payload?.body?.cashOutOptions) {
            infoCashOut.officeCode = value.payload.officeCode ?? ''
            infoCashOut.paymentNumber = value.paymentNumber ?? ''
            // infoCashOut.documents = value.payload.body.cashOutOptions.documents
            value.payload.body.cashOutOptions.map((o) => {
              if (o.type === 'CASH_AT_COUNTER') {
                infoCashOut.officeCode = value.payload.officeCode ?? ''
                if (o.attributesExt?.PAYEE_INFO) {
                  payeeNotPo = o.attributesExt?.PAYEE_INFO.isPayeeNotPO ?? false
                  infoCashOut.payeeName = o.attributesExt?.PAYEE_INFO.payeeName ?? ''
                  infoCashOut.payeeID = o.attributesExt?.PAYEE_INFO.idNumber ?? ''
                }
                getCashoutPrintData(item, infoCashOut, payeeNotPo)
              }
            })
          }
        }
        return ZIO.unit
      }),
      bindLoading,
      ZIO.unsafeRun({})
    )
  }

  const getGaNameDefault = (officeCode: string) => {
    if (officeCode === 'PRU') return 'CSC FLEMINGTON'
    else if (officeCode === 'VCO') return 'CSC Hà Nội VINCOM'
    else if (officeCode === 'DNG') return 'CSC ĐÀ NẴNG'
    else if (officeCode === 'HCM') return 'CSC Trần Cao Vân'
    return '-'
  }

  const getAddressDefault = (officeCode: string) => {
    if (officeCode === 'PRU') return 'Tầng 1, Tòa nhà Flemington, 182 Lê Đại Hành, Phường 15, Quận 11, TP.HCM'
    else if (officeCode === 'VCO') return 'Tầng 3 TTTM Vincom, 54A Nguyễn Chí Thanh, Láng Thượng, Đống Đa, Hà Nội'
    else if (officeCode === 'DNG') return '205 Trần Phú, Phường Phước Ninh, Quận Hải Châu, Tp. Đà Nẵng'
    else if (officeCode === 'HCM') return '25B Trần Cao Vân, Đakao, Quận 1, Tp.HCM'
    return '-'
  }

  const getBankCodeDefault = (officeCode: string) => {
    if (officeCode === 'PRU') return 'S8'
    else if (officeCode === 'VCO') return 'H2'
    else if (officeCode === 'DNG') return 'N1'
    else if (officeCode === 'HCM') return 'H6'
    return '-'
  }

  const officeCodeListDefault = ['PRU', 'VCO', 'DNG', 'HCM']

  const getCashoutPrintData = (item: TaskOp, infoCashOut: InfoCashout, payeeNotPo: boolean) => {
    pipe(
      ZIO.zipPar(
        officeGA ? GeneralService.getOfficeCode(officeGA.value) : ZIO.succeed(null),
        gaCode ? CashOutService.getGaInfo(gaCode) : ZIO.succeed(null),
        AuthService.userInfo,
        CashOutService.checkPrintPv(item.caseId)
      ),
      ZIO.tap(([officeInfo, gaInfo, user, infoCheck]) => {
        const dataGA = {
          bankCode: '',
          gaUser: ''
        }
        // console.log(officeInfo)
        if (officeInfo && officeInfo.body && officeInfo?.body[0]) {
          const officeCode = officeInfo.body[0]
          dataGA.bankCode = officeCode.bankCode ?? '-'
          dataGA.gaUser = officeCode.code
        }
        const createdDate = moment(new Date()).format('DD/MM/YYYY HH:mm:ss')
        const authorizeDate = !!item.endTime ? moment(item.endTime).format('DD/MM/YYYY HH:mm:ss') : ''
        const fileCashout: CashOutService.FileCashout = {
          uuid: item.id,
          pushCode: 'CASH_OUT_TEMPLATE',
          outputFileName: item.policyNumber + '-' + item.caseId,
          docTypeCode: 'DPS12',
          transactionTypeWF: 'CASH-OUT_REQUEST',
          businessKey: infoCashOut.businessKey,
          params: {
            gaName:
              gaInfo && gaInfo?.officeDesc
                ? gaInfo.officeDesc
                : officeCodeListDefault.includes(infoCashOut.officeCode)
                ? getGaNameDefault(infoCashOut.officeCode)
                : '-',
            gaAddress:
              gaInfo && gaInfo.officeAddress
                ? gaInfo.officeAddress
                : officeCodeListDefault.includes(infoCashOut.officeCode)
                ? getAddressDefault(infoCashOut.officeCode)
                : '-',
            gaTaxCode:
              gaInfo && gaInfo.taxCode
                ? gaInfo.taxCode
                : officeCodeListDefault.includes(infoCashOut.officeCode)
                ? '0301840443'
                : '-',
            createdDate: createdDate,
            payeeName: infoCashOut.payeeName ?? item.poName,
            payeeID: infoCashOut.payeeID,
            paymentAmount: infoCashOut.paymentAmount ? convertToAmount(infoCashOut.paymentAmount) : '',
            paymentAmountDescription: infoCashOut.paymentAmount ? changeToAmount(infoCashOut.paymentAmount) : '',
            paymentService: infoCashOut.paymentService,
            policyNumber: item.policyNumber,
            policyOwnerName: item.poName,
            authorizeDate: authorizeDate,
            documents: [],
            bankCode: dataGA.bankCode
              ? dataGA.bankCode
              : officeCodeListDefault.includes(infoCashOut.officeCode)
              ? getBankCodeDefault(infoCashOut.officeCode)
              : '-',
            gaUser: user.name,
            paymentNumber: infoCashOut.paymentNumber,
            authorizeLetter: payeeNotPo ? ' + giấy ủy quyền' : ''
          }
        }
        const cashoutData: CashOutService.CashoutType = {
          body: {
            files: [fileCashout]
          },
          createdBy: user.name,
          transType: item.transactionType ?? '',
          officeCode:
            officeGA && officeGA.value
              ? officeGA.value
              : officeCodeListDefault.includes(infoCashOut.officeCode)
              ? infoCashOut.officeCode
              : '-'
        }
        const currentDatePrint = moment(new Date()).format('DD/MM/YYYY')
        const firstPrint = infoCheck.createdDate
          ? moment(infoCheck.createdDate).format('DD/MM/YYYY')
          : moment(new Date()).format('DD/MM/YYYY')
        // console.log(currentDatePrint, 'currentDatePrint')
        // console.log(firstPrint, 'firstPrint')
        // Warning no amount or no bankCode
        if(!fileCashout.params.paymentAmount || !infoCashOut.paymentAmount || fileCashout.params.paymentAmount === '0' ||
           !fileCashout.params.bankCode || fileCashout.params.bankCode === '-') {
          showToast(t('message:MS990105'), 'error')
        }
        else if (currentDatePrint === firstPrint || item.printCount === 0) {
          pipe(printFile(cashoutData), () => loadPrintList())
        } else {
          showToast(t('message:MS030035'), 'error')
        }
        return ZIO.unit
      }),
      bindLoading,
      ZIO.unsafeRun({})
    )
  }

  const printFile = (cashoutPrint: CashOutService.CashoutType) => {
    pipe(
      CashOutService.printCashout(cashoutPrint),
      ZIO.flatMap((responseData) => {
        return ZIO.zipPar(
          ZIO.succeed(responseData),
          ZIO.fromPromise(() => responseData.blob())
        )
      }),
      ZIO.map(([res, blob]) => {
        resetToDefault()
        const fileString = window.URL.createObjectURL(blob)
        window.open(fileString, '_blank')
        return ZIO.unit
      }),
      bindLoading,
      ErrorHandling.run({})
    )
  }

  const setRemoveData = (data: TaskOp) => {
    cancelPaymentForm.setValue('processInstanceId', data.id ?? '')
    cancelPaymentForm.setValue('policyNumber', data.policyNumber)
    cancelPaymentForm.setValue('paymentNumber', data.paymentNumber ?? '')
    cancelPaymentForm.setValue('cancelBy', createdByName)
    cancelPaymentForm.setValue('cancelDate', PulseOpsFormat.datetoFormat(currentDate, 'DDMMYYYY'))
    setCancelPaymentVisible(true)
  }

  const removeCashout = async () => {
    const isValid = await cancelPaymentForm.trigger()
    if (isValid) {
      setCancelPaymentVisible(false)
      pipe(
        CashOutService.removeCashoutCase(cancelPaymentForm.watch()),
        ZIO.delay(Duration.seconds(5)),
        ZIO.map(() => {
          cancelPaymentForm.reset()
          getCashoutPending(submittedData, pageSizeCashout, 0, false)
        }),
        bindLoading,
        ZIO.unsafeRun({})
      )
    }
  }

  return (
    <SafeAreaView style={{ flex: 1, backgroundColor: '#FFF' }}>
      <ScrollView>
        <View style={{ flex: 1 }}>
          <View style={styles.searchContainer}>
            <View style={[styles.row, styles.titleContainer]}>
              {roles.includes(Permission['CashoutListCashout']) && (
                <View style={[{ padding: 16 }, tab === TabSearch.CashOutSearch ? { backgroundColor: '#FAFAFA' } : {}]}>
                  <RadioButton
                    key="CashOutSearch"
                    label={t('CashOut:CashOutList')}
                    selected={tab === TabSearch.CashOutSearch}
                    disable={tab === TabSearch.CashOutSearch}
                    onChange={() => {
                      changeTab(TabSearch.CashOutSearch)
                      setTab(TabSearch.CashOutSearch)
                      setRowsCashout([])
                      setRowsPrint([])
                    }}
                  />
                </View>
              )}
              {roles.includes(Permission['PrintPVCashout']) && (
                <View
                  style={[{ padding: 16 }, tab === TabSearch.CashOutComplete ? { backgroundColor: '#FAFAFA' } : {}]}
                >
                  <RadioButton
                    key="CashOutComplete"
                    label={t('CashOut:PrintPV')}
                    selected={tab === TabSearch.CashOutComplete}
                    disable={tab === TabSearch.CashOutComplete}
                    onChange={() => {
                      changeTab(TabSearch.CashOutComplete)
                      setTab(TabSearch.CashOutComplete)
                      setRowsCashout([])
                      setRowsPrint([])
                    }}
                  />
                </View>
              )}
            </View>
            {(roles.includes(Permission['CashoutListCashout']) || roles.includes(Permission['PrintPVCashout'])) && (
              <View>
                <View style={styles.row}>
                  <View style={styles.col}>
                    <Controller
                      name="policyNumber"
                      control={base.control}
                      render={({ field: { onChange, value } }) => (
                        <Input
                          required={officeGA ? true : false}
                          title={t('ServiceInquiry:PolicyNumber')}
                          value={value ?? undefined}
                          maxLength={8}
                          inputType="number"
                          onChange={onChange}
                          errorMessage={base.formState.errors.policyNumber?.message}
                        />
                      )}
                    />
                  </View>
                  <View style={styles.col}>
                    <Controller
                      name="transactionType"
                      control={base.control}
                      render={({ field: { onChange, onBlur, value } }) => (
                        <SelectSearch
                          label={t('ServiceInquiry:TransactionType')}
                          options={transTypeOptions}
                          placeholder={t('common:Select')}
                          onChange={onChange}
                          onBlur={onBlur}
                          value={value}
                          multiple={true}
                        />
                      )}
                    />
                  </View>
                </View>
                {/* From Date, To Date */}
                <View style={styles.row}>
                  <View style={styles.col}>
                    <Controller
                      name="fromDate"
                      control={base.control}
                      render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                        <DatePicker
                          required
                          label={t('ServiceInquiry:FromDate')}
                          onChange={onChange}
                          onBlur={onBlur}
                          value={value}
                          maxDate={currentDate}
                          maxDateMessage={t('message:MS990032')}
                          errorMessage={error?.message}
                        />
                      )}
                    />
                  </View>
                  <View style={styles.col}>
                    <Controller
                      name="toDate"
                      control={base.control}
                      render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                        <DatePicker
                          required
                          label={t('ServiceInquiry:ToDate')}
                          onChange={onChange}
                          onBlur={onBlur}
                          value={value}
                          maxDate={currentDate}
                          maxDateMessage={t('message:MS990032')}
                          errorMessage={error?.message}
                        />
                      )}
                    />
                  </View>
                </View>
                <View style={styles.row}>
                  {!officeGA && (
                    <View style={styles.col}>
                      <Controller
                        name="offices"
                        control={base.control}
                        render={({ field: { onChange, onBlur, value } }) => (
                          <SelectSearch
                            label={t('ServiceInquiry:Office')}
                            options={officeCodes}
                            buildLabel={(select) => `${select.value} - ${select.label}`}
                            placeholder={officeGA ? '' : t('common:Select')}
                            onChange={onChange}
                            onBlur={onBlur}
                            value={value}
                            multiple={true}
                            disabled={officeGA !== null}
                            showPopupIcon={officeGA === null}
                          />
                        )}
                      />
                    </View>
                  )}
                  {officeGA && (
                    <View style={styles.col}>
                      <Controller
                        name="offices"
                        control={base.control}
                        render={({ field: { onChange, onBlur, value } }) => (
                          <Select
                            label={t('ServiceInquiry:Office')}
                            options={officeCodes}
                            placeholder={t('common:Select')}
                            onChange={(e) => base.setValue('offices', e ? [e] : [])}
                            onBlur={onBlur}
                            value={value[0]}
                          />
                        )}
                      />
                    </View>
                  )}
                  {tab === TabSearch.CashOutComplete && (
                    <View style={styles.col}>
                      <Controller
                        name="status"
                        control={base.control}
                        render={({ field: { onChange, onBlur, value } }) => (
                          <Select
                            label={t('CashOut:Status')}
                            options={optionStatus}
                            placeholder={t('common:Select')}
                            onChange={onChange}
                            onBlur={onBlur}
                            value={value}
                          />
                        )}
                      />
                    </View>
                  )}
                </View>
                {(roles.includes(Permission['SearchCashoutList']) || roles.includes(Permission['SearchPrintPV'])) && (
                  <View style={[styles.row, { alignItems: 'center' }]}>
                    <TouchableOpacity style={{ width: 118, height: 40 }} onPress={search}>
                      <View style={styles.btn}>
                        <Text style={{ textAlign: 'center', color: '#fff' }}>{t('common:Search')}</Text>
                      </View>
                    </TouchableOpacity>
                  </View>
                )}
                <View style={[styles.row, { marginTop: 6 }]}>
                  <Text style={{ color: '#ED1B2E' }}>{_.get(base.formState.errors, '.message')}</Text>
                </View>
              </View>
            )}
          </View>
          {loading && loadingList && <LinearProgress color="secondary" />}

          {rowsCashout.length > 0 &&
          roles.includes(Permission['CashoutListCashout']) &&
          tab === TabSearch.CashOutSearch ? (
            <View style={[styles.section, { marginTop: 10 }]}>
              <TableContainer component={Paper} style={{ maxHeight: 500 }} elevation={1}>
                <Table style={{ userSelect: 'text' }} stickyHeader>
                  <TableHead className={classes.tableHeader}>
                    <TableRow>
                      <TableCell className={classes.tableCell}>{t('ServiceInquiry:table:CaseID')}</TableCell>
                      <TableCell className={classes.tableCell}>{t('ServiceInquiry:table:PolicyNumber')}</TableCell>
                      <TableCell className={classes.tableCell}>{t('ServiceInquiry:table:POName')}</TableCell>
                      <TableCell className={classes.tableCell}>{t('ServiceInquiry:table:Transaction')}</TableCell>
                      <TableCell className={classes.tableCell}>{t('ServiceInquiry:table:SubTransaction')}</TableCell>
                      <TableCell className={classes.tableCell}>{t('ServiceInquiry:table:UserSubmission')}</TableCell>
                      <TableCell className={classes.tableCell}>{t('ServiceInquiry:table:CreatedDate')}</TableCell>
                      <TableCell className={classes.tableCell}>{t('ServiceInquiry:table:Aging')}</TableCell>
                      <TableCell className={classes.tableCell}>{t('ServiceInquiry:table:LastUpdatedDate')}</TableCell>
                      <TableCell className={classes.tableCell} style={{ textAlign: 'center' }}>
                        {t('ServiceInquiry:table:Note')}
                      </TableCell>
                      <TableCell className={classes.tableCell} style={{ textAlign: 'center' }}>
                        {t('CashOut:Action')}
                      </TableCell>
                      <TableCell className={classes.tableCell} style={{ textAlign: 'center' }}>
                        {t('CashOut:Action')}
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {rowsCashout.map((row, i) => {
                      return (
                        <TableRow key={`TableRow-${i}`}>
                          {/* CaseID */}
                          <TableCell>
                            {row.caseId && roles.includes(Permission['ViewcasedetailCashoutList']) ? (
                              <Pressable
                                onPress={() =>
                                  props.navigation.navigate('TaskDetailStack', {
                                    screen: 'TaskDetailScreen',
                                    params: {
                                      id: row.id,
                                      caterogy: row.category,
                                      basket: '',
                                      basketName: '',
                                      isInquiry: '1',
                                      isHistory: 'true'
                                    }
                                  })
                                }
                              >
                                <Text style={[{ color: '#ed1b2e' }, styles.cellNoWrap]}>{row.caseId}</Text>
                              </Pressable>
                            ) : (
                              <Text style={styles.cellNoWrap}>{row.caseId || '-'}</Text>
                            )}
                          </TableCell>
                          {/* PolicyNumber */}
                          <TableCell>{row.policyNumber || '-'}</TableCell>
                          {/* POName */}
                          <TableCell>{row.poName || '-'}</TableCell>
                          {/* Transaction */}
                          <TableCell className={classes.cellNoWrap}>
                            {t(TransactionLabel(row.transactionType))}
                          </TableCell>
                          {/* SubTransaction */}
                          <TableCell>
                            {subTransactionTypeList.find((x) => x.value === row.subTransactionType)?.label || '-'}
                          </TableCell>
                          {/* UserSubmission */}
                          <TableCell>{row.createdBy ? row.createdBy.split('@')[0] : '-'}</TableCell>
                          {/* CreatedDate */}
                          <TableCell>{PulseOpsFormat.datetoFormat(row.createdDate, 'DD/MM/YYYY HH:mm:ss')}</TableCell>
                          {/* Aging */}
                          <TableCell>{row.aging}</TableCell>
                          {/* LastUpdatedDate */}
                          <TableCell>
                            {row.lastUpdateDate
                              ? PulseOpsFormat.datetoFormat(row.lastUpdateDate, 'DD/MM/YYYY HH:mm:ss')
                              : '-'}
                          </TableCell>
                          {/* Note */}
                          <TableCell>
                            {roles.includes(Permission['ViewNoteServiceInquiry']) &&
                            [Category.COMPLAINT, Category.INQUIRY].includes(row.category as Category) ? (
                              <TouchableOpacity onPress={() => getServiceInquiryNote(row.id)}>
                                <View style={styles.btnDetail}>
                                  <Text style={{ textAlign: 'center', color: '#0000ee' }}>{t('common:Detail')}</Text>
                                </View>
                              </TouchableOpacity>
                            ) : row.transactionType === TransactionType.UPLOAD_DOCUMENT ? (
                              <TouchableOpacity onPress={() => showUploadDocument(row.id)}>
                                <View style={styles.btnDetail}>
                                  <Text style={{ textAlign: 'center', color: '#0000ee' }}>{t('common:Detail')}</Text>
                                </View>
                              </TouchableOpacity>
                            ) : (
                              <Text> {'-'}</Text>
                            )}
                          </TableCell>
                          <TableCell>
                            {roles.includes(Permission['CashoutdetailCashoutList']) && (
                              <TouchableOpacity
                                onPress={() => {
                                  props.navigation.navigate('CashOutScreen', {
                                    policyNum: row.policyNumber,
                                    policyOwner: row.poName,
                                    id: row.id,
                                    clientNum: row.clientNumber,
                                    createdDate: row.createdDate,
                                    caseId: row.caseId
                                  })
                                }}
                              >
                                <View style={{ flexDirection: 'row', paddingLeft: 5, width: 100 }}>
                                  <Icon>
                                    <assets.CashOutIcon />
                                  </Icon>
                                  <Text style={styles.textButtonRed}> {t('CashOut:CashOutButton')} </Text>
                                </View>
                              </TouchableOpacity>
                            )}
                          </TableCell>
                          <TableCell>
                            {roles.includes(Permission['CancelPaymentCashoutList']) && (
                              <TouchableOpacity onPress={() => setRemoveData(row)}>
                                <View style={{ flexDirection: 'row', width: 138 }}>
                                  <Icon style={{ paddingRight: 3 }}>
                                    <assets.DeleteBin />
                                  </Icon>
                                  <Text style={styles.textButtonRed}> {t('CashOut:RemoveButton')} </Text>
                                </View>
                              </TouchableOpacity>
                            )}
                          </TableCell>
                        </TableRow>
                      )
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                page={pageNumCashout}
                rowsPerPage={pageSizeCashout}
                count={totalItemCashout}
                onPageChange={(e, page) => {
                  setPageNumCashout(page)
                  getCashoutPending(submittedData, pageSizeCashout, page)
                }}
                onRowsPerPageChange={(e) => {
                  setPageSizeCashout(Number(e.target.value))
                  setPageNumCashout(0)
                  getCashoutPending(submittedData, Number(e.target.value), 0)
                }}
                labelRowsPerPage={t('common:PaginationSize')}
                labelDisplayedRows={({ from, to, count }) => t('common:Pagination', { from, to, count })}
                component={View}
              />
            </View>
          ) : (
            <View style={{ marginHorizontal: 30 }}>
              <Text style={{ color: '#ED1B2E' }}>
                {rowsCashout.length === 0 && tab === TabSearch.CashOutSearch ? messageResponse : ''}
              </Text>
            </View>
          )}

          {rowsPrint.length > 0 && roles.includes(Permission['PrintPVCashout']) && tab === TabSearch.CashOutComplete ? (
            <View style={[styles.section, { marginTop: 10 }]}>
              <TableContainer component={Paper} style={{ maxHeight: 500 }} elevation={1}>
                <Table style={{ userSelect: 'text' }} stickyHeader>
                  <TableHead className={classes.tableHeader}>
                    <TableRow>
                      <TableCell className={classes.tableCell}>{t('ServiceInquiry:table:CaseID')}</TableCell>
                      <TableCell className={classes.tableCell}>{t('ServiceInquiry:table:PolicyNumber')}</TableCell>
                      <TableCell className={classes.tableCell}>{t('ServiceInquiry:table:POName')}</TableCell>
                      <TableCell className={classes.tableCell}>{t('ServiceInquiry:table:Transaction')}</TableCell>
                      <TableCell className={classes.tableCell}>{t('ServiceInquiry:table:SubTransaction')}</TableCell>
                      <TableCell className={classes.tableCell}>{t('ServiceInquiry:table:UserSubmission')}</TableCell>
                      <TableCell className={classes.tableCell}>{t('ServiceInquiry:table:CreatedDate')}</TableCell>
                      <TableCell className={classes.tableCell}>{t('ServiceInquiry:table:Aging')}</TableCell>
                      <TableCell className={classes.tableCell}>{t('ServiceInquiry:table:LastUpdatedDate')}</TableCell>
                      <TableCell className={classes.tableCell}>{t('CashOut:PrintCount')}</TableCell>
                      <TableCell className={classes.tableCell} style={{ textAlign: 'center' }}>
                        {t('ServiceInquiry:table:Note')}
                      </TableCell>
                      <TableCell className={classes.tableCell} style={{ textAlign: 'center' }}>
                        {t('CashOut:Action')}
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {rowsPrint.map((row, i) => {
                      return (
                        <TableRow key={`TableRow-${i}`}>
                          {/* CaseID */}
                          <TableCell>
                            {row.caseId && roles.includes(Permission['ViewcasedetailPrintPV']) ? (
                              <Pressable
                                onPress={() =>
                                  props.navigation.navigate('TaskDetailStack', {
                                    screen: 'TaskDetailScreen',
                                    params: {
                                      id: row.id,
                                      caterogy: row.category,
                                      basket: '',
                                      basketName: '',
                                      isInquiry: '1',
                                      isHistory: 'true'
                                    }
                                  })
                                }
                              >
                                <Text style={[{ color: '#ed1b2e' }, styles.cellNoWrap]}>{row.caseId}</Text>
                              </Pressable>
                            ) : (
                              <Text style={styles.cellNoWrap}>{row.caseId || '-'}</Text>
                            )}
                          </TableCell>
                          {/* PolicyNumber */}
                          <TableCell>{row.policyNumber || '-'}</TableCell>
                          {/* POName */}
                          <TableCell>{row.poName || '-'}</TableCell>
                          {/* Transaction */}
                          <TableCell className={classes.cellNoWrap}>
                            {t(TransactionLabel(row.transactionType))}
                          </TableCell>
                          {/* SubTransaction */}
                          <TableCell>
                            {subTransactionTypeList.find((x) => x.value === row.subTransactionType)?.label || '-'}
                          </TableCell>
                          {/* UserSubmission */}
                          <TableCell>{row.createdBy ? row.createdBy.split('@')[0] : '-'}</TableCell>
                          {/* CreatedDate */}
                          <TableCell>{PulseOpsFormat.datetoFormat(row.createdDate, 'DD/MM/YYYY HH:mm:ss')}</TableCell>
                          {/* Aging */}
                          <TableCell>{row.aging}</TableCell>
                          {/* LastUpdatedDate */}
                          <TableCell>
                            {row.lastUpdateDate
                              ? PulseOpsFormat.datetoFormat(row.lastUpdateDate, 'DD/MM/YYYY HH:mm:ss')
                              : '-'}
                          </TableCell>
                          {/* PrintCount*/}
                          <TableCell>{row.printCount}</TableCell>
                          <TableCell>
                            {roles.includes(Permission['NotePrintPV']) && (
                              <TouchableOpacity onPress={() => showUploadNote(row.caseId)}>
                                <View style={{ flexDirection: 'row', paddingLeft: 5, width: 100 }}>
                                  <Icon>
                                    <assets.UploadStatus />
                                  </Icon>
                                  <Text style={styles.textButtonRed}> {t('CashOut:UpdateStatus')} </Text>
                                </View>
                              </TouchableOpacity>
                            )}
                          </TableCell>
                          <TableCell>
                            {row.printCount < 3 && roles.includes(Permission['PrintPrintPV']) && (
                              <TouchableOpacity onPress={() => printCashout(row)}>
                                <View style={{ flexDirection: 'row', paddingLeft: 5, width: 100 }}>
                                  <Icon style={{ paddingRight: 5 }}>
                                    <assets.PrintIcon />
                                  </Icon>
                                  <Text style={styles.textButtonRed}> {t('CashOut:PrintButton')} </Text>
                                </View>
                              </TouchableOpacity>
                            )}
                          </TableCell>
                        </TableRow>
                      )
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                page={pageNumPrint}
                rowsPerPage={pageSizePrint}
                count={totalItemPrint}
                onPageChange={(e, page) => {
                  setPageNumPrint(page)
                  getCashoutCompleted(submittedData, pageSizePrint, page)
                }}
                onRowsPerPageChange={(e) => {
                  setPageSizePrint(Number(e.target.value))
                  setPageNumPrint(0)
                  getCashoutCompleted(submittedData, Number(e.target.value), 0)
                }}
                labelRowsPerPage={t('common:PaginationSize')}
                labelDisplayedRows={({ from, to, count }) => t('common:Pagination', { from, to, count })}
                component={View}
              />
            </View>
          ) : (
            <View style={{ marginHorizontal: 30 }}>
              <Text style={{ color: '#ED1B2E' }}>
                {rowsCashout.length === 0 && tab === TabSearch.CashOutComplete ? messageResponse : ''}
              </Text>
            </View>
          )}

          <ModalComponent
            title={t('ServiceInquiry:DetailNote')}
            visible={infoVisible}
            onClose={() => setInfoVisible(false)}
            actions={[
              {
                text: t('common:Close'),
                type: 'filled',
                disabled: false,
                loading,
                onlyWide: false,
                style: { height: 40 },
                action: () => setInfoVisible(false)
              }
            ]}
          >
            <View style={{ marginHorizontal: 20, marginTop: 20, maxHeight: height - 200 }}>
              <Text style={{ paddingVertical: 10, fontWeight: 'bold' }}>{t('ServiceInquiry:CustomerComment')}</Text>
              <View
                style={{
                  minHeight: 80,
                  maxHeight: 'calc((100vh - 300px)/2)',
                  borderRadius: 5,
                  borderWidth: 1,
                  borderColor: '#4f4f4f',
                  backgroundColor: '#fff',
                  padding: 9
                }}
              >
                <ScrollView>
                  <Text>{noteDetail.customerComment}</Text>
                </ScrollView>
              </View>
              <Text style={{ paddingVertical: 10, fontWeight: 'bold' }}>{t('ServiceInquiry:ClosedInfo')}</Text>
              <View
                style={{
                  minHeight: 80,
                  maxHeight: 'calc((100vh - 300px)/2)',
                  borderRadius: 5,
                  borderWidth: 1,
                  borderColor: '#4f4f4f',
                  backgroundColor: '#fff',
                  padding: 9
                }}
              >
                <ScrollView>
                  <Text>{noteDetail.closedInfo}</Text>
                </ScrollView>
              </View>
            </View>
          </ModalComponent>

          <ModalComponent
            title={t('common:Detail')}
            visible={uploadDocVisible}
            onClose={() => {
              setUploadDocVisible(false)
              //setUploadDocumentTaskId(undefined)
            }}
            actions={[
              {
                text: t('common:Cancel'),
                type: 'filled',
                disabled: false,
                loading,
                onlyWide: false,
                style: { height: 40 },
                action: () => {
                  setUploadDocVisible(false)
                  //setUploadDocumentTaskId(undefined)
                }
              }
            ]}
          >
            {uploadDocumentTaskId && <UploadDocumentDetail taskId={uploadDocumentTaskId} />}
          </ModalComponent>

          <ModalComponent
            title={t('CashOut:UpdateTitle')}
            visible={updateNoteVisible}
            onClose={() => {
              setUpdateNoteVisible(false)
              statusChangeForm.reset()
            }}
            actions={[
              {
                text: t('common:Update'),
                type: 'filled',
                action: () => {
                  updateChangeStatusCashout()
                },
                disabled: false,
                loading: false,
                onlyWide: false,
                style: { height: 40, marginRight: 20 }
              },
              {
                text: t('common:Cancel'),
                type: 'outline',
                disabled: false,
                loading,
                onlyWide: false,
                style: { height: 40 },
                action: () => {
                  setUpdateNoteVisible(false)
                  statusChangeForm.reset()
                }
              }
            ]}
          >
            <UploadStatus form={statusChangeForm} />
          </ModalComponent>

          <ModalComponent
            title={t('CashOut:CancelTitle')}
            visible={cancelPaymentVisible}
            onClose={() => {
              setCancelPaymentVisible(false)
              cancelPaymentForm.reset()
            }}
            actions={[
              {
                text: t('common:Cancel'),
                type: 'outline',
                action: () => {
                  setCancelPaymentVisible(false)
                  cancelPaymentForm.reset()
                },
                disabled: false,
                loading: false,
                onlyWide: false,
                style: { height: 40, marginRight: 20 }
              },
              {
                text: t('common:Confirm'),
                type: 'filled',
                disabled: false,
                loading: false,
                onlyWide: false,
                style: { height: 40 },
                action: () => {
                  removeCashout()
                }
              }
            ]}
          >
            <View style={styles.row}>
              <View style={{ flex: 1, paddingHorizontal: 10 }}>
                <Controller
                  name="comment"
                  control={cancelPaymentForm.control}
                  rules={{
                    required: {
                      value: true,
                      message: `${t('message:MS020001', { field: t('CashOut:Comment') })}`
                    }
                  }}
                  render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                    <Input
                      required
                      multiline
                      labelStyle={{ paddingBottom: 10 }}
                      inputStyle={{ padding: 10, minHeight: 60 }}
                      title={t('CashOut:Comment')}
                      value={value ?? ''}
                      onChange={onChange}
                      onBlur={onBlur}
                      errorMessage={error?.message}
                    />
                  )}
                />
              </View>
            </View>
          </ModalComponent>
        </View>
      </ScrollView>
    </SafeAreaView>
  )
}

const styles = StyleSheet.create({
  section: {
    marginLeft: 30,
    marginRight: 30
  },

  textButtonRed: {
    color: '#ff0000',
    fontWeight: 'bold',
    paddingTop: 5
    // display: "flex",
  },

  searchContainer: {
    backgroundColor: '#FAFAFA',
    borderRadius: 8,
    borderWidth: 1,
    borderColor: '#D3DCE6',
    marginHorizontal: 30,
    marginVertical: 30,
    paddingBottom: 16,
    // paddingTop: 16,
    flexDirection: 'column'
  },

  titleContainer: {
    backgroundColor: '#EAEAEA',
    paddingHorizontal: 0,
    marginBottom: 16
  },

  row: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    paddingHorizontal: 16
  },

  col: {
    width: '33.33%',
    marginBottom: 16,
    paddingHorizontal: 16
  },

  btn: {
    width: 118,
    height: 40,
    backgroundColor: '#ed1b2e',
    borderRadius: 100,
    padding: 10,
    boxShadow: 'none'
  },

  btnDetail: {
    width: 80,
    height: 40,
    backgroundColor: '#ebe9e9',
    borderRadius: 100,
    padding: 10
  },

  tableHeader: {
    backgroundColor: '#E5EAEF'
  },

  cellNoWrap: {
    whiteSpace: 'nowrap'
  } as TextStyle
} as const)
