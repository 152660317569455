import { ActivityIndicator, Pressable, ScrollView, StyleSheet, Text, useWindowDimensions, View } from 'react-native'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { AppContext, assets, IBPolicyPermission, SelectOption, SubmissionService } from '@pulseops/common'
import { ClientScreenContext, IBButton, IBGeneralField, TypeInputComponent } from '../../common'
import { UseFormReturn } from 'react-hook-form'
import { IBPopupSendZNSForm } from './IBPopupSendZNSForm'
import { isEmpty, isNil, orderBy } from 'lodash'
import { GuideDocumentInfo, IBService } from '../../ib-service'
import { pipe } from 'fp-ts/lib/function'
import { ZIO } from '@mxt/zio'
import { useLoading } from '@mxt/zio-react'
import moment from 'moment'
interface Props {
  showPopup: boolean
  setShowPopup: (open: boolean) => void
  form: UseFormReturn<IBPopupSendZNSForm, any>
  policyList: SelectOption[]
  setGuidelineZNSArr: (value: GuideDocumentInfo[]) => void
  guidelineZNSArr: GuideDocumentInfo[]
}

enum TypePromotion {
  PRE_REMINDER = 'PRE-REMINDER',
  LAPSE_REIN = 'LAPSE-REIN'
}
enum GuideLineDocumentZNS {
  REMINDER = 'REMINDER',
  LAPSE = 'LAPSE'
}

export const IBPopupSendZNS = (props: Props) => {
  const { height, width } = useWindowDimensions()
  const { t, i18n } = useTranslation('Inbound')
  const [isMaximize, setMaximize] = React.useState<boolean>(false)
  const { form, showPopup, policyList, setGuidelineZNSArr, guidelineZNSArr, setShowPopup } = props
  const [loading, bindLoading] = useLoading(false)
  const [pushCodeList, setPushCodeList] = React.useState<SelectOption[]>([])
  const { showToast } = React.useContext(AppContext.AppContextInstance)
  const { permissions } = React.useContext(ClientScreenContext)

  React.useEffect(() => {
    if (showPopup) {
      pipe(
        IBService.getGuidelineDocumentList('COMMON_IB_ZNS' || ''),
        ZIO.map((t10) => {
          setGuidelineZNSArr(t10)
          return ZIO.unit
        }),
        bindLoading,
        ZIO.unsafeRun({})
      )
    }
  }, [showPopup])

  React.useEffect(() => {
    if (!form.watch().policyNumber) {
      form?.clearErrors('pushCode')
      form.setValue('pushCode', undefined)
      form.setValue('phoneNumber', '')
    }
  }, [form.watch().policyNumber])

  const checkRuleZNS = (
    promotionFlag: {
      hasPromotion: boolean
      countPromotion: number
      premiumRein: string | null | undefined
      typePromotion: string | null
    },
    policyStatus: string
  ): boolean => {
    const hasPromotion = promotionFlag?.hasPromotion
    const typePromotion = promotionFlag?.typePromotion as TypePromotion
    const premiumRein = parseInt(promotionFlag?.premiumRein || '0')

    const setErrorGuidelineDocument = (message: string) => {
      return form?.setError('pushCode', { message: t(message) })
    }

    if (promotionFlag && promotionFlag?.countPromotion >= 2) {
      setErrorGuidelineDocument('message:IB0024')
      return false
    } else if (!hasPromotion && !['IF', 'LA'].includes(policyStatus || '')) {
      setErrorGuidelineDocument('message:IB0021')
      return false
    } else if (hasPromotion && ![TypePromotion.LAPSE_REIN, TypePromotion.PRE_REMINDER].includes(typePromotion)) {
      setErrorGuidelineDocument('message:IB0023')
      return false
    } else {
      form?.clearErrors('pushCode')
      return true
    }
  }

  const getGuideDocumentZNSOptions = (
    promotionFlag: {
      hasPromotion: boolean
      countPromotion: number
      premiumRein: string | null | undefined
      typePromotion: string | null
    },
    policyStatus: string
  ) => {
    const filterData = () => {
      const hasPromotion = promotionFlag?.hasPromotion
      const typePromotion = promotionFlag?.typePromotion
      const filterDataByPromotion = guidelineZNSArr.filter((x) =>
        hasPromotion ? x.pushCode.includes('PROMOTION') : !x.pushCode.includes('PROMOTION')
      )

      if (!hasPromotion) {
        switch (policyStatus) {
          case 'IF':
            return filterDataByPromotion.filter((x) => x.pushCode.includes(GuideLineDocumentZNS.REMINDER))
          case 'LA':
            return filterDataByPromotion.filter((x) => x.pushCode.includes(GuideLineDocumentZNS.LAPSE))
          default:
            return []
        }
      } else if (typePromotion === TypePromotion.PRE_REMINDER) {
        return filterDataByPromotion.filter((x) => x.pushCode.includes(GuideLineDocumentZNS.REMINDER))
      } else if (typePromotion === TypePromotion.LAPSE_REIN) {
        return filterDataByPromotion.filter((x) => x.pushCode.includes(GuideLineDocumentZNS.LAPSE))
      } else {
        return []
      }
    }

    setPushCodeList(
      filterData().map((item) => ({
        label: i18n.language === 'en' ? item.pushCodeName : item.pushCodeName,
        value: item.pushCode
      }))
    )
  }

  const checkPromotion = (value: string) => {
    if (value) {
      pipe(
        ZIO.zipPar(
          IBService.getPromotionInfo(value),
          SubmissionService.getNewOwnerInfo(value),
          IBService.getProductType(value)
        ),
        ZIO.map(([res, owner, product]) => {
          const promotionFlag = {
            hasPromotion: res?.data?.havePromotion ?? false,
            countPromotion: res?.data?.promotions?.length ?? 0,
            premiumRein: res.data?.promotions?.length === 1 ? res.data?.promotions[0].reinAmount : null,
            typePromotion: res.data?.promotions?.length === 1 ? res.data?.promotions[0].type : null
          }
          if (promotionFlag && checkRuleZNS(promotionFlag, product.body.status) && promotionFlag?.countPromotion < 2) {
            getGuideDocumentZNSOptions(promotionFlag, product.body.status)
          }
          form.setValue('phoneNumber', owner.mobilePhone || '')
          // form.setValue('phoneNumber', owner.contactDetails.DISPATCH_ADDRESS_PHONE?.value || '')

          return ZIO.unit
        }),
        bindLoading,
        ZIO.unsafeRun({})
      )
    }
  }

  const onSubmit = form.handleSubmit((value) => {
    pipe(
      IBService.postSendZNS({
        policyNumber: value.policyNumber?.value || '',
        pushCode: value.pushCode?.value || '',
        feature: 'IB',
        phoneNumber: value.phoneNumber,
        requestTime: moment().toISOString()
      }),
      ZIO.map((res) => {
        showToast(t('message:IB0018'), 'success')
        form.setValue('policyNumber', undefined)
        setShowPopup(false)
        return ZIO.unit
      }),
      bindLoading,
      ZIO.unsafeRun({})
    )
  })
  if (!showPopup) return <></>
  return (
    <View style={[styles.modalCallResult, { height: 330, width: isMaximize ? width * 0.8 : width * 0.55 }]}>
      <ScrollView showsVerticalScrollIndicator={false}>
        <View
          style={{
            flexDirection: 'row',
            justifyContent: 'space-between',
            paddingHorizontal: 20,
            paddingTop: 20,
            marginBottom: 10
          }}
        >
          <Text style={{ fontWeight: '700', fontSize: 16 }}>{t('SendZNS').toUpperCase()}</Text>
          <Pressable onPress={() => setMaximize(!isMaximize)} style={{ marginRight: 15 }}>
            {isMaximize ? <assets.IBArrowMinimize /> : <assets.IBArrowMaximize />}
          </Pressable>
        </View>
        <View style={styles.formStyle}>
          <IBGeneralField
            FieldForm={form}
            col={2}
            typeInput={[
              {
                type: TypeInputComponent.INPUT,
                formName: 'policyOwner',
                title: t('OwnerName'),
                inputType: 'input',
                disabled: true,
                inputStyle: { color: 'red' }
              },
              {
                type: TypeInputComponent.SELECT,
                formName: 'policyNumber',
                title: t('common:PolicyNumber'),
                option: orderBy(
                  policyList.map((item) => ({ value: item.value, label: item.label })),
                  'value',
                  'desc'
                ),
                required: true,
                handleData: (value) => {
                  setPushCodeList([])
                  form.setValue('pushCode', undefined)
                  checkPromotion(value?.value as string)
                }
              },
              {
                type: TypeInputComponent.INPUT,
                formName: 'phoneNumber',
                title: t('MobilePhone'),
                inputType: 'input',
                disabled: true
              },
              {
                type: TypeInputComponent.SELECT,
                formName: 'pushCode',
                title: t('Type'),
                option: pushCodeList,
                required: true
              }
            ]}
          />
        </View>
        <View style={styles.btnBar}>
          <IBButton
            onPress={onSubmit}
            title={t('Submit')}
            backgroundFill
            widthDiv={'100%'}
            disabled={
              !permissions.includes(IBPolicyPermission.IBSendZNSPopup) ||
              Object.values(form.watch()).some((x) => x === undefined || x === '')
            }
          />
        </View>
      </ScrollView>
      {loading ? (
        <View style={styles.loadingBlur}>
          <ActivityIndicator size="large" color="red" />
        </View>
      ) : (
        <></>
      )}
    </View>
  )
}

const styles = StyleSheet.create({
  modalCallResult: {
    backgroundColor: '#ffffff',
    borderTopLeftRadius: 20,
    borderTopRightRadius: 20,
    shadowColor: 'rgba(0, 0, 0, 0.2)',
    shadowOffset: {
      width: 0,
      height: 0
    },
    shadowRadius: 40,
    elevation: 40,
    shadowOpacity: 1
  },
  formStyle: {
    flex: 1,
    padding: 20,
    paddingRight: 0,
    height: '100%'
  },
  btnBar: {
    borderTopWidth: 1,
    borderColor: '#EBEBF0',
    height: 70,
    width: '100%',
    flexDirection: 'row',
    paddingVertical: 10,
    paddingHorizontal: 50,
    justifyContent: 'space-between'
  },
  loadingBlur: {
    margin: 0,
    position: 'absolute',
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0,0,0,0.25)',
    justifyContent: 'center',
    borderRadius: 10
  }
})
