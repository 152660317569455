import * as t from 'io-ts'
import { TransactionType } from '../TransactionType'
import { Maybe } from '@mxt/zio/codec'

export const riders = t.type({
  productCode: t.string,
  riskCommDate: t.string,
  riskCessDate: Maybe(t.string),
  sumAssured: t.number,
  installmentPremium: Maybe(t.number),
  newInstallmentPremium: t.number,
  lifeNo: t.string,
  coverageNo: t.string,
  riderNo: t.string,
  riderStatus: Maybe(t.string),
})
export const lifeAssured = t.type({
  lifeAssured: Maybe(t.string),
  riders: t.array(riders)
})

export const CancelRider = t.type({
  transactionType: t.literal(TransactionType.CANCEL_RIDER),
  payload: t.type({
    body: t.type({
      status: Maybe(t.string),
      policyNo: Maybe(t.string),
      lifeAssureds: Maybe(t.array(lifeAssured))
    }),
    source: Maybe(t.string)
  })
})
export type CancelRider = t.TypeOf<typeof CancelRider>
