import { pipe } from 'fp-ts/lib/function'
import * as t from 'io-ts'
import { Maybe, Nullable } from '@mxt/zio/codec'
import { DateFromISOString } from 'io-ts-types'
import * as A from 'fp-ts/Array'
import { PageParameter, PageResult, TaskType, SubTaskType } from '@pulseops/common'
import { TaskSource } from './TaskSource'
import { Vip } from './Vip'
import { TransactionType } from './TransactionType'
import { SubTaskCode, SubTaskCodeFromString } from './TaskType'
import { TransactionStatus } from './TransactionStatus'
import { TaskOp, TaskPremiumOp, TaskDSOp, TaskAccountingOP, TaskIDCOp } from './TaskOp'

export namespace QueryTask {
  export type Filter = {
    source?: TaskSource[]
    vip?: Vip[]
    correlationId?: string
    policyNum?: string
    clientNum?: string
    caseId?: string
    transactionType?: TransactionType[]
    fromDate?: Date
    toDate?: Date
    assignee?: string | null
    client?: string
    categories?: string[]
    subServiceTypes?: string[]
    subTransactionTypes?: string[]
    offices?: string[]
    claimNumber?: string
    claimTypes?: string[]
    businessKeyEmpty?: boolean
    redFlagResult?: boolean
    idNumber?: string
    phoneNumber?: string
    paymentMethod?: string
    bankCode?: string
    amount?: string
    proposalNumber?: string
    eref?: string
    agentCode?: string
    agentFirstName?: string
    agentLastName?: string
    agentPhoneNumber?: string
    clientId?: string
    caseStatus?: number
    lastActivity?: string
    createdBy?: string
    amlResult?: string
    referenceNumber?: string
    suspendReason?: string
    suspendTypes?: {
      code: string
      typeEn: string
      typeVn: string
    }[]
    pvNumber?: string
    paymentDate?: Date
    payeeName?: string
    paymentNumber?: string
    authorizedDate?: Date
    callTimes?: number
    smsTimes?: number
  }

  export type LasTransactionFilter = {
    code?: string
    number?: string
    date?: Date
    fromDate?: Date
    toDate?: Date
    description?: string
  }

  export type InputData = {
    isTeam?: boolean
    pagination: PageParameter
    filter: Filter
    order?: 'desc' | 'asc'
    taskCategory?: SubTaskCode
    category?: string
    active?: boolean
    suspended?: boolean
    isFilterTeam?: boolean
    complete?: boolean
    isPayout?: boolean
    caseStatus?: string
    paymentMethod?: string
    eref?: string
    suspendReasonCode?: string
  }

  export type PostData = {
    sourceIn?: TaskSource[]
    vipIn?: Vip[]
    transactionTypeIn?: TransactionType[]
    correlationId?: string
    policyNumber?: string
    clientId?: string
    businessKey?: string
    createdAfter?: Date
    createdBefore?: Date
    createdOn?: string
    start: number
    size: number
    order?: 'desc' | 'asc'
    taskCategory?: string
    active?: boolean
    assignee?: string
    category?: string
    categories?: string[]
    complete?: boolean
    suspended?: boolean
    isFilterTeam?: boolean
    isPayout?: boolean
    subServiceTypes?: string[]
    subTransactionTypes?: string[]
    offices?: string[]
    claimNumber?: string
    claimTypes?: string[]
    businessKeyEmpty?: boolean
    idNumber?: string
    paymentMethod?: string
    phoneNumber?: string
    caseStatus?: number
    bankCode?: string
    amount?: string
    proposalNumber?: string
    eref?: string
    agentCode?: string
    lastActivity?: string
    createdBy?: string
    amlResult?: string
    redFlagResult?: boolean
    referenceNumber?: string
    paymentDate?: Date
    payeeName?: string
    pvNumber?: string
    paymentNumber?: string
    authorizedDate?: Date
    callTimes?: number
    smsTimes?: number
    clientNumber?: string
  }
  export const PostData = ({
    pagination,
    filter,
    order,
    taskCategory,
    active,
    category,
    isFilterTeam
  }: InputData): PostData => ({
    active: active != null ? active : true,
    order: order || 'desc',
    size: pagination.pageSize,
    start: [TaskType.IDC].includes(category as TaskType) ? pagination.pageIndex : pagination.pageSize * pagination.pageIndex,
    sourceIn: filter.source,
    vipIn: filter.vip,
    transactionTypeIn: filter.transactionType,
    correlationId: filter.correlationId,
    policyNumber: filter.policyNum,
    idNumber: filter.idNumber,
    phoneNumber: filter.phoneNumber,
    clientId: filter.clientNum,
    businessKey: filter.caseId,
    createdAfter: filter.fromDate,
    createdBefore: filter.toDate,
    assignee: filter.assignee || '',
    taskCategory: taskCategory ? taskCategory.subTask === SubTaskType.QCUnderwriting ? 'UW-QC' : SubTaskCodeFromString.encode(taskCategory) : undefined,
    isFilterTeam: isFilterTeam,
    category: category,
    categories: filter.categories && filter.categories.length > 0 ? filter.categories : undefined,
    subServiceTypes: filter.subServiceTypes,
    subTransactionTypes: filter.subTransactionTypes,
    offices: filter.offices,
    claimNumber: filter.claimNumber,
    claimTypes: filter.claimTypes,
    businessKeyEmpty: filter.businessKeyEmpty,
    paymentMethod: filter.paymentMethod ?? undefined,
    bankCode: filter.bankCode,
    amount: filter.amount,
    proposalNumber: filter.proposalNumber,
    eref: filter.eref,
    agentCode: filter.agentCode,
    lastActivity: filter.lastActivity,
    createdBy: filter.createdBy,
    amlResult: filter.amlResult,
    redFlagResult: filter.redFlagResult,
    referenceNumber: filter.referenceNumber,
    suspendReason: filter.suspendReason,
    authorizedDate: filter.authorizedDate,
    callTimes: filter.callTimes,
    smsTimes: filter.smsTimes,
    clientNumber: [TaskType.IDC].includes(category as TaskType) ? filter.client : undefined
  })

  export const PostDataCashOut = ({
    pagination,
    filter,
    order,
    taskCategory,
    active,
    category,
    isFilterTeam,
    suspended,
    complete,
    caseStatus,
    isPayout,
    paymentMethod
  }: InputData): PostData => ({
    active: active != null ? active : true,
    order: order || 'desc',
    suspended: suspended,
    complete: complete,
    caseStatus: filter.caseStatus,
    isPayout: isPayout,
    size: pagination.pageSize,
    start: pagination.pageSize * pagination.pageIndex,
    sourceIn: filter.source,
    vipIn: filter.vip,
    transactionTypeIn: filter.transactionType,
    correlationId: filter.correlationId,
    policyNumber: filter.policyNum,
    idNumber: filter.idNumber,
    phoneNumber: filter.phoneNumber,
    clientId: filter.clientNum,
    businessKey: filter.caseId,
    createdAfter: filter.fromDate,
    createdBefore: filter.toDate,
    assignee: filter.assignee,
    taskCategory: taskCategory && SubTaskCodeFromString.encode(taskCategory),
    isFilterTeam: isFilterTeam,
    category: category,
    categories: filter.categories && filter.categories.length > 0 ? filter.categories : undefined,
    subServiceTypes: filter.subServiceTypes,
    subTransactionTypes: filter.subTransactionTypes,
    offices: filter.offices,
    claimNumber: filter.claimNumber,
    claimTypes: filter.claimTypes,
    businessKeyEmpty: filter.businessKeyEmpty,
    paymentMethod: paymentMethod
  })

  export const PostDataAccounting = ({
    pagination,
    filter,
    order,
    taskCategory,
    active,
    isFilterTeam,
    isPayout,
    paymentMethod,
    caseStatus
  }: InputData): PostData => ({
    active: active != null ? active : true,
    order: order || 'desc',
    isPayout: isPayout,
    size: pagination.pageSize,
    start: pagination.pageSize * pagination.pageIndex,
    sourceIn: filter.source,
    transactionTypeIn: filter.transactionType,
    correlationId: filter.correlationId,
    policyNumber: filter.policyNum,
    phoneNumber: filter.phoneNumber,
    businessKey: filter.caseId,
    createdAfter: filter.fromDate,
    createdBefore: filter.toDate,
    taskCategory: taskCategory && SubTaskCodeFromString.encode(taskCategory),
    isFilterTeam: isFilterTeam,
    categories: filter.categories && filter.categories.length > 0 ? filter.categories : undefined,
    offices: filter.offices,
    paymentMethod: paymentMethod,
    paymentDate: filter.paymentDate,
    amount: filter.amount,
    payeeName: filter.payeeName,
    pvNumber: filter.pvNumber,
    paymentNumber: filter.paymentNumber,
    bankCode: filter.bankCode,
    caseStatus: Number(caseStatus),
    authorizedDate: filter.authorizedDate
  })

  export const PostSuspendData = ({
    pagination,
    filter,
    order,
    category,
    active,
    suspended,
    taskCategory
  }: InputData): PostData => ({
    active: active != null ? active : true,
    order: order || 'desc',
    size: pagination.pageSize,
    start: [TaskType.IDC].includes(category as TaskType) ? pagination.pageIndex : pagination.pageSize * pagination.pageIndex,
    sourceIn: filter.source,
    vipIn: filter.vip,
    transactionTypeIn: filter.transactionType,
    correlationId: filter.correlationId,
    businessKey: filter.caseId,
    createdAfter: filter.fromDate,
    createdBefore: filter.toDate,
    assignee: filter.assignee,
    category,
    suspended,
    eref: filter.eref,
    agentCode: filter.agentCode,
    lastActivity: filter.lastActivity,
    createdBy: filter.createdBy,
    ignoreWriteOff: taskCategory?.task === TaskType.PremiumCollection,
    suspendSpecialReason: taskCategory?.subTask === SubTaskType.WriteOff ? 'WRITE_OFF' : '',
    claimNumber: filter.claimNumber,
    policyNumber: filter.policyNum,
    clientNumber: [TaskType.IDC].includes(category as TaskType) ? filter.client : undefined
  })

  export const codec = t.type({
    id: t.string,
    assignee: Maybe(t.string),
    assigneeName: Maybe(t.string),
    agingDate: Maybe(t.number),
    processInstanceId: Maybe(t.string),
    category: Maybe(t.string),
    transactionType: Maybe(t.string),
    correlationId: Maybe(t.string),
    createTime: Maybe(DateFromISOString),
    endTime: Maybe(DateFromISOString),
    source: Maybe(t.string),
    vip: Maybe(t.string),
    businessKey: Maybe(t.string),
    startTime: Maybe(t.string),
    completed: Maybe(t.boolean),
    suspended: t.boolean,
    updateToCore: t.boolean,
    clientNumber: Maybe(t.string),
    policyNumber: Maybe(t.string),
    activity: Maybe(t.string),
    proposalNumber: Maybe(t.string),
    status: Maybe(
      t.union([
        t.literal('IN_PROGRESS'),
        t.literal('PENDING'),
        t.literal('COMPLETED'),
        t.literal('END'),
        t.literal('REJECTED'),
        t.literal('In process'),
        t.literal('Pending'),
        t.literal('Completed'),
        t.literal('End'),
        t.literal('Rejected'),
        t.literal('Calling'),
        t.literal('VERIFICATION_SAMPLING'),
        t.literal('VR_INIT_PROCESS'),
        t.literal('CHECK_AI_RESULT'),
        t.literal('VERIFICATION'),
        t.string
      ])
    ),
    createdDate: Maybe(DateFromISOString),
    createdBy: Maybe(t.string),
    lastUpdateByAdj: Maybe(t.string),
    lastUpdateByVer: Maybe(t.string),
    lastUpdateDateAdj: Maybe(DateFromISOString),
    lastUpdateDateVer: Maybe(DateFromISOString),
    lastUpdateDate: Maybe(DateFromISOString),
    subServiceType: Maybe(t.string),
    subTransactionType: Maybe(t.string),
    subTransactionCode: Maybe(t.string),
    poClientNumber: Maybe(t.string),
    poName: Maybe(t.string),
    poPhoneNumber: Maybe(t.string),
    claimType: Maybe(t.string),
    lifeAssuredName: Maybe(t.string),
    claimNumber: Maybe(t.string),
    request: Maybe(t.string),
    amlResult: Maybe(
      t.union([
        t.literal('HIT'),
        t.literal('NO_HIT'),
        t.literal('ERROR'),
        t.literal('WARNING'),
        t.literal('HIT_ACCEPT'),
        t.literal('HIT_DECLINE'),
        t.literal('NOTCONNECT'),
        t.literal('INSUFFICIENT_INFORMATION')
      ])
    ),
    redFlagResult: Maybe(t.boolean),
    printCountCashAtCounter: Maybe(t.number),
    paymentMethod: Maybe(t.string),
    bankCode: Maybe(t.string),
    amount: Maybe(t.string),
    officeCode: Maybe(t.string),
    referenceNumber: Maybe(t.string),
    paymentReason: Maybe(t.string),
    receiptDate: Maybe(t.string),
    eref: Maybe(t.string),
    agentCode: Maybe(t.string),
    agentFirstName: Maybe(t.string),
    agentLastName: Maybe(t.string),
    agentPhoneNumber: Maybe(t.string),
    isCancelPayment: Maybe(t.boolean),
    cancelPaymentStatus: Maybe(t.string),
    suspendReason: Maybe(t.string),
    unsuspendReason: Maybe(t.string),
    remark: Maybe(t.string),
    pendingNum: Maybe(t.string),
    actionName: Maybe(t.string),
    writeOffAction: Maybe(t.string),
    suspendTypes: Maybe(
      t.array(
        t.type({
          code: t.string,
          typeEn: t.string,
          typeVn: t.string
        })
      )
    ),
    subServiceTypeCode: Maybe(t.string),
    pvNumber: Maybe(t.string),
    paymentNumber: Maybe(t.string),
    paymentDate: Maybe(t.number),
    payeeName: Maybe(t.string),
    statusNote: Maybe(t.string),
    notes: Maybe(t.string),
    sumAmount: Maybe(t.number),
    idNumber: Maybe(t.string),
    lastUpdateBy: Maybe(t.string),
    lastUpdatedBy: Maybe(t.string),
    authorizedDate: Maybe(t.number),
    hasVoucher: Maybe(t.boolean),
    vouchers: Maybe(
      t.array(
        t.type({
          code: t.string,
          amount: t.string
        })
      )
    ),
    stpFunction: Maybe(t.string),
    channel: Maybe(t.string),
    callTimes: Nullable(t.number),
    smsTimes: Nullable(t.number),
    idcType: Maybe(t.string),
    lastActivity: Maybe(t.string),
    payload: Maybe(t.type({
      body: Maybe(t.type({
        poFullName: Maybe(t.string),
        idNumber: Maybe(t.string),
      }))
    })),
    batchName: Maybe(t.string)
  })

  export const Response = t.type({
    data: t.union([t.array(QueryTask.codec), t.null]),
    total: t.number
  })

  export type Response = t.TypeOf<typeof Response>

  export const toPageResult =
    (pagination: PageParameter, status: TransactionStatus | null) =>
      (result: Response): PageResult<TaskOp> =>
        PageResult({
          items: pipe(
            result.data || [],
            A.map((task) => TaskOp(task, status))
          ),
          total: result.total,
          pageIndex: pagination.pageIndex,
          pageSize: pagination.pageSize
        })

  export const toPagePCresult =
    (pagination: PageParameter, status: TransactionStatus | null) =>
      (result: Response): PageResult<TaskPremiumOp> =>
        PageResult({
          items: pipe(
            result.data || [],
            A.map((task) => TaskPremiumOp(task, status))
          ),
          total: result.total,
          pageIndex: pagination.pageIndex,
          pageSize: pagination.pageSize
        })
  export const toPageDSresult =
    (pagination: PageParameter, status: TransactionStatus | null) =>
      (result: Response): PageResult<TaskDSOp> =>
        PageResult({
          items: pipe(
            result.data || [],
            A.map((task) => TaskDSOp(task, status))
          ),
          total: result.total,
          pageIndex: pagination.pageIndex,
          pageSize: pagination.pageSize
        })
  export const toPageAccounting =
    (pagination: PageParameter, status: TransactionStatus | null) =>
      (result: Response): PageResult<TaskAccountingOP> =>
        PageResult({
          items: pipe(
            result.data || [],
            A.map((task) => TaskAccountingOP(task, status))
          ),
          total: result.total,
          pageIndex: pagination.pageIndex,
          pageSize: pagination.pageSize
        })
  export const toPageIDCresult =
    (pagination: PageParameter, status: TransactionStatus | null) =>
      (result: Response): PageResult<TaskIDCOp> =>
        PageResult({
          items: pipe(
            result.data || [],
            A.map((task) => TaskIDCOp(task, status))
          ),
          total: result.total,
          pageIndex: pagination.pageIndex,
          pageSize: pagination.pageSize
        })
}

export type QueryTask = t.TypeOf<typeof QueryTask.codec>
