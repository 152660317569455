/* eslint-disable @nrwl/nx/enforce-module-boundaries */
/* eslint-disable no-extra-boolean-cast */
import React from 'react'
import { View, Text } from 'react-native'
import { useTranslation } from 'react-i18next'
import { useLoading } from '@mxt/zio-react'
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, makeStyles } from '@material-ui/core'
import { Panel } from '../component'
import {
  TaskDetail,
  ErrorHandling,
  AuthService,
  AppContext,
  Toast,
  Checkbox,
  SourceType,
  TransactionType
} from '@pulseops/common'
import { AgentEvaluationForm } from './da-agent-form'
import { pipe } from 'fp-ts/lib/function'
import { ZIO } from '@mxt/zio'
import { GeneralInformationSelect } from '../mock'
import { Controller, UseFormReturn, useWatch } from 'react-hook-form'
import { DaAgentService } from './da-agent-evaluation-services'
import moment from 'moment'
import { DaDocumentService } from '../da-documents/da-doccument-services'
import { DAagentEvaluationData } from './da-agentevaluation-data'
import { GeneralForm } from '../da-general-info'
import { Footer } from '../da-request-info'
import { Color } from '@material-ui/lab'
import { StructureForm } from '../da-structure'

const useStyles = makeStyles({
  tableHeader: {
    fontWeight: 700,
    fontSize: 15,
    width: 'calc(100%/2.5)',
    paddingRight: 30,
    paddingLeft: 50,
    color: '#70777E'
  },
  tableHeaderStandard: {
    fontWeight: 700,
    fontSize: 15,
    color: '#70777E',
    width: 'calc(100%/3)',
    paddingLeft: 50
  },
  tableHeaderCell: {
    fontWeight: 700,
    fontSize: 15,
    color: '#70777E',
    width: 'calc(100%/3)'
  },
  tableHeaderCellUnder: {
    fontWeight: 700,
    fontSize: 15,
    color: '#70777E',
    flex: 1
  },
  tableHeaderCellbody: {
    paddingRight: 30,
    paddingLeft: 50
  },
  tableHeaderRow: {
    backgroundColor: 'white'
  },
  tableHea: {
    fontWeight: 700,
    fontSize: 15,
    color: '#000',
    paddingLeft: 50
  },
  tableCellUnder: {
    textDecorationLine: 'underline',
    color: 'blue',
    fontWeight: 700,
    fontSize: 15
  },
  tableStandard: {
    fontWeight: 700,
    fontSize: 15,
    color: '#70777E',
    paddingLeft: 50
  },
  tableReality: {
    fontWeight: 700,
    fontSize: 15,
    color: '#70777E'
  },
  tableCandidate1: {
    fontWeight: 700,
    fontSize: 15,
    color: '#70777E',
    paddingLeft: 50,
    width: 'calc(100%/3)'
  },
  tableCandidate2: {
    fontWeight: 700,
    fontSize: 15,
    color: '#70777E',
    width: 'calc(100%/3)',
    backgroundColor: '#fff'
  },
  tableCandidate3: {
    fontWeight: 700,
    fontSize: 15,
    color: '#70777E',
    width: 'calc(100%/3)',
    backgroundColor: '#fff'
  },
  tableHeaderQualified: {
    backgroundColor: '#fff'
  },
  tableQualified: {
    backgroundColor: '#F4F4F4',
    fontWeight: 700,
    fontSize: 15,
    color: '#70777E',
    paddingLeft: 50,
    flex: 1
  },
  tableQualifiedUnder: {
    backgroundColor: '#F4F4F4',
    fontWeight: 700,
    fontSize: 15,
    color: '#70777E'
  },
  tableDate: {
    fontWeight: 700,
    fontSize: 15,
    color: '#70777E',
    backgroundColor: '#fff',
    flex: 1
  },
  tableMOF: {
    fontWeight: 700,
    fontSize: 15,
    color: '#70777E',
    // width: 'calc(100%/2)',
    backgroundColor: '#fff'
  }
})
type Props = {
  detail: TaskDetail.AgentOnboardNew
  form: Omit<UseFormReturn<AgentEvaluationForm.Raw>, 'handleSubmit'>
  generalForm: Omit<UseFormReturn<GeneralForm.Raw>, 'handleSubmit'>
  structureForm: Omit<UseFormReturn<StructureForm.Raw>, 'handleSubmit'>
  isHistory: boolean | null
  setStatusSave: (val: boolean) => void
}

const DEFAULT_QSCORE = {
  age: 0,
  eduacation: 0,
  work: 0,
  customer: 0
}
const enum SalesType {
  AGENCY = 'AGENCY',
  GALLERIE = 'GALLERIE',
  MILLENNIAL = 'MILLENNIAL',
  PTD = 'PTD',
  DSR = 'DSR'
}
const enum SubChannel {
  FSC = 'FSC',
  TMR = 'TMR',
  QAS = 'QAS'
}
export const DAagentEvaluationScreen = (props: Props) => {
  const classes = useStyles()
  const { trigger, setValue, reset, getValues, watch } = props.form
  const { control } = props.generalForm
  const { t, i18n } = useTranslation('RequestInfo')
  // const { t, i18n } = useTranslation(['requestInfo', 'roles'])
  const [loading, bindLoader] = useLoading(false)
  const { showGlobalLoading, showToast } = React.useContext(AppContext.AppContextInstance)
  const dateofBirth = useWatch({ control, name: 'dateofBirth' })
  const [toast, setToast] = React.useState<{
    open: boolean
    message: string
    type?: Color
  }>({ open: false, message: '' })

  const sumAge = React.useMemo(() => {
    const newDate = new Date()
    const ageDage = dateofBirth ? moment(dateofBirth, 'DD/MM/YYYY') : moment(props.detail.dob, 'DD/MM/YYYY')
    return moment(newDate).diff(ageDage, 'year')
  }, [])

  const eduCation = useWatch({ control: props.generalForm.control, name: 'Education' })?.value
  const Educations = React.useMemo(() => {
    return GeneralInformationSelect.EducationData.find((item) => item.code === eduCation)
  }, [])

  const { trainee, candidate, agentEvaluation, certifiCate, channelDocument, validateRule } = pipe(
    DaDocumentService.getDocumentLog(props.detail.businessKey ?? ''),
    ZIO.flatMap((resLog) => {
      if (!!resLog && !!resLog.detailList && resLog.detailList.length > 0) {
        return ZIO.succeed(resLog.detailList)
      } else {
        return pipe(
          DaDocumentService.getChannelDocument({
            source: props.detail.source,
            channelId: props.detail.businessKey,
            idNo: props.detail.idNo
          }),
          ZIO.map((res) => {
            return res.data
          })
        )
      }
    }),

    ZIO.flatMap((resData) => {
      return pipe(
        ZIO.zipPar(
          DaAgentService.checkTraineeQualified({
            fullName: props.generalForm.getValues('lastName') + ' ' + props.generalForm.getValues('firstName'),
            birthdayStr: moment(props.generalForm.getValues('dateofBirth')).format('DD/MM/yyyy'),
            identifyPassport: props.detail.idNo
          }),
          DaAgentService.checkCandidateInfo({
            fullName: props.generalForm.getValues('lastName') + ' ' + props.generalForm.getValues('firstName'),
            birthdayStr: moment(props.generalForm.getValues('dateofBirth')).format('DD/MM/yyyy'),
            identifyPassport: props.detail.idNo
          }),
          DaAgentService.getAgentEvaluation(props.detail.businessKey ?? ''),
          DaAgentService.checkCertificateInfo({
            fullName: props.generalForm.getValues('lastName') + ' ' + props.generalForm.getValues('firstName'),
            birthdayStr: moment(props.generalForm.getValues('dateofBirth')).format('DD/MM/yyyy'),
            identifyPassport: props.detail.idNo
          }),
          DaAgentService.getValidateRule(props.detail.idNo ?? '', props.detail.oldIdNo ?? '')
        ),
        ZIO.map(([trainee, candidate, agentEvaluationRes, certifiCate, validateRule]) => {
          return {
            trainee: trainee?.data,
            candidate: candidate?.data,
            agentEvaluation: agentEvaluationRes.data,
            certifiCate: certifiCate.data,
            channelDocument: resData,
            validateRule: validateRule
          }
        })
      )
    }),
    ZIO.tapError((error) => {
      return ZIO.unit
    }),
    bindLoader,
    ErrorHandling.runDidMount({
      trainee: null,
      candidate: null,
      agentEvaluation: null,
      certifiCate: null,
      channelDocument: [],
      validateRule: null
    })
  )

  const { count, qscore } = React.useMemo(() => {
    let score = 0
    let qScore = DEFAULT_QSCORE

    const arrEducations = ['TH', 'CD', 'DH', 'CH']
    if (sumAge >= 21 && sumAge <= 45) {
      score += 1
      qScore = { ...qScore, age: 1 }
    }
    if (arrEducations.includes(Educations?.code ?? '')) {
      score += 1
      qScore = { ...qScore, eduacation: 1 }
    }

    if (channelDocument.find((item) => item.docID === '20102161' && item.status === 'done')) {
      //// item.docID === '20102161' dưạ vào DOC để cộng điểm
      score += 1
      qScore = { ...qScore, work: 1 }
    }
    if (
      (props.detail.source === SourceType.PRUDAILY &&
        channelDocument.find((item) => item.docID === '20102181' && item.status === 'done')) ||
      props.detail.source === SourceType.PRUFORCE
    ) {
      //// item.docID === '20102161' dưạ vào DOC để cộng điểm
      score += 1
      qScore = { ...qScore, customer: 1 }
    }
    return {
      qscore: qScore,
      count: score
    }
  }, [Educations, sumAge, channelDocument]) ?? {
    count: 0,
    qscore: DEFAULT_QSCORE
  }

  React.useEffect(() => {
    showGlobalLoading(loading)
  }, [loading])

  const initData = React.useMemo(() => {
    return {}
  }, [agentEvaluation])

  const onReset = () => {
    reset(initData as any)
  }

  React.useEffect(() => {
    checkCertificateUncheck()
    if (!!agentEvaluation) {
      const CandiDate = (
        agentEvaluation.candidate ? JSON.parse(agentEvaluation.candidate ?? '') : ''
      ) as DAagentEvaluationData.Candidate
      const qsCore = (
        agentEvaluation.candidate ? JSON.parse(agentEvaluation.candidate ?? '') : ''
      ) as DAagentEvaluationData.QsCore
      const QualiFied = (
        agentEvaluation.qualified ? JSON.parse(agentEvaluation.qualified ?? '') : ''
      ) as DAagentEvaluationData.Qualified
      reset({
        ...getValues(),
        sumAge: qsCore.sumAge,
        Educations: qsCore.Educations,
        workExperiences: qsCore.workExperiences,
        hasAListOfPotentialCustomers: qsCore.hasAListOfPotentialCustomers,
        total: qsCore.total,
        nameSystem: QualiFied.nameSystem,
        nameApplication: QualiFied.nameApplication,
        DateOfbirthSys: QualiFied.DateOfbirthSys,
        DateOfbirthApp: QualiFied.DateOfbirthApp,
        MOFnumber: QualiFied.MOFnumber,
        MOFdate: QualiFied.MOFdate,
        ExamCode: QualiFied.ExamCode,
        sumAgeCandidate: CandiDate.sumAge,
        qsCoreCheck: agentEvaluation?.qsCoreCheck,
        qualifiedCheck: agentEvaluation?.qualifiedCheck,
        candidateCheck: agentEvaluation?.candidateCheck,
        certificateCheck: agentEvaluation?.certificateCheck
      })
    } else {
      // const mofDate = moment(candidate?.mofDate, 'DD/MM/YYYY')
      // const distanceDay = moment().diff(mofDate, 'days')
      // const eCode = candidate?.examCode
      // const examCut = (eCode?.trim() ?? '').split(' ')
      // const examCode = examCut[0]
      // if (trainee?.result === 'Pass' && candidate?.courseTypeCode === 'OB' && distanceDay <= 90) {
      //   setValue('qualifiedCheck', true)
      // } else {
      //   setValue('qualifiedCheck', false)
      // }
      if (sumAge >= 21 && sumAge <= 60) {
        setValue('candidateCheck', true)
      } else {
        setValue('candidateCheck', false)
      }
      if (count >= 2) {
        setValue('qsCoreCheck', true)
      } else {
        setValue('qsCoreCheck', false)
      }
    }
  }, [agentEvaluation, trainee, sumAge, count, certifiCate, candidate])

  const onSave = () => {
    return pipe(
      ZIO.fromPromise(async () => {
        return await trigger()
      }),

      ZIO.flatMap((valid) => {
        setValue('isSaveAgent', true)
        const AgentEvaluationFormData = getValues() as AgentEvaluationForm.Validated
        if (valid) {
          const qsCore = {
            sumAge: AgentEvaluationFormData.sumAge,
            Educations: AgentEvaluationFormData.Educations,
            hasAListOfPotentialCustomers: AgentEvaluationFormData.hasAListOfPotentialCustomers,
            total: count,
            workExperiences: AgentEvaluationFormData.workExperiences
          }
          const qualified = {
            nameSystem: AgentEvaluationFormData.nameSystem,
            nameApplication: AgentEvaluationFormData.nameApplication,
            DateOfbirthSys: AgentEvaluationFormData.DateOfbirthSys,
            DateOfbirthApp: AgentEvaluationFormData.DateOfbirthApp,
            MOFnumber: AgentEvaluationFormData.MOFnumber,
            MOFdate: AgentEvaluationFormData.MOFdate,
            ExamCode: AgentEvaluationFormData.ExamCode
          }
          const candidate = {
            sumAge: AgentEvaluationFormData.sumAge
          }
          return pipe(
            ZIO.zipPar(AuthService.userInfo, DaAgentService.getAgentEvaluation(props.detail.businessKey ?? '')),
            ZIO.map(([res, agentInfo]) => {
              const data: DaAgentService.AgentEvaluationLogRqData = {
                qsCore: JSON.stringify(qsCore),
                qualified: JSON.stringify(qualified),
                candidate: JSON.stringify(candidate),
                processInstanceId: props.detail.processInstanceId,
                taskId: props.detail?.taskId,
                agentCode: props.detail.agentCode,
                clientId: props.detail?.clientId,
                businessKey: props.detail?.businessKey,
                author: res.email,
                source: props.detail?.source,
                eref: props.detail?.eREF,
                qsCoreCheck: AgentEvaluationFormData.qsCoreCheck,
                qualifiedCheck: AgentEvaluationFormData.qualifiedCheck,
                candidateCheck: AgentEvaluationFormData.candidateCheck,
                certificateCheck: AgentEvaluationFormData.certificateCheck,
                id: agentInfo.data?.id,
                email: res.email,
                saleType: props.detail.salesType,
                status: JSON.stringify(AgentEvaluationFormData.isSaveAgent),
                submissionDate: props.detail.createdDate,
                transactionType: props.detail.transactionType
              }
              return data
            }),
            ZIO.flatMap((data) => {
              return DaAgentService.saveAgentEvaluationLog(data)
            }),
            ZIO.tap((data) => {
              props?.setStatusSave?.(true)
              showToast(t('message:MS100003'), 'success')
              return ZIO.unit
            }),
            ZIO.flatMap((data) => {
              return DaAgentService.getAgentEvaluation(props.detail.businessKey ?? '')
            }),
            ZIO.tapError((e) => {
              return ZIO.unit
            })
          )
        } else {
          return ZIO.succeed({})
        }
      }),
      bindLoader,
      ErrorHandling.run({})
    )
  }
  const checkCertificateUncheck = () => {
    const mofDateCer = moment(certifiCate?.mofDate, 'DD/MM/YYYY')
    const mofDateCerDay = moment().diff(mofDateCer, 'days')
    switch (props.detail.salesType) {
      case SalesType.PTD:
        if (
          props.structureForm.getValues('ref') === 'Y' &&
          certifiCate?.id &&
          certifiCate?.fullName &&
          certifiCate?.dob &&
          mofDateCerDay <= 90 &&
          candidate?.certificateDate !== null &&
          certifiCate?.validflag === 1
        ) {
          return setValue('qualifiedCheck', true)
        } else if (
          props.structureForm.getValues('ref') !== 'Y' &&
          certifiCate?.id &&
          certifiCate?.fullName &&
          certifiCate?.dob &&
          mofDateCerDay <= 90 &&
          certifiCate?.certificateDate !== null &&
          certifiCate?.validflag === 1 &&
          candidate?.firstName &&
          candidate?.lastName &&
          candidate?.birthDay &&
          (candidate?.courseTypeCode === 'OB' ||
            candidate?.courseTypeCode === 'PD OB' ||
            candidate?.courseTypeCode === 'GTD OB')
        ) {
          return setValue('qualifiedCheck', true)
        } else {
          return setValue('qualifiedCheck', false)
        }
      case SalesType.DSR:
        if (
          certifiCate?.id &&
          certifiCate?.fullName &&
          certifiCate?.dob &&
          mofDateCerDay <= 90 &&
          certifiCate?.certificateDate !== null &&
          certifiCate?.validflag === 1 &&
          candidate?.firstName &&
          candidate?.lastName &&
          candidate?.birthDay &&
          (candidate?.courseTypeCode === 'OB' ||
            candidate?.courseTypeCode === 'PD OB' ||
            candidate?.courseTypeCode === 'GTD OB')
        ) {
          return setValue('qualifiedCheck', true)
        } else {
          return setValue('qualifiedCheck', false)
        }
      case SalesType.AGENCY:
        if (
          certifiCate?.id &&
          certifiCate?.fullName &&
          certifiCate?.dob &&
          mofDateCerDay <= 90 &&
          certifiCate?.certificateDate !== null &&
          certifiCate?.validflag === 1 &&
          candidate?.firstName &&
          candidate?.lastName &&
          candidate?.birthDay &&
          (candidate?.courseTypeCode === 'OB' ||
            candidate?.courseTypeCode === 'PD OB' ||
            candidate?.courseTypeCode === 'GTD OB')
        ) {
          return setValue('qualifiedCheck', true)
        } else {
          return setValue('qualifiedCheck', false)
        }
      case SalesType.MILLENNIAL:
        if (
          certifiCate?.id &&
          certifiCate?.fullName &&
          certifiCate?.dob &&
          mofDateCerDay <= 90 &&
          certifiCate?.certificateDate !== null &&
          certifiCate?.validflag === 1 &&
          candidate?.firstName &&
          candidate?.lastName &&
          candidate?.birthDay &&
          (candidate?.courseTypeCode === 'OB' ||
            candidate?.courseTypeCode === 'PD OB' ||
            candidate?.courseTypeCode === 'GTD OB')
        ) {
          return setValue('qualifiedCheck', true)
        } else {
          return setValue('qualifiedCheck', false)
        }
      case SalesType.GALLERIE:
        if (
          certifiCate?.id &&
          certifiCate?.fullName &&
          certifiCate?.dob &&
          mofDateCerDay <= 90 &&
          certifiCate?.certificateDate !== null &&
          certifiCate?.validflag === 1 &&
          candidate?.firstName &&
          candidate?.lastName &&
          candidate?.birthDay &&
          (candidate?.courseTypeCode === 'OB' ||
            candidate?.courseTypeCode === 'PD OB' ||
            candidate?.courseTypeCode === 'GTD OB')
        ) {
          return setValue('qualifiedCheck', true)
        } else {
          return setValue('qualifiedCheck', false)
        }
      default:
        return null
    }
  }
  const checkCertificate = () => {
    const mofDate = moment(candidate?.mofDate, 'DD/MM/YYYY')
    const distanceDay = moment().diff(mofDate, 'days')
    if (
      getValues('certificateCheck') === true &&
      candidate?.firstName &&
      candidate?.lastName &&
      candidate?.birthDay &&
      distanceDay <= 90 &&
      candidate?.courseTypeCode?.toUpperCase() === 'OB ONLINE'
    ) {
      return setValue('qualifiedCheck', true)
    } else if (getValues('certificateCheck') === false) {
      return checkCertificateUncheck()
    } else {
      return setValue('qualifiedCheck', false)
    }
  }

  const agentNumber =
    props.detail.transactionType === TransactionType.AGENT_OFFICE_MOVING ||
    props.detail.transactionType === TransactionType.AGENT_TERMINATION_AG ||
    props.detail.transactionType === TransactionType.AGENT_UNIT_TRANSFER
  const policyNumber = props.detail.transactionType === TransactionType.AGENT_TRANSFER_POLICY
  const checkAvicadStatus = () => {
    if (
      (props.detail.transactionType === TransactionType.AGENT_ONBOARD_NEW &&
        props.detail.suspendReason === 'AVICAD_SUSPEND' &&
        props.detail.unsuspendReason !== 'AVICAD_SUSPEND') ||
      (props.detail.transactionType === TransactionType.AGENT_ONBOARD_REINS &&
        props.detail.suspendReason !== 'AVICAD_SUSPEND' &&
        props.detail.unsuspendReason === 'AVICAD_SUSPEND')
    ) {
      return 'Not Pass'
    } else if (agentNumber || policyNumber) {
      return '-'
    } else {
      return 'Pass'
    }
  }
  return (
    <View style={{ flex: 1 }}>
      <View>
        <Panel
          title={
            <View style={{ flexDirection: 'row' }}>
              <View>
                <Text
                  style={{
                    fontSize: 15,
                    lineHeight: 22,
                    fontWeight: '800',
                    textTransform: 'uppercase',
                    color: '#4F4F4F'
                  }}
                >
                  {t('QSCORE')}
                </Text>
              </View>
              <View
                style={{
                  width: 20,
                  height: 20,
                  justifyContent: 'center',
                  alignItems: 'center',
                  alignSelf: 'center',
                  marginLeft: 40
                }}
              >
                <Controller
                  name="qsCoreCheck"
                  control={props.form.control}
                  render={({ field: { onChange, onBlur, value } }) => (
                    <Checkbox value={value} onBlur={onBlur} onChange={onChange} />
                  )}
                />
              </View>
            </View>
          }
        >
          <View style={{ flex: 1 }}>
            <View
              style={{
                backgroundColor: '#FAFAFA',
                borderRadius: 8,
                borderWidth: 1,
                borderColor: '#D3DCE6',
                marginHorizontal: 16,
                marginVertical: 16
              }}
            >
              <TableContainer
                component={Paper}
                style={{
                  borderRadius: 8,
                  borderWidth: 1
                }}
              >
                <Table stickyHeader>
                  <TableHead>
                    <TableRow style={{ backgroundColor: '#F4F4F4' }}>
                      <TableCell style={{ borderRight: '1px solid #D3DCE6' }} className={classes.tableHeaderStandard}>
                        {t('Standard')}
                      </TableCell>
                      <TableCell className={classes.tableHeaderCell}>{t('Reality')}</TableCell>
                      <TableCell
                        style={{ borderLeft: '1px solid #D3DCE6' }}
                        align={'center'}
                        className={classes.tableHeaderCell}
                      >
                        {t('Evaluatescore')}
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow className={classes.tableHeaderRow}>
                      <TableCell style={{ borderRight: '1px solid #D3DCE6' }} className={classes.tableStandard}>
                        {t('AgeFrom')}
                      </TableCell>
                      <TableCell>
                        {sumAge} {t('YearsOld')}
                      </TableCell>
                      {/* moment(new Date()).valueOf() - moment(props.detail.dob).valueOf() */}
                      <TableCell
                        style={{ borderLeft: '1px solid #D3DCE6' }}
                        className={classes.tableReality}
                        align={'center'}
                      >
                        {qscore.age}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                  <TableBody>
                    <TableRow className={classes.tableHeaderRow}>
                      <TableCell style={{ borderRight: '1px solid #D3DCE6' }} className={classes.tableStandard}>
                        {t('Education')}
                      </TableCell>
                      <TableCell>{i18n.language === 'vi' ? Educations?.nameVN : Educations?.nameEN}</TableCell>
                      <TableCell
                        style={{ borderLeft: '1px solid #D3DCE6' }}
                        className={classes.tableReality}
                        align={'center'}
                      >
                        {qscore.eduacation}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                  <TableBody>
                    <TableRow className={classes.tableHeaderRow}>
                      <TableCell style={{ borderRight: '1px solid #D3DCE6' }} className={classes.tableStandard}>
                        {t('Workexperiences')}
                      </TableCell>
                      {channelDocument.find((item) => item.docID === '20102161' && item.status === 'done') ? (
                        <TableCell> {t('common:Yes')}</TableCell>
                      ) : (
                        <TableCell> {t('common:No')}</TableCell>
                      )}

                      <TableCell
                        style={{ borderLeft: '1px solid #D3DCE6' }}
                        className={classes.tableReality}
                        align={'center'}
                      >
                        {qscore.work}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                  <TableBody>
                    <TableRow className={classes.tableHeaderRow}>
                      <TableCell style={{ borderRight: '1px solid #D3DCE6' }} className={classes.tableStandard}>
                        {t('ListofPotentialCustomer')}
                      </TableCell>
                      <TableCell>
                        {channelDocument.find((item) => item.docID === '20102181' && item.status === 'done')
                          ? t('common:Yes')
                          : t('common:No')}
                      </TableCell>
                      <TableCell
                        style={{ borderLeft: '1px solid #D3DCE6' }}
                        className={classes.tableReality}
                        align={'center'}
                      >
                        {qscore.customer}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                  <TableHead>
                    <TableRow style={{ backgroundColor: '#F4F4F4' }}>
                      <TableCell className={classes.tableHeaderStandard}>
                        {' '}
                        <Text style={{ fontSize: 20, color: '#000000', fontWeight: 'bold' }}>{t('Total')}</Text>
                      </TableCell>
                      <TableCell className={classes.tableHeaderStandard}></TableCell>
                      <TableCell align={'center'} className={classes.tableHeaderCell}>
                        <View>
                          <View
                            style={{
                              width: 60,
                              height: 60,
                              borderRadius: 30,
                              backgroundColor: '#4CAF50',
                              justifyContent: 'center',
                              alignItems: 'center',
                              alignSelf: 'center',
                              alignContent: 'center'
                            }}
                          >
                            <Text style={{ fontSize: 30, color: '#fff', textAlign: 'center', fontWeight: 'bold' }}>
                              {count}
                            </Text>
                          </View>
                        </View>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                </Table>
              </TableContainer>
            </View>
          </View>
        </Panel>
      </View>
      <View>
        <Panel
          title={
            <View style={{ flexDirection: 'row' }}>
              <View style={{ flexDirection: 'row' }}>
                <Text
                  style={{
                    fontSize: 15,
                    lineHeight: 22,
                    fontWeight: '800',
                    textTransform: 'uppercase',
                    color: '#4F4F4F'
                  }}
                >
                  {t('CheckCertificated')}
                </Text>
                <View
                  style={{
                    width: 20,
                    height: 20,
                    justifyContent: 'center',
                    alignItems: 'center',
                    alignSelf: 'center',
                    marginLeft: 40
                  }}
                >
                  <Controller
                    name="certificateCheck"
                    control={props.form.control}
                    render={({ field: { onChange, onBlur, value } }) => (
                      <Checkbox
                        value={value}
                        onBlur={onBlur}
                        onChange={(val) => {
                          onChange(val)
                          checkCertificate()
                        }}
                        // onChange={onChange}
                      />
                    )}
                  />
                </View>
              </View>
            </View>
          }
          titleRight={
            <View style={{ flexDirection: 'row' }}>
              <Text
                style={{
                  fontSize: 15,
                  lineHeight: 22,
                  fontWeight: '800',
                  textTransform: 'uppercase',
                  color: '#4F4F4F',
                  marginLeft: 180
                }}
              >
                {t('Qualifiedtraining')}
              </Text>
              <View
                style={{
                  width: 20,
                  height: 20,
                  justifyContent: 'center',
                  alignItems: 'center',
                  alignSelf: 'center',
                  marginLeft: 40
                }}
              >
                <Controller
                  name="qualifiedCheck"
                  control={props.form.control}
                  render={({ field: { onChange, onBlur, value } }) => (
                    <Checkbox value={value} onBlur={onBlur} onChange={onChange} />
                  )}
                />
              </View>
            </View>
          }
          isExand={true}
        >
          <View style={{ flex: 1 }}>
            <View
              style={{
                backgroundColor: '#FAFAFA',
                borderRadius: 8,
                borderWidth: 1,
                borderColor: '#D3DCE6',
                marginHorizontal: 16,
                marginVertical: 16
              }}
            >
              <TableContainer
                component={Paper}
                style={{
                  borderRadius: 8,
                  borderWidth: 1,
                  maxHeight: 600
                }}
              >
                <Table stickyHeader>
                  <TableHead>
                    <TableRow style={{ backgroundColor: '#F4F4F4' }}>
                      <TableCell
                        style={{ borderRight: '1px solid #D3DCE6' }}
                        className={classes.tableHeaderQualified}
                      ></TableCell>
                      <TableCell
                        style={{ borderRight: '1px solid #D3DCE6' }}
                        align={'center'}
                        className={classes.tableHeaderCell}
                      >
                        {t('OnSystem')}
                      </TableCell>
                      <TableCell align={'center'} className={classes.tableHeaderCell}>
                        {t('OnApplication')}
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow className={classes.tableHeaderRow}>
                      <TableCell style={{ borderRight: '1px solid #D3DCE6' }} className={classes.tableQualified}>
                        {t('Name')}
                      </TableCell>
                      <TableCell style={{ borderRight: '1px solid #D3DCE6' }} align={'center'}>
                        {certifiCate?.fullName ?? ''}
                      </TableCell>
                      <TableCell align={'center'}>
                        {candidate?.firstName ?? ''} {candidate?.lastName ?? ''}
                      </TableCell>
                    </TableRow>
                    <TableRow className={classes.tableHeaderRow}>
                      <TableCell style={{ borderRight: '1px solid #D3DCE6' }} className={classes.tableQualified}>
                        {t('Dateofbirth')}
                      </TableCell>
                      <TableCell style={{ borderRight: '1px solid #D3DCE6' }} align={'center'}>
                        {certifiCate?.dob ?? ''}
                      </TableCell>
                      <TableCell align={'center'}>{candidate?.birthDay ?? ''}</TableCell>
                    </TableRow>
                    <TableRow className={classes.tableMOF}>
                      <TableCell style={{ borderRight: '1px solid #D3DCE6' }} className={classes.tableQualified}>
                        {t('MOFnumber')}
                      </TableCell>
                      <TableCell style={{ borderRight: '1px solid #D3DCE6' }} align={'center'}>
                        {certifiCate?.mofNumber ?? ''}
                      </TableCell>
                      <TableCell style={{ borderRight: '1px solid #D3DCE6' }} align={'center'}>
                        {candidate?.courseTypeCode?.toUpperCase() === 'OB ONLINE'
                          ? 'OB ONLINE'
                          : candidate?.mofNumber ?? ''}
                      </TableCell>
                    </TableRow>
                    <TableRow className={classes.tableMOF}>
                      <TableCell style={{ borderRight: '1px solid #D3DCE6' }} className={classes.tableQualified}>
                        {t('MOFdate')}
                      </TableCell>
                      <TableCell style={{ borderRight: '1px solid #D3DCE6' }} align={'center'}>
                        {certifiCate?.mofDate ?? ''}
                      </TableCell>
                      <TableCell style={{ borderRight: '1px solid #D3DCE6' }} align={'center'}>
                        {candidate?.courseTypeCode?.toUpperCase() === 'OB ONLINE'
                          ? candidate?.qualifiedDate
                          : candidate?.mofDate ?? ''}
                      </TableCell>
                    </TableRow>
                    <TableRow className={classes.tableMOF}>
                      <TableCell style={{ borderRight: '1px solid #D3DCE6' }} className={classes.tableQualified}>
                        {t('Examcode')}
                      </TableCell>
                      <TableCell style={{ borderRight: '1px solid #D3DCE6' }} align={'center'}>
                        {certifiCate?.examCode ?? ''}
                      </TableCell>
                      <TableCell style={{ borderRight: '1px solid #D3DCE6' }} align={'center'}>
                        {candidate?.examCode ?? ''}
                      </TableCell>
                    </TableRow>
                    <TableRow className={classes.tableMOF}>
                      <TableCell style={{ borderRight: '1px solid #D3DCE6' }} className={classes.tableQualified}>
                        {t('Qualifydate')}
                      </TableCell>
                      <TableCell style={{ borderRight: '1px solid #D3DCE6' }} align={'center'}>
                        {certifiCate?.qualifiedDate ?? ''}
                      </TableCell>
                      <TableCell style={{ borderRight: '1px solid #D3DCE6' }} align={'center'}>
                        {candidate?.qualifiedDate ?? ''}
                      </TableCell>
                    </TableRow>
                    <TableRow className={classes.tableMOF}>
                      <TableCell style={{ borderRight: '1px solid #D3DCE6' }} className={classes.tableQualified}>
                        {t('Certificatedate')}
                      </TableCell>
                      <TableCell style={{ borderRight: '1px solid #D3DCE6' }} align={'center'}>
                        {certifiCate?.certificateDate ?? ''}
                      </TableCell>
                      <TableCell style={{ borderRight: '1px solid #D3DCE6' }} align={'center'}>
                        {candidate?.certificateDate ?? ''}
                      </TableCell>
                    </TableRow>
                    <TableRow className={classes.tableMOF}>
                      <TableCell style={{ borderRight: '1px solid #D3DCE6' }} className={classes.tableQualified}>
                        {t('Certificatenumber')}
                      </TableCell>
                      <TableCell style={{ borderRight: '1px solid #D3DCE6' }} align={'center'}>
                        {certifiCate?.certificateNumber ?? ''}
                      </TableCell>
                      <TableCell style={{ borderRight: '1px solid #D3DCE6' }} align={'center'}>
                        {candidate?.certificateNumber ?? ''}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </View>
          </View>
        </Panel>
      </View>
      <View>
        <Panel
          title={
            <View style={{ flexDirection: 'row' }}>
              <Text
                style={{
                  fontSize: 15,
                  lineHeight: 22,
                  fontWeight: '800',
                  textTransform: 'uppercase',
                  color: '#4F4F4F'
                }}
              >
                {t('CandidateAge')}
              </Text>
              <View
                style={{
                  width: 20,
                  height: 20,
                  justifyContent: 'center',
                  alignItems: 'center',
                  alignSelf: 'center',
                  marginLeft: 40
                }}
              >
                <Controller
                  name="candidateCheck"
                  control={props.form.control}
                  render={({ field: { onChange, onBlur, value } }) => (
                    <Checkbox value={value} onBlur={onBlur} onChange={onChange} />
                  )}
                />
              </View>
            </View>
          }
          isExand={true}
        >
          <View style={{ flex: 1 }}>
            <View
              style={{
                backgroundColor: '#FAFAFA',
                borderRadius: 8,
                borderWidth: 1,
                borderColor: '#D3DCE6',
                marginHorizontal: 16,
                marginVertical: 16
              }}
            >
              <TableContainer
                component={Paper}
                style={{
                  borderRadius: 8,
                  borderWidth: 1,
                  maxHeight: 350
                }}
              >
                <Table stickyHeader>
                  <TableHead>
                    <TableRow style={{ backgroundColor: '#F4F4F4' }}>
                      <TableCell style={{ borderRight: '1px solid #D3DCE6' }} className={classes.tableCandidate1}>
                        {t('Age')}
                      </TableCell>
                      <TableCell align={'center'} className={classes.tableCandidate2}>
                        {sumAge} {t('YearsOld')}
                      </TableCell>
                    </TableRow>
                  </TableHead>
                </Table>
              </TableContainer>
            </View>
          </View>
        </Panel>
      </View>
      <View>
        <Panel
          title={
            <View style={{ flexDirection: 'row' }}>
              <Text
                style={{
                  fontSize: 15,
                  lineHeight: 22,
                  fontWeight: '800',
                  textTransform: 'uppercase',
                  color: '#4F4F4F'
                }}
              >
                {t('DistributionService:AvicadStatus')}
              </Text>
            </View>
          }
          isExand={true}
        >
          <View style={{ flex: 1 }}>
            <View
              style={{
                backgroundColor: '#FAFAFA',
                borderRadius: 8,
                borderWidth: 1,
                borderColor: '#D3DCE6',
                marginHorizontal: 16,
                marginVertical: 16
              }}
            >
              <TableContainer
                component={Paper}
                style={{
                  borderRadius: 8,
                  borderWidth: 1,
                  maxHeight: 350
                }}
              >
                <Table stickyHeader>
                  <TableHead>
                    <TableRow style={{ backgroundColor: '#F4F4F4' }}>
                      <TableCell style={{ borderRight: '1px solid #D3DCE6' }} className={classes.tableCandidate1}>
                        {t('DistributionService:AvicadStatus')}
                      </TableCell>
                      <TableCell align={'center'} className={classes.tableCandidate2}>
                        {validateRule?.message ?? ''}
                      </TableCell>
                    </TableRow>
                  </TableHead>
                </Table>
              </TableContainer>
            </View>
          </View>
        </Panel>
      </View>
      {props.isHistory === true ? null : <Footer isLoading={loading} onReset={onReset} onSubmit={onSave} />}

      <Toast
        message={toast.message}
        visible={toast.open}
        type={toast.type}
        onClose={() => setToast({ open: false, message: '', type: undefined })}
      />
    </View>
  )
}
