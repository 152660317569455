/* eslint-disable @nrwl/nx/enforce-module-boundaries */
import { ZIO, Task } from '@mxt/zio'
import { pipe } from 'fp-ts/lib/function'
import * as t from 'io-ts'
import { DAagentEvaluationData } from './da-agentevaluation-data'
import { Maybe, Nullable } from '@mxt/zio/codec'
import { POApi } from '@pulseops/common'
export namespace DaAgentService {
  export const DaAgentEvaluationTypeC = t.type({
    status: Nullable(t.string),
    data: Nullable(
      t.type({
        firstName: Nullable(t.string),
        lastName: Nullable(t.string),
        birthDay: Nullable(t.string),
        gender: Nullable(t.string),
        mofNumber: Nullable(t.string),
        mofDate: Nullable(t.string),
        examCode: Nullable(t.string),
        examPlace: Nullable(t.string),
        identifyPassport: Nullable(t.string),
        courseTypeCode: Nullable(t.string),
        courseTypeName: Nullable(t.string),
        qualifiedDate: Nullable(t.string),
        certificateNumber: Nullable(t.string),
        certificateDate: Nullable(t.string)
      })
    )
  })
  export const DaAgentEvaluationCheckTypeC = t.type({
    status: Nullable(t.string),
    data: Nullable(
      t.type({
        result: Nullable(t.string)
      })
    ),
    oerrorResult: Nullable(
      t.type({
        errorCode: Nullable(t.string),
        errorDescription: Nullable(t.string),
        errorTime: Nullable(t.string),
        oErrorList: Nullable(t.string)
      })
    )
  })

  export const DaCertificateTypeC = t.type({
    status: Nullable(t.string),
    data: Nullable(
      t.type({
        id: Nullable(t.string),
        fullName: Nullable(t.string),
        dob: Nullable(t.string),
        sex: Nullable(t.string),
        idNumber: Nullable(t.string),
        ciNumber: Nullable(t.string),
        examCode: Nullable(t.string),
        examDate: Nullable(t.string),
        examPlace: Nullable(t.string),
        mofNumber: Nullable(t.string),
        mofDate: Nullable(t.string),
        certificateNumber: Nullable(t.string),
        certificateDate: Nullable(t.string),
        validflag: Nullable(t.number),
        userImport: Nullable(t.string),
        importDate: Nullable(t.number),
        qualifiedDate: Nullable(t.number)
      })
    )
  })

  export type DaAgentCheckType = t.TypeOf<typeof DaAgentEvaluationCheckTypeC>
  export type DaAgentType = t.TypeOf<typeof DaAgentEvaluationTypeC>
  export type DaCertificateType = t.TypeOf<typeof DaCertificateTypeC>

  export const AgentEvaluationLogRqData = t.type({
    qsCoreCheck: t.boolean,
    qsCore: Maybe(t.string),
    qualifiedCheck: t.boolean,
    qualified: Maybe(t.string),
    candidateCheck: t.boolean,
    certificateCheck: t.boolean,
    candidate: Maybe(t.string),
    processInstanceId: Maybe(t.string),
    taskId: Maybe(t.string),
    agentCode: Maybe(t.string),
    clientId: Maybe(t.string),
    businessKey: Maybe(t.string),
    author: Maybe(t.string),
    source: Maybe(t.string),
    eref: Maybe(t.string),
    id: Maybe(t.string),
    email: Maybe(t.string),
    saleType: Maybe(t.string),
    status: Maybe(t.string),
    submissionDate: Maybe(t.union([t.string, t.number])),
    transactionType: Maybe(t.string)
  })
  export type AgentEvaluationLogRqData = t.TypeOf<typeof AgentEvaluationLogRqData>
  export const AgentEvaluationLog = t.type({
    processInstanceId: Maybe(t.string),
    taskId: Maybe(t.string),
    agentCode: Maybe(t.string),
    clientId: Maybe(t.string),
    businessKey: Maybe(t.string),
    author: Maybe(t.string),
    createdDate: Maybe(t.string),
    responseStatus: Maybe(
      t.type({
        code: Maybe(t.string),
        message: Maybe(t.string),
        errorMessage: Maybe(t.string)
      })
    ),
    data: Maybe(AgentEvaluationLogRqData),
    eref: Maybe(t.string)
  })

  export type AgentEvaluationLog = t.TypeOf<typeof AgentEvaluationLog>
  export const ValidateRuleLog = t.type({
    suspendCode: Maybe(t.string),
    suspendReason: Maybe(t.string),
    clientError: Maybe(t.string),
    code: Maybe(t.string),
    message: Maybe(t.string),
    errors: Maybe(t.string)
  })

  export type ValidateRuleLog = t.TypeOf<typeof ValidateRuleLog>
  export const checkTraineeQualified = (
    data: DAagentEvaluationData.DAagentEvaluationDataRule
  ): Task<DaAgentCheckType> => {
    return pipe(
      POApi.post('distribution-agents-service/distribution-service/check-trainee-qualified')(
        DaAgentEvaluationCheckTypeC
      )(data),
      ZIO.map((res) => res)
    )
  }
  export const checkCandidateInfo = (data: DAagentEvaluationData.DAagentEvaluationDataRule): Task<DaAgentType> => {
    return pipe(
      POApi.post('distribution-agents-service/distribution-service/get-candidate-info')(DaAgentEvaluationTypeC)(data),
      ZIO.map((res) => res)
    )
  }
  export const saveAgentEvaluationLog = (data: AgentEvaluationLogRqData): Task<AgentEvaluationLog> => {
    return pipe(
      POApi.post('distribution-agents-service/agent-evaluation/save')(AgentEvaluationLog)(data),
      ZIO.map((res) => res)
    )
  }
  export const getAgentEvaluation = (businessKey: string): Task<AgentEvaluationLog> => {
    return pipe(
      POApi.getConfig({ params: { businessKey } })('distribution-agents-service/agent-evaluation/get-by')(
        AgentEvaluationLog
      ),
      ZIO.map((res) => res)
    )
  }
  export const checkCertificateInfo = (
    data: DAagentEvaluationData.DAagentEvaluationDataRule
  ): Task<DaCertificateType> =>
    pipe(
      POApi.post(`distribution-agents-service/certificate/get-candidate-info`)(DaCertificateTypeC)(data),
      ZIO.map((res) => res)
    )
  export const getValidateRule = (idnum: string, idnumOld: string): Task<ValidateRuleLog> => {
    return pipe(
      POApi.getConfig({ params: { idnum, idnumOld } })('distribution-agents-service/avicad/validateRule')(
        ValidateRuleLog
      ),
      ZIO.map((res) => res)
    )
  }
}
