import { Form, Input, Panel } from '@pulseops/common'
import { VeriInquiryComplaintData, VeriPayloadData } from 'libs/common/src/service/model/task-detail/InquiryComplaint'
import React, { useEffect } from 'react'
import { Controller, UseFormReturn } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { StyleSheet, View } from 'react-native'
import { Title } from '../../common'
import { InquiryComplaintForm } from './InquiryComplaintForm'
interface Props {
  // detail: VeriInquiryComplaintData
  form: UseFormReturn<InquiryComplaintForm>
  validateReadOnly: () => boolean
  updatedPayloadData: VeriPayloadData
}

export const CommunicationInfo: React.FC<Props> = ({ form, validateReadOnly, updatedPayloadData }) => {
  const { t, i18n } = useTranslation(['requestInfo', 'roles'])

  const register = Form.register(form)

  const { setValue, control, watch } = form

  // const { submissionData } = detail
  const submissionData = updatedPayloadData.body.attributesExt
  const isDisabled = validateReadOnly()

  useEffect(() => {
    let customerName: string = changeCmt(submissionData.CUSTOMER_CMT)
    const payloadInfo = watch('payloadData')
    setValue('communicationInfo.customerComments', customerName)
    setValue('communicationInfo.responsesToCustomer', submissionData.RES_TO_CUSTOMER)
    // if (payloadInfo && !!payloadInfo.body.attributesExt.CLOSED_INFO) {
    //   setValue('communicationInfo.ClosedInfo', payloadInfo.body.attributesExt.CLOSED_INFO)
    // } else {
    //   setValue('communicationInfo.ClosedInfo', submissionData.CLOSED_INFO ?? '')
    // }
    setValue('communicationInfo.ClosedInfo', submissionData.CLOSED_INFO ?? '')
  }, [submissionData])

  const changeCmt = (initStr: string): string => {
    let cmt: string = initStr
    const PREMIUMCOLLECTION = 'Premium Collection'
    const CLAIMDECISION = 'Claim decision'
    const REQUESTPROCESS = 'Request process'
    const RECOMMENDATION = 'Recommendation'
    const OTHERS = 'Others'
    if (initStr.includes(PREMIUMCOLLECTION)) {
      cmt = initStr.replace(PREMIUMCOLLECTION, translateCmt(PREMIUMCOLLECTION, 'Nộp phí bảo hiểm'))
    } else if (initStr.includes(CLAIMDECISION)) {
      cmt = initStr.replace(CLAIMDECISION, translateCmt(CLAIMDECISION, 'Bồi thường'))
    } else if (initStr.includes(REQUESTPROCESS)) {
      cmt = initStr.replace(REQUESTPROCESS, translateCmt(REQUESTPROCESS, 'Xử lý yêu cầu'))
    } else if (initStr.includes(RECOMMENDATION)) {
      cmt = initStr.replace(RECOMMENDATION, translateCmt(RECOMMENDATION, 'Chất lượng dịch vụ'))
    } else if (initStr.includes(OTHERS)) {
      cmt = initStr.replace(OTHERS, translateCmt(OTHERS, 'Khác'))
    }
    return cmt
  }

  const translateCmt = (enText: string, viText: string) => {
    return i18n.language === 'en' ? enText : viText
  }

  return (
    <View>
      <Title title={t('CommunicationInfo')} />
      <Panel title="">
        <View style={styles.colFull}>
          <Input
            title={t('CustomerComments')}
            {...register('communicationInfo.customerComments')}
            disabled
            maxLength={10000}
            multiline
            numberOfLines={5}
          />
        </View>
        <View style={styles.colFull}>
          <Input
            title={t('ResponseToCustomer')}
            {...register('communicationInfo.responsesToCustomer')}
            disabled
            maxLength={10000}
            multiline
            numberOfLines={5}
          />
        </View>
        <View style={styles.colFull}>
          <Controller
            name="communicationInfo.ClosedInfo"
            rules={{
              required: {
                value: true,
                message: `${t('message:MS020001', { field: t('ClosedInfo') })}`
              }
            }}
            control={control}
            render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
              <Input
                title={t('ClosedInfo')}
                {...register('communicationInfo.ClosedInfo')}
                disabled={isDisabled}
                required
                maxLength={10000}
                multiline
                numberOfLines={5}
                onChange={onChange}
                onBlur={onBlur}
                value={value}
                errorMessage={isDisabled ? '' : error?.message}
              />
            )}
          />
        </View>
      </Panel>
    </View>
  )
}

const styles = StyleSheet.create({
  row: {
    flexDirection: 'row'
  },
  rowMobile: {
    flexDirection: 'column'
  },
  col: {
    width: '33.33333333333%',
    marginBottom: 16,
    paddingHorizontal: 16
  },
  colFull: {
    width: '100%',
    paddingHorizontal: 16,
    marginBottom: 16
  },
  required: {
    color: '#ed1b2e'
  },
  mR5: {
    marginRight: 5
  },
  loadingContainer: {
    flex: 1,
    justifyContent: 'center',
    alignContent: 'center'
  }
})
