import * as React from 'react'
import { View, Text, StyleSheet, TouchableOpacity } from 'react-native'
import { useTranslation } from 'react-i18next'
import _ from 'lodash'
import { Permission, assets } from '@pulseops/common'
import { AdmintoolList, SettingStackParamList } from '../SettingStackParamList'
import { useNavigation } from '@react-navigation/native'
import { DrawerNavigationProp } from '@react-navigation/drawer'
import { CategoryTabInfo, OBCategoryTab } from '@pulseops/outbound'

export const AdmintoolTab = ({ roles }: { roles: string[] }) => {
  const { t } = useTranslation()
  const drawer = useNavigation<DrawerNavigationProp<SettingStackParamList>>()
  const [adminToolTabList, setAdminToolTabList] = React.useState<CategoryTabInfo[]>([])
  const [categoryTabIndex, setCategoryTabIndex] = React.useState(0)

  React.useEffect(() => {
    checkTabViewPermission()
  }, [])

  const checkTabViewPermission = () => {
    const listTabPermission: CategoryTabInfo[] = []
    roles.includes(Permission['AdminToolOBSetting']) && listTabPermission.push({
      label: t('Outbound'),
      key: 'OB'
    })
    roles.includes(Permission['AdminToolIBSetting']) && listTabPermission.push({
      label: t('Inbound'),
      key: 'IB'
    })

    setAdminToolTabList(listTabPermission)
  }

  const SelectionTableConfig = (name: string, screenNavigate: AdmintoolList, role: string) => {
    return (
      roles.includes(role) &&
      <TouchableOpacity
        activeOpacity={0.7}
        style={styles.optionView}
        onPress={() => {
          drawer.navigate(screenNavigate)
        }}
      >
        <Text style={styles.title}>{name}</Text>
        <assets.OBArrowRight />
      </TouchableOpacity>
    )
  }

  const TabsAdmintool = () => {
    return (
      <View style={{ paddingTop: 20 }}>
        <OBCategoryTab
          dataSource={adminToolTabList}
          onChangeTab={(index) => {
            setCategoryTabIndex(index)
          }}
          activeTabIndex={categoryTabIndex}
        ></OBCategoryTab>
      </View>
    )
  }

  const ListTableConfig = () => {
    if (adminToolTabList[categoryTabIndex]?.key === 'OB') {
      return (
        <View style={styles.wrapper}>
          {SelectionTableConfig(t('Outbound:AdminTool:T4S7'), AdmintoolList.ConfigT4S7MaxCaseScreen, Permission.T4S7MaxCaseAssignmentAdminTool)}
          {SelectionTableConfig(t('Outbound:AdminTool:T4S14'), AdmintoolList.ConfigT4S14ManagerTeamScreen, Permission.T4S14ManagerOfTeamAdminTool)}
          {SelectionTableConfig(t('Outbound:AdminTool:T4S15'), AdmintoolList.ConfigT4S15OutboundStaffListScreen, Permission.T4S15OutboundStaffListAdminTool)}
          {SelectionTableConfig(t('Outbound:AdminTool:T4S17'), AdmintoolList.ConfigT4S17TransferTransaction, Permission.T4S17SetupTransferTransactionAdminTool)}
        </View>
      )
    }
    else if (adminToolTabList[categoryTabIndex]?.key === 'IB') {
      return (
        <View style={styles.wrapper}>
          {SelectionTableConfig(t('Outbound:AdminTool:T4S18'), AdmintoolList.ConfigT4S18InboundStaffListScreen, Permission.T4S18InboundStaffListAdminTool)}
        </View>
      )
    }
    else {
      return (
        <View style={styles.wrapper}>
        </View>
      )
    }
  }

  return (
    <View style={styles.container}>
      <Text style={styles.header}>{t('Setting:AdminTool')}</Text>
      {TabsAdmintool()}
      {ListTableConfig()}
      <View style={styles.btnSave}>
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    padding: 30
  },
  header: {
    color: '#000000',
    fontSize: 24,
    fontWeight: 'bold'
  },
  wrapper: {
    marginBottom: 12,
    alignItems: 'center',
    paddingTop: 30,
    paddingLeft: 15
  },
  btnSave: {
    width: 100,
    marginLeft: 'auto',
    marginRight: 'auto'
  },
  option: {
    marginBottom: 12,
    flexDirection: 'row',
    alignItems: 'center',
    paddingVertical: 10,
    paddingHorizontal: 15,
    backgroundColor: '#FFFFFF',
    borderRadius: 8,
    overflow: 'hidden',
    shadowColor: 'rgba(0, 0, 0, 0.05)',
    shadowOffset: {
      width: 0,
      height: 4
    },
    shadowOpacity: 0.32,
    shadowRadius: 5.46,
    elevation: 9
  },
  title: {
    color: '#58647A',
    fontSize: 15,
    flex: 1,
    fontWeight: '500'
  },
  optionView: {
    justifyContent: 'space-between',
    flexDirection: 'row',
    borderWidth: 1,
    borderColor: '#D3DCE6',
    width: '100%',
    alignItems: 'center',
    marginBottom: 12,
    paddingVertical: 10,
    paddingHorizontal: 15,
    // backgroundColor: '#FFFFFF',
    borderRadius: 8,
    overflow: 'hidden',
    shadowColor: 'rgba(0, 0, 0, 0.05)',
    shadowOffset: {
      width: 0,
      height: 4
    },
    shadowOpacity: 0.32,
    shadowRadius: 5.46,
    elevation: 9
  }
})
