import * as api from '../task-detail-api'
import { PaymentMethod } from '../PaymentMethod'
import { TransactionType } from '../TransactionType'
import { SourceType } from '../SourceType'
import { getCoreDetail, getDetail } from './common/payout'
import { PaymentType } from '../PaymentType'
import { Pulse4OpsPayout } from '../task-detail-api/ExcessPremium'
import { InternalPayments } from '../task-detail-api/PaymentData'
import { ClientPolicy } from '../Client'
import { PulseOpsFormat } from '../../../Formatter'
import { ExcessPremiumSubmission } from '../ExcessPremium'
import moment from 'moment'

export interface ExcessPremium {
  tag: TransactionType.EXCESS_PREMIUM
  clientName: string
  excessPremium: number

  totalAmount: number
  methods: PaymentMethod[]
  cashOutList?: api.PaymentData.CashOutList
  branchName?: string
  source?: string
}

export const ExcessPremium = (
  detail: api.ExcessPremium & api.TaskDetailBase,
  clientPolicy: Array<ClientPolicy>,
  poName: string,
  excess: ExcessPremiumSubmission.Detail,
  branchName?: string
): ExcessPremium => {
  switch (detail.source) {
    case SourceType.BANCA:
    case SourceType.PRUGREAT:
    // case SourceType.PRUONLINE:
    case SourceType.PULSE:
    case SourceType.ZALO:
    case SourceType.EKIOSK:
      const p = detail.payload.body
      return {
        tag: TransactionType.EXCESS_PREMIUM,
        clientName: poName,
        excessPremium: p.excessPremiumAmount ? Number(p.excessPremiumAmount) : excess.excessPremiumAmount,
        totalAmount: Number(p.totalPayoutAmount),
        methods: mapMethod(detail),
        cashOutList: undefined
      }
    case SourceType.PRUONLINE:
    case SourceType.PULSE4OPS:
      const coreStatus = getCoreDetail(detail.coreResponseDetail, detail.payload.body.cashOutOptions ?? [])
      const cashOutList =
        detail.payload && detail.payload.body
          ? detail.payload.body.cashOutOptions?.filter(
              (x) =>
                PaymentType.BANKTRANSFER === x.type ||
                PaymentType.PAID_AT_BANK === x.type ||
                PaymentType.CASH_AT_COUNTER === x.type
            )
          : []
      return {
        tag: TransactionType.EXCESS_PREMIUM,
        clientName: poName,
        excessPremium:
          detail.source !== SourceType.PRUONLINE
            ? Number(detail.payload.body.policy.attributesExt.EXCESS_PREMIUM_AMOUNT)
            : +excess.excessPremiumAmount,
        totalAmount: Number(detail.payload.body.policy.attributesExt.TOTAL_PAYOUT_AMOUNT),
        cashOutList: cashOutList,
        branchName: branchName,
        source: detail.source,
        methods:
          detail.payload.body.cashOutOptions?.map((v, i) => {
            const _detail =
              v.type === PaymentType.BANKTRANSFER
                ? // ? `${v.bankAccount?.bankName ? v.bankAccount?.bankName + ' - ' : ''} ${
                  //     v.bankAccount?.accountName + '-' ?? ''
                  //   } ${v.bankAccount?.accountNo || ''}`
                  `${v.bankAccount?.bankName ?? ''} - ${branchName} - ${v.bankAccount?.accountName ?? ''} - ${
                    v.bankAccount?.accountNo ?? ''
                  }`
                : v.type === PaymentType.PAID_AT_BANK
                ? `${v.bankAccount?.bankName ?? ''} - ${branchName} - ${
                    v.attributesExt?.PAYEE_INFO?.payeeName ?? poName
                  } - ${v.attributesExt?.PAYEE_INFO?.idNumber ?? detail.clientNumber} - ${
                    v.attributesExt?.PAYEE_INFO?.issuedDate
                      ? moment(v.attributesExt?.PAYEE_INFO?.issuedDate).format('DD/MM/YYYY')
                      : ''
                  } - ${v.attributesExt?.PAYEE_INFO?.issuedBy ?? ''}
                  `
                : v.type === PaymentType.CASH_AT_COUNTER
                ? `${v.attributesExt?.PVA_CASH_OUT_OPTION_INFO.officeCode ?? ''} - ${
                    v.attributesExt?.PAYEE_INFO?.payeeName ?? poName
                  } - ${v.attributesExt?.PAYEE_INFO?.idNumber ?? detail.clientNumber}`
                : v.type === PaymentType.MOMO
                ? `${v.attributesExt?.PAYEE_INFO?.payeeName} ${v.attributesExt?.PAYEE_INFO?.idNumber} - ${
                    v.attributesExt?.PAYEE_INFO?.phone ?? ''
                  }`
                : v.type && InternalPayments.includes(v.type)
                ? getDetail(v.policy?.policyNo, clientPolicy)
                : getDetail(v.policy?.policyNo, clientPolicy)

            return {
              method: v.type || '-',
              detail: _detail && _detail.length > 1 ? _detail : getDetail(v.policy?.policyNo, clientPolicy),
              amount: v.paymentOption.amount || 0,
              status: coreStatus[i].status,
              note: coreStatus[i].errorMes,
              action: '-',
              bankCode: v.type === PaymentType.BANKTRANSFER ? v.bankAccount?.bankName || '' : undefined
            }
          }) ?? []
      }
  }
}

export const mapMethod = (detail: api.ExcessPremium & api.TaskDetailBase): PaymentMethod[] => {
  const mapPayout = (p: Pulse4OpsPayout[] | undefined | null) =>
    p
      ? p.map((x) => {
          return {
            method: x.payoutOptionType ?? '-',
            detail: getPayoutDetail(x),
            amount: PulseOpsFormat.thousandSepartorReverse(x.amount ?? '0'),
            status: x.status,
            note: '-',
            action: '-'
          }
        })
      : []
  switch (detail.source) {
    case SourceType.BANCA:
    case SourceType.EKIOSK:
    case SourceType.PRUGREAT:
    case SourceType.PRUONLINE:
    case SourceType.PULSE:
    case SourceType.ZALO:
      const p = detail.payload.body
      // return p.policiesReceived ? p.policiesReceived.map(v => MapMethod(v, detail.payload.lasStatus, detail.correlationId)) : []
      return mapPayout(p.payouts)
    case SourceType.PULSE4OPS:
      return []
  }
}

const getPayoutDetail = (payout: Pulse4OpsPayout): string => {
  switch (payout.payoutOptionType) {
    case PaymentType.BANKTRANSFER:
      return `${payout.bankName} - ${payout.bankAccountName} - ${payout.bankAccountNumber}`
    case PaymentType.CASH_AT_BANK:
    case PaymentType.EWALLET:
      return `${payout.bankName} - ${payout.bankAccountName} - ${payout.bankAccountNumber} - ${payout.nationalId}`
    default:
      return `${payout.policyReceivedNum} - ${payout.bankAccountName}`
  }
}
