import { ZIO } from '@mxt/zio'
import {
  AppContext,
  assets,
  AuthService,
  ErrorHandling,
  getLanguage,
  InquiryComplaintService,
  Panel
} from '@pulseops/common'
import { pipe } from 'fp-ts/lib/function'
import { VeriInquiryComplaintData } from 'libs/common/src/service/model/task-detail/InquiryComplaint'
import moment from 'moment'
import React from 'react'
import { UseFormReturn } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { StyleSheet, Text, TouchableOpacity, View } from 'react-native'
import { HeaderTable, Title } from '../../common'
import { DiaryModal } from './DiaryModal'
import { DiaryModalView } from './DiaryModalView'
import { UpdatedPayloadData } from './InquiryComplaint'
import { DiaryForm } from './InquiryComplaintForm'
interface Props {
  detail: VeriInquiryComplaintData
  form: UseFormReturn<DiaryForm>
  onGetDiaryList: (diaries: InquiryComplaintService.VeriIACDiary[]) => void
  diariesSubmit: InquiryComplaintService.VeriIACDiary[]
  isReset: boolean
  validateReadOnly: () => boolean
  isHistory: boolean | null
  isSuspend: boolean
  updatedPayloadData: UpdatedPayloadData
}

export const Diary: React.FC<Props> = ({
  detail,
  form,
  onGetDiaryList,
  diariesSubmit,
  isReset,
  validateReadOnly,
  isHistory,
  isSuspend,
  updatedPayloadData
}) => {
  const { t } = useTranslation(['requestInfo', 'roles'])

  const [diaryList, setDiaryList] = React.useState<InquiryComplaintService.VeriIACDiary[]>([])
  // const [diaryTypeList, setDiaryTypeList] = React.useState<InquiryComplaintService.VeriIACDiaryType[]>([])
  const [userInfo, setUserInfo] = React.useState<{ email: string; name: string }>({
    email: '',
    name: ''
  })

  const [contentView, setContentView] = React.useState<string>('')

  const [isOpenDiaryModal, setIsOpenDiaryModal] = React.useState<boolean>(false)
  const [isOpenDiaryViewModal, setIsOpenDiaryViewModal] = React.useState<boolean>(false)
  // const [isFirstVersion, setIsFirstVersion] = React.useState<boolean>(false)
  const { handleSubmit } = form

  const { showGlobalLoading } = React.useContext(AppContext.AppContextInstance)
  const diaryTypeList = detail.resolutionDataList.diaryTypeList
  pipe(
    AuthService.userInfo,
    ZIO.map((user) => {
      setUserInfo(user)
    }),
    ErrorHandling.runDidUpdate([detail])
  )

  // pipe(InquiryComplaintService.getDiaryTypeList(), ZIO.map(setDiaryTypeList), ErrorHandling.runDidMount({}))

  const getDiaryList = () => {
    if (detail.processInstanceId || (detail.taskId && (isHistory || isSuspend))) {
      const sortData: InquiryComplaintService.VeriSortData = {
        order: 'desc',
        page: 0,
        processInstanceId: detail.processInstanceId ? detail.processInstanceId : detail.taskId,
        size: 100,
        sort: 'createdDate'
      }
      showGlobalLoading(true)
      setDiaryList([])
      pipe(
        InquiryComplaintService.getDiaryList(sortData),
        ZIO.catchAll((_error) => {
          showGlobalLoading(false)
          // setDiaryList([])
          return ZIO.fail(null)
        }),
        ZIO.map((response) => {
          if (response && response.data.length > 0) {
            const diaryList = response.data.map((item) => {
              return {
                ...item,
                createdBy: item.createdBy.toUpperCase(),
                isViewed: false
              }
            })
            setDiaryList(diaryList)
          }
          showGlobalLoading(false)
        }),
        ZIO.unsafeRun({})
      )
    }
  }

  // const getDiaryTypeList = () => pipe(InquiryComplaintService.getDiaryTypeList(), ZIO.map(setDiaryTypeList), ZIO.unsafeRun({}))

  const getDiaryTypeName = (diaryTypeCode: string) => {
    const item = diaryTypeList.find((x) => x.id === diaryTypeCode)
    return getLanguage() === 'en' ? item?.desc_en : item?.desc_vi
  }

  const formatDiaryDate = (createdDate: string) => {
    return createdDate ? moment(new Date(createdDate)).format('DD/MM/YYYY HH:mm') : ''
  }

  React.useEffect(() => {
    if (detail && isReset) {
      getDiaryList()
    }
  }, [isReset])

  React.useEffect(() => {
    let isCalledDiaryList = false
    if (detail && !isCalledDiaryList) {
      getDiaryList()
    }
    // run when component unmount
    return () => {
      isCalledDiaryList = true
    }
  }, [detail.processInstanceId ?? detail.taskId])

  // Use for case to view Diary block in History view screen
  // React.useEffect(() => {
  //   if (updatedPayloadData.isViewHistory && updatedPayloadData.verionNumber === 1) {
  //     setIsFirstVersion(true)
  //   } else {
  //     setIsFirstVersion(false)
  //   }
  // }, [updatedPayloadData])

  const columns = [
    {
      key: '1',
      title: t('DiaryType'),
      name: 'diaryType'
    },
    {
      key: '2',
      title: t('CreatedBy'),
      name: 'createdBy'
    },
    {
      key: '3',
      title: t('Date'),
      name: 'date'
    },
    {
      key: '4',
      title: t('Description'),
      name: 'description'
    }
  ]

  const compareItemsByCreateDate = (item1: string, item2: string) => {
    const temp1 = moment(item1).valueOf()
    const temp2 = moment(item2).valueOf()
    return temp1 > temp2 ? -1 : 1
  }

  const onShowModalAddDiary = () => {
    form.reset()
    setIsOpenDiaryModal(true)
  }

  const showContentView = (item: InquiryComplaintService.VeriIACDiary, index: number) => {
    const customedDiaryArr = diaryList.map((diaryItem, idx) => {
      return {
        ...diaryItem,
        isViewed: diaryItem.isViewed || idx === index
      }
    })
    setDiaryList(customedDiaryArr)
    setContentView(item.description)
    setIsOpenDiaryViewModal(true)
  }

  const onSubmit = handleSubmit(() => {
    const { description, diaryType } = form.getValues()
    const newDiaries: InquiryComplaintService.VeriIACDiary[] = [...diariesSubmit]
    const newDiary: InquiryComplaintService.VeriIACDiary = {
      id: '',
      processInstanceId: detail.processInstanceId,
      createdDate: moment().format(),
      typeCode: diaryType?.value || '',
      description: description,
      createdBy: userInfo.name,
      isViewed: false
    }
    newDiaries.push(newDiary)

    setDiaryList((prevDiary) => [...prevDiary, newDiary])
    setIsOpenDiaryModal(false)
    onGetDiaryList(newDiaries)
  })

  return (
    <View>
      <Title title={t('Diary')} />
      <Panel>
        <HeaderTable
          columns={columns}
          dataSource={diaryList
            .sort((item1, item2) => compareItemsByCreateDate(item1.createdDate, item2.createdDate))
            .map((item: InquiryComplaintService.VeriIACDiary, index) => ({
              diaryType: getDiaryTypeName(item.typeCode) || '-',
              createdBy: item.createdBy,
              date: formatDiaryDate(item.createdDate),
              description: (
                <TouchableOpacity onPress={() => showContentView(item, index)}>
                  <Text style={{ color: item.isViewed ? '#808080' : '#1ea5fc', fontWeight: '300', fontSize: 16 }}>
                    {t('DiaryView')}
                  </Text>
                </TouchableOpacity>
              )
            }))}
        />
        {!validateReadOnly() && (
          <View style={styles.addContainer}>
            <TouchableOpacity activeOpacity={0.7} style={styles.addBtn} onPress={onShowModalAddDiary}>
              <assets.PlusCircleIcon />
              <Text style={styles.addTitle}>{t('Add')}</Text>
            </TouchableOpacity>
          </View>
        )}
      </Panel>
      <DiaryModal
        open={isOpenDiaryModal}
        onClose={() => setIsOpenDiaryModal(false)}
        selectedValue={{}}
        form={form}
        onSubmit={onSubmit}
        diaryTypeList={diaryTypeList}
      />
      <DiaryModalView
        open={isOpenDiaryViewModal}
        onClose={() => setIsOpenDiaryViewModal(false)}
        selectedValue={{}}
        content={contentView}
      />
    </View>
  )
}

const styles = StyleSheet.create({
  addContainer: {
    flexDirection: 'row',
    justifyContent: 'flex-end'
  },

  addBtn: {
    alignItems: 'center',
    flexDirection: 'row',
    marginTop: 16,
    marginRight: 16
  },

  addTitle: {
    fontSize: 15,
    fontWeight: '500',
    marginStart: 10
  }
})
