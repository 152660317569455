import { Maybe, Nullable } from '@mxt/zio/codec'
import * as t from 'io-ts'

export namespace SupplementaryInfo {
  export const Groups = t.type({
    data: Nullable(
      t.array(
        t.type({
          code: t.string,
          nameEn: t.string,
          nameVi: t.string
        })
      )
    )
  })
  export type Groups = t.TypeOf<typeof Groups>

  export const Types = t.type({
    data: t.array(
      t.type({
        code: t.string,
        typeEn: t.string,
        typeVn: t.string,
        timeout: Maybe(t.string),
        description: Maybe(t.array(t.string))
      })
    )
  })
  export type Types = t.TypeOf<typeof Types>

  export const History = t.type({
    createdBy: Nullable(t.string),
    createdDate: Nullable(t.string),
    id: Nullable(t.string),
    modifiedBy: Nullable(t.string),
    modifiedDate: Nullable(t.string),
    pendingLetters: Nullable(
      t.array(
        t.type({
          azureBlobName: Nullable(t.string),
          url: Nullable(t.string)
        })
      )
    ),
    status: Nullable(t.string),
    suspendCode: Nullable(t.string),
    suspendGroupCode: Nullable(t.string),
    suspendGroupEN: t.string,
    suspendGroupVN: t.string,
    suspendType: t.string,
    suspendTypeVN: t.string,
    expiredDate: Maybe(t.string),
    addLackDocs: Nullable(
      t.array(
        t.type({
          azureBlobName: Nullable(t.string),
          processInstanceId: Nullable(t.string),
          receivedBy: Nullable(t.string),
          receivedDate: Nullable(t.string)
        })
      )
    ),
    receivedDate: Nullable(t.string)
  })
  export type History = t.TypeOf<typeof History>

  export const HistoryList = t.type({
    data: t.array(History)
  })
  export type HistoryList = t.TypeOf<typeof HistoryList>

  export const GeneralInfo = t.type({
    suspendGroup: Nullable(t.string),
    suspendCodes: Nullable(t.array(t.string)),
    suspendTypes: Nullable(t.array(t.string)),
    azureBlobName: Nullable(t.string),
    url: Nullable(t.string),
    createdBy: Nullable(t.string),
    createdDate: Maybe(t.string),
    clientId: Nullable(t.string),
    businessKey: Nullable(t.string),
    processInstanceId: Nullable(t.string),
    status: Nullable(t.string),
    letterContents: Nullable(t.array(t.string)),
    docTypeCode: Nullable(t.string),
    transactionType: Nullable(t.string),
    policyNumber: Nullable(t.string),
    policyOwnerName: Nullable(t.string),
    userID: Nullable(t.string),
    unsuspendDate: Nullable(t.string),
    isUploadedToAzure: Nullable(t.boolean),
    isSuspend: Nullable(t.boolean),
    payeeName: Nullable(t.string),
    addresses: Nullable(t.array(t.string)),
    phoneNumber: Nullable(t.string),
    dayTime: Nullable(t.union([t.string, t.number])),
    monthTime: Nullable(t.union([t.string, t.number])),
    yearTime: Nullable(t.union([t.string, t.number])),
    claimNo: Nullable(t.string),
    policyNo: Nullable(t.string),
    assuranceType: Nullable(t.string),
    poName: Nullable(t.string),
    laName: Nullable(t.string),
    time: Nullable(t.string),
    suspendTypesVN: Nullable(t.array(t.string)),
    typeGenerateLetter: Maybe(t.string),
    claimType: Maybe(t.string),
    suspendGroupVN: Nullable(t.string),
    street: Nullable(t.string),
    ward: Nullable(t.string),
    district: Nullable(t.string),
    city: Nullable(t.string),
    locationCode: Nullable(t.string),
    clientNumber: Nullable(t.string),
    claimOwnerEmail: Nullable(t.string),
    claimAntEmail: Nullable(t.string)
  })

  export type GeneralInfo = t.TypeOf<typeof GeneralInfo>

  export const GeneralInfoList = t.type({
    data: t.array(GeneralInfo)
  })
  export type GeneralInfoList = t.TypeOf<typeof GeneralInfoList>

  export const ExpireTime = t.type({
    expiredDate: t.string //"22/06/2021 15:24:52"
  })
  export type ExpireTime = t.TypeOf<typeof ExpireTime>

  export interface SavaData {
    pendingLetters?: string[]
    status: string
    suspendCode: string
    user: string
    expiredDate?: Date
  }

  export interface UpudateData extends SavaData {
    id: string
    suspendGroup?: string
  }

  export interface Save {
    data: SavaData[]
    processInstanceId: string
  }

  export interface UpdateList {
    data: UpudateData[]
    processInstanceId: string
  }
  export interface DataPostPreview {
    transactionType: string
    businessKey: string
    suspendGroup: string
    suspendCodes: string[]
    suspendTypes: string[]
    letterContents: string[]
    policyNumber: string
    policyOwnerName: string
    unsuspendDate: string
    userID: string
    clientId: string
    payeeName?: string
    addresses?: string[]
    phoneNumber?: string
    dayTime?: number
    monthTime?: number
    yearTime?: number
    claimNo?: string
    policyNo?: string
    assuranceType?: string
    isUploadedToAzure?: boolean
    poname?: string
    laname?: string
    time?: string
    suspendTypesVN?: string[]
    officeCode: string
    requestDate?: string
    suspendGroupVN?: string
    street?: string
    ward?: string
    district?: string
    city?: string
    locationCode?: string
    claimOwnerEmail?: string
    claimAntEmail?: string
    exchangeId?: string
    claimantName?: string
    claimantPhone?: string
    claimOwnerPhone?: string
    processInstanceId?: string
  }

  export const CodecDataPostPreview = t.type({
    transactionType: t.string,
    businessKey: t.string,
    suspendGroup: t.string,
    suspendCodes: t.array(t.string),
    suspendTypes: t.array(t.string),
    letterContents: t.array(t.string),
    policyNumber: t.string,
    policyOwnerName: t.string,
    unsuspendDate: t.string,
    userID: t.string,
    clientId: t.string
  })

  export type CodecDataPostPreview = t.TypeOf<typeof CodecDataPostPreview>
}
