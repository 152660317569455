import { ZIO } from '@mxt/zio'
import { useLoading } from '@mxt/zio-react'
import {
  AppContext,
  assets,
  Checkbox,
  ErrorHandling,
  Form,
  Input,
  InquiryComplaintData,
  InquiryComplaintService,
  Panel,
  // RadioButton,
  RadioButtonGroup,
  SelectSearch,
  useMobile
} from '@pulseops/common'
// import { useIsFocused } from '@react-navigation/native'
import { pipe } from 'fp-ts/lib/function'
import { VeriInquiryComplaintData } from 'libs/common/src/service/model/task-detail/InquiryComplaint'
import React, { useEffect, useState } from 'react'
import { Controller, UseFormReturn } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { ActivityIndicator, StyleSheet, Text, View } from 'react-native'
import { Title } from '../../common'
import { UpdatedPayloadData } from './InquiryComplaint'
import { InquiryComplaintForm, ResolutionInfo } from './InquiryComplaintForm'
import { Error } from '@pulseops/submission/common'
interface Props {
  detail: VeriInquiryComplaintData
  form: UseFormReturn<InquiryComplaintForm>
  subTaskCode: string
  isHistory: boolean | null
  isSuspend: boolean
  showLoading: (loading: boolean) => void
  isReset: boolean
  validateReadOnly: () => boolean
  onSubmitInquiry: (isSubmit: boolean) => void
  updatedPayloadData: UpdatedPayloadData
}

type Option = {
  label: string
  value: string
}

type VeriResolutionData = {
  id: string
  agentCode: string
  agentName: string
  amount: string
  attachmentIds: string[]
  changeType: boolean
  complaintAgainstId: string
  correctiveStatementId: string
  createdBy: string
  createdDate: string
  createdLetter: boolean
  isSerious: boolean
  processInstanceId: string
  reasonDealingId: string
  reasonId: string
  resolutionActionId: string
  responseMethodId: string
  reasonExceedingId?: string | undefined | null
  detail?: string | undefined | null
  newGroup: string | undefined | null
  newType: string | null | undefined
  newSubType: string | null | undefined
}

export const Resolution: React.FC<Props> = ({
  detail,
  form,
  isHistory,
  isSuspend,
  subTaskCode,
  showLoading,
  isReset,
  validateReadOnly,
  onSubmitInquiry,
  updatedPayloadData
}) => {
  const { t, i18n } = useTranslation(['requestInfo', 'roles'])
  const { isMobile } = useMobile()
  const [isloading, bindLoader] = useLoading(false)
  const [isAgentLoading, setIsAgentLoading] = useState<boolean>(false)
  // const isFocused = useIsFocused()

  // const [resolutionList, setResolutionList] = useState<InquiryComplaintService.VeriIACDiaryType[]>([])
  // const [responseMethodList, setResponseMethodList] = useState<InquiryComplaintService.VeriIACDiaryType[]>([])
  // const [complaintAgainstList, setComplaintAgainstList] = useState<InquiryComplaintService.VeriIACDiaryType[]>([])
  // const [reasonForDealingList, setReasonForDealingList] = useState<InquiryComplaintService.VeriIACDiaryType[]>([])
  // const [correctiveSettlementList, setCorrectiveSettlementList] = useState<InquiryComplaintService.VeriIACDiaryType[]>(
  //   []
  // )
  // const [reasonList, setReasonList] = useState<InquiryComplaintService.VeriIACDiaryType[]>([])
  // const [reasonForExceedingTAT, setReasonForExceedingTAT] = useState<InquiryComplaintService.VeriIACDiaryType[]>([])

  // const [groupList, setGroupList] = useState<InquiryComplaintData.IACGroup[]>([])
  const [subServiceTypeList, setSubServiceTypeList] = useState<InquiryComplaintData.IACType[]>([])
  const [subTransactionTypeList, setSubTransactionTypeList] = useState<InquiryComplaintData.IACSubtypes[]>([])

  const [disabledDetail, setDisabledDetail] = useState<boolean>(false)
  const [disabledAmount, setDisabledAmount] = useState<boolean>(false)
  const [disabledAgentCode, setDisabledAgentCode] = useState<boolean>(false)

  const [resolutionData, setResolutionData] = useState<VeriResolutionData>()
  // const [oldResolutionData, setOldResolutionData] = useState<ResolutionInfo>()
  const { showGlobalLoading } = React.useContext(AppContext.AppContextInstance)

  const correctiveStatementAmountEnableList: string[] = ['CST05', 'CST06', 'CST07', 'CST08']
  const correctiveStatementDetailEnableList: string[] = ['CST01', 'CST03', 'CST12']
  const complaintAgainsEnabletList: string[] = ['CLA01', 'CLA19', 'CLA20', 'CLA34']

  const register = Form.register(form)

  const {
    setValue,
    clearErrors,
    control,
    watch,
    setError,
    reset,
    getValues,
    formState
    // trigger,
  } = form

  const complaintAgainstList = detail.resolutionDataList.complaintAgainstList
  const resolutionList = detail.resolutionDataList.resolutionList
  const responseMethodList = detail.resolutionDataList.responseMethodList
  const reasonForDealingList = detail.resolutionDataList.reasonForDealingList
  const correctiveSettlementList = detail.resolutionDataList.correctiveSettlementList
  const reasonList = detail.resolutionDataList.reasonList
  const reasonForExceedingTAT = detail.resolutionDataList.reasonForExceedingTAT

  const filterGroupListByCagetory = (groupList: InquiryComplaintData.IACGroup[]) => {
    const itemList = groupList.filter(
      (x) => x.categoryName.toLocaleUpperCase() === detail?.submissionData?.CATEGORY_CODE
    )
    return itemList
  }

  const groupList = filterGroupListByCagetory(detail.groupList)

  useEffect(() => {
    if (detail && isReset && (detail.processInstanceId || detail.taskId) && subTaskCode === 'CH') {
      if (isHistory || isSuspend) {
        getLatestResolutionDetailByProcessInsID(detail.taskId)
      } else {
        getLatestResolutionDetailByProcessInsID(detail.processInstanceId)
      }
    }
  }, [isReset])

  useEffect(() => {
    if (detail && (detail.processInstanceId || detail.taskId) && subTaskCode === 'CH') {
      if (isHistory || isSuspend) {
        getLatestResolutionDetailByProcessInsID(detail.taskId)
      } else {
        getLatestResolutionDetailByProcessInsID(detail.processInstanceId)
      }
    }
  }, [detail.processInstanceId ?? detail.taskId])

  const compareItemsByName = (item1: any, item2: any) => {
    return i18n.language === 'en' ? item1.name.localeCompare(item2.name) : item1.nameVi.localeCompare(item2.nameVi)
  }

  const getLatestResolutionDetailByProcessInsID = (processInstanceId: string) => {
    setResolutionData(undefined)
    // showGlobalLoading(true)
    pipe(
      InquiryComplaintService.getResolutionByProID(processInstanceId),
      ZIO.foldM(
        (error) =>
          ZIO.effect(() => {
            // showGlobalLoading(false)
          }),
        (success) =>
          ZIO.effect(() => {
            if (success) {
              // if (!!success.newGroup) {
              //   getSubTransactionTypeList(success.newType ?? '')
              //   getSubServiceTypeList(success.newGroup)
              // }
              setResolutionData(success)
            }
            // showGlobalLoading(false)
          })
      ),
      bindLoader,
      ErrorHandling.run()
    )
  }

  useEffect(() => {
    console.log('resolutionData1')
    if (!!resolutionData) {
      const complaintAgainst =
        converOptions(complaintAgainstList).find(
          (complaintAgainstItem) => complaintAgainstItem.value === resolutionData?.complaintAgainstId
        ) || null

      const resolution =
        converOptions(resolutionList).find(
          (resolutionItem) => resolutionItem.value === resolutionData?.resolutionActionId
        ) || null

      const responseMethod =
        converOptions(responseMethodList).find(
          (responseMethodItem) => responseMethodItem.value === resolutionData?.responseMethodId
        ) || null

      const reasonDealing =
        converOptions(reasonForDealingList).find(
          (reasonDealingItem) => reasonDealingItem.value === resolutionData?.reasonDealingId
        ) || null

      const correctiveStatement =
        converOptions(correctiveSettlementList).find(
          (correctiveStatementItem) => correctiveStatementItem.value === resolutionData?.correctiveStatementId
        ) || null

      const reason =
        converOptions(reasonList).find((reasonItem) => reasonItem.value === resolutionData?.reasonId) || null

      // console.log('call times in binding data')
      const finalGroup =
        converTypeOptions(groupList).find((groupListItem) => groupListItem.value === resolutionData?.newGroup) || null

      const typeList = detail.subServiceTypeList.filter((item) => item.groupCode === resolutionData?.newGroup)
      const sortTypeList = typeList.slice().sort((item1, item2) => compareItemsByName(item1, item2))
      setSubServiceTypeList(sortTypeList)
      const finalType =
        converTypeOptions(typeList).find(
          (subServiceTypeItem) => subServiceTypeItem.value === resolutionData?.newType
        ) || null

      const subTypeList = detail.subTransactionTypeList.filter((item) => item.typeCode === resolutionData?.newType)
      const tranTypeList = subTypeList.slice().sort((item1, item2) => compareItemsByName(item1, item2))
      setSubTransactionTypeList(tranTypeList)
      const finalSubType =
        converTypeOptions(subTypeList).find(
          (subTransactionTypeItem) => subTransactionTypeItem.value === resolutionData?.newSubType
        ) || null

      const reasonExceeding =
        converOptions(reasonForExceedingTAT).find(
          (reasonExceedingItem) => reasonExceedingItem.value === resolutionData?.reasonExceedingId
        ) || null
      if (checkEnable(resolutionData?.complaintAgainstId, 'ComplaintAgainst')) {
        setDisabledAgentCode(false)
      } else {
        setDisabledAgentCode(true)
      }

      if (checkEnable(resolutionData?.correctiveStatementId, 'Amount')) {
        setDisabledAmount(false)
        // setValue('resolution.amount', resolutionData?.amount || '0')
        reset({
          ...getValues(),
          resolution: {
            ...getValues().resolution,
            amount: resolutionData?.amount || '0'
          }
        })
      } else {
        setDisabledAmount(true)
        // setValue('resolution.amount', '0')
        reset({
          ...getValues(),
          resolution: {
            ...getValues().resolution,
            amount: '0'
          }
        })
      }
      if (checkEnable(resolutionData?.correctiveStatementId, 'Detail')) {
        setDisabledDetail(false)
        // setValue('resolution.detail', resolutionData?.detail ?? '')
        reset({
          ...getValues(),
          resolution: {
            ...getValues().resolution,
            detail: resolutionData?.detail ?? ''
          }
        })
      } else {
        setDisabledDetail(true)
        // setValue('resolution.detail', '')
        reset({
          ...getValues(),
          resolution: {
            ...getValues().resolution,
            detail: ''
          }
        })
      }
      const resolutionItem: ResolutionInfo = {
        ...getValues().resolution,
        complaintAgainst: complaintAgainst,
        agentCode: resolutionData?.agentCode || '',
        agentName: resolutionData?.agentName || '',
        significant: resolutionData?.isSerious || false,
        resolution: resolution,
        responseMethod: responseMethod,
        reasonForDealing: reasonDealing,
        correctiveSettlement: correctiveStatement,
        changetype_Subtype: resolutionData?.changeType ? 'yes' : 'no',
        reason: reason,
        reasonForExceedingTAT: reasonExceeding,
        finalGroup: finalGroup,
        finalType: finalType,
        finalSubType: finalSubType
      }
      reset({
        ...getValues(),
        resolution: {
          ...resolutionItem
        }
      })
      // setOldResolutionData(resolutionItem)
    } else {
      reset({
        ...getValues(),
        resolution: {
          ...getValues().resolution,
          complaintAgainst: null,
          agentCode: '',
          agentName: '',
          significant: false,
          resolution: null,
          responseMethod: null,
          reasonForDealing: null,
          correctiveSettlement: null,
          changetype_Subtype: 'no',
          reason: null,
          reasonForExceedingTAT: null,
          finalGroup: null,
          finalType: null,
          finalSubType: null,
          amount: '',
          detail: ''
        }
      })
    }
  }, [
    isReset,
    resolutionData
    // // isHistory,
    // // isSuspend,
    // // subTaskCode,
    // complaintAgainstList,
    // resolutionList,
    // responseMethodList,
    // reasonForDealingList,
    // correctiveSettlementList,
    // reasonList,
    // reasonForExceedingTAT
    // subServiceTypeList,
    // subTransactionTypeList
  ])

  // Use for case to view Resolution block in History view screen
  // React.useEffect(() => {
  //   if (subTaskCode === 'CH') {
  //     if (updatedPayloadData.isViewHistory && updatedPayloadData.verionNumber === 1) {
  //       reset({
  //         ...getValues(),
  //         resolution: {
  //           complaintAgainst: null,
  //           agentCode: '',
  //           agentName: '',
  //           significant: false,
  //           resolution: null,
  //           responseMethod: null,
  //           reasonForDealing: null,
  //           correctiveSettlement: null,
  //           changetype_Subtype: 'no',
  //           reason: null,
  //           reasonForExceedingTAT: null,
  //           finalGroup: null,
  //           finalType: null,
  //           finalSubType: null,
  //           amount: '',
  //           detail: ''
  //         }
  //       })
  //     } else {
  //       reset({
  //         ...getValues(),
  //         resolution: oldResolutionData
  //       })
  //     }
  //   }
  // }, [updatedPayloadData])

  useEffect(() => {
    showGlobalLoading(isloading)
  }, [bindLoader])

  const showOptionLanguage = () => {
    return i18n.language === 'en' ? 'Select' : 'Chọn'
  }

  const converOptions = (arr: any[]): Option[] => {
    return (
      arr.map((item) => ({
        label: i18n.language === 'en' ? item.desc_en : item.desc_vi,
        value: item.id
      })) || []
    )
  }

  const converTypeOptions = (arr: any[]): Option[] => {
    return (
      arr.map((item) => ({
        label: i18n.language === 'en' ? item.name : item.nameVi,
        value: item.code
      })) || []
    )
  }

  const onComplaintAgainst = (option: any) => {
    if (!!option && checkEnable(option.value, 'ComplaintAgainst')) {
      clearErrors('resolution.agentCode')
      setDisabledAgentCode(false)
    } else {
      setDisabledAgentCode(true)
    }
    setValue('resolution.agentCode', '')
    setValue('resolution.agentName', '')
  }

  const onCorectiveSettlementSelect = (option: any) => {
    if (option && checkEnable(option.value, 'Amount')) {
      setDisabledAmount(false)
    } else {
      setDisabledAmount(true)
      clearErrors('resolution.amount')
      setValue('resolution.amount', '0')
    }
    if (option && checkEnable(option.value, 'Detail')) {
      setDisabledDetail(false)
    } else {
      setDisabledDetail(true)
      setValue('resolution.detail', '')
    }
  }

  const checkEnable = (value: string | undefined, type: string): boolean => {
    if (value && subTaskCode === 'CH') {
      if (type === 'ComplaintAgainst' && complaintAgainsEnabletList.includes(value)) {
        return true
      }
      if (type === 'Detail' && correctiveStatementDetailEnableList.includes(value)) {
        return true
      }
      if (type === 'Amount' && correctiveStatementAmountEnableList.includes(value)) {
        return true
      }
    }
    return false
  }

  const onChangeAgentCode = (e: string) => {
    if (e && e.length === 8) {
      setIsAgentLoading(true)
      pipe(
        InquiryComplaintService.getAgentInfo(e),
        ZIO.map((res) => {
          if (!!res.agentName && res.agentName.trim() !== '') {
            setValue('resolution.agentName', res.agentName)
            // clearErrors('resolution.agentCode')
            setError('resolution.agentCode', { message: '' })
          } else {
            setValue('resolution.agentName', '')
            setError('resolution.agentCode', { message: t('message:MS100005') })
            // setError(
            //   'resolution.agentCode',
            //   {
            //     type: 'focus',
            //     message: t('message:MS100005')
            //   },
            //   { shouldFocus: true }
            // )
          }
        }),
        ZIO.foldM(
          (_) =>
            ZIO.effect(() => {
              setIsAgentLoading(false)
            }),
          (_) =>
            ZIO.effect(() => {
              setIsAgentLoading(false)
            })
        ),
        // bindLoader,
        ErrorHandling.run()
      )
    } else {
      setValue('resolution.agentName', '')
      setError('resolution.agentCode', { message: t('message:MS100005') })
      // setError(
      //   'resolution.agentCode',
      //   {
      //     type: 'focus',
      //     message: t('message:MS100005')
      //   },
      //   { shouldFocus: true }
      // )
    }
  }

  // const getSubServiceTypeList = (groupCode: string) => {
  //   pipe(
  //     InquiryComplaintService.getTypes(groupCode),
  //     ZIO.map((typeList) => {
  //       if (typeList && typeList.length > 0) {
  //         const sortTypeList = typeList.slice().sort((item1, item2) => compareItemsByName(item1, item2))
  //         setSubServiceTypeList(sortTypeList)
  //       }
  //     }),
  //     bindLoader,
  //     ZIO.unsafeRun({})
  //   )
  // }

  // const getSubTransactionTypeList = (typeCode: string) => {
  //   pipe(
  //     InquiryComplaintService.getSubType(typeCode),
  //     ZIO.map((subTypeList) => {
  //       if (subTypeList && subTypeList.length > 0) {
  //         const tranTypeList = subTypeList.slice().sort((item1, item2) => compareItemsByName(item1, item2))
  //         setSubTransactionTypeList(tranTypeList)
  //       }
  //     }),
  //     bindLoader,
  //     ZIO.unsafeRun({})
  //   )
  // }

  const onChangeTypeAndSubType = (type: string) => {
    clearErrors(['resolution.reason'])
    setValue('resolution.reason', null)
    setValue('resolution.finalGroup', null)
    setValue('resolution.finalType', null)
    setSubServiceTypeList([])
    setValue('resolution.finalSubType', null)
    setSubTransactionTypeList([])
  }

  const onChangeFinalType = (typeCode: string, firtTimeFlag: boolean) => {
    // setFirstLoading(true)
    setSubTransactionTypeList([])
    setValue('resolution.finalSubType', null)
    if (typeCode) {
      // showGlobalLoading(true)
      // pipe(
      //   InquiryComplaintService.getSubType(typeCode),
      //   ZIO.map((subTypeList) => {
      //     if (subTypeList && subTypeList.length > 0) {
      //       const tranTypeList = subTypeList.slice().sort((item1, item2) => compareItemsByName(item1, item2))
      //       setSubTransactionTypeList(tranTypeList)
      //     }
      //   }),
      //   ZIO.tap((_) => {
      //     showGlobalLoading(false)
      //     return ZIO.unit
      //   }),
      //   ZIO.tapError((_) => {
      //     showGlobalLoading(false)
      //     return ZIO.unit
      //   }),
      //   ZIO.unsafeRun({})
      // )
      const subTypeList = detail.subTransactionTypeList.filter((item) => item.typeCode === typeCode)
      const tranTypeList = subTypeList.slice().sort((item1, item2) => compareItemsByName(item1, item2))
      setSubTransactionTypeList(tranTypeList)
    } else {
      clearErrors('resolution.finalSubType')
    }
  }

  const changeFinalGroup = (groupCode: string, firstTime: boolean) => {
    // setFirstLoading(true)
    setValue('resolution.finalType', null)
    setSubServiceTypeList([])
    onChangeFinalType('', false)
    if (groupCode) {
      // showGlobalLoading(true)
      // pipe(
      //   InquiryComplaintService.getTypes(groupCode),
      //   ZIO.map((typeList) => {
      //     if (typeList && typeList.length > 0) {
      //       const sortTypeList = typeList.slice().sort((item1, item2) => compareItemsByName(item1, item2))
      //       setSubServiceTypeList(sortTypeList)
      //     }
      //   }),
      //   ZIO.tap((_) => {
      //     showGlobalLoading(false)
      //     return ZIO.unit
      //   }),
      //   ZIO.tapError((_) => {
      //     showGlobalLoading(false)
      //     return ZIO.unit
      //   }),
      //   ZIO.unsafeRun({})
      // )
      const typeList = detail.subServiceTypeList.filter((item) => item.groupCode === groupCode)
      const sortTypeList = typeList.slice().sort((item1, item2) => compareItemsByName(item1, item2))
      setSubServiceTypeList(sortTypeList)
    } else {
      clearErrors('resolution.finalType')
    }
  }

  const isRequired = subTaskCode !== 'CS' && !validateReadOnly()
  const isDisabled = subTaskCode === 'CS' || validateReadOnly()

  return (
    <View>
      <Title title={t('Resolution')} />
      <Panel title="">
        <View style={isMobile ? styles.rowMobile : styles.row}>
          <View style={isMobile ? styles.colFull : styles.col}>
            <Controller
              name="resolution.complaintAgainst"
              control={control}
              rules={{
                required: {
                  value: isRequired,
                  message: `${t('message:MS020009', { field: t('ComplaintAgainst') })}`
                }
              }}
              render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                <SelectSearch
                  popupIcon={<assets.ArrowDownDropdownIcon />}
                  label={t('ComplaintAgainst')}
                  required
                  disabled={isDisabled}
                  options={converOptions(complaintAgainstList)}
                  disableUnderline={!isRequired}
                  placeholder={showOptionLanguage()}
                  onChange={(e) => {
                    onComplaintAgainst(e)
                    onChange(e)
                    onSubmitInquiry(false)
                  }}
                  value={value}
                  onBlur={onBlur}
                  errorMessage={error?.message}
                  key={value?.value}
                  isShowFullText={true}
                />
              )}
            />
          </View>
          <View style={isMobile ? styles.colFull : styles.col}>
            <View style={[styles.row, styles.mB5]}>
              <Text style={[styles.mR5, styles.label]}>{t('Agent')}</Text>
              <Text style={styles.required}>*</Text>
            </View>
            <View style={styles.row}>
              <View style={{ width: '40%' }}>
                <Controller
                  name="resolution.agentCode"
                  control={control}
                  rules={{
                    required: {
                      value: isRequired && !disabledAgentCode,
                      message: `${t('message:MS020001', { field: t('Agent') })}`
                    },
                    maxLength: {
                      value: 8,
                      message: `${t('message:MS100005')}`
                    },
                    minLength: {
                      value: 8,
                      message: `${t('message:MS100005')}`
                    }
                  }}
                  render={({ field: { onBlur, value }, fieldState: { error } }) => (
                    <View style={{ flex: 1, flexDirection: 'column' }}>
                      <View style={{ flexDirection: 'row' }}>
                        <Input
                          onChange={(e) => {
                            const newVal = e.replace(/\D/g, '')
                            form.setValue('resolution.agentCode', newVal)
                            onChangeAgentCode(e)
                            onSubmitInquiry(false)
                          }}
                          onBlur={onBlur}
                          containerStyle={{ width: '80%' }}
                          maxLength={8}
                          value={value}
                          // errorMessage={!isDisabled && !disabledAgentCode && error?.message ? error?.message : ''}
                          disabled={isDisabled || disabledAgentCode}
                        />
                        {isAgentLoading && <ActivityIndicator color="red" size={20} />}
                      </View>
                      <View style={{ flex: 1, marginTop: 5 }}>
                        {!isDisabled && !disabledAgentCode && formState.errors?.resolution?.agentCode?.message && (
                          <Error message={formState.errors?.resolution?.agentCode?.message}></Error>
                        )}
                      </View>
                    </View>
                  )}
                />
              </View>
              <View style={{ width: '60%', paddingTop: 8 }}>
                <Controller
                  name="resolution.agentName"
                  control={control}
                  render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                    <Input
                      onChange={(e) => {
                        onChange(e)
                        onSubmitInquiry(false)
                      }}
                      onBlur={onBlur}
                      value={value}
                      errorMessage={error?.message}
                      disabled
                      multiline
                      numberOfLines={2}
                    />
                  )}
                />
              </View>
            </View>
          </View>

          <View style={isMobile ? styles.colFull : styles.col}>
            <Controller
              name="resolution.significant"
              control={control}
              render={({ field: { onChange, onBlur, value } }) => (
                <Checkbox
                  title={t('Significant')}
                  value={value}
                  onBlur={onBlur}
                  onChange={(e) => {
                    onChange(e)
                    onSubmitInquiry(false)
                  }}
                  disabled={isDisabled}
                />
              )}
            />
          </View>
        </View>

        <View style={isMobile ? styles.rowMobile : styles.row}>
          <View style={isMobile ? styles.colFull : styles.col}>
            <Controller
              name="resolution.resolution"
              rules={{
                required: {
                  value: isRequired,
                  message: `${t('message:MS020009', { field: t('Resolution') })}`
                }
              }}
              control={control}
              render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                <SelectSearch
                  popupIcon={<assets.ArrowDownDropdownIcon />}
                  label={t('Resolution')}
                  required
                  disabled={isDisabled}
                  options={converOptions(resolutionList)}
                  disableUnderline={isDisabled}
                  value={value}
                  onBlur={onBlur}
                  onChange={(e) => {
                    onChange(e)
                    onSubmitInquiry(false)
                  }}
                  placeholder={showOptionLanguage()}
                  errorMessage={error?.message}
                  key={value?.value}
                  isShowFullText={true}
                />
              )}
            />
          </View>
          <View style={isMobile ? styles.colFull : styles.col}>
            <Controller
              name="resolution.responseMethod"
              rules={{
                required: {
                  value: isRequired,
                  message: `${t('message:MS020009', { field: t('ResponseMethod') })}`
                }
              }}
              control={control}
              render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                <SelectSearch
                  popupIcon={<assets.ArrowDownDropdownIcon />}
                  label={t('ResponseMethod')}
                  required
                  disabled={isDisabled}
                  options={converOptions(responseMethodList)}
                  disableUnderline={isDisabled}
                  value={value}
                  onBlur={onBlur}
                  onChange={(e) => {
                    onChange(e)
                    onSubmitInquiry(false)
                  }}
                  placeholder={showOptionLanguage()}
                  errorMessage={error?.message}
                  key={value?.value}
                  isShowFullText={true}
                />
              )}
            />
          </View>
          <View style={isMobile ? styles.colFull : styles.col}>
            <Controller
              name="resolution.reasonForDealing"
              rules={{
                required: {
                  value: isRequired,
                  message: `${t('message:MS020009', { field: t('ReasonForDealing') })}`
                }
              }}
              control={control}
              render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                <SelectSearch
                  popupIcon={<assets.ArrowDownDropdownIcon />}
                  label={t('ReasonForDealing')}
                  required
                  disabled={isDisabled}
                  options={converOptions(reasonForDealingList)}
                  disableUnderline={isDisabled}
                  value={value}
                  onBlur={onBlur}
                  onChange={(e) => {
                    onChange(e)
                    onSubmitInquiry(false)
                  }}
                  placeholder={showOptionLanguage()}
                  errorMessage={error?.message}
                  key={value?.value}
                  isShowFullText={true}
                />
              )}
            />
          </View>
        </View>
        <View style={isMobile ? styles.rowMobile : styles.row}>
          <View style={isMobile ? styles.colFull : styles.col}>
            <Controller
              name="resolution.correctiveSettlement"
              control={control}
              rules={{
                required: {
                  value: isRequired,
                  message: `${t('message:MS020009', { field: t('CorretiveSettlement') })}`
                }
              }}
              render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                <SelectSearch
                  popupIcon={<assets.ArrowDownDropdownIcon />}
                  label={t('CorretiveSettlement')}
                  required
                  disabled={isDisabled}
                  options={converOptions(correctiveSettlementList)}
                  disableUnderline={isDisabled}
                  placeholder={showOptionLanguage()}
                  onChange={(e) => {
                    onCorectiveSettlementSelect(e)
                    onChange(e)
                    onSubmitInquiry(false)
                  }}
                  value={value}
                  onBlur={onBlur}
                  errorMessage={error?.message}
                  key={value?.value}
                  isShowFullText={true}
                />
              )}
            />
          </View>
          <View style={isMobile ? styles.colFull : styles.col}>
            <Input title={t('Detail')} {...register('resolution.detail')} disabled={isDisabled || disabledDetail} />
          </View>
          <View style={isMobile ? styles.colFull : styles.col}>
            <Controller
              name="resolution.amount"
              control={control}
              rules={{
                required: {
                  value: isRequired && !disabledAmount,
                  message: `${t('message:MS020001', { field: t('Amount1') })}`
                }
              }}
              render={({ field: { onBlur, value }, fieldState: { error } }) => (
                <Input
                  title={t('Amount1')}
                  required={!isDisabled && !disabledAmount}
                  onChange={(val: string) => {
                    const newVal = val.replace(/\D/g, '').replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                    form.setValue('resolution.amount', newVal)
                    onSubmitInquiry(false)
                  }}
                  onBlur={onBlur}
                  value={value}
                  errorMessage={error?.message}
                  disabled={isDisabled || disabledAmount}
                />
              )}
            />
          </View>
        </View>

        <View style={isMobile ? styles.rowMobile : styles.row}>
          {/* {watch('resolution.changetype_Subtype') === 'yes' && ( */}
          <View style={isMobile ? styles.colFull : styles.col}>
            <Controller
              name={'resolution.changetype_Subtype'}
              control={control}
              defaultValue="no"
              render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                <RadioButtonGroup
                  title={t('ChangeType&SubType')}
                  colLength={'100%'}
                  required
                  value={value}
                  options={[
                    { id: 'yes', label: t('common:Yes') },
                    { id: 'no', label: t('common:No') }
                  ]}
                  onChange={(e) => {
                    onChangeTypeAndSubType(e)
                    onChange(e)
                    onSubmitInquiry(false)
                  }}
                  onBlur={onBlur}
                  errorMessage={error?.message}
                  disabled={isDisabled}
                />
              )}
            />
          </View>
          {/* )} */}

          <View style={isMobile ? styles.colFull : styles.col}>
            <Controller
              name="resolution.reason"
              rules={{
                required: {
                  value: isRequired && watch('resolution.changetype_Subtype') === 'yes',
                  message: `${t('message:MS020009', { field: t('Reason1') })}`
                }
              }}
              control={control}
              render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                <SelectSearch
                  popupIcon={<assets.ArrowDownDropdownIcon />}
                  label={t('Reason1')}
                  required={!isDisabled && watch('resolution.changetype_Subtype') === 'yes'}
                  disabled={isDisabled || watch('resolution.changetype_Subtype') === 'no'}
                  options={converOptions(reasonList)}
                  disableUnderline={isDisabled || watch('resolution.changetype_Subtype') === 'no'}
                  value={value}
                  onBlur={onBlur}
                  onChange={(e) => {
                    onChange(e)
                    onSubmitInquiry(false)
                  }}
                  placeholder={showOptionLanguage()}
                  errorMessage={error?.message}
                  key={value?.value}
                  isShowFullText={true}
                />
              )}
            />
          </View>
          <View style={isMobile ? styles.colFull : styles.col}>
            <Controller
              control={control}
              name="resolution.reasonForExceedingTAT"
              render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                <SelectSearch
                  popupIcon={<assets.ArrowDownDropdownIcon />}
                  label={t('ReasonForExceedingTAT')}
                  options={converOptions(reasonForExceedingTAT)}
                  disableUnderline={isDisabled}
                  disabled={isDisabled}
                  onChange={(e) => {
                    onChange(e)
                    onSubmitInquiry(false)
                  }}
                  onBlur={onBlur}
                  value={value}
                  placeholder={showOptionLanguage()}
                  errorMessage={error?.message}
                  key={value?.value}
                  isShowFullText={true}
                />
              )}
            />
          </View>
        </View>
        {/* {watch('resolution.changetype_Subtype') === 'yes' &&
          watch('resolution.finalGroup') &&
          watch('resolution.finalType') &&
          watch('resolution.finalSubType') && ( */}
        <View style={isMobile ? styles.rowMobile : styles.row}>
          <View style={isMobile ? styles.colFull : styles.col}>
            <Controller
              control={control}
              name="resolution.finalGroup"
              rules={{
                required: {
                  value: isRequired && watch('resolution.changetype_Subtype') === 'yes',
                  message: `${t('message:MS020009', { field: t('finalGroup') })}`
                }
              }}
              render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                <SelectSearch
                  popupIcon={<assets.ArrowDownDropdownIcon />}
                  label={t('finalGroup')}
                  required={!isDisabled && watch('resolution.changetype_Subtype') === 'yes'}
                  onChange={(e) => {
                    onChange(e)
                    changeFinalGroup(e?.value ?? '', false)
                    onSubmitInquiry(false)
                  }}
                  onBlur={onBlur}
                  value={value}
                  // disabled={true}
                  disabled={isDisabled || watch('resolution.changetype_Subtype') === 'no'}
                  disableUnderline={isDisabled || watch('resolution.changetype_Subtype') === 'no'}
                  options={converTypeOptions(groupList)}
                  placeholder={showOptionLanguage()}
                  errorMessage={isRequired && watch('resolution.changetype_Subtype') === 'yes' ? error?.message : ''}
                  key={value?.value}
                  isShowFullText={true}
                />
              )}
            />
          </View>
          <View style={isMobile ? styles.colFull : styles.col}>
            <Controller
              control={control}
              name="resolution.finalType"
              rules={{
                required: {
                  value: isRequired && watch('resolution.changetype_Subtype') === 'yes',
                  message: `${t('message:MS020009', { field: t('finalType') })}`
                }
              }}
              render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                <SelectSearch
                  popupIcon={<assets.ArrowDownDropdownIcon />}
                  label={t('finalType')}
                  required={!isDisabled && watch('resolution.changetype_Subtype') === 'yes'}
                  onChange={(e) => {
                    onChange(e)
                    onChangeFinalType(e?.value ?? '', false)
                    onSubmitInquiry(false)
                  }}
                  onBlur={onBlur}
                  value={value}
                  // disabled={true}
                  disabled={isDisabled || watch('resolution.changetype_Subtype') === 'no'}
                  disableUnderline={
                    isDisabled ||
                    watch('resolution.changetype_Subtype') === 'no' ||
                    !watch('resolution.finalGroup')?.value
                  }
                  options={converTypeOptions(subServiceTypeList)}
                  placeholder={showOptionLanguage()}
                  errorMessage={isRequired && watch('resolution.changetype_Subtype') === 'yes' ? error?.message : ''}
                  key={value?.value}
                  isShowFullText={true}
                />
              )}
            />
          </View>
          <View style={isMobile ? styles.colFull : styles.col}>
            <Controller
              control={control}
              name="resolution.finalSubType"
              rules={{
                required: {
                  value: isRequired && watch('resolution.changetype_Subtype') === 'yes',
                  message: `${t('message:MS020009', { field: t('finalSubType') })}`
                }
              }}
              render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                <SelectSearch
                  popupIcon={<assets.ArrowDownDropdownIcon />}
                  label={t('finalSubType')}
                  required={!isDisabled && watch('resolution.changetype_Subtype') === 'yes'}
                  onChange={(e) => {
                    onChange(e)
                    onSubmitInquiry(false)
                  }}
                  onBlur={onBlur}
                  value={value}
                  // disabled={true}
                  disabled={isDisabled || watch('resolution.changetype_Subtype') === 'no'}
                  disableUnderline={
                    isDisabled ||
                    watch('resolution.changetype_Subtype') === 'no' ||
                    !watch('resolution.finalType')?.value
                  }
                  options={converTypeOptions(subTransactionTypeList)}
                  placeholder={showOptionLanguage()}
                  errorMessage={isRequired && watch('resolution.changetype_Subtype') === 'yes' ? error?.message : ''}
                  key={value?.value}
                  isShowFullText={true}
                />
              )}
            />
          </View>
        </View>
        {/* )} */}
      </Panel>
    </View>
  )
}

const styles = StyleSheet.create({
  row: {
    flexDirection: 'row'
  },
  rowMobile: {
    flexDirection: 'column'
  },
  col: {
    width: '33.33333333333%',
    marginBottom: 32,
    paddingHorizontal: 16
  },
  colFull: {
    width: '100%',
    paddingHorizontal: 16,
    marginBottom: 32
  },
  label: {
    fontSize: 15,
    lineHeight: 20,
    color: '#70777e',
    fontWeight: 'bold'
  },
  required: {
    color: '#ed1b2e'
  },
  mR5: {
    marginRight: 5
  },
  mB5: {
    marginBottom: 5
  },
  loadingContainer: {
    flex: 1,
    justifyContent: 'center',
    alignContent: 'center'
  }
})
